import React, { memo } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";

import Grid from "./../../components/Grid";

import Cancel from "./../Cancel";
import Csv from "./../Csv";
import Pdf from "./../Pdf";

import ReportService from "./../../services/Report";

const MyReports = ({
  props: { currentUser, usersSearchSelected, periodDateEnd, periodDateStart },
  funcs: { enableLoader, disableLoader }
}) => {
  const { t } = useTranslation();
  return (
    <Grid options={{
      api: new ReportService(),
      actions: [
        {
          content: ({ resetActionsForced, selectedRows }) => <Pdf options={{ close: () => { resetActionsForced(); }, orientation: "portrait", report: JSON.parse(JSON.stringify(selectedRows[0])) }} />,
          render: ({ index, selectedRows, showAction }) => {
            let checkEnabled = false;
            if(selectedRows.length === 1) {
              const report = JSON.parse(JSON.stringify(selectedRows[0]));
              checkEnabled = selectedRows.length === 1 && report.active ? true : false;
            }
            return (
              <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-pdf-portrait-${(Math.random().toString(36).substring(2, 11))}`}>
                <OverlayTrigger overlay={<Tooltip>{t("Title.Pdf.Portrait")}</Tooltip>}>
                  <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}>
                    <i className="fas fa-file-pdf" />
                  </Button>
                </OverlayTrigger>
              </li>
            );
          },
          show: false,
          title: () => t("Title.Pdf.Portrait")
        },
        {
          content: ({ resetActionsForced, selectedRows }) => <Pdf options={{ close: () => { resetActionsForced(); }, orientation: "landscape", report: JSON.parse(JSON.stringify(selectedRows[0])) }} />,
          render: ({ index, selectedRows, showAction }) => {
            let checkEnabled = false;
            if(selectedRows.length === 1) {
              const report = JSON.parse(JSON.stringify(selectedRows[0]));
              checkEnabled = selectedRows.length === 1 && report.active ? true : false;
            }
            return (
              <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-pdf-landscape-${(Math.random().toString(36).substring(2, 11))}`}>
                <OverlayTrigger overlay={<Tooltip>{t("Title.Pdf.Landscape")}</Tooltip>}>
                  <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}>
                    <i className="fas fa-file-pdf" />
                  </Button>
                </OverlayTrigger>
              </li>
            );
          },
          show: false,
          title: () => t("Title.Pdf.Landscape")
        },
        {
          content: ({ resetActionsForced, selectedRows }) => <Csv options={{ close: () => { resetActionsForced(); }, report: JSON.parse(JSON.stringify(selectedRows[0])), delimiter: ",", encoding: "UTF-8"} } />,
          render: ({ index, selectedRows, showAction }) => {
            let checkEnabled = false;
            if(selectedRows.length === 1) {
              const report = JSON.parse(JSON.stringify(selectedRows[0]));
              checkEnabled = selectedRows.length === 1 && report.active ? true : false;
            }
            return (
              <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-pdf-landscape-${(Math.random().toString(36).substring(2, 11))}`}>
                <OverlayTrigger overlay={<Tooltip>{t("Title.CSV")}</Tooltip>}>
                  <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}>
                    <i className="fas fa-file-csv" />
                  </Button>
                </OverlayTrigger>
              </li>
            );
          },
          show: false,
          title: () => t("Title.CSV")
        },
        {
          content: ({ resetActionsForced, selectedRows }) => <Csv options={{ close: () => { resetActionsForced(); }, report: JSON.parse(JSON.stringify(selectedRows[0])), delimiter: ";", encoding: "ISO-8859-1"} } />,
          render: ({ index, selectedRows, showAction }) => {
            let checkEnabled = false;
            if(selectedRows.length === 1) {
              const report = JSON.parse(JSON.stringify(selectedRows[0]));
              checkEnabled = selectedRows.length === 1 && report.active ? true : false;
            }
            return (
              <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-pdf-landscape-${(Math.random().toString(36).substring(2, 11))}`}>
                <OverlayTrigger overlay={<Tooltip>{t("Title.CSV.ISO")}</Tooltip>}>
                  <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}>
                    <i className="fas fa-file-csv" />
                  </Button>
                </OverlayTrigger>
              </li>
            );
          },
          show: false,
          title: () => t("Title.CSV.ISO")
        },
        {
          content: ({ resetActionsForced, selectedRows }) => <Cancel options={{ close: () => { resetActionsForced(); }, report: JSON.parse(JSON.stringify(selectedRows[0])) }} />,
          render: ({ index, selectedRows, showAction }) => {
            let checkEnabled = false;
            if(selectedRows.length === 1) {
              const report = JSON.parse(JSON.stringify(selectedRows[0]));
              checkEnabled = selectedRows.length === 1;
              if (report.active || report.canceled || report.error) checkEnabled = false;
            }
            return (
              <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-pdf-landscape-${(Math.random().toString(36).substring(2, 11))}`}>
                <OverlayTrigger overlay={<Tooltip>{t("Title.MyReports.Cancel")}</Tooltip>}>
                  <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}>
                    <i className="fa-solid fa-ban" />
                  </Button>
                </OverlayTrigger>
              </li>
            );
          },
          show: false,
          title: () => t("Title.MyReports.Cancel")
        }
      ],
      title: t("Title.MyReports")
    }} />
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MyReports));
