import React, { useState } from "react";
import {
  Form,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import Select from "react-select";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import "./index.css";

import { Creators as LastPositionActions } from "./../../store/ducks/lastPositions";
import { isScopes } from "./../../services/Auth";

const nipleTypeOptions = [
  { value: "ALL", label: "Todos" },
  { value: "ELE", label: "Eletrodo" },
  { value: "MEC", label: "Mecânico" },
  { value: "PIE", label: "Piezo" }
];

const LastPositionPatrimonyType = ({
  props: {
    currentUser,
    lastPositionPatrimonyType,
    lastPositionPatrimonyNipleType,
    lastPositionNipleEnabled,
    lastPositionVehicleEnabled
  },
  funcs: {
    setLastPositionPatrimonyType,
    setLastPositionPatrimonyNipleType
  }
}) => {
  const { t } = useTranslation();
  const currentNipleType = nipleTypeOptions.find(x => x.value === lastPositionPatrimonyNipleType);
  return (
    <ul className="last-position-patrimony-type">
      {
        (lastPositionNipleEnabled && (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Patrimony.Type.NipleView")}</Tooltip>}
            trigger={(isMobile || isScopes({ currentUser, scopes: ["is:master"], every: false })) ? null : ["hover", "focus"]}
          >
            <li className={lastPositionPatrimonyType ? "active" : ""} onClick={() => setLastPositionPatrimonyType(true)}>
              <div className={`last-position-patrimony-type-title-box ${isScopes({ currentUser, scopes: ["is:master"], every: false }) ? "" : "full"}`}>{t("Title.Patrimony.Type.Niple")} {isScopes({ currentUser, scopes: ["is:master"], every: false }) ? currentNipleType ? (`(${currentNipleType.label})`) : (`(Todos)`) : ""}</div>
              {
                ((isScopes({ currentUser, scopes: ["is:master"], every: false })) && (
                  <div className="last-position-patrimony-type-select-box">
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      isDisabled={false}
                      onChange={e => setLastPositionPatrimonyNipleType(e.value)}
                      options={nipleTypeOptions}
                      value={nipleTypeOptions.find(x => x.value === lastPositionPatrimonyNipleType)}
                    />
                  </div>
                ))
              }
            </li>
          </OverlayTrigger>
        ))}
      {
        (lastPositionVehicleEnabled && (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Patrimony.Type.VehicleView")}</Tooltip>}
            trigger={(isMobile || isScopes({ currentUser, scopes: ["is:master"], every: false })) ? null : ["hover", "focus"]}
          >
            <li className={!lastPositionPatrimonyType ? "active" : ""} onClick={() => setLastPositionPatrimonyType(false)}>
              { t("Title.Patrimony.Type.Vehicle") }
            </li>
          </OverlayTrigger>
        ))
      }
    </ul>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    lastPositionPatrimonyType: state.lastPositions.positionsSearchQuery.niple,
    lastPositionPatrimonyNipleType: state.lastPositions.positionsSearchQuery.nipleType,
    lastPositionNipleEnabled: state.lastPositions.positionsNipleEnabled,
    lastPositionVehicleEnabled: state.lastPositions.positionsVehicleEnabled
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LastPositionActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastPositionPatrimonyType);
