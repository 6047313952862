import React, { memo, useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  Row
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import "./index.css";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as PatrimoniesActions } from "./../../store/ducks/patrimonies";

import { mergeObject } from "./../../utils/Object";
import { isScopes } from "./../../services/Auth";

import ClientService from "./../../services/Client";
import ClientSchemaReduced from "./../../services/Client/SchemaReduced";

import PatrimonyService from "./../../services/Patrimony";

import FilterPatrimonyItem from "./../FilterPatrimonyItem";
import SwitchDefault from "./../Switch/Default";

const FilterPatrimony = ({
  props: {
    currentUser,
    patrimonies,
    patrimoniesSearch,
    patrimoniesSearchOnlyClient,
    patrimoniesSearchQueryEnabled
  },
  funcs: {
    disableLoader,
    enableLoader,
    searchPatrimonies,
    setPatrimonies,
    setPatrimoniesSelected
  },
  options: {
    filterActive,
    filterMultiple,
    filterNiple,
    filterPump,
    filterPreSelected,
    currentUser: _currentUserAlternative
  }
}) => {
  const { t } = useTranslation();
  const clientService = new ClientService();
  const patrimonyService = new PatrimonyService();

  const [switchAllChecked, setSwitchAllChecked] = useState(false);
  const [switchSingleChecked, setSwitchSingleChecked] = useState(false);
  const [_patrimoniesSelected, _setPatrimoniesSelected] = useState([]);

  const [_patrimoniesSearch, _setPatrimoniesSearch] = useState({
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    word: "",
    active: filterActive ? -1 : true
  });

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") },
    { value: -1, label: t("All") }
  ]);

  const [clientSelectedOption, setClientSelectedOption] = useState(_patrimoniesSearch.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), _patrimoniesSearch.client),
    label: _patrimoniesSearch.client.type === "LEG" ? _patrimoniesSearch.client.legal.socialReason : _patrimoniesSearch.client.personal.name,
    value: _patrimoniesSearch.client.id
  });

  useEffect(() => {
    findPatrimonies();
  }, []); /* eslint-disable-line */

  useEffect(() => {
    findPatrimonies();
  }, [_currentUserAlternative]);

  useEffect(() => {
    searchPatrimonies(_patrimoniesSearch);
  }, [_patrimoniesSearch]); /* eslint-disable-line */

  useEffect(() => {
    if(!switchSingleChecked) {
      if(switchAllChecked) {
        return _setPatrimoniesSelected(patrimoniesSearchOnlyClient);
      }
      return _setPatrimoniesSelected([]);
    }
    setSwitchSingleChecked(false);
  }, [switchAllChecked]); /* eslint-disable-line */

  useEffect(() => {
    setPatrimoniesSelected(_patrimoniesSelected);
    if(_patrimoniesSelected.length > 0 && (patrimoniesSearchOnlyClient.length === _patrimoniesSelected.length)) {
      return setSwitchAllChecked(true);
    }
    setSwitchAllChecked(false);
  }, [_patrimoniesSelected]); /* eslint-disable-line */

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? e.legal.socialReason : e.personal.name,
        value: e.id
      };
    });
  };

  const findPatrimonies = async () => {
    enableLoader();
    const patrimonies = await patrimonyService.findAll({
      data: {
        limit: 1000,
        page: 1,
        search: "",
        patrimonies: _currentUserAlternative ? _currentUserAlternative.patrimonies : undefined,
        clientId: _currentUserAlternative ? _currentUserAlternative.client.id : undefined
      },
      token: currentUser.token
    });
    let patrimoniesFilter = patrimonies.docs;
    if (filterNiple) patrimoniesFilter = patrimoniesFilter.filter((p) => p.automobile && p.automobile.board && p.automobile.board.type === "ATV");
    else if (filterPump) patrimoniesFilter = patrimoniesFilter.filter((p) => p.automobile && p.automobile.board && p.automobile.board.type === "TRU");
    setPatrimonies(patrimoniesFilter);
    if(filterPreSelected) {
      _setPatrimoniesSelected(
        filterPreSelected.length === 0 ? (
          patrimoniesFilter
        ) : (
          patrimoniesFilter.filter(x => filterPreSelected.findIndex(y => y === x.id) !== -1)
        )
      );
    }
    disableLoader();
  };

  const handleClickPatrimony = (e, patrimony) => {
    if(filterMultiple) {
      return handleClickPatrimonyMultiple(e, patrimony);
    }
    return handleClickPatrimonySingle(e, patrimony);
  };

  const handleClickPatrimonyMultiple = (e, patrimony) => {
    const patrimoniesSelected = _patrimoniesSelected.filter(x => x.id !== patrimony.id);
    if(!e) {
      patrimoniesSelected.push(patrimony);
    }
    setSwitchSingleChecked(true);
    _setPatrimoniesSelected(patrimoniesSelected);
  };

  const handleClickPatrimonySingle = (e, patrimony) => {
    const patrimoniesSelected = [patrimony];
    setSwitchSingleChecked(true);
    _setPatrimoniesSelected(patrimoniesSelected);
  };

  return (
    <Card>
      <Card.Header>{t("Title.Patrimony")}</Card.Header>
      <Card.Body>
        <Row>
          {
            isScopes({ currentUser, scopes: [
              "is:master",
              "read:patrimonies:all"
            ], every: false }) ? (
                <>
                  <Col xs={12} md={4}>
                    <Form.Group controlId="filter-patrimony-search-client">
                      <AsyncSelect
                        className="menu-outer-bottom menu-outer-zindex"
                        classNamePrefix="select"
                        cacheOptions
                        defaultOptions
                        isClearable
                        loadOptions={clientLoadOptions}
                        loadingMessage={() => t("React.Select.Wait")}
                        noOptionsMessage={() => t("React.Select.NoOptions")}
                        onChange={e => {
                          if(e === null) {
                            setClientSelectedOption(e);
                            _setPatrimoniesSearch(prevState => ({
                              ...prevState,
                              client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                            }));
                          }
                          else {
                            const { dataAux, label, value } = e;
                            setClientSelectedOption({ dataAux, label, value });
                            _setPatrimoniesSearch(prevState => ({
                              ...prevState,
                              client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                            }));
                          }
                          setSwitchAllChecked(true);
                          setTimeout(() => {
                            setSwitchAllChecked(false);
                          }, 100);
                        }}
                        placeholder={t("React.Select.SelectClient")}
                        value={clientSelectedOption}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group controlId="filter-patrimony-search-patrimony">
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.Search")}
                        autoComplete={"off"}
                        onChange={e => {
                          e.persist();
                          _setPatrimoniesSearch(prevState => ({
                            ...prevState,
                            word: e.target.value
                          }));
                        }}
                        value={_patrimoniesSearch.word}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group controlId="filter-patrimony-search-active">
                      <Select
                        className="menu-outer-bottom"
                        classNamePrefix="select"
                        options={activeOptions}
                        onChange={e => {
                          if(e === null) {
                            _setPatrimoniesSearch(prevState => ({
                              ...prevState,
                              active: -1
                            }));
                          }
                          else {
                            _setPatrimoniesSearch(prevState => ({
                              ...prevState,
                              active: e.value
                            }));
                          }
                          setSwitchAllChecked(true);
                          setTimeout(() => {
                            setSwitchAllChecked(false);
                          }, 100);
                        }}
                        isDisabled={!filterActive}
                        value={activeOptions.find(x => x.value === _patrimoniesSearch.active)}
                      />
                    </Form.Group>
                  </Col>
                </>
              ) : (
                <Col>
                  <Form.Group controlId="filter-patrimony-search-patrimony">
                    <Form.Control
                      type="text"
                      placeholder={t("Placeholder.Search")}
                      autoComplete={"off"}
                      onChange={e => {
                        e.persist();
                        _setPatrimoniesSearch(prevState => ({
                          ...prevState,
                          word: e.target.value
                        }));
                      }}
                      value={_patrimoniesSearch.word}
                    />
                  </Form.Group>
                </Col>
              )
          }
        </Row>
        <Row>
          <Col>
            <div className="filter-patrimony-list-all">
              {
                filterMultiple ? (
                  <div className="filter-patrimony-select-all">
                    <SwitchDefault title={`${t("Title.All")} (${patrimoniesSearchQueryEnabled ? patrimoniesSearch.length : patrimonies.length})`} selected={switchAllChecked} onClick={e => {
                      setSwitchSingleChecked(false);
                      setSwitchAllChecked(!switchAllChecked);
                    }} />
                  </div>
                ) : null
              }
              <div className="filter-patrimony-list-box">
                <ul>
                  {
                    patrimoniesSearchQueryEnabled ? (
                      patrimoniesSearch.map(patrimony => {
                        const selected = _patrimoniesSelected.find(x => x.id === patrimony.id) ? true : false;
                        return (
                          <FilterPatrimonyItem
                            key={`filter-patrimony-list-item-${patrimony.id}`}
                            checkbox={filterMultiple}
                            name={filterMultiple ? `filter-patrimony-list-item-${patrimony.id}` : "filter-patrimony-list-item"}
                            patrimony={patrimony}
                            selected={selected}
                            onClick={e => handleClickPatrimony(selected, patrimony)}
                          />
                        );
                      })
                    ) : (
                      patrimonies.map(patrimony => {
                        const selected = _patrimoniesSelected.find(x => x.id === patrimony.id) ? true : false;
                        return (
                          <FilterPatrimonyItem
                            key={`filter-patrimony-list-item-${patrimony.id}`}
                            checkbox={filterMultiple}
                            name={filterMultiple ? `filter-patrimony-list-item-${patrimony.id}` : "filter-patrimony-list-item"}
                            patrimony={patrimony}
                            selected={selected}
                            onClick={e => handleClickPatrimony(selected, patrimony)}
                          />
                        );
                      })
                    )
                  }
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    patrimonies: state.patrimonies.patrimonies,
    patrimoniesSearch: state.patrimonies.patrimoniesSearch,
    patrimoniesSearchOnlyClient: state.patrimonies.patrimoniesSearchOnlyClient,
    patrimoniesSearchQueryEnabled: state.patrimonies.patrimoniesSearchQueryEnabled
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, PatrimoniesActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(FilterPatrimony));
