export function jsonToForm(json) {
  return json;
}

export function getDefaultOptions() {
  return {
    dateStart: false,
    dateEnd: false,
    filterActive: true,
    filterMultiple: false,
    filterNiple: false,
    filterPatrimony: false,
    filterPreSelected: false,
    filterUser: false,
    layout: true
  };
};

export function getDefaultOptionsPatrimony() {
  const basic = getDefaultOptions();
  return Object.assign({}, basic, {
    filterPatrimony: true
  });
}

export function getDefaultOptionsUser() {
  const basic = getDefaultOptions();
  return Object.assign({}, basic, {
    filterUser: true
  });
}

export function getCronOptions() {
  return [
    { value: "0 * * * * *", label: "A cada minuto" },
    { value: "0 */30 * * * *", label: "A cada trinta minutos" },
    //hours
    { value: "0 0 * * * *", label: "A cada uma hora" },
    { value: "0 0 */12 * * *", label: "A cada doze horas" },
    //days
    { value: "0 0 3 * * *", label: "A cada dia" },
    { value: "0 0 3 */15 * *", label: "A cada quinze dias" },
    //months
    { value: "0 0 3 1 * *", label: "A cada mês" },
    { value: "0 0 3 1 */6 *", label: "A cada seis meses" },
    //years
    { value: "0 0 3 1 1 *", label: "A cada ano" },
    //every day in time
    { value: "0 0 0 * * *", label: "Todos os dias às 0h" },
    { value: "0 30 0 * * *", label: "Todos os dias às 0h30m" },
    //
    { value: "0 0 1 * * *", label: "Todos os dias às 1h" },
    { value: "0 30 2 * * *", label: "Todos os dias às 1h30m" },
    //
    { value: "0 0 2 * * *", label: "Todos os dias às 2h" },
    { value: "0 30 2 * * *", label: "Todos os dias às 2h30m" },
    //
    { value: "0 0 3 * * *", label: "Todos os dias às 3h" },
    { value: "0 30 4 * * *", label: "Todos os dias às 3h30m" },
    //
    { value: "0 0 4 * * *", label: "Todos os dias às 4h" },
    { value: "0 30 4 * * *", label: "Todos os dias às 4h30m" },
    //
    { value: "0 0 5 * * *", label: "Todos os dias às 5h" },
    { value: "0 30 5 * * *", label: "Todos os dias às 5h30m" },
    //
    { value: "0 0 6 * * *", label: "Todos os dias às 6h" },
    { value: "0 30 6 * * *", label: "Todos os dias às 6h30m" },
    //
    { value: "0 0 7 * * *", label: "Todos os dias às 7h" },
    { value: "0 30 7 * * *", label: "Todos os dias às 7h30m" },
    //
    { value: "0 0 8 * * *", label: "Todos os dias às 8h" },
    { value: "0 30 8 * * *", label: "Todos os dias às 8h30m" },
    //
    { value: "0 0 9 * * *", label: "Todos os dias às 9h" },
    { value: "0 30 9 * * *", label: "Todos os dias às 9h30m" },
    //
    { value: "0 0 10 * * *", label: "Todos os dias às 10h" },
    { value: "0 30 10 * * *", label: "Todos os dias às 10h30m" },
    //
    { value: "0 0 11 * * *", label: "Todos os dias às 11h" },
    { value: "0 30 11 * * *", label: "Todos os dias às 11h30m" },
    //
    { value: "0 0 12 * * *", label: "Todos os dias às 12h" },
    { value: "0 30 12 * * *", label: "Todos os dias às 12h30m" },
    //
    { value: "0 0 13 * * *", label: "Todos os dias às 13h" },
    { value: "0 30 13 * * *", label: "Todos os dias às 13h30m" },
    //
    { value: "0 0 14 * * *", label: "Todos os dias às 14h" },
    { value: "0 30 14 * * *", label: "Todos os dias às 14h30m" },
    //
    { value: "0 0 15 * * *", label: "Todos os dias às 15h" },
    { value: "0 30 15 * * *", label: "Todos os dias às 15h30m" },
    //
    { value: "0 0 16 * * *", label: "Todos os dias às 16h" },
    { value: "0 30 16 * * *", label: "Todos os dias às 16h30m" },
    //
    { value: "0 0 17 * * *", label: "Todos os dias às 17h" },
    { value: "0 30 17 * * *", label: "Todos os dias às 17h30m" },
    //
    { value: "0 0 18 * * *", label: "Todos os dias às 18h" },
    { value: "0 30 18 * * *", label: "Todos os dias às 18h30m" },
    //
    { value: "0 0 19 * * *", label: "Todos os dias às 19h" },
    { value: "0 30 19 * * *", label: "Todos os dias às 19h30m" },
    //
    { value: "0 0 20 * * *", label: "Todos os dias às 20h" },
    { value: "0 30 20 * * *", label: "Todos os dias às 20h30m" },
    //
    { value: "0 0 21 * * *", label: "Todos os dias às 21h" },
    { value: "0 30 21 * * *", label: "Todos os dias às 21h30m" },
    //
    { value: "0 0 22 * * *", label: "Todos os dias às 22h" },
    { value: "0 30 22 * * *", label: "Todos os dias às 22h30m" },
    //
    { value: "0 0 23 * * *", label: "Todos os dias às 23h" },
    { value: "0 30 23 * * *", label: "Todos os dias às 23h30m" },
    //every day in time
    { value: "0 0 0 * * 1-5", label: "Segunda à sexta às 0h" },
    { value: "0 30 0 * * 1-5", label: "Segunda à sexta às 0h30m" },
    //
    { value: "0 0 1 * * 1-5", label: "Segunda à sexta às 1h" },
    { value: "0 30 1 * * 1-5", label: "Segunda à sexta às 1h30m" },
    //
    { value: "0 0 2 * * 1-5", label: "Segunda à sexta às 2h" },
    { value: "0 30 2 * * 1-5", label: "Segunda à sexta às 2h30m" },
    //
    { value: "0 0 3 * * 1-5", label: "Segunda à sexta às 3h" },
    { value: "0 30 3 * * 1-5", label: "Segunda à sexta às 3h30m" },
    //
    { value: "0 0 4 * * 1-5", label: "Segunda à sexta às 4h" },
    { value: "0 30 4 * * 1-5", label: "Segunda à sexta às 4h30m" },
    //
    { value: "0 0 5 * * 1-5", label: "Segunda à sexta às 5h" },
    { value: "0 30 5 * * 1-5", label: "Segunda à sexta às 5h30m" },
    //
    { value: "0 0 6 * * 1-5", label: "Segunda à sexta às 6h" },
    { value: "0 30 6 * * 1-5", label: "Segunda à sexta às 6h30m" },
    //
    { value: "0 0 7 * * 1-5", label: "Segunda à sexta às 7h" },
    { value: "0 30 7 * * 1-5", label: "Segunda à sexta às 7h30m" },
    //
    { value: "0 0 8 * * 1-5", label: "Segunda à sexta às 8h" },
    { value: "0 30 8 * * 1-5", label: "Segunda à sexta às 8h30m" },
    //
    { value: "0 0 9 * * 1-5", label: "Segunda à sexta às 9h" },
    { value: "0 30 9 * * 1-5", label: "Segunda à sexta às 9h30m" },
    //
    { value: "0 0 10 * * 1-5", label: "Segunda à sexta às 10h" },
    { value: "0 30 10 * * 1-5", label: "Segunda à sexta às 10h30m" },
    //
    { value: "0 0 11 * * 1-5", label: "Segunda à sexta às 11h" },
    { value: "0 30 11 * * 1-5", label: "Segunda à sexta às 11h30m" },
    //
    { value: "0 0 12 * * 1-5", label: "Segunda à sexta às 12h" },
    { value: "0 30 12 * * 1-5", label: "Segunda à sexta às 12h30m" },
    //
    { value: "0 0 13 * * 1-5", label: "Segunda à sexta às 13h" },
    { value: "0 30 13 * * 1-5", label: "Segunda à sexta às 13h30m" },
    //
    { value: "0 0 14 * * 1-5", label: "Segunda à sexta às 14h" },
    { value: "0 30 14 * * 1-5", label: "Segunda à sexta às 14h30m" },
    //
    { value: "0 0 15 * * 1-5", label: "Segunda à sexta às 15h" },
    { value: "0 30 15 * * 1-5", label: "Segunda à sexta às 15h30m" },
    //
    { value: "0 0 16 * * 1-5", label: "Segunda à sexta às 16h" },
    { value: "0 30 16 * * 1-5", label: "Segunda à sexta às 16h30m" },
    //
    { value: "0 0 17 * * 1-5", label: "Segunda à sexta às 17h" },
    { value: "0 30 17 * * 1-5", label: "Segunda à sexta às 17h30m" },
    //
    { value: "0 0 18 * * 1-5", label: "Segunda à sexta às 18h" },
    { value: "0 30 18 * * 1-5", label: "Segunda à sexta às 18h30m" },
    //
    { value: "0 0 19 * * 1-5", label: "Segunda à sexta às 19h" },
    { value: "0 30 19 * * 1-5", label: "Segunda à sexta às 19h30m" },
    //
    { value: "0 0 20 * * 1-5", label: "Segunda à sexta às 20h" },
    { value: "0 30 20 * * 1-5", label: "Segunda à sexta às 20h30m" },
    //
    { value: "0 0 21 * * 1-5", label: "Segunda à sexta às 21h" },
    { value: "0 30 21 * * 1-5", label: "Segunda à sexta às 21h30m" },
    //
    { value: "0 0 22 * * 1-5", label: "Segunda à sexta às 22h" },
    { value: "0 30 22 * * 1-5", label: "Segunda à sexta às 22h30m" },
    //
    { value: "0 0 23 * * 1-5", label: "Segunda à sexta às 23h" },
    { value: "0 30 23 * * 1-5", label: "Segunda à sexta às 23h30m" },
    //every day in time
    { value: "0 0 0 * * 0,6", label: "Finais de semana às 0h" },
    { value: "0 30 0 * * 0,6", label: "Finais de semana às 0h30m" },
    //
    { value: "0 0 1 * * 0,6", label: "Finais de semana às 1h" },
    { value: "0 30 1 * * 0,6", label: "Finais de semana às 1h30m" },
    //
    { value: "0 0 2 * * 0,6", label: "Finais de semana às 2h" },
    { value: "0 30 2 * * 0,6", label: "Finais de semana às 2h30m" },
    //
    { value: "0 0 3 * * 0,6", label: "Finais de semana às 3h" },
    { value: "0 30 3 * * 0,6", label: "Finais de semana às 3h30m" },
    //
    { value: "0 0 4 * * 0,6", label: "Finais de semana às 4h" },
    { value: "0 30 4 * * 0,6", label: "Finais de semana às 4h30m" },
    //
    { value: "0 0 5 * * 0,6", label: "Finais de semana às 5h" },
    { value: "0 30 5 * * 0,6", label: "Finais de semana às 5h30m" },
    //
    { value: "0 0 6 * * 0,6", label: "Finais de semana às 6h" },
    { value: "0 30 6 * * 0,6", label: "Finais de semana às 6h30m" },
    //
    { value: "0 0 7 * * 0,6", label: "Finais de semana às 7h" },
    { value: "0 30 7 * * 0,6", label: "Finais de semana às 7h30m" },
    //
    { value: "0 0 8 * * 0,6", label: "Finais de semana às 8h" },
    { value: "0 30 8 * * 0,6", label: "Finais de semana às 8h30m" },
    //
    { value: "0 0 9 * * 0,6", label: "Finais de semana às 9h" },
    { value: "0 30 9 * * 0,6", label: "Finais de semana às 9h30m" },
    //
    { value: "0 0 10 * * 0,6", label: "Finais de semana às 10h" },
    { value: "0 30 10 * * 0,6", label: "Finais de semana às 10h30m" },
    //
    { value: "0 0 11 * * 0,6", label: "Finais de semana às 11h" },
    { value: "0 30 11 * * 0,6", label: "Finais de semana às 11h30m" },
    //
    { value: "0 0 12 * * 0,6", label: "Finais de semana às 12h" },
    { value: "0 30 12 * * 0,6", label: "Finais de semana às 12h30m" },
    //
    { value: "0 0 13 * * 0,6", label: "Finais de semana às 13h" },
    { value: "0 30 13 * * 0,6", label: "Finais de semana às 13h30m" },
    //
    { value: "0 0 14 * * 0,6", label: "Finais de semana às 14h" },
    { value: "0 30 14 * * 0,6", label: "Finais de semana às 14h30m" },
    //
    { value: "0 0 15 * * 0,6", label: "Finais de semana às 15h" },
    { value: "0 30 15 * * 0,6", label: "Finais de semana às 15h30m" },
    //
    { value: "0 0 16 * * 0,6", label: "Finais de semana às 16h" },
    { value: "0 30 16 * * 0,6", label: "Finais de semana às 16h30m" },
    //
    { value: "0 0 17 * * 0,6", label: "Finais de semana às 17h" },
    { value: "0 30 17 * * 0,6", label: "Finais de semana às 17h30m" },
    //
    { value: "0 0 18 * * 0,6", label: "Finais de semana às 18h" },
    { value: "0 30 18 * * 0,6", label: "Finais de semana às 18h30m" },
    //
    { value: "0 0 19 * * 0,6", label: "Finais de semana às 19h" },
    { value: "0 30 19 * * 0,6", label: "Finais de semana às 19h30m" },
    //
    { value: "0 0 20 * * 0,6", label: "Finais de semana às 20h" },
    { value: "0 30 20 * * 0,6", label: "Finais de semana às 20h30m" },
    //
    { value: "0 0 21 * * 0,6", label: "Finais de semana às 21h" },
    { value: "0 30 21 * * 0,6", label: "Finais de semana às 21h30m" },
    //
    { value: "0 0 22 * * 0,6", label: "Finais de semana às 22h" },
    { value: "0 30 22 * * 0,6", label: "Finais de semana às 22h30m" },
    //
    { value: "0 0 23 * * 0,6", label: "Finais de semana às 23h" },
    { value: "0 30 23 * * 0,6", label: "Finais de semana às 23h30m" },
  ];
}

export function getCronName(cronString) {
  const crons = getCronOptions();
  const cronItem = crons.find(cron => cron.value === cronString);
  if (cronItem) return cronItem;
  return null;
}

export function getCommadName(commandString) {
  const commands = [
    { value: "AACT", label: "Ancorar" },
    { value: "ADIS", label: "Desancorar" },
    { value: "BACT", label: "Bloqueio" },
    { value: "BDIS", label: "Desbloqueio" }
  ];
  const commandItem = commands.find(command => command.value === commandString);
  if (commandItem) return commandItem;
  return null;
}
