import ClientSchemaReduced, { schemaDate as schemaDateClient } from "../Client/SchemaReducedTitle";

const schema = {
  active: "Status",
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  createdAt: "Criado em",
  global: "Global",
  id: "Id",
  location: {
    type: "Tipo de área",
    coordinates: "Coordenadas da área"
  },
  name: "Nome",
  range: "Raio",
  type: "Tipo",
  updatedAt: "Atualizado em"
};

export const schemaDate = {
  createdAt: "datetime",
  client: JSON.parse(JSON.stringify(schemaDateClient)),
  updatedAt: "datetime"
};

export default schema;
