import React, { useState, useEffect } from "react";
import {
  OverlayTrigger,
  Tabs,
  Tab,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import "./index.css";

import { isScopes } from "./../../services/Auth";

import { Creators as MenusActions } from "./../../store/ducks/menus";
import { Creators as LastPositionsProblemsActions } from "./../../store/ducks/lastPositionsProblems";

import LastDateCorrectionList from "./../LastDateCorrectionList";
import LastDateCorrectionSearch from "./../LastDateCorrectionSearch";
import LastDelayedLocationList from "./../LastDelayedLocationList";
import LastDelayedLocationSearch from "./../LastDelayedLocationSearch";
import LastExporterErrorList from "./../LastExporterErrorList";
import LastExporterErrorSearch from "./../LastExporterErrorSearch";
import LastNotificationList from "./../LastNotificationList";
import LastNotificationSearch from "./../LastNotificationSearch";
import LastPositionAction from "./../LastPositionAction";
import LastPositionList from "./../LastPositionList";
import LastPositionPatrimonyType from "./../LastPositionPatrimonyType";
import LastPositionSearch from "./../LastPositionSearch";

import LastPositionListProblems from "../LastPositionListProblems";
import LastPositionPatrimonyTypeProblems from "../LastPositionPatrimonyTypeProblems";
import LastPositionSearchProblems from "../LastPositionSearchProblems";

import VirtualFenceList from "./../VirtualFenceList";
import VirtualFenceSearch from "../VirtualFenceSearch";

import { getCount } from "./../../utils/Generic";

const Menu = ({
  props: {
    currentUser,
    lastDateCorrections,
    lastDateCorrectionsSearch,
    lastDateCorrectionsSearchQueryEnabled,
    lastDelayedLocations,
    lastDelayedLocationsSearch,
    lastDelayedLocationsSearchQueryEnabled,
    lastExporterErrors,
    lastExporterErrorsSearch,
    lastExporterErrorsSearchQueryEnabled,
    lastExporterErrorSelected,
    lastNotifications,
    lastNotificationsSearch,
    lastNotificationsSearchQueryEnabled,

    lastPositions,
    lastPositionsSearch,
    lastPositionsSearchQueryEnabled,
    lastPositionSelected,


    lastPositionsProblems,
    lastPositionsSearchProblems,
    lastPositionsSearchQueryEnabledProblems,
    lastPositionSelectedProblems,
    // lastPositionProblem,
    menu,
    singleRoute,
    virtualFences,
    virtualFencesLoad,
    virtualFencesSearch,
    virtualFencesSearchQueryEnabled
  },
  funcs: {
    menuHandleActionType,
    menuHandleClose,
    menuHandleOpen,
    menuHandleToggle,

    setLastPositionShowProblems
  }
}) => {
  const { t } = useTranslation();
  const [toggledSelect, setToggledSelect] = useState(false);

  const handleMenuClick = (e) => {
    e.preventDefault();
    menuHandleToggle();
  };

  const handleOpen = () => {
    menuHandleOpen();
  };

  useEffect(() => {
    if(lastPositionSelected && !toggledSelect) {
      setTimeout(handleOpen, 100);
      setToggledSelect(true);
    }
    else if(!lastPositionSelected && toggledSelect) {
      setToggledSelect(false);
    }
  }, [lastPositionSelected, toggledSelect]); /* eslint-disable-line */

  const arrMenus = [
    {
      count: lastPositionsSearchQueryEnabled ? lastPositionsSearch.length : lastPositions.length,
      id: "positions",
      icon: <i className="fas fa-map-marker-alt" />,
      render: (id, key) => {
        return (
          <Tab tabClassName={`${menu.actionType === id ? "" : "hide"}`} eventKey="positions" title={<span>{t("Title.Positions")} ({lastPositionsSearchQueryEnabled ? (lastPositionSelected ? `${(lastPositionsSearch.findIndex(x => x.id === lastPositionSelected.id) + 1)} de ${lastPositionsSearch.length}` : lastPositionsSearch.length) : (lastPositionSelected ? `${(lastPositions.findIndex(x => x.id === lastPositionSelected.id) + 1)} de ${lastPositions.length}` : lastPositions.length)})</span>} key={key}>
            <div className={`menu-positions-item-search ${isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:all"], every: false }) ? "menu-positions-item-search-client" : ""} ${lastPositionSelected ? "hide" : ""}`}>
              <LastPositionSearch />
            </div>
            <div className={`menu-positions-item-patrimony-type ${lastPositionSelected ? "hide" : ""}`}>
              <LastPositionPatrimonyType />
            </div>
            <div className={`menu-positions-item-box ${isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:all"], every: false }) ? "menu-positions-item-box-client" : ""} ${lastPositionSelected ? "full-size" : ""}`}>
              <LastPositionList />
            </div>
            <div className={"menu-positions-item-action"}>
              <LastPositionAction />
            </div>
          </Tab>
        );
      },
      title: t("Title.Positions"),
      scope: true
    },
    {
      count: lastPositionsSearchQueryEnabledProblems ? lastPositionsSearchProblems.length : lastPositionsProblems.length,
      id: "positionsProblems",
      icon: <i className="fas fa-stethoscope" />,
      render: (id, key) => {
        return (
          <Tab tabClassName={`${menu.actionType === id ? "" : "hide"}`} eventKey="positionsProblems" title={<span>{t("Title.PositionsProblems")} ({lastPositionsSearchQueryEnabledProblems ? (lastPositionSelectedProblems ? `${(lastPositionsSearchProblems.findIndex(x => x.id === lastPositionSelectedProblems.id) + 1)} de ${lastPositionsSearchProblems.length}` : lastPositionsSearchProblems.length) : (lastPositionSelectedProblems ? `${(lastPositionsProblems.findIndex(x => x.id === lastPositionSelectedProblems.id) + 1)} de ${lastPositionsProblems.length}` : lastPositionsProblems.length)})</span>} key={key}>
            <div className={`menu-positions-item-search ${isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:all"], every: false }) ? "menu-positions-item-search-client problems" : ""} ${lastPositionSelectedProblems ? "hide" : ""}`}>
              <LastPositionSearchProblems />
            </div>
            <div className={`menu-positions-item-patrimony-type ${lastPositionSelectedProblems ? "hide" : ""}`}>
              <LastPositionPatrimonyTypeProblems />
            </div>
            <div className={`menu-positions-item-box ${isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:all"], every: false }) ? "menu-positions-item-box-client problems" : ""} ${lastPositionSelectedProblems ? "full-size" : ""}`}>
              <LastPositionListProblems />
            </div>
            <div className={"menu-positions-item-action"}>
              {/* <LastPositionPrAction /> */}
            </div>
          </Tab>
        );
      },
      onClick: (e, m) => {
        menuHandleActionType(m.id);
        setLastPositionShowProblems(true);
      },
      title: t("Title.PositionsProblems"),
      scope: isScopes({ currentUser, scopes: ["is:master"], every: false })
    },
    {
      count: lastNotificationsSearchQueryEnabled ? lastNotificationsSearch.length : lastNotifications.length,
      id: "notifications",
      icon: <i className="fas fa-bell" />,
      render: (id, key) => {
        return (
          <Tab tabClassName={`${menu.actionType === id ? "" : "hide"}`} eventKey="notifications" title={<span>{t("Title.Notifications")} ({lastNotificationsSearchQueryEnabled ? lastNotificationsSearch.length : lastNotifications.length})</span>} key={key}>
            <div className={"menu-notifications-item-search"}>
              <LastNotificationSearch />
            </div>
            <div className={"menu-notifications-item-box"}>
              <LastNotificationList />
            </div>
          </Tab>
        );
      },
      title: t("Title.Notifications"),
      scope: isScopes({ currentUser, scopes: ["is:master", "menu:notifications"], every: false })
    },
    {
      count: virtualFencesSearchQueryEnabled ? virtualFencesSearch.length : virtualFences.length,
      id: "virtualFences",
      icon: <i className="fas fa-sign" />,
      render: (id, key) => {
        return (
          <Tab tabClassName={`${menu.actionType === id ? "" : "hide"}`} eventKey="virtualFences" title={<span>{t("Title.VirtualFences")} ({virtualFencesSearchQueryEnabled ? virtualFencesSearch.length : virtualFences.length})</span>} key={key}>
            <div className={"menu-virtual-fences-item-search"}>
              <VirtualFenceSearch />
            </div>
            <div className={"menu-virtual-fences-item-box"}>
              <VirtualFenceList />
            </div>
          </Tab>
        );
      },
      title: t("Title.VirtualFences"),
      scope: isScopes({ currentUser, scopes: ["is:master", "menu:registers:virtualFences"], every: false }) && virtualFencesLoad
    },
    {
      count: lastDateCorrectionsSearchQueryEnabled ? lastDateCorrectionsSearch.length : lastDateCorrections.length,
      id: "dateCorrection",
      icon: <i className="fas fa-eye-dropper" />,
      render: (id, key) => {
        return (
          <Tab tabClassName={`${menu.actionType === id ? "" : "hide"}`} eventKey="dateCorrection" title={<span>{t("Title.DateCorrection")} ({lastDateCorrectionsSearchQueryEnabled ? lastDateCorrectionsSearch.length : lastDateCorrections.length})</span>} key={key}>
            <div className={"menu-date-corrections-item-search"}>
              <LastDateCorrectionSearch />
            </div>
            <div className={"menu-date-corrections-item-box"}>
              <LastDateCorrectionList />
            </div>
          </Tab>
        );
      },
      title: t("Title.DateCorrection"),
      scope: isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      count: lastDelayedLocationsSearchQueryEnabled ? lastDelayedLocationsSearch.length : lastDelayedLocations.length,
      id: "delayedLocation",
      icon: <i className="fas fa-clock" />,
      render: (id, key) => {
        return (
          <Tab tabClassName={`${menu.actionType === id ? "" : "hide"}`} eventKey="delayedLocation" title={<span>{t("Title.DelayedLocation")} ({lastDelayedLocationsSearchQueryEnabled ? lastDelayedLocationsSearch.length : lastDelayedLocations.length})</span>} key={key}>
            <div className={"menu-delayed-locations-item-search"}>
              <LastDelayedLocationSearch />
            </div>
            <div className={"menu-delayed-locations-item-box"}>
              <LastDelayedLocationList />
            </div>
          </Tab>
        );
      },
      title: t("Title.DelayedLocation"),
      scope: isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      count: lastExporterErrorsSearchQueryEnabled ? lastExporterErrorsSearch.length : lastExporterErrors.length,
      id: "exporterError",
      icon: <i className="fas fa-cloud-upload-alt" />,
      render: (id, key) => {
        return (
          <Tab tabClassName={`${menu.actionType === id ? "" : "hide"}`} eventKey="exporterError" title={<span>{t("Title.ExporterError")} ({lastExporterErrorsSearchQueryEnabled ? lastExporterErrorsSearch.length : lastExporterErrors.length})</span>} key={key}>
            <div className={"menu-exporter-errors-item-search"}>
              <LastExporterErrorSearch />
            </div>
            <div className={"menu-exporter-errors-item-box"}>
              <LastExporterErrorList />
            </div>
          </Tab>
        );
      },
      title: t("Title.ExporterError"),
      scope: isScopes({ currentUser, scopes: ["is:master"], every: false })
    }
  ];

  return (
    <>
      <aside className={`aside-menu ${menu.toggled ? "toggled" : ""}`}>
        <div className="menu-itens">
          <ul>
            <li>
              <div className="menu-icon active" onClick={handleMenuClick}>
                {
                  menu.toggled ? (
                    <i className="fa-solid fa-xmark" key="menu-icon-open" />
                  ) : (
                    <i className="fa-solid fa-bars" key="menu-icon-closed" />
                  )
                }
              </div>
            </li>
            {
              menu.toggled && arrMenus.map((m, i) => {
                if(m.scope) {
                  return (
                    <li key={`menu-icon-${i}`}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>{m.title}</Tooltip>}
                        trigger={isMobile ? null : ["hover", "focus"]}
                      >
                        <div className={`menu-icon ${menu.actionType === m.id ? "active" : ""}`} onClick={m.onClick ? (e) => m.onClick(e, m) : e => {
                          setLastPositionShowProblems(false);
                          menuHandleActionType(m.id);
                        }}>
                          {m.icon}
                          {getCount(m.count) !== false ? <div className="menu-icon-count">{getCount(m.count)}</div> : null}
                        </div>
                      </OverlayTrigger>
                    </li>
                  );
                }
                return null;
              })
            }
          </ul>
        </div>
        <div className="menu-content">
          <Tabs defaultActiveKey="positions" activeKey={menu.actionType} onSelect={e => menuHandleActionType(e)}>
            {
              arrMenus.map((m, i) => {
                if(m.scope) {
                  return m.render(m.id, `menu-content-${i}`);
                }
                return null;
              })
            }
          </Tabs>
        </div>
      </aside>
      <div className="aside-menu-backdrop" onClick={handleMenuClick}></div>
    </>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    lastDateCorrections: state.lastDateCorrections.dateCorrections,
    lastDateCorrectionsSearch: state.lastDateCorrections.dateCorrectionsSearch,
    lastDateCorrectionsSearchQueryEnabled: state.lastDateCorrections.dateCorrectionsSearchQueryEnabled,
    lastDelayedLocations: state.lastDelayedLocations.delayedLocations,
    lastDelayedLocationsSearch: state.lastDelayedLocations.delayedLocationsSearch,
    lastDelayedLocationsSearchQueryEnabled: state.lastDelayedLocations.delayedLocationsSearchQueryEnabled,
    lastExporterErrors: state.lastExporterErrors.exporterErrors,
    lastExporterErrorsSearch: state.lastExporterErrors.exporterErrorsSearch,
    lastExporterErrorsSearchQueryEnabled: state.lastExporterErrors.exporterErrorsSearchQueryEnabled,
    lastExporterErrorSelected: state.lastExporterErrors.exporterErrorSelected,
    lastNotifications: state.lastNotifications.notifications,
    lastNotificationsSearch: state.lastNotifications.notificationsSearch,
    lastNotificationsSearchQueryEnabled: state.lastNotifications.notificationsSearchQueryEnabled,

    lastPositions: state.lastPositions.positions,
    lastPositionsSearch: state.lastPositions.positionsSearch,
    lastPositionsSearchQueryEnabled: state.lastPositions.positionsSearchQueryEnabled,
    lastPositionSelected: state.lastPositions.positionSelected,

    lastPositionsProblems: state.lastPositionsProblems.positions,
    lastPositionsSearchProblems: state.lastPositionsProblems.positionsSearch,
    lastPositionsSearchQueryEnabledProblems: state.lastPositionsProblems.positionsSearchQueryEnabled,
    lastPositionSelectedProblems: state.lastPositionsProblems.positionSelected,

    menu: state.menus.menu,
    singleRoute: state.routes.singleRoute,
    virtualFences: state.virtualFences.virtualFences,
    virtualFencesLoad: state.virtualFences.virtualFencesLoad,
    virtualFencesSearch: state.virtualFences.virtualFencesSearch,
    virtualFencesSearchQueryEnabled: state.virtualFences.virtualFencesSearchQueryEnabled
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LastPositionsProblemsActions, MenusActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
