import React, { memo, useEffect, useState } from "react";
import {
  Button
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "./index.css";

import { isScopes } from "./../../../services/Auth";
import DateUtil from "./../../../utils/Date";

import PatrimonyMarker from "./../Patrimony";

import { getHatchStatus } from "./../../../utils/Position";
import { mergeObject } from "./../../../utils/Object";

import SupportPointSchema from "../../../services/SupportPoint/Schema";

import { Creators as MenusActions } from "../../../store/ducks/menus";

const DEFAULT_ZINDEX = 1000;
const SCALE_HOVER = 1;
const SCALE_NORMAL = 0.65;

const Event = ({ onClick, ePosition, eType, profile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [eventHover, setEventHover] = useState(false);
  const [popupShow, setPopupShow] = useState(false);
  const [transhipmentChargeShow, setTranshipmentChargeShow] = useState(false);
  const [transhipmentDischargeShow, setTranshipmentDischargeShow] = useState(false);

  const [scale, setScale] = useState(SCALE_NORMAL);
  const [zIndex, setZIndex] = useState(DEFAULT_ZINDEX);

  useEffect(() => {
    setScale(eventHover || popupShow ? SCALE_HOVER : SCALE_NORMAL);
    setZIndex(Math.round(scale * DEFAULT_ZINDEX) - (popupShow ? 20 : 0) + (eventHover ? DEFAULT_ZINDEX : 0));
  }, [eventHover, popupShow, scale]);

  const getEventClusterType = () => {
    const eTypes = [
      {
        id: "anchorOk",
        title: () => t("Title.Anchor"),
        icon: () => <i className="fas fa-anchor" style={{ color: "#087d06" }} />,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("id")}:</div>
                <div><span>{ePosition.id}</span></div>
              </li>
              <li>
                <div>{t("Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              {
                (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                  <li>
                    <div>{t("Enter")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                ))
              }
              <li>
                <div>{t("Coordinate")}:</div>
                <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
              </li>
              <li>
                <div>{t("Anchor")}:</div>
                <div><span>{t("Title.Anchor.Ok")}</span></div>
              </li>
            </ul>
            <div className="address">
              { ePosition.gps.address }
            </div>
          </>
        )
      },
      {
        id: "anchorViolated",
        title: () => t("Title.Anchor"),
        icon: () => <i className="fas fa-anchor" />,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("id")}:</div>
                <div><span>{ePosition.id}</span></div>
              </li>
              <li>
                <div>{t("Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              {
                (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                  <li>
                    <div>{t("Enter")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                ))
              }
              <li>
                <div>{t("Coordinate")}:</div>
                <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
              </li>
              <li>
                <div>{t("Anchor")}:</div>
                <div><span>{t("Title.Anchor.Violated")}</span></div>
              </li>
            </ul>
            <div className="address">
              { ePosition.gps.address }
            </div>
          </>
        )
      },
      {
        id: "battery",
        title: () => t("Title.Battery"),
        icon: () => <i className="fas fa-battery-empty" />,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("id")}:</div>
                <div><span>{ePosition.id}</span></div>
              </li>
              <li>
                <div>{t("Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              {
                (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                  <li>
                    <div>{t("Enter")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                ))
              }
              <li>
                <div>{t("Coordinate")}:</div>
                <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
              </li>
              <li>
                <div>{t("Battery")}:</div>
                <div><span>{ePosition.tracker.battery ? t("BatteryLow") : t("BatteryHigh")}</span></div>
              </li>
            </ul>
            <div className="address">
              { ePosition.gps.address }
            </div>
          </>
        )
      },
      {
        id: "block",
        title: () => t("Title.Block"),
        icon: () => <i className="fas fa-lock" />,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("id")}:</div>
                <div><span>{ePosition.id}</span></div>
              </li>
              <li>
                <div>{t("Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              {
                (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                  <li>
                    <div>{t("Enter")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                ))
              }
              <li>
                <div>{t("Coordinate")}:</div>
                <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
              </li>
              <li>
                <div>{t("Block")}:</div>
                <div><span>{ePosition.tracker.block ? t("Active") : t("Inactive")}</span></div>
              </li>
            </ul>
            <div className="address">
              { ePosition.gps.address }
            </div>
          </>
        )
      },
      {
        id: "course",
        title: () => t("Title.Course"),
        icon: () => <i className="fas fa-arrow-up" />,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("id")}:</div>
                <div><span>{ePosition.id}</span></div>
              </li>
              <li>
                <div>{t("Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              {
                (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                  <li>
                    <div>{t("Enter")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                ))
              }
              <li>
                <div>{t("Course")}:</div>
                <div><span>{ePosition.gps.course}°</span></div>
              </li>
              <li>
                <div>{t("Coordinate")}:</div>
                <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
              </li>
            </ul>
            <div className="address">
              { ePosition.gps.address }
            </div>
          </>
        )
      },
      {
        id: "espRestarted",
        title: () => t("Title.Reason.ESPRestarted"),
        icon: () => <span>R</span>,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("id")}:</div>
                <div><span>{ePosition.id}</span></div>
              </li>
              <li>
                <div>{t("Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              {
                (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                  <li>
                    <div>{t("Enter")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                ))
              }
              <li>
                <div>{t("Coordinate")}:</div>
                <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
              </li>
              <li>
                <div>{t("Title.Operation")}:</div>
                <div><span>{t("Title.Reason.ESPRestarted")}</span></div>
              </li>
            </ul>
            <div className="address">
              { ePosition.gps.address }
            </div>
          </>
        )
      },
      {
        id: "hatchOpen",
        title: () => t("Title.Hatch.HatchOpen"),
        icon: () => <span>A</span>,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("id")}:</div>
                <div><span>{ePosition.id}</span></div>
              </li>
              <li>
                <div>{t("Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              {
                (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                  <li>
                    <div>{t("Enter")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                ))
              }
              <li>
                <div>{t("Coordinate")}:</div>
                <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
              </li>
              {
                ePosition.nipleNetwork.hatchs.map((hatch, index) => {
                  const hatchStatus = getHatchStatus(hatch, true);
                  if(hatchStatus.status) {
                    if(hatchStatus.now > hatchStatus.max) {
                      return (
                        <li className="full" key={`hatch-distance-smaller-${hatch.serial}-${index}`}>
                          <ul>
                            <li>
                              <div>{t("Title.Hatch.Distance.Bigger")}:</div>
                              <div><span>{hatchStatus.max}</span></div>
                            </li>
                            <li>
                              <div>{t("Title.Hatch.Distance.Current")}:</div>
                              <div><span>{hatchStatus.now}</span></div>
                            </li>
                          </ul>
                        </li>
                      );
                    }
                    else if(hatchStatus.now < hatchStatus.min) {
                      return (
                        <li className="full" key={`hatch-distance-smaller-${hatch.serial}-${index}`}>
                          <ul>
                            <li>
                              <div>{t("Title.Hatch.Distance.Smaller")}:</div>
                              <div><span>{hatchStatus.min}</span></div>
                            </li>
                            <li>
                              <div>{t("Title.Hatch.Distance.Current")}:</div>
                              <div><span>{hatchStatus.now}</span></div>
                            </li>
                          </ul>
                        </li>
                      );
                    }
                  }
                  return null;
                })
              }
            </ul>
            <div className="address">
              { ePosition.gps.address }
            </div>
          </>
        )
      },
      {
        id: "ignitionOff",
        title: () => t("Title.Ignition.Off"),
        icon: () => <i className="fas fa-key" />,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("id")}:</div>
                <div><span>{ePosition.id}</span></div>
              </li>
              <li>
                <div>{t("Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              {
                (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                  <li>
                    <div>{t("Enter")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                ))
              }
              <li>
                <div>{t("Coordinate")}:</div>
                <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
              </li>
              <li>
                <div>{t("Ignition")}:</div>
                <div><span>{ePosition.tracker.ignition ? t("IgnitionOn") : t("IgnitionOff")}</span></div>
              </li>
              <li>
                <div>{t("Speed")}:</div>
                <div><span>{ePosition.gps.speed} Km/h</span></div>
              </li>
            </ul>
            <div className="address">
              { ePosition.gps.address }
            </div>
          </>
        )
      },
      {
        id: "ignitionOn",
        title: () => t("Title.Ignition.On"),
        icon: () => <i className="fas fa-key" style={{ color: "#087d06" }} />,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("id")}:</div>
                <div><span>{ePosition.id}</span></div>
              </li>
              <li>
                <div>{t("Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              {
                (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                  <li>
                    <div>{t("Enter")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                ))
              }
              <li>
                <div>{t("Coordinate")}:</div>
                <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
              </li>
              <li>
                <div>{t("Ignition")}:</div>
                <div><span>{ePosition.tracker.ignition ? t("IgnitionOn") : t("IgnitionOff")}</span></div>
              </li>
              <li>
                <div>{t("Speed")}:</div>
                <div><span>{ePosition.gps.speed} Km/h</span></div>
              </li>
            </ul>
            <div className="address">
              { ePosition.gps.address }
            </div>
          </>
        )
      },
      {
        id: "jammer",
        title: () => t("Title.Jammer"),
        icon: () => <i className="fas fa-broadcast-tower" />,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("id")}:</div>
                <div><span>{ePosition.id}</span></div>
              </li>
              <li>
                <div>{t("Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              {
                (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                  <li>
                    <div>{t("Enter")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                ))
              }
              <li>
                <div>{t("Coordinate")}:</div>
                <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
              </li>
              <li>
                <div>{t("Jammer")}:</div>
                <div><span>{ePosition.tracker.jammer ? t("Active") : t("Inactive")}</span></div>
              </li>
            </ul>
            <div className="address">
              { ePosition.gps.address }
            </div>
          </>
        )
      },
      {
        id: "route",
        title: () => t("Title.Route"),
        icon: () => <i className="fas fa-route" />,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("id")}:</div>
                <div><span>{ePosition.id}</span></div>
              </li>
              <li>
                <div>{t("Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              {
                (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                  <li>
                    <div>{t("Enter")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                ))
              }
              <li>
                <div>{t("Coordinate")}:</div>
                <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
              </li>
              <li>
                <div>{t("Ignition")}:</div>
                <div><span>{ePosition.tracker.ignition ? t("IgnitionOn") : t("IgnitionOff")}</span></div>
              </li>
            </ul>
            <div className="address">
              { ePosition.gps.address }
            </div>
          </>
        )
      },
      {
        id: "speed",
        title: () => t("Title.Speeding"),
        icon: () => <i className="fas fa-tachometer-alt" />,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("id")}:</div>
                <div><span>{ePosition.id}</span></div>
              </li>
              <li>
                <div>{t("Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              {
                (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                  <li>
                    <div>{t("Enter")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                ))
              }
              <li>
                <div>{t("Coordinate")}:</div>
                <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
              </li>
              <li>
                <div>{t("Speed")}:</div>
                <div><span>{ePosition.gps.speed} Km/h</span></div>
              </li>
            </ul>
            <div className="address">
              { ePosition.gps.address }
            </div>
          </>
        )
      },
      {
        id: "stop",
        title: () => t("Title.Downtime"),
        icon: () => <i className="fas fa-hourglass-half" />,
        content: () => {
          if(typeof ePosition.downtime !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("From")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.downtime.dateStart), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  <li>
                    <div>{t("To")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.downtime.dateEnd), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  <li>
                    <div>{t("TotalAccounted")}:</div>
                    <div><span>{DateUtil.toHHMMSS(ePosition.downtime.all.seconds)}</span></div>
                  </li>
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
                {
                  (isScopes({ currentUser: profile.currentUser, scopes: ["is:master", "create:supportPoints", "create:supportPoints:all"], every: false }) && (
                    <div className="actions">
                      <Button
                        variant="dark"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(
                            MenusActions.menuActionsHandleOpen(
                              true,
                              t("Title.SupportPoint"),
                              "SupportPointForm",
                              {
                                supportPoint: mergeObject(JSON.parse(JSON.stringify(SupportPointSchema)), {
                                  location: {
                                    type: "Point",
                                    coordinates: [ePosition.gps.coordinate.longitude, ePosition.gps.coordinate.latitude]
                                  },
                                  range: 10
                                })
                              }
                            )
                          );
                        }}
                      >
                        Gerar ponto de apoio
                      </Button>
                    </div>
                  ))
                }
              </>
            );
          }
        }
      },
      {
        id: "stopRework",
        title: () => t("Title.Downtime"),
        icon: () => <i className="fas fa-hourglass-half" />,
        content: () => {
          if(typeof ePosition.downtime !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("From")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.downtime.dateStart), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  <li>
                    <div>{t("To")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.downtime.dateEnd), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  <li>
                    <div>{t("TotalAccounted")}:</div>
                    <div><span>{DateUtil.toHHMMSS(ePosition.downtime.all.seconds)}</span></div>
                  </li>
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
                {
                  (isScopes({ currentUser: profile.currentUser, scopes: ["is:master", "create:supportPoints", "create:supportPoints:all"], every: false }) && (
                    <div className="actions">
                      <Button
                        variant="dark"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(
                            MenusActions.menuActionsHandleOpen(
                              true,
                              t("Title.SupportPoint"),
                              "SupportPointForm",
                              {
                                supportPoint: mergeObject(JSON.parse(JSON.stringify(SupportPointSchema)), {
                                  location: {
                                    type: "Point",
                                    coordinates: [ePosition.gps.coordinate.longitude, ePosition.gps.coordinate.latitude]
                                  },
                                  range: 10
                                })
                              }
                            )
                          );
                        }}
                      >
                        Gerar ponto de apoio
                      </Button>
                    </div>
                  ))
                }
              </>
            );
          }
        }
      },
      {
        id: "panic",
        title: () => () => t("Title.Panic"),
        icon: () => <i className="fas fa-skull-crossbones" />,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("id")}:</div>
                <div><span>{ePosition.id}</span></div>
              </li>
              <li>
                <div>{t("Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              {
                (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                  <li>
                    <div>{t("Enter")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                ))
              }
              <li>
                <div>{t("Coordinate")}:</div>
                <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
              </li>
              <li>
                <div>{t("Panic")}:</div>
                <div><span>{ePosition.tracker.panic ? t("Active") : t("Inactive")}</span></div>
              </li>
            </ul>
            <div className="address">
              { ePosition.gps.address }
            </div>
          </>
        )
      },
      {
        id: "pump",
        title: () => t("Title.Pump"),
        icon: () => <i className="fas fa-tint" />,
        content: () => {
          if(typeof ePosition.pump !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("From")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.pump.dateStart), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  <li>
                    <div>{t("To")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.pump.dateEnd), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  <li>
                    <div>{t("TotalAccounted")}:</div>
                    <div><span>{DateUtil.toHHMMSS(ePosition.pump.all.seconds)}</span></div>
                  </li>
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
              </>
            );
          }
        }
      },
      {
        id: "maintenance",
        title: () => t("Title.Niple.Maintenance"),
        icon: () => <span>M</span>,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("id")}:</div>
                <div><span>{ePosition.id}</span></div>
              </li>
              <li>
                <div>{t("Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              {
                (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                  <li>
                    <div>{t("Enter")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                ))
              }
              <li>
                <div>{t("Coordinate")}:</div>
                <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
              </li>
              <li>
                <div>{t("Title.Niple.Maintenance")}:</div>
                <div><span>{ePosition.tracker.maintenance ? t("Active") : t("Inactive")}</span></div>
              </li>
            </ul>
            <div className="address">
              { ePosition.gps.address }
            </div>
          </>
        )
      },
      {
        id: "nipleTestTes",
        title: () => t("Title.Niple.Test"),
        icon: () => <span style={{ color: "#087d06" }}>T</span>,
        content: () => {
          return (
            <>
              <ul>
                <li>
                  <div>{t("id")}:</div>
                  <div><span>{ePosition.id}</span></div>
                </li>
                <li>
                  <div>{t("Date")}:</div>
                  <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                </li>
                {
                  (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                    <li>
                      <div>{t("Enter")}:</div>
                      <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                  ))
                }
                <li>
                  <div>{t("Coordinate")}:</div>
                  <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                </li>
                <li>
                  <div>{t("Title.Niple.Test")}:</div>
                  <div><span>{t("Title.Niple.Test")}</span></div>
                </li>
              </ul>
              <div className="address">
                { ePosition.gps.address }
              </div>
            </>
          );
        }
      },
      {
        id: "nipleTestTpw",
        title: () => t("Title.Niple.Test"),
        icon: () => <span style={{ color: "#087d06" }}>T</span>,
        content: () => {
          return (
            <>
              <ul>
                <li>
                  <div>{t("id")}:</div>
                  <div><span>{ePosition.id}</span></div>
                </li>
                <li>
                  <div>{t("Date")}:</div>
                  <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                </li>
                {
                  (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                    <li>
                      <div>{t("Enter")}:</div>
                      <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                  ))
                }
                <li>
                  <div>{t("Coordinate")}:</div>
                  <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                </li>
                <li>
                  <div>{t("Title.Niple.Test")}:</div>
                  <div><span>{t("Title.Niple.Test.TestPerformedWorking")}</span></div>
                </li>
              </ul>
              <div className="address">
                { ePosition.gps.address }
              </div>
            </>
          );
        }
      },
      {
        id: "nipleTestTpe",
        title: () => t("Title.Niple.Test"),
        icon: () => <span>T</span>,
        content: () => {
          return (
            <>
              <ul>
                <li>
                  <div>{t("id")}:</div>
                  <div><span>{ePosition.id}</span></div>
                </li>
                <li>
                  <div>{t("Date")}:</div>
                  <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                </li>
                {
                  (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                    <li>
                      <div>{t("Enter")}:</div>
                      <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                  ))
                }
                <li>
                  <div>{t("Coordinate")}:</div>
                  <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                </li>
                <li>
                  <div>{t("Title.Niple.Test")}:</div>
                  <div><span>{t("Title.Niple.Test.TestPerformedError")}</span></div>
                </li>
              </ul>
              <div className="address">
                { ePosition.gps.address }
              </div>
            </>
          );
        }
      },
      {
        id: "nipleFraud",
        title: () => t("Title.Niple.Fraud"),
        icon: () => <span>B</span>,
        content: () => {
          if(typeof ePosition.operation !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("Date")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  {
                  (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                    <li>
                      <div>{t("Enter")}:</div>
                      <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                  ))
                }
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Operation")}:</div>
                    <div><span>{t("Title.Niple.Fraud")}</span></div>
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
              </>
            );
          }
        }
      },
      {
        id: "nipleClosed",
        title: () => t("Title.Niple.Closed"),
        icon: () => <span>F</span>,
        content: () => {
          if(typeof ePosition.operation !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("Date")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  {
                    (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                      <li>
                        <div>{t("Enter")}:</div>
                        <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    ))
                  }
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Operation")}:</div>
                    <div><span>{t("Title.Niple.Closed")}</span></div>
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
              </>
            );
          }
        }
      },
      {
        id: "nipleClosedBasic",
        title: () => t("Title.Niple.Closed"),
        icon: () => <span>F</span>,
        content: () => {
          if(typeof ePosition.operation !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("Date")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  {
                    (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                      <li>
                        <div>{t("Enter")}:</div>
                        <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    ))
                  }
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Operation")}:</div>
                    <div><span>{t("Title.Niple.Closed")}</span></div>
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
              </>
            );
          }
        }
      },
      {
        id: "nipleDischarge",
        title: () => t("Title.Niple.Discharge"),
        icon: () => <span>D</span>,
        content: () => {
          if(typeof ePosition.operation !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("Date")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  {
                    (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                      <li>
                        <div>{t("Enter")}:</div>
                        <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    ))
                  }
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Operation")}:</div>
                    <div><span>{t("Title.Niple.Discharge")}</span></div>
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
              </>
            );
          }
        }
      },
      {
        id: "nipleDischargeTest",
        title: () => t("Title.Niple.DischargeTest"),
        icon: () => <span style={{ color: "#999999"}}>D</span>,
        content: () => {
          if(typeof ePosition.operation !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("Date")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  {
                    (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                      <li>
                        <div>{t("Enter")}:</div>
                        <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    ))
                  }
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Operation")}:</div>
                    <div><span>{t("Title.Niple.DischargeTest")}</span></div>
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
              </>
            );
          }
        }
      },
      {
        id: "nipleDischargeBasic",
        title: () => t("Title.Niple.Discharge"),
        icon: () => <span>D</span>,
        content: () => {
          if(typeof ePosition.operation !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("Date")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  {
                    (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                      <li>
                        <div>{t("Enter")}:</div>
                        <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    ))
                  }
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Operation")}:</div>
                    <div><span>{t("Title.Niple.Discharge")}</span></div>
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
              </>
            );
          }
        }
      },
      {
        id: "nipleCharge",
        title: () => t("Title.Niple.Charge"),
        icon: () => <span>C</span>,
        content: () => {
          if(typeof ePosition.operation !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("Date")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  {
                    (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                      <li>
                        <div>{t("Enter")}:</div>
                        <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    ))
                  }
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Operation")}:</div>
                    <div><span>{t("Title.Niple.Charge")}</span></div>
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
              </>
            );
          }
        }
      },
      {
        id: "nipleChargeTest",
        title: () => t("Title.Niple.ChargeTest"),
        icon: () => <span style={{ color: "#999999" }}>C</span>,
        content: () => {
          if(typeof ePosition.operation !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("Date")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  {
                    (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                      <li>
                        <div>{t("Enter")}:</div>
                        <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    ))
                  }
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Operation")}:</div>
                    <div><span>{t("Title.Niple.ChargeTest")}</span></div>
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
              </>
            );
          }
        }
      },
      {
        id: "nipleChargeBasic",
        title: () => t("Title.Niple.Charge"),
        icon: () => <span>C</span>,
        content: () => {
          if(typeof ePosition.operation !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("Date")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  {
                    (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                      <li>
                        <div>{t("Enter")}:</div>
                        <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    ))
                  }
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Operation")}:</div>
                    <div><span>{t("Title.Niple.Charge")}</span></div>
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
              </>
            );
          }
        }
      },
      {
        id: "timeInterval",
        title: () => t("Title.TimeInterval"),
        icon: () => <i className="fas fa-stopwatch" />,
        content: () => {
          return (
            <>
              <ul>
                <li>
                  <div>{t("id")}:</div>
                  <div><span>{ePosition.id}</span></div>
                </li>
                <li>
                  <div>{t("Date")}:</div>
                  <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                </li>
                {
                  (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                    <li>
                      <div>{t("Enter")}:</div>
                      <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                  ))
                }
                <li>
                  <div>{t("Course")}:</div>
                  <div><span>{ePosition.gps.course}°</span></div>
                </li>
                <li>
                  <div>{t("Coordinate")}:</div>
                  <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                </li>
              </ul>
              <div className="address">
                { ePosition.gps.address }
              </div>
            </>
          );
        }
      },
      {
        id: "transhipment",
        title: () => t("Title.Transhipment"),
        icon: () => <i className="fas fa-exchange-alt" />,
        content: () => {
          return (
            <>
              <ul>
                <li>
                  <div>{t("id")}:</div>
                  <div><span>{ePosition.id}</span></div>
                </li>
                <li>
                  <div>{t("Title.Patrimony")}:</div>
                  <div><span>{ePosition.patrimony.automobile.board.board}</span></div>
                </li>
                <li>
                  <div>{t("Title.Operation")}:</div>
                  <div><span>{t("Title.Niple.Discharge")}</span></div>
                </li>
                <li>
                  <div>{t("From")}:</div>
                  <div><span>{DateUtil.to({ date: new Date(ePosition.transhipment.dateStart), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                </li>
                <li>
                  <div>{t("To")}:</div>
                  <div><span>{DateUtil.to({ date: new Date(ePosition.transhipment.dateEnd), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                </li>
                <li>
                  <div>{t("TotalAccounted")}:</div>
                  <div><span>{DateUtil.toHHMMSS(ePosition.transhipment.all.seconds)}</span></div>
                </li>
                <li>
                  <div>{t("Coordinate")}:</div>
                  <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span> | <button onClick={e => {
                    setTranshipmentChargeShow(false);
                    setTranshipmentDischargeShow(true);
                  }}><i className="fas fa-eye" /></button></div>
                </li>
                <li>
                  <div>------</div>
                </li>
                <li>
                  <div>{t("Title.Patrimony")}:</div>
                  <div><span>{ePosition.transhipment.charges[0].patrimony.automobile.board.board}</span></div>
                </li>
                <li>
                  <div>{t("Title.Operation")}:</div>
                  <div><span>{t("Title.Niple.Charge")}</span></div>
                </li>
                <li>
                  <div>{t("From")}:</div>
                  <div><span>{DateUtil.to({ date: new Date(ePosition.transhipment.charges[0].transhipment.dateStart), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                </li>
                <li>
                  <div>{t("To")}:</div>
                  <div><span>{DateUtil.to({ date: new Date(ePosition.transhipment.charges[0].transhipment.dateEnd), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                </li>
                <li>
                  <div>{t("TotalAccounted")}:</div>
                  <div><span>{DateUtil.toHHMMSS(ePosition.transhipment.charges[0].transhipment.all.seconds)}</span></div>
                </li>
                <li>
                  <div>{t("Coordinate")}:</div>
                  <div><span>{ePosition.transhipment.charges[0].gps.coordinate.latitude} | {ePosition.transhipment.charges[0].gps.coordinate.longitude}</span> | <button onClick={e => {
                    setTranshipmentChargeShow(true);
                    setTranshipmentDischargeShow(false);
                  }}><i className="fas fa-eye" /></button></div>
                </li>
              </ul>
              <div className="address">
                { ePosition.gps.address }
              </div>
            </>
          );
        }
      }
    ];

    const eTypeFilter = eTypes.filter(x => x.id === eType());
    if(eTypeFilter.length > 0) {
      return eTypeFilter[0];
    }
    return false;
  };

  const eventDetails = getEventClusterType();
  const transform = eType() === "course" ? `rotate(${ePosition.gps.course}deg)` : "rotate(0deg)";

  const popup = (
    <div
      id={`event-marker-popup-${ePosition.index}`}
      className="marker-event-popup"
      onClick={e => e.stopPropagation()}
    >
      <h3 className="mep-title">{eventDetails && eventDetails.title()}</h3>
      <div className="mep-content">{eventDetails && eventDetails.content()}</div>
    </div>
  );

  return (
    <div
      className="marker-event-all"
      onClick={e => {
        setPopupShow(!popupShow);
        setTranshipmentChargeShow(false);
        setTranshipmentDischargeShow(false);
      }}
      onMouseEnter={e => setEventHover(true)}
      onMouseLeave={e => setEventHover(false)}
      style={{
        transform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        MozTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        WebkitTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        zIndex: zIndex
      }}
    >
      <div className="marker-event">
        <div className="icon" style={{
          transform,
          MozTransform: transform,
          WebkitTransform: transform }}>
          {
            eventDetails && eType() !== "speed" && eventDetails.icon()
          }
          {
            eventDetails && eType() === "speed" &&
            <>
              <span className="icon-km">{parseInt(ePosition.gps.speed)}</span>
              <small>Km/h</small>
            </>
          }
        </div>
        {popupShow && !transhipmentChargeShow && !transhipmentDischargeShow && popup}
        {
          transhipmentChargeShow && <div className="event-transhipment-box"><PatrimonyMarker
            key={`position-charge-${ePosition.transhipment.charges[0].id}-${ePosition.transhipment.charges[0].patrimony.id}`}
            lat={ePosition.transhipment.charges[0].gps.latitude}
            lng={ePosition.transhipment.charges[0].gps.longitude}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setTranshipmentChargeShow(false);
              setTranshipmentDischargeShow(false);
            }}
            patrimony={ePosition.transhipment.charges[0].patrimony}
            position={ePosition.transhipment.charges[0]}
            profile={{
              currentUser: profile.currentUser,
              miniMarkers: profile.miniMarkers
            }}
            selected={false}
          /></div>
        }
        {
          transhipmentDischargeShow && <div className="event-transhipment-box"><PatrimonyMarker
            key={`position-discharge-${ePosition.id}-${ePosition.patrimony.id}`}
            lat={ePosition.gps.latitude}
            lng={ePosition.gps.longitude}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setTranshipmentChargeShow(false);
              setTranshipmentDischargeShow(false);
            }}
            patrimony={ePosition.patrimony}
            position={ePosition}
            profile={{
              currentUser: profile.currentUser,
              miniMarkers: profile.miniMarkers
            }}
            selected={false}
          /></div>
        }
      </div>
    </div>
  );
};

export default memo(Event);
