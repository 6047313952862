import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as NotificationsActions } from "./../../store/ducks/notifications";

import { mergeObject } from "./../../utils/Object";

import ReportService from "./../../services/Report";
import ReportSchema from "./../../services/Report/Schema";

const Csv = ({
  options,
  props: {
    currentUser
  },
  funcs: {
    addNotice,
    disableLoader,
    enableLoader
  }
}) => {
  const reportService = new ReportService();
  const { t } = useTranslation();

  const {
    close: __close = () => {},
    report: __report = {},
    delimiter: _delimiter = ",",
    encoding: _encoding = "UTF-8"
  } = options;

  const [report] = useState(mergeObject(JSON.parse(JSON.stringify(ReportSchema)), __report));
  const [reportBlob, setReportBlob] = useState(false);
  const [running, setRunning] = useState(false);
  const [delimiter] = useState(_delimiter);
  const [encoding] = useState(_encoding);

  useEffect(() => {
    getReport();
  }, []); /* eslint-disable-line */

  const getReport = async () => {
    if(!running) {
      setRunning(true);
      enableLoader();
      const response = await reportService.reportCSV({ id: report.id, token: currentUser.token, data: { encoding, delimiter } });
      setReportBlob(window.URL.createObjectURL(new Blob([response], { type: "text/csv" })));
      setTimeout(() => {
        disableLoader();
        setRunning(false);
        __close();
      }, 100);
    }
  };

  return (
    <div className="user-reports-csv-content-data">
      {
        (
          reportBlob && (
            <iframe
            className="iframe-fluid"
            src={reportBlob}
            title={report.name} />
          )
        )
      }
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Csv));
