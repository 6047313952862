import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

import { Creators as LastPositionProblemsActions } from "../../store/ducks/lastPositionsProblems";
import { Creators as LoadersActions } from "../../store/ducks/loaders";
import { Creators as MenusActions } from "../../store/ducks/menus";
import LastPositionListItemProblems from "../LastPositionListItemProblems";
import LastPositionSelectedItemProblems from "../LastPositionSelectedItemProblems";

const LastPositionListProblems = ({
  props: {
    currentUser,
    databaseTest,
    lastPositionsProblems,
    lastPositionsSearchProblems,
    lastPositionsSearchQueryEnabledProblems,
    lastPositionSelectedProblems
  },
  funcs: {
    disableLoader,
    enableLoader,
    menuHandleClose,
    selectLastPositionProblems
  }
}) => {
  if (lastPositionSelectedProblems) {
    return (
      <LastPositionSelectedItemProblems key={lastPositionSelectedProblems.id} options={{
        ...lastPositionSelectedProblems,
        hasBack: () => {
          const indexNow = lastPositionsSearchQueryEnabledProblems ? lastPositionsSearchProblems.findIndex(x => x.id === lastPositionSelectedProblems.id) : lastPositionsProblems.findIndex(x => x.id === lastPositionSelectedProblems.id);
          if (indexNow > 0) return true;
          return false;
        },
        hasNext: () => {
          const indexNow = lastPositionsSearchQueryEnabledProblems ? lastPositionsSearchProblems.findIndex(x => x.id === lastPositionSelectedProblems.id) : lastPositionsProblems.findIndex(x => x.id === lastPositionSelectedProblems.id);
          const indexAll = lastPositionsSearchQueryEnabledProblems ? (lastPositionsSearchProblems.length - 1) : (lastPositionsProblems.length - 1);
          if (indexNow < indexAll) return true;
          return false;
        },
        onClick: () => {
          selectLastPositionProblems(lastPositionSelectedProblems.id);
        },
        onClickBack: async () => {
          const indexNow = lastPositionsSearchQueryEnabledProblems ? lastPositionsSearchProblems.findIndex(x => x.id === lastPositionSelectedProblems.id) : lastPositionsProblems.findIndex(x => x.id === lastPositionSelectedProblems.id);
          if (indexNow > 0) {
            const newPositionSelected = lastPositionsSearchQueryEnabledProblems ? lastPositionsSearchProblems[indexNow - 1] : lastPositionsProblems[indexNow - 1];
            selectLastPositionProblems(newPositionSelected.id);
          }
        },
        onClickNext: async () => {
          const indexNow = lastPositionsSearchQueryEnabledProblems ? lastPositionsSearchProblems.findIndex(x => x.id === lastPositionSelectedProblems.id) : lastPositionsProblems.findIndex(x => x.id === lastPositionSelectedProblems.id);
          const indexAll = lastPositionsSearchQueryEnabledProblems ? (lastPositionsSearchProblems.length - 1) : (lastPositionsProblems.length - 1);
          if (indexNow < indexAll) {
            const newPositionSelected = lastPositionsSearchQueryEnabledProblems ? lastPositionsSearchProblems[indexNow + 1] : lastPositionsProblems[indexNow + 1];
            selectLastPositionProblems(newPositionSelected.id);
          }
        }
      }} />
    );
  }
  return (
    <ul className="last-position-list">
      {
        lastPositionsSearchQueryEnabledProblems ? (
          lastPositionsSearchProblems.map(lastPosition => (
            <LastPositionListItemProblems key={`lp-${lastPosition.id}`} options={{...lastPosition, onClick: () => {
              selectLastPositionProblems(lastPosition.id);
            }}} />
          ))
        ) : (
          lastPositionsProblems.map(lastPosition => (
            <LastPositionListItemProblems key={`lp-${lastPosition.id}`} options={{...lastPosition, onClick: () => {
              selectLastPositionProblems(lastPosition.id);
            }}} />
          ))
        )
      }
    </ul>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    databaseTest: state.databaseTest.databaseTest,
    lastPositionsProblems: state.lastPositionsProblems.positions,
    lastPositionsSearchProblems: state.lastPositionsProblems.positionsSearch,
    lastPositionsSearchQueryEnabledProblems: state.lastPositionsProblems.positionsSearchQueryEnabled,
    lastPositionSelectedProblems: state.lastPositionsProblems.positionSelected
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LastPositionProblemsActions, LoadersActions, MenusActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastPositionListProblems);
