import DateUtil from "./Date";

export function jsonToForm(json) {
  return json;
}

export function filterByClient(drivers, search) {
  let filter = drivers;
  let enabled = false;
  if(typeof search !== "undefined") {
    if(typeof search.client !== "undefined" && search.client.id !== "") {
      enabled = true;
      filter = filter.filter((p) => typeof p.client !== "undefined" && p.client.id === search.client.id);
    }
    if(typeof search.active !== "undefined" && search.active !== -1) {
      enabled = true;
      filter = filter.filter((p) => typeof p.active !== "undefined" && p.active === search.active);
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function filterByClientAndWord(drivers, search) {
  let filter = drivers;
  let enabled = false;
  if(typeof search !== "undefined") {
    if(typeof search.client !== "undefined" && search.client.id !== "") {
      enabled = true;
      filter = filter.filter((p) => typeof p.client !== "undefined" && p.client.id === search.client.id);
    }
    if(typeof search.word !== "undefined" && search.word !== "") {
      enabled = true;
      filter = filter.filter(
        (p) =>
          (p.name && p.name.toLowerCase().indexOf(search.word.toLowerCase()) !== -1) ||
          (p.cpf && p.cpf.toLowerCase().indexOf(search.word.toLowerCase()) !== -1)
      );
    }
    if(typeof search.active !== "undefined" && search.active !== -1) {
      enabled = true;
      filter = filter.filter((p) => typeof p.active !== "undefined" && p.active === search.active);
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function sortDrivers(drivers, asc = true) {
  if(asc) {
    return drivers.sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;
      if(nameA > nameB) return 1;
      else if(nameA < nameB) return -1;
      return 0;
    });
  }
  return drivers.sort((a, b) => {
    const nameA = a.name;
    const nameB = b.name;
    if(nameA > nameB) return -1;
    else if(nameA < nameB) return 1;
    return 0;
  });
}
