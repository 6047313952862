import ClientSchemaReduced, { schemaDate as schemaDateClient } from "../Client/SchemaReducedTitle";

const schema = {
  active: "Status",
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  createdAt: "Criado em",
  global: "Global",
  id: "Id",
  location: {
    type: "Tipo de área",
    coordinates: "Coordenadas da área"
  },
  name: "Nome",
  notifyDay: "Dias (notificação)",
  notifyMinHour: "Horário mínimo (notificação)",
  notifyMaxHour: "Horário máximo (notificação)",
  patrimony: {
    patrimonies: "Patrimônios",
    type: "Tipom de patrimônios"
  },
  permitedMinHour: "Horário mínimo (permitido)",
  permitedMaxHour: "Horário máximo (permitido)",
  time: "Tempo",
  type: "Tipo de cerca",
  updatedAt: "Atualizado em"
};

export const schemaDate = {
  createdAt: "datetime",
  client: JSON.parse(JSON.stringify(schemaDateClient)),
  updatedAt: "datetime"
};

export default schema;
