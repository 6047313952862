import React, { memo, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import { Creators as NotificationsActions } from "../../store/ducks/notifications";

import { isScopes } from "../../services/Auth";
import { maskCPF } from "../../utils/CPF";
import { maskCNPJ } from "../../utils/CNPJ";
import Date from "../../utils/Date";
import { isValidForm } from "../../utils/Form";
import { isEmpty, ucFirstAll } from "../../utils/String";
import { clearObject, mergeObject, mergeArrayObj } from "../../utils/Object";

import ClientService from "../../services/Client";
import ClientSchemaReduced from "../../services/Client/SchemaReduced";
import WorkingWeekService from "../../services/WorkingWeek";
import WorkingWeekSchema from "../../services/WorkingWeek/Schema";

const ACTIVE_KEY_GENERAL_DATA = "0";
const ACTIVE_KEY_SUN = "1";
const ACTIVE_KEY_MON = "2";
const ACTIVE_KEY_TUE = "3";
const ACTIVE_KEY_WED = "4";
const ACTIVE_KEY_THU = "5";
const ACTIVE_KEY_FRI = "6";
const ACTIVE_KEY_SAT = "7";

const WorkingWeek = ({
  options,
  props: { currentUser },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const clientService = new ClientService();
  const workingWeekService = new WorkingWeekService();

  const {
    workingWeek: __workingWeek = {},
    close: __close = () => {}
  } = options;

  const [activeKey, setActiveKey] = useState(ACTIVE_KEY_GENERAL_DATA);
  const [workingWeek, setWorkingWeek] = useState(mergeObject(JSON.parse(JSON.stringify(WorkingWeekSchema)), __workingWeek));

  /*
    SUN -> Sunday
    MON -> Monday
    TUE -> Tuesday
    WED -> Wednesday
    THU -> Thursday
    FRI -> Friday
    SAT -> Saturday
  */
  const [workingWeekDays, setWorkingWeekDays] = useState(
    mergeArrayObj(
      JSON.parse(
        JSON.stringify(
          [
            { breakEnter: "", breakLeave: "", day: "SUN", enter: "", leave: "", key: ACTIVE_KEY_SUN },
            { breakEnter: "", breakLeave: "", day: "MON", enter: "", leave: "", key: ACTIVE_KEY_MON },
            { breakEnter: "", breakLeave: "", day: "TUE", enter: "", leave: "", key: ACTIVE_KEY_TUE },
            { breakEnter: "", breakLeave: "", day: "WED", enter: "", leave: "", key: ACTIVE_KEY_WED },
            { breakEnter: "", breakLeave: "", day: "THU", enter: "", leave: "", key: ACTIVE_KEY_THU },
            { breakEnter: "", breakLeave: "", day: "FRI", enter: "", leave: "", key: ACTIVE_KEY_FRI },
            { breakEnter: "", breakLeave: "", day: "SAT", enter: "", leave: "", key: ACTIVE_KEY_SAT }
          ]
        )
      ),
      __workingWeek.days ?? [],
      (item, item2) => item.day === item2.day
    )
  );

  useEffect(() => {
    setTimeout(() => {
      if(!isScopes({ currentUser, scopes: [
        "is:master",
        "read:workingWeeks:all"
      ], every: false })) {
        setClientSelectedOption({
          dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client),
          label: currentUser.client.type === "LEG" ? (`${currentUser.client.legal.socialReason} | CNPJ: ${maskCNPJ(currentUser.client.legal.cnpj)}`) : (`${currentUser.client.personal.name} | CPF: ${maskCPF(currentUser.client.personal.cpf)}`),
          value: currentUser.client.id
        });
        setWorkingWeek(prevState => ({
          ...prevState,
          client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client)
        }));
      }
    }, 1000);
  }, []); /* eslint-disable-line */

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [clientSelectedOption, setClientSelectedOption] = useState(workingWeek.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), workingWeek.client),
    label: workingWeek.client.type === "LEG" ? (`${workingWeek.client.legal.socialReason} | CNPJ: ${maskCNPJ(workingWeek.client.legal.cnpj)}`) : (`${workingWeek.client.personal.name} | CPF: ${maskCPF(workingWeek.client.personal.cpf)}`),
    value: workingWeek.client.id
  });

  const [running, setRunning] = useState(false);

  const buttonDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const changeActiveKey = (e) => {
    if (activeKey === e) {
      return setActiveKey("-1");
    }
    setActiveKey(e);
  };

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? (`${e.legal.socialReason} | CNPJ: ${maskCNPJ(e.legal.cnpj)}`) : `${e.personal.name} | CPF: ${maskCPF(e.personal.cpf)}`,
        value: e.id
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { eventKey, message, valid } = isValidForm(e);
      if (!valid) {
        if (!message) {
          message = t("Error.Fields.Empty");
        }
        if (eventKey !== -1) {
          setActiveKey(eventKey);
        }
        addNotice({
          title: t("Title.WorkingWeek"),
          content: message
        });
      }
      else {
        let response = false;
        if(isCreate()) {
          response = await workingWeekService.create({
            data: handleSubmitForm({ workingWeek, workingWeekDays }),
            token: currentUser.token
          });
        }
        else {
          response = await workingWeekService.update({
            id: workingWeek.id,
            data: handleSubmitForm({ workingWeek, workingWeekDays }),
            token: currentUser.token
          });
        }

        if(response.success) {
          addNotice({
            title: t("Title.WorkingWeek"),
            content: isCreate() ? t("Success.Insert") : t("Success.Update")
          });
          setTimeout(() => {
            __close();
          }, 200);
        }
        else {
          let error = ucFirstAll(response.error.details[0].path);
          addNotice({
            title: t("Title.WorkingWeek"),
            content: t(`Error.WorkingWeek.${response.error.type}.${error}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ workingWeek, workingWeekDays }) => {
    let newWorkingWeek = Object.assign({}, JSON.parse(JSON.stringify(workingWeek)));
    newWorkingWeek = clearObject({ data: newWorkingWeek });
    newWorkingWeek.days = clearObject({ data: workingWeekDays.filter(workingWeekDay => (typeof workingWeekDay.enter !== "undefined" && workingWeekDay.enter !== "") || (typeof workingWeekDay.leave !== "undefined" && workingWeekDay.leave !== "")) });
    newWorkingWeek.id = undefined;
    return newWorkingWeek;
  };

  const inputDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const isCreate = () => {
    return workingWeek.id === "";
  };

  return (
    <Form id="forms-workingWeek" noValidate onSubmit={handleSubmit}>
      <Accordion defaultActiveKey={ACTIVE_KEY_GENERAL_DATA} activeKey={activeKey}>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_GENERAL_DATA} onClick={e => changeActiveKey(ACTIVE_KEY_GENERAL_DATA)}>{t("Title.GeneralData")}</Accordion.Toggle>
          <Accordion.Collapse eventKey={ACTIVE_KEY_GENERAL_DATA}>
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-working-week-active">
                    <Form.Label>{t("Label.Active")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        const active = e.value;
                        setWorkingWeek(prevState => ({
                          ...prevState,
                          active
                        }));
                      }}
                      options={activeOptions}
                      value={activeOptions.find(x => x.value === workingWeek.active)}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-user-client">
                    <Form.Label>{t("Title.Client")}:</Form.Label>
                    <AsyncSelect
                      className={`menu-outer-bottom ${!isEmpty(workingWeek.client.id) ? "is-valid" : "is-invalid"}`}
                      classNamePrefix="select"
                      cacheOptions
                      defaultOptions
                      isDisabled={inputDisabled() || !isScopes({
                        currentUser,
                        scopes: [
                          "is:master",
                          "read:workingWeeks:all"
                        ],
                        every: false
                      })}
                      loadOptions={clientLoadOptions}
                      loadingMessage={() => t("React.Select.Wait")}
                      noOptionsMessage={() => t("React.Select.NoOptions")}
                      onChange={e => {
                        if(e === null) {
                          setClientSelectedOption(e);
                          setWorkingWeek(prevState => ({
                            ...prevState,
                            client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                          }));
                        }
                        else {
                          const { dataAux, label, value } = e;
                          setClientSelectedOption({ dataAux, label, value });
                          setWorkingWeek(prevState => ({
                            ...prevState,
                            client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                          }));
                        }
                      }}
                      placeholder={t("Title.Client.Select")}
                      value={clientSelectedOption}
                      required={true}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                    />
                    <Form.Control.Feedback className={`${!isEmpty(workingWeek.client.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Client")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="forms-working-week-name">
                    <Form.Label>{t("Label.Name")}:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Placeholder.Name")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const name = e.target.value;
                        setWorkingWeek(prevState => ({
                          ...prevState,
                          name: name.toUpperCase()
                        }));
                      }}
                      autoComplete="off"
                      value={workingWeek.name}
                      isValid={!isEmpty(workingWeek.name)}
                      isInvalid={isEmpty(workingWeek.name)}
                      required={true}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                    />
                    <Form.Control.Feedback type="invalid">{t("Feedback.Name")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        {
          (workingWeekDays && (
            workingWeekDays.map((workingWeekDay, workingWeekDayIndex) => {
              return (
                <Card key={`working-week-key-${workingWeekDay.day}-${workingWeekDayIndex}`}>
                  <Accordion.Toggle as={Card.Header} eventKey={workingWeekDay.key} onClick={e => changeActiveKey(workingWeekDay.key)}>{t(`Title.WorkingWeek.${workingWeekDay.day}`)}</Accordion.Toggle>
                  <Accordion.Collapse eventKey={workingWeekDay.key}>
                    <Card.Body>
                      <Row>
                        <Col xs={12} md={3}>
                          <Form.Group controlId={`forms-working-week-working-day-enter-${workingWeekDay.day}`}>
                            <Form.Label>{t("Label.Enter")}:</Form.Label>
                            <InputMask
                              id={`forms-working-week-working-day-enter-${workingWeekDay.day}`}
                              className={`form-control ${isEmpty(workingWeekDay.enter) || (!isEmpty(workingWeekDay.enter) && Date.isTime(workingWeekDay.enter)) ? "is-valid" : "is-invalid"}`}
                              type="text"
                              placeholder={t("Placeholder.Enter")}
                              disabled={inputDisabled()}
                              onChange={e => {
                                const enter = e.target.value;
                                setWorkingWeekDays(prevState => {
                                  const newState = JSON.parse(JSON.stringify(prevState));
                                  const filter = newState.findIndex(x => x.day === workingWeekDay.day);
                                  if (filter !== -1) {
                                    newState[filter] = {
                                      ...newState[filter],
                                      enter
                                    };
                                  }
                                  return newState;
                                });
                              }}
                              mask="99:99:99"
                              autoComplete="off"
                              defaultValue={workingWeekDay.enter}
                              dataeventkey={workingWeekDay.key}
                            />
                            <Form.Control.Feedback type="invalid">
                              { t("Feedback.Format.Time") }
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={3}>
                          <Form.Group controlId={`forms-working-week-working-day-break-enter-${workingWeekDay.day}`}>
                            <Form.Label>{t("Label.BreakEnter")}:</Form.Label>
                            <InputMask
                              id={`forms-working-week-working-day-break-enter-${workingWeekDay.day}`}
                              className={`form-control ${isEmpty(workingWeekDay.breakEnter) || (!isEmpty(workingWeekDay.breakEnter) && Date.isTime(workingWeekDay.breakEnter)) ? "is-valid" : "is-invalid"}`}
                              type="text"
                              placeholder={t("Placeholder.BreakEnter")}
                              disabled={inputDisabled()}
                              onChange={e => {
                                const breakEnter = e.target.value;
                                setWorkingWeekDays(prevState => {
                                  const newState = JSON.parse(JSON.stringify(prevState));
                                  const filter = newState.findIndex(x => x.day === workingWeekDay.day);
                                  if (filter !== -1) {
                                    newState[filter] = {
                                      ...newState[filter],
                                      breakEnter
                                    };
                                  }
                                  return newState;
                                });
                              }}
                              mask="99:99:99"
                              autoComplete="off"
                              defaultValue={workingWeekDay.breakEnter}
                              dataeventkey={workingWeekDay.key}
                            />
                            <Form.Control.Feedback type="invalid">
                              { t("Feedback.Format.Time") }
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={3}>
                          <Form.Group controlId={`forms-working-week-working-day-break-leave-${workingWeekDay.day}`}>
                            <Form.Label>{t("Label.BreakLeave")}:</Form.Label>
                            <InputMask
                              id={`forms-working-week-working-day-break-leave-${workingWeekDay.day}`}
                              className={`form-control ${isEmpty(workingWeekDay.breakLeave) || (!isEmpty(workingWeekDay.breakLeave) && Date.isTime(workingWeekDay.breakLeave)) ? "is-valid" : "is-invalid"}`}
                              type="text"
                              placeholder={t("Placeholder.BreakLeave")}
                              disabled={inputDisabled()}
                              onChange={e => {
                                const breakLeave = e.target.value;
                                setWorkingWeekDays(prevState => {
                                  const newState = JSON.parse(JSON.stringify(prevState));
                                  const filter = newState.findIndex(x => x.day === workingWeekDay.day);
                                  if (filter !== -1) {
                                    newState[filter] = {
                                      ...newState[filter],
                                      breakLeave
                                    };
                                  }
                                  return newState;
                                });
                              }}
                              mask="99:99:99"
                              autoComplete="off"
                              defaultValue={workingWeekDay.breakLeave}
                              dataeventkey={workingWeekDay.key}
                            />
                            <Form.Control.Feedback type="invalid">
                              { t("Feedback.Format.Time") }
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={3}>
                          <Form.Group controlId={`forms-working-week-working-day-leave-${workingWeekDay.day}`}>
                            <Form.Label>{t("Label.Leave")}:</Form.Label>
                            <InputMask
                              id={`forms-working-week-working-day-leave-${workingWeekDay.day}`}
                              className={`form-control ${isEmpty(workingWeekDay.leave) || (!isEmpty(workingWeekDay.leave) && Date.isTime(workingWeekDay.leave)) ? "is-valid" : "is-invalid"}`}
                              type="text"
                              placeholder={t("Placeholder.Leave")}
                              disabled={inputDisabled()}
                              onChange={e => {
                                const leave = e.target.value;
                                setWorkingWeekDays(prevState => {
                                  const newState = JSON.parse(JSON.stringify(prevState));
                                  const filter = newState.findIndex(x => x.day === workingWeekDay.day);
                                  if (filter !== -1) {
                                    newState[filter] = {
                                      ...newState[filter],
                                      leave
                                    };
                                  }
                                  return newState;
                                });
                              }}
                              mask="99:99:99"
                              autoComplete="off"
                              defaultValue={workingWeekDay.leave}
                              dataeventkey={workingWeekDay.key}
                            />
                            <Form.Control.Feedback type="invalid">
                              { t("Feedback.Format.Time") }
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })
          ))
        }
      </Accordion>
      <Form.Group className="default-form-button">
        <Button
          variant="dark"
          type="submit"
          disabled={buttonDisabled()}
        >
          {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
        </Button>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(WorkingWeek));
