const schemaReduced = {
  id: "Id do chip",
  line: "Linha do chip",
  personalSerial: "Serial (pessoal) do chip",
  serial: "Serial do chip"
};

export const schemaDate = {};

export default schemaReduced;
