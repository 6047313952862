import { createActions, createReducer } from "reduxsauce";
import DateUtil from "./../../utils/Date";
import { isEmpty } from "../../utils/String";
import {
  cropPositions,
  hatchPositions,
  niplePositions,
  niplePositionsBasic,
  nipleTestPositions,
  pumpedPositions,
  stopedPositions,
  stopedPositionsRework,
  singleRouteAnchor,
  singleRouteBattery,
  singleRouteBlock,
  singleRouteCourse,
  singleRouteEspRestarted,
  singleRouteHatchOpen,
  singleRouteIgnitionOn,
  singleRouteIgnitionOff,
  singleRouteJammer,
  singleRouteNipleCharge,
  singleRouteNipleChargeTest,
  singleRouteNipleClosed,
  singleRouteNipleDischarge,
  singleRouteNipleDischargeTest,
  singleRouteNipleFraud,
  singleRouteMaintenance,
  singleRoutePanic,
  singleRouteSpeed,
  sortPositionsPolyline
} from "./../../utils/Position";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addSingleRoute: ["end", "options", "patrimony", "route", "start", "parameters", "routeCroped"],
  changeSingleRouteDowntime: ["downtimeTolerance"],
  changeSingleRouteSpeed: ["speedTolerance"],
  closeMenuGpsSignal: [],
  closeMenuPositions: [],
  openMenuGpsSignal: [],
  openMenuPositions: [],
  removeSingleRoute: [],
  selectMenuSingleRoute: ["id"],
  selectMenuSingleRouteCroped: ["id"],
  selectSingleRouteTracker: ["tracker"],
  addSingleRouteMenuPositionsMarkers: ["position"],
  removeSingleRouteMenuPositionsMarkers: ["position"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  singleRoute: false,
  singleRouteStartEnd: false,
  singleRouteMenu: false,
  singleRouteMenuSelected: false,
  singleRouteMenuGpsSignalSelected: false,
  singleRouteMenuPositionsSelected: false,
  singleRouteMenuPositionsMarkersSelected: [],
  singleRouteTracker: false,
  singleRouteParameters: {
    downtimeTolerance: "00:04:00",
    speedTolerance: 100
  }
};

const addSingleRoute = (state = INITIAL_STATE, action) => {
  const { end, options, patrimony, route, start, parameters, routeCroped } = action;

  const singleRouteParameters = INITIAL_STATE.singleRouteParameters;
  if (typeof parameters !== "undefined") {
    if (typeof parameters.downtimeTolerance !== "undefined" && !isEmpty(parameters.downtimeTolerance) && DateUtil.isTime(parameters.downtimeTolerance)) {
      singleRouteParameters.downtimeTolerance = parameters.downtimeTolerance;
    }
    if (typeof parameters.speedTolerance !== "undefined" && !isEmpty(parameters.speedTolerance) && !isNaN(Number(parameters.speedTolerance))) {
      singleRouteParameters.speedTolerance = parameters.speedTolerance;
    }
  }

  const gpsSignal = state.singleRouteMenuGpsSignalSelected;

  const originalRoute = JSON.parse(JSON.stringify(route));
  const cropedPositions = cropPositions({ positions: originalRoute });
  let cropedPosition = routeCroped ? cropedPositions.find(x => x.id === routeCroped) : (cropedPositions.length > 0 ? cropedPositions[0] : false);
  cropedPosition = cropedPosition ? cropedPosition : false;

  let auxiliarRoute = cropedPosition ? JSON.parse(JSON.stringify(cropedPosition.positions)) : JSON.parse(JSON.stringify(originalRoute));
  if (state.singleRouteTracker !== false) auxiliarRoute = auxiliarRoute.filter(x => x.tracker.serial === state.singleRouteTracker);
  if (gpsSignal) auxiliarRoute = auxiliarRoute.filter(x => x.gps.gpsSignal === true);
  auxiliarRoute = sortPositionsPolyline(auxiliarRoute, options.orderAsc);

  const operations = niplePositions(JSON.parse(JSON.stringify(auxiliarRoute)));
  const operationsBasic = niplePositionsBasic(JSON.parse(JSON.stringify(auxiliarRoute)));

  const tests = nipleTestPositions(JSON.parse(JSON.stringify(auxiliarRoute)));
  const downtimes = stopedPositions(JSON.parse(JSON.stringify(auxiliarRoute)), singleRouteParameters.downtimeTolerance);
  const downtimesRework = stopedPositionsRework(JSON.parse(JSON.stringify(auxiliarRoute)), singleRouteParameters.downtimeTolerance);
  const pumps = pumpedPositions(JSON.parse(JSON.stringify(auxiliarRoute)));
  const hatchsOperations = hatchPositions(JSON.parse(JSON.stringify(auxiliarRoute)));

  return {
    ...state,
    singleRoute: {
      cropedPositions,
      cropedPosition,
      originalRoute,
      orderAsc: options.orderAsc,
      patrimony,
      page: options.page,
      pages: options.pages,
      route: auxiliarRoute
    },
    singleRouteStartEnd: {
      end,
      start
    },
    singleRouteMenu: {
      anchorOk: singleRouteAnchor(auxiliarRoute, false),
      anchorViolated: singleRouteAnchor(auxiliarRoute, true),
      battery: singleRouteBattery(auxiliarRoute),
      block: singleRouteBlock(auxiliarRoute),
      course: singleRouteCourse(auxiliarRoute),
      espRestarted: singleRouteEspRestarted(auxiliarRoute),
      hatchOpen: singleRouteHatchOpen(hatchsOperations),
      ignitionOn: singleRouteIgnitionOn(auxiliarRoute),
      ignitionOff: singleRouteIgnitionOff(auxiliarRoute),
      jammer: singleRouteJammer(auxiliarRoute),
      nipleCharge: singleRouteNipleCharge(operations),
      nipleChargeTest: singleRouteNipleChargeTest(operations),
      nipleClosed: singleRouteNipleClosed(operations),
      nipleDischarge: singleRouteNipleDischarge(operations),
      nipleDischargeTest: singleRouteNipleDischargeTest(operations),
      nipleChargeBasic: singleRouteNipleCharge(operationsBasic),
      nipleClosedBasic: singleRouteNipleClosed(operationsBasic),
      nipleDischargeBasic: singleRouteNipleDischarge(operationsBasic),
      nipleFraud: singleRouteNipleFraud(operations),
      nipleTestTpw: tests.testsTpw,
      nipleTestTpe: tests.testsTpe,
      maintenance: singleRouteMaintenance(auxiliarRoute),
      panic: singleRoutePanic(auxiliarRoute),
      pump: pumps,
      route: [],
      routes: [],
      speed: singleRouteSpeed(auxiliarRoute, singleRouteParameters.speedTolerance),
      stop: downtimes,
      stopRework: downtimesRework
    },
    singleRouteMenuSelected: {
      id: "route",
      value: []
    },
    singleRouteMenuPositionsMarkersSelected: [],
    singleRouteParameters
  };
};

const changeSingleRouteDowntime = (state = INITIAL_STATE, action) => {
  const { downtimeTolerance } = action;
  const singleRouteParameters = INITIAL_STATE.singleRouteParameters;
  if (typeof downtimeTolerance !== "undefined" && !isEmpty(downtimeTolerance) && DateUtil.isTime(downtimeTolerance)) {
    singleRouteParameters.downtimeTolerance = downtimeTolerance;
  }
  if (state.singleRoute) {
    const downtimes = stopedPositions(JSON.parse(JSON.stringify(state.singleRoute.route)), downtimeTolerance);
    const downtimesRework = stopedPositionsRework(JSON.parse(JSON.stringify(state.singleRoute.route)), downtimeTolerance);

    let singleRouteMenuSelected = JSON.parse(JSON.stringify(state.singleRouteMenuSelected));
    if (singleRouteMenuSelected && singleRouteMenuSelected.id === "stopRework") singleRouteMenuSelected = { id: "stopRework", value: downtimesRework };
    else singleRouteMenuSelected = { id: "stop", value: downtimes };

    return {
      ...state,
      singleRouteMenu: {
        ...state.singleRouteMenu,
        stop: downtimes,
        stopRework: downtimesRework
      },
      singleRouteMenuSelected,
      singleRouteMenuPositionsMarkersSelected: [],
      singleRouteParameters: {
        ...state.singleRouteParameters,
        downtimeTolerance: singleRouteParameters.downtimeTolerance
      }
    };
  }
  return state;
};

const changeSingleRouteSpeed = (state = INITIAL_STATE, action) => {
  const { speedTolerance } = action;
  const singleRouteParameters = INITIAL_STATE.singleRouteParameters;
  if (typeof speedTolerance !== "undefined" && !isEmpty(speedTolerance) && !isNaN(speedTolerance)) {
    singleRouteParameters.speedTolerance = speedTolerance;
  }
  if (state.singleRoute) {
    const speeds = singleRouteSpeed(JSON.parse(JSON.stringify(state.singleRoute.route)), speedTolerance);
    return {
      ...state,
      singleRouteMenu: {
        ...state.singleRouteMenu,
        speed: speeds
      },
      singleRouteMenuSelected: {
        id: "speed",
        value: speeds
      },
      singleRouteMenuPositionsMarkersSelected: [],
      singleRouteParameters: {
        ...state.singleRouteParameters,
        speedTolerance: singleRouteParameters.speedTolerance
      }
    };
  }
  return state;
};

const addSingleRouteMenuPositionsMarkers = (state = INITIAL_STATE, action) => {
  const { position } = action;
  const singleRouteMenuPositionsMarkersSelected = state.singleRouteMenuPositionsMarkersSelected;
  if (singleRouteMenuPositionsMarkersSelected.findIndex(x => x.id === position.id) === -1) {
    singleRouteMenuPositionsMarkersSelected.push(position);
  }
  return {
    ...state,
    singleRouteMenuPositionsMarkersSelected
  };
};

const removeSingleRouteMenuPositionsMarkers = (state = INITIAL_STATE, action) => {
  const { position } = action;
  const singleRouteMenuPositionsMarkersSelected = state.singleRouteMenuPositionsMarkersSelected;
  const index = singleRouteMenuPositionsMarkersSelected.findIndex(x => x.id === position.id);
  if (index !== -1) {
    singleRouteMenuPositionsMarkersSelected.splice(index, 1);
  }
  return {
    ...state,
    singleRouteMenuPositionsMarkersSelected
  };
};

const closeMenuGpsSignal = (state = INITIAL_STATE, action) => {
  const { cropedPosition, originalRoute, orderAsc, patrimony, page, pages } = state.singleRoute;
  const { end, start } = state.singleRouteStartEnd;
  return addSingleRoute({
    ...state,
    singleRouteMenuGpsSignalSelected: false
  }, { end, options: { orderAsc, page, pages }, patrimony, route: originalRoute, routeCroped: cropedPosition ? cropedPosition.id : false, start, parameters: state.singleRouteParameters });
};

const openMenuGpsSignal = (state = INITIAL_STATE, action) => {
  const { cropedPosition, originalRoute, orderAsc, patrimony, page, pages } = state.singleRoute;
  const { end, start } = state.singleRouteStartEnd;
  return addSingleRoute({
    ...state,
    singleRouteMenuGpsSignalSelected: true
  }, { end, options: { orderAsc, page, pages }, patrimony, route: originalRoute, routeCroped: cropedPosition ? cropedPosition.id : false, start, parameters: state.singleRouteParameters });
};

const closeMenuPositions = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    singleRouteMenuPositionsSelected: false,
    singleRouteMenuPositionsMarkersSelected: []
  };
};

const openMenuPositions = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    singleRouteMenuPositionsSelected: true,
    singleRouteMenuPositionsMarkersSelected: []
  };
};

const removeSingleRoute = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    singleRoute: false,
    singleRouteStartEnd: false,
    singleRouteMenu: false,
    singleRouteMenuSelected: false,
    singleRouteMenuGpsSignalSelected: false,
    singleRouteMenuPositionsSelected: false,
    singleRouteMenuPositionsMarkersSelected: [],
    singleRouteTracker: false,
    singleRouteParameters: INITIAL_STATE.singleRouteParameters
  };
};

const selectMenuSingleRoute = (state = INITIAL_STATE, action) => {
  const { id } = action;
  return {
    ...state,
    singleRouteMenuSelected: {
      id,
      value: state.singleRouteMenu[id]
    },
    singleRouteMenuPositionsMarkersSelected: []
  };
};

const selectMenuSingleRouteCroped = (state = INITIAL_STATE, action) => {
  const { id } = action;
  const singleRoute = state.singleRoute;
  if (singleRoute) {
    const { originalRoute, orderAsc, patrimony, page, pages } = singleRoute;
    const { end, start } = state.singleRouteStartEnd;
    return addSingleRoute({
      ...state
    }, { end, options: { orderAsc, page, pages }, patrimony, route: originalRoute, routeCroped: Number(id), start, parameters: state.singleRouteParameters });
  }
  return state;
}

const selectSingleRouteTracker = (state = INITIAL_STATE, action) => {
  const { tracker } = action;
  const { cropedPosition, originalRoute, orderAsc, patrimony, page, pages } = state.singleRoute;
  const { end, start } = state.singleRouteStartEnd;
  return addSingleRoute({
    ...state,
    singleRouteTracker: tracker
  }, { end, options: { orderAsc, page, pages }, patrimony, route: originalRoute, routeCroped: cropedPosition ? cropedPosition.id : false, start, parameters: state.singleRouteParameters });
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_SINGLE_ROUTE]: addSingleRoute,
  [Types.CHANGE_SINGLE_ROUTE_DOWNTIME]: changeSingleRouteDowntime,
  [Types.CHANGE_SINGLE_ROUTE_SPEED]: changeSingleRouteSpeed,
  [Types.CLOSE_MENU_GPS_SIGNAL]: closeMenuGpsSignal,
  [Types.CLOSE_MENU_POSITIONS]: closeMenuPositions,
  [Types.OPEN_MENU_GPS_SIGNAL]: openMenuGpsSignal,
  [Types.OPEN_MENU_POSITIONS]: openMenuPositions,
  [Types.REMOVE_SINGLE_ROUTE]: removeSingleRoute,
  [Types.SELECT_MENU_SINGLE_ROUTE]: selectMenuSingleRoute,
  [Types.SELECT_MENU_SINGLE_ROUTE_CROPED]: selectMenuSingleRouteCroped,
  [Types.SELECT_SINGLE_ROUTE_TRACKER]: selectSingleRouteTracker,
  [Types.ADD_SINGLE_ROUTE_MENU_POSITIONS_MARKERS]: addSingleRouteMenuPositionsMarkers,
  [Types.REMOVE_SINGLE_ROUTE_MENU_POSITIONS_MARKERS]: removeSingleRouteMenuPositionsMarkers
});
