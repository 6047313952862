import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as DatabaseTestActions } from "./../../store/ducks/databaseTest";
import { Creators as LastPositionActions } from "./../../store/ducks/lastPositions";
import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as MenusActions } from "./../../store/ducks/menus";
import { Creators as RegionsActions } from "./../../store/ducks/regions";
import { Creators as UsersActions } from "./../../store/ducks/users";
import { Creators as VirtualFencesActions } from "./../../store/ducks/virtualFences";

import CityService from "./../../services/City";
import UserService from "./../../services/User";
import VirtualFenceService from "./../../services/VirtualFence";
import MenuOptionsItem from "./../MenuOptionsItem";

import { isScopes } from "./../../services/Auth";

const MenuOptions = ({
  props: {
    currentUser,
    databaseMounted,
    databaseTest,
    lastDateCorrections,
    lastDateCorrectionsSearch,
    lastDateCorrectionsSearchQueryEnabled,
    lastDelayedLocations,
    lastDelayedLocationsSearch,
    lastDelayedLocationsSearchQueryEnabled,
    lastNotifications,
    lastNotificationsSearch,
    lastNotificationsSearchQueryEnabled,
    lastPositionLoad,
    lastPositionPatrimonyType,
    lastPositionNipleEnabled,
    lastPositionVehicleEnabled,
    menu,
    regions,
    virtualFencesLoad
  },
  funcs: {
    addRegions,
    setVirtualFences,
    disableDatabaseMounted,
    disableDatabaseTest,
    disableLoader,
    enableDatabaseMounted,
    enableDatabaseTest,
    enableLoader,
    menuHandleActionType,
    menuHandleOpen,
    removeRegions,
    setCurrentUserProfileMapMiniMarkers,
    setCurrentUserProfileMapTraffic,
    setCurrentUserProfileMapType,
    setLastPositionPatrimonyType,
    removeVirtualFences
  }
}) => {
  const { t } = useTranslation();
  const cityService = new CityService();
  const userService = new UserService();
  const virtualFenceService = new VirtualFenceService();

  const [miniMarkers, setMiniMarkers] = useState(currentUser.profile.map.miniMarkers);
  const [satellite, setSatellite] = useState(currentUser.profile.map.type === "SAT" ? true : false);
  const [traffic, setTraffic] = useState(currentUser.profile.map.traffic);

  const [virtualFencesOpen, setVirtualFencesOpen] = useState(false);
  const [virtualFencesExec, setVirtualFencesExec] = useState(false);

  const [regionsOpen, setRegionsOpen] = useState(false);
  const [regionsExec, setRegionsExec] = useState(false);

  useEffect(() => {
    if(regionsOpen && !regions && !regionsExec) {
      setRegionsExec(true);
      setTimeout(async () => {
	      enableLoader();
        const regions = await cityService.findByStateExternalId({ externalId: 42 });
        addRegions(regions);
        disableLoader();
      }, 100);
    }
    else if(!regionsOpen && regions) {
      setRegionsExec(false);
      removeRegions();
    }
  }, [regionsOpen]); /* eslint-disable-line */

  useEffect(() => {
    if (lastPositionLoad && isScopes({ currentUser, scopes: ["is:master", "menu:registers:virtualFences"], every: false })) {
      if(virtualFencesOpen && !virtualFencesLoad && !virtualFencesExec) {
        setVirtualFencesExec(true);
        setTimeout(async () => {
          enableLoader();
          const virtualFences = await virtualFenceService.findAllActive({ data: {
            filter: {
              search: ""
            },
            limit: 1000,
            page: 1
          }, token: currentUser.token });
          setVirtualFences(virtualFences.docs);
          disableLoader();
        }, 100);
      }
      else if(!virtualFencesOpen && virtualFencesLoad) {
        setVirtualFencesExec(false);
        removeVirtualFences();
      }
    }
  }, [lastPositionLoad, virtualFencesOpen]); /* eslint-disable-line */

  useEffect(() => {
    if (virtualFencesLoad && !virtualFencesOpen && !virtualFencesExec) {
      setVirtualFencesOpen(true);
      setVirtualFencesExec(true);
    }
  }, [virtualFencesLoad]);

  useEffect(() => {
    setMiniMarkers(currentUser.profile.map.miniMarkers);
    setSatellite(currentUser.profile.map.type === "SAT" ? true : false);
    setTraffic(currentUser.profile.map.traffic);
  }, [currentUser]);

  const changeMap = async () => {
    enableLoader();
    await userService.changeMap({
      id: currentUser.id,
      data: currentUser.profile.map,
      token: currentUser.token
    });
    disableLoader();
  };

  const options = [
    {
      id: "moi-notification",
      title: t("Title.Notifications"),
      icon: <i className="fas fa-bell" />,
      getCount: () => lastNotificationsSearchQueryEnabled ? lastNotificationsSearch.length : lastNotifications.length,
      onClick: () => {
        menuHandleActionType("notifications");
        menuHandleOpen();
      },
      selected: () => menu.actionType === "notifications" ? true : false,
      disabled: () => false,
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:notifications"], every: false })
    },
    {
      id: "moi-date-correction",
      title: t("Title.DateCorrection"),
      icon: <i className="fas fa-eye-dropper" />,
      getCount: () => lastDateCorrectionsSearchQueryEnabled ? lastDateCorrectionsSearch.length : lastDateCorrections.length,
      onClick: () => {
        menuHandleActionType("dateCorrection");
        menuHandleOpen();
      },
      selected: () => menu.actionType === "dateCorrection" ? true : false,
      disabled: () => false,
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "moi-delayed-location",
      title: t("Title.DelayedLocation"),
      icon: <i className="fas fa-clock" />,
      getCount: () => lastDelayedLocationsSearchQueryEnabled ? lastDelayedLocationsSearch.length : lastDelayedLocations.length,
      onClick: () => {
        menuHandleActionType("delayedLocation");
        menuHandleOpen();
      },
      selected: () => menu.actionType === "delayedLocation" ? true : false,
      disabled: () => false,
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "moi-patrimony-type",
      title: lastPositionPatrimonyType ? t("Title.Patrimony.Type.See.Vehicle") : t("Title.Patrimony.Type.See.Niple"),
      icon: <img src={process.env.PUBLIC_URL + "/images/niple.png"} alt={lastPositionPatrimonyType ? t("Title.Patrimony.Type.Niple") : t("Title.Patrimony.Type.Vehicle")} />,
      onClick: () => {
        menuHandleActionType("positions");
        setLastPositionPatrimonyType(!lastPositionPatrimonyType);
        menuHandleOpen();
      },
      selected: () => lastPositionPatrimonyType,
      disabled: () => false,
      scoped: () => (lastPositionNipleEnabled && lastPositionVehicleEnabled)
    },
    {
      id: "moi-satellite",
      title: t("Title.Satellite"),
      icon: <i className="fas fa-satellite" />,
      onClick: () => {
        setCurrentUserProfileMapType(satellite ? "DEF" : "SAT");
        setSatellite(!satellite);
        changeMap();
      },
      selected: () => satellite,
      disabled: () => false,
      scoped: () => true
    },
    {
      id: "moi-traffic",
      title: t("Title.Traffic"),
      icon: <i className="fas fa-traffic-light" />,
      onClick: () => {
        setCurrentUserProfileMapTraffic(!traffic);
        setTraffic(!traffic);
        changeMap();
      },
      selected: () => traffic,
      disabled: () => false,
      scoped: () => true
    },
    {
      id: "moi-pin",
      title: t("Title.MiniMarkers"),
      icon: <i className="fas fa-map-pin" />,
      onClick: () => {
        setCurrentUserProfileMapMiniMarkers(!miniMarkers);
        setMiniMarkers(!miniMarkers);
        changeMap();
      },
      selected: () => miniMarkers,
      disabled: () => false,
      scoped: () => true
    },
    {
      id: "moi-regions",
      title: t("Title.Regions"),
      icon: <i className="fas fa-globe-americas" />,
      onClick: () => setRegionsOpen(!regionsOpen),
      selected: () => regionsOpen,
      disabled: () => false,
      scoped: () => true
    },
    {
      id: "moi-virtual-fences",
      title: t("Title.VirtualFences"),
      icon: <i className="fas fa-sign" />,
      onClick: () => setVirtualFencesOpen(!virtualFencesLoad),
      selected: () => virtualFencesLoad,
      disabled: () => false,
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:registers:virtualFences"], every: false })
    },
    {
      id: "moi-database-mounted",
      title: databaseMounted ? t("Title.DatabaseUnmounted") : t("Title.DatabaseMounted"),
      icon: <i className="fas fa-layer-group" />,
      onClick: () => databaseMounted ? disableDatabaseMounted() : enableDatabaseMounted(),
      selected: () => databaseMounted,
      disabled: () => false,
      scoped: () => isScopes({ currentUser, scopes: ["is:master"], every: false })
    },
    {
      id: "moi-database-test",
      title: databaseTest ? t("Title.DatabaseMain") : t("Title.DatabaseTest"),
      icon: <i className="fas fa-database" />,
      onClick: () => databaseTest ? disableDatabaseTest() : enableDatabaseTest(),
      selected: () => databaseTest,
      disabled: () => false,
      scoped: () => isScopes({ currentUser, scopes: ["is:master"], every: false })
    }
  ];

  return (
    <aside className={`aside-menu-options ${menu.toggled ? "" : "active"}`}>
      <ul>
        {
          options.map(option => {
            if(option.scoped()) {
              return (
                <MenuOptionsItem key={option.id} {...option} onClick={option.onClick} />
              );
            }
            return null;
          })
        }
      </ul>
    </aside>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    databaseMounted: state.databaseTest.databaseMounted,
    databaseTest: state.databaseTest.databaseTest,
    lastDateCorrections: state.lastDateCorrections.dateCorrections,
    lastDateCorrectionsSearch: state.lastDateCorrections.dateCorrectionsSearch,
    lastDateCorrectionsSearchQueryEnabled: state.lastDateCorrections.dateCorrectionsSearchQueryEnabled,
    lastDelayedLocations: state.lastDelayedLocations.delayedLocations,
    lastDelayedLocationsSearch: state.lastDelayedLocations.delayedLocationsSearch,
    lastDelayedLocationsSearchQueryEnabled: state.lastDelayedLocations.delayedLocationsSearchQueryEnabled,
    lastNotifications: state.lastNotifications.notifications,
    lastNotificationsSearch: state.lastNotifications.notificationsSearch,
    lastNotificationsSearchQueryEnabled: state.lastNotifications.notificationsSearchQueryEnabled,
    lastPositionLoad: state.lastPositions.positionsLoad,
    lastPositionPatrimonyType: state.lastPositions.positionsSearchQuery.niple,
    lastPositionNipleEnabled: state.lastPositions.positionsNipleEnabled,
    lastPositionVehicleEnabled: state.lastPositions.positionsVehicleEnabled,
    menu: state.menus.menu,
    regions: state.regions.regions,
    virtualFencesLoad: state.virtualFences.virtualFencesLoad
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, DatabaseTestActions, LastPositionActions, LoadersActions, MenusActions, RegionsActions, UsersActions, VirtualFencesActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuOptions);
