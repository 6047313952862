const schemaReduced = {
  id: "Id do cliente",
  legal: {
    cnpj: "CNPJ do cliente",
    fantasyName: "Nome fantasia do cliente",
    socialReason: "Razão social do cliente"
  },
  personal: {
    cpf: "CPF do cliente",
    name: "Nome do cliente"
  },
  type: "Tipo do cliente"
};

export const schemaDate = {};

export default schemaReduced;
