import React, { memo, useEffect, useState } from "react";
import "./index.css";

import { getBattery, getHatchType, getNiple, getNipleBasic, getJammer, getPump } from "./../../../utils/Position";
import { isScopes } from "./../../../services/Auth";

const DEFAULT_ZINDEX = 1;
const SCALE_HOVER = 1;
const SCALE_NORMAL = 1;

const Patrimony = ({ onClick, patrimony, position, profile, selected }) => {
  const [eventHover, setEventHover] = useState(false);
  const [scale, setScale] = useState(SCALE_NORMAL);
  const [zIndex, setZIndex] = useState(DEFAULT_ZINDEX);

  useEffect(() => {
    setScale(eventHover ? SCALE_HOVER : SCALE_NORMAL);
    setZIndex(Math.round(scale * DEFAULT_ZINDEX) + (eventHover ? DEFAULT_ZINDEX : 0));
  }, [eventHover, scale]);

  const getBoardType = (patrimony) => {
    switch (patrimony.type) {
    case "AUT":
      if (patrimony.automobile.type === "BOA") {
        return patrimony.automobile.board.type;
      }
      return "CAR";
    case "OBJ":
      return "OBJ";
    case "VES":
      return "VES";
    default:
      return "CAR";
    }
  };

  /**
   * Marker data content
   */
  const markerDataContent = () => {
    switch (patrimony.type) {
    case "AUT":
      return markerAutomobile();
    case "OBJ":
      return markerObject();
    case "VES":
      return markerVessel();
    default:
      return markerAutomobile();
    }
  };

  const markerAutomobile = () => {
    switch (patrimony.automobile.type) {
    case "BOA":
      return markerAutomobileBoard();
    case "CAR":
      return markerAutomobileCard();
    default:
      return markerAutomobileBoard();
    }
  };

  const markerAutomobileBoard = () => {
    const board = patrimony.automobile.board;
    if(!profile.miniMarkers) {
      if(board.layout === "MER") {
        return (
          <>
            <div className="marker-data-top">
              <div className="marker-data-top-mercosul" />
              <div className="marker-data-top-country" />
              <div className="marker-data-top-flag" />
            </div>
            <div className="marker-data-bottom">
              {
                (board.type !== "MOT") ? (
                  <div className="marker-data-bottom-board">{board.board}</div>
                ) : (
                  <div className="marker-data-bottom-board">
                    <div className="marker-board-top">{board.board.substr(0, 3)}</div>
                    <div className="marker-board-bottom">{board.board.substr(3, 4)}</div>
                  </div>
                )
              }
            </div>
          </>
        );
      }
      else {
        return (
          <>
            <div className="marker-screw top" />
            <div className="marker-screw bottom" />
            <div className="marker-description">
              <div className="marker-description-all">{board.description}</div>
            </div>
            <div className="marker-board">
              {
                (board.type !== "MOT") ? (
                  <div className="marker-board-all">{board.board}</div>
                ) : (
                  <>
                    <div className="marker-board-top">{board.board.substr(0, 3)}</div>
                    <div className="marker-board-bottom">{board.board.substr(4, 4)}</div>
                  </>
                )
              }
            </div>
          </>
        );
      }
    }
    else {
      return (
        <div className="marker-mini-board-all">{patrimony.automobile.board.board}</div>
      );
    }
  };

  const markerAutomobileCard = () => {
    return (
      <div className="marker-mini-board-all">{patrimony.automobile.card.number}</div>
    );
  };

  const markerObject = () => {
    return (
      <div className="marker-mini-board-all">{patrimony.object.name}</div>
    );
  };

  const markerVessel = () => {
    const { description, name, registrationNumber } = patrimony.vessel;
    return (
      <div className="marker-mini-board-all">
        <div className="marker-vessel-icon" />
        <ul>
          <li><div>{description}</div></li>
          <li><div>{name}</div></li>
          <li><div>{registrationNumber}</div></li>
        </ul>
      </div>
    );
  };

  /**
   * Marker data notification
   */
  const markerDataNotification = () => {
    const notifications = [
      {
        check: position.tracker.anchor && !position.tracker.anchorViolated,
        render: <>
          <i className="fa-solid fa-anchor" />
          <i className="fa-solid fa-check icon-anchor-status-ok" />
        </>
      },
      {
        check: position.tracker.anchor && position.tracker.anchorViolated,
        render: <>
          <i className="fa-solid fa-anchor" style={{ zIndex: 1 }} />
          <i className="fa-solid fa-exclamation icon-anchor-status-violated" />
        </>
      },
      {
        check: getBattery(position),
        render: <i className="fas fa-battery-empty" />
      },
      {
        check: position.tracker.block,
        render: <i className="fas fa-lock" />
      },
      {
        check: position.tracker.delayedLocation,
        render: <i className="fas fa-clock" />
      },
      {
        check: position.tracker.ignition,
        render: <i className="fas fa-key" />
      },
      {
        check: getJammer(position),
        render: <i className="fas fa-broadcast-tower" />
      },
      {
        check: position.tracker.panic,
        render: <i className="fas fa-skull-crossbones" />
      },
      {
        check: position.tracker.maintenance,
        render: <span>{"M"}</span>
      },
      {
        check: position.tracker.maintenanceNeed,
        render: <i className="fa-solid fa-screwdriver-wrench" />
      }
    ];

    let niples = [];
    let hatchs = [];
    if(isScopes({ currentUser: profile.currentUser, scopes: ["read:patrimonies:basic"], every: false })) {
      const nipleBasic = getNipleBasic(position);
      niples = nipleBasic.niples;
    }
    if(isScopes({ currentUser: profile.currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })) {
      const nipleMaster = getNiple(position);
      niples = nipleMaster.niples;
      hatchs = nipleMaster.hatchs;
    }

    const pump = getPump(position);
    if (pump && pump.operation === "ON") {
      notifications.push({
        check: true,
        render: <i className="fas fa-tint" />
      });
    }

    if(isScopes({ currentUser: profile.currentUser, scopes: ["is:master", "read:patrimonies:basic", "read:patrimonies:description"], every: false })) {
      let countFraud = 0;
      let countHOpen = 0;

      niples.map(niple => {
        if (niple.operationBoardFraud !== false && countFraud === 0) {
          countFraud++;
          notifications.push({
            check: true,
            render: <span>B</span>
          });
        }
        if (niple.operationBoard !== false) {
          notifications.push({
            check: niple.check,
            render: <span>{niple.operationBoard}</span>
          });
        }
        return false;
      });

      hatchs.map(hatch => {
        const type = position.patrimony.trackers.find(x => x.serial === hatch.hatch.serial);
        if(getHatchType(type) === "DEF") {
          if (hatch.check) {
            let show = false;
            if (hatch.operation === "B" && countFraud === 0) {
              countFraud++;
              show = true;
            }
            else if (hatch.operation === "A" && countHOpen === 0) {
              countHOpen++;
              show = true;
            }
            if (show) {
              notifications.push({
                check: hatch.check,
                render: <span>{hatch.operation}</span>
              });
            }
          }
        }
        return false;
      });
    }

    return notifications.map((notification, index) => (
      notification.check ? <li key={`notification-${index}-${position.index}`}>{notification.render}</li> : null
    ));
  };

  return (
    <div
      className="marker-all"
      board-color={(patrimony.automobile && patrimony.automobile.board && patrimony.automobile.board.color) || ""}
      board-country={(patrimony.automobile && patrimony.automobile.board && patrimony.automobile.board.country) || ""}
      board-layout={(patrimony.automobile && patrimony.automobile.board && patrimony.automobile.board.layout) || ""}
      board-type={getBoardType(patrimony || "")}
      mini-markers={profile.miniMarkers ? "true" : "false"}
      onClick={onClick}
      onMouseEnter={e => setEventHover(true)}
      onMouseLeave={e => setEventHover(false)}
      style={{
        transform: `scale(${scale} , ${scale})`,
        MozTransform: `scale(${scale} , ${scale})`,
        WebkitTransform: `scale(${scale} , ${scale})`,
        zIndex: zIndex
      }}
    >
      <div className="marker-data">
        <div className="marker-data-content">
          { markerDataContent() }
        </div>
        <div className="marker-data-notification">
          <ul>
            { markerDataNotification() }
          </ul>
        </div>
      </div>
      <div className="marker-crop">
        <div className="marker-crop-crop"></div>
      </div>
      <div className={`marker-selected ${selected ? "active" : ""}`} style={{
        transform: `rotate(${position.gps.course}deg)`,
        MozTransform: `rotate(${position.gps.course}deg)`,
        WebkitTransform: `rotate(${position.gps.course}deg)` }}>
        <div className="marker-selected-direction" />
      </div>
    </div>
  );
};

export default memo(Patrimony);
