import ClientSchemaReduced, { schemaDate as schemaDateClient } from "../Client/SchemaReducedTitle";
import ContractSchemaReduced, { schemaDate as schemaDateContract } from "../Contract/SchemaReducedTitle";
import DriverSchemaReduced, { schemaDate as schemaDateDriver } from "../Driver/SchemaReducedTitle";

const schema = {
  active: "Status",
  activeExporter: "Status de exportação",
  activeProduction: "Status de produção",
  automobile: {
    board: {
      autoVaccum: {
        backCover: "Tipo de tampa traseira",
        license: "Licença"
      },
      board: "Placa",
      color: "Cor da placa",
      country: "País",
      description: "Descrição",
      layout: "Layout da placa",
      type: "Tipo de veículo emplacado"
    },
    card: {
      number: "Número da tarjeta"
    },
    brand: "Marca",
    color: "Cor predominante",
    model: "Modelo",
    type: "Tipo de veículo"
  },
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  contract: JSON.parse(JSON.stringify(ContractSchemaReduced)),
  driver: JSON.parse(JSON.stringify(DriverSchemaReduced)),
  id: "Id",
  installAt: "Data de instalação",
  object: {
    name: "Nome do objeto"
  },
  parameters: {
    consumptionAverage: "Consumo médio",
    delayedLocation: "Tolerância de localização atrasada",
    downtimeTolerance: "Tolerância de tempo parado",
    fixRoutePeriodic: "Correção de rota automática",
    fixRouteReport: "Correção de rota no relatório",
    maintenanceMotorMaxHours: "Manutenção de motor (horas)",
    maintenanceMotorMaxMonths: "Manutenção de motor (meses)",
    maxSpeed: "Velocidade máxima",
    runMinHour: "Horário mínimo permitido (deslocamento)",
    runMaxHour: "Horário máximo permitido (deslocamento)"
  },
  pictures: "Fotos",
  scopes: "Permissões",
  serial: "Serial",
  trackers: "Rastreadores",
  type: "Tipo de patrimônio",
  uninstallAt: "Data de desinstalação",
  vessel: {
    brand: "Marca da embarcação",
    description: "Descrição da embarcação",
    model: "Modelo da embarcação",
    motors: "Número de motores da embarcação",
    name: "Nome da embarcação",
    registrationNumber: "Número de registro da embarcação",
    type: "Tipo da embarcação"
  },
  virtualFences: "Cercas virtuais"
};

export const schemaDate = {
  createdAt: "datetime",
  client: JSON.parse(JSON.stringify(schemaDateClient)),
  contract: JSON.parse(JSON.stringify(schemaDateContract)),
  driver: JSON.parse(JSON.stringify(schemaDateDriver)),
  installAt: "date",
  uninstallAt: "date",
  updatedAt: "datetime"
};

export default schema;
