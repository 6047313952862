import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  disablePinboard: [],
  enablePinboard: ["value"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  description: "",
  handleCancel: () => {},
  handleExecute: () => {},
  onlyConfirm: false,
  open: false
};

const disable = (state = INITIAL_STATE, action) => {
  return {
    ...INITIAL_STATE,
    open: false
  };
};

const enable = (state = INITIAL_STATE, action) => {
  const { value } = action;
  return {
    ...value,
    open: true
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.DISABLE_PINBOARD]: disable,
  [Types.ENABLE_PINBOARD]: enable
});
