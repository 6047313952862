import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import DateUtil from "../../utils/Date";
import { getDescriptionClient } from "../../utils/Client";
import { getDescriptionPatrimony, isAutomobile, isAutomobileBoardAutoVaccum } from "../../utils/Patrimony";
import { getNiple, getNipleBasic, getPump } from "../../utils/Position";
import { isScopes } from "../../services/Auth";

const LastPositionListItem = ({
  options: { id, onClick, position, selected },
  props: { currentUser }
}) => {
  const { t } = useTranslation();

  let hatchs = [];
  let niples = [];
  if(isScopes({ currentUser, scopes: ["read:patrimonies:basic"], every: false })) {
    const nipleBasic = getNipleBasic(position, false);
    niples = nipleBasic.niples;
  }
  else if(isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })) {
    const nipleMaster = getNiple(position, false);
    hatchs = nipleMaster.hatchs;
    niples = nipleMaster.niples;
  }

  const pump = getPump(position, false);

  return (
    <li className={`last-position-list-item ${selected ? "active" : ""}`} onClick={onClick}>
      {
        (
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
          && typeof position._events !== "undefined"
          && (
            position._events.ignitionOn > 0 ||
            position._events.ignitionOff > 0 ||
            position._events.battery > 0 ||
            position._events.maintenance > 0 ||
            position._events.espRestarted > 0 ||
            position._events.nipleFraud > 0 ||
            position._events.nipleClosed > 0 ||
            position._events.nipleDischarge > 0 ||
            position._events.nipleDischargeTest > 0 ||
            position._events.nipleCharge > 0 ||
            position._events.nipleChargeTest > 0 ||
            position._events.hatchOpen > 0
          )
          && (
            <div className="positions-item-events">
              <ul>
                {
                  (position._events.ignitionOn > 0 && (
                    <li>
                      <div className="icon"><i className="fas fa-key" style={{ color: "#087d06" }} /></div>
                      <div className="count">{position._events.ignitionOn}</div>
                    </li>
                  ))
                }
                {
                  (position._events.ignitionOff > 0 && (
                    <li>
                      <div className="icon"><i className="fas fa-key" style={{ color: "#ff0000" }} /></div>
                      <div className="count">{position._events.ignitionOff}</div>
                    </li>
                  ))
                }
                {
                  (position._events.battery > 0 && (
                    <li>
                      <div className="icon"><i className="fas fa-battery-empty" /></div>
                      <div className="count">{position._events.battery}</div>
                    </li>
                  ))
                }
                {
                  (position._events.maintenance > 0 && (
                    <li>
                      <div className="icon">M</div>
                      <div className="count">{position._events.maintenance}</div>
                    </li>
                  ))
                }
                {
                  (position._events.espRestarted > 0 && (
                    <li>
                      <div className="icon">R</div>
                      <div className="count">{position._events.espRestarted}</div>
                    </li>
                  ))
                }
                {
                  (position._events.nipleFraud > 0 && (
                    <li>
                      <div className="icon">B</div>
                      <div className="count">{position._events.nipleFraud}</div>
                    </li>
                  ))
                }
                {
                  (position._events.nipleClosed > 0 && (
                    <li>
                      <div className="icon">F</div>
                      <div className="count">{position._events.nipleClosed}</div>
                    </li>
                  ))
                }
                {
                  (position._events.nipleDischarge > 0 && (
                    <li>
                      <div className="icon">D</div>
                      <div className="count">{position._events.nipleDischarge}</div>
                    </li>
                  ))
                }
                {
                  (position._events.nipleDischargeTest > 0 && (
                    <li>
                      <div className="icon" style={{ color: "#999999" }}>D</div>
                      <div className="count">{position._events.nipleDischargeTest}</div>
                    </li>
                  ))
                }
                {
                  (position._events.nipleCharge > 0 && (
                    <li>
                      <div className="icon">C</div>
                      <div className="count">{position._events.nipleCharge}</div>
                    </li>
                  ))
                }
                {
                  (position._events.nipleChargeTest > 0 && (
                    <li>
                      <div className="icon" style={{ color: "#999999" }}>C</div>
                      <div className="count">{position._events.nipleChargeTest}</div>
                    </li>
                  ))
                }
                {
                  (position._events.hatchOpen > 0 && (
                    <li>
                      <div className="icon">A</div>
                      <div className="count">{position._events.hatchOpen}</div>
                    </li>
                  ))
                }
              </ul>
            </div>
          )
        )
      }
      {
        (
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
          && typeof position._events !== "undefined"
          && (
            position._events.lastNipleCharge ||
            position._events.lastNipleChargeTest ||
            position._events.lastNipleDischarge ||
            position._events.lastNipleDischargeTest
          )
          && (
            <div className="positions-item-events-description">
              <ul>
                {
                  (
                    position._events.lastNipleCharge
                    && (
                      <li>
                        <div className="icon">C</div>
                        <div className="description"><span>{DateUtil.to({ date: new Date(position._events.lastNipleCharge), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    )
                  )
                }
                {
                  (
                    position._events.lastNipleChargeTest
                    && (
                      <li>
                        <div className="icon" style={{ color: "#999999" }}>C</div>
                        <div className="description"><span>{DateUtil.to({ date: new Date(position._events.lastNipleChargeTest), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    )
                  )
                }
                {
                  (
                    position._events.lastNipleDischarge
                    && (
                      <li>
                        <div className="icon">D</div>
                        <div className="description"><span>{DateUtil.to({ date: new Date(position._events.lastNipleDischarge), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    )
                  )
                }
                {
                  (
                    position._events.lastNipleDischargeTest
                    && (
                      <li>
                        <div className="icon" style={{ color: "#999999" }}>D</div>
                        <div className="description"><span>{DateUtil.to({ date: new Date(position._events.lastNipleDischargeTest), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    )
                  )
                }
              </ul>
            </div>
          )
        )
      }
      <ul>
        <li>
          <div>{t("Title.Client")}:</div>
          <div><span><strong>{getDescriptionClient(position.patrimony.client)}</strong></span></div>
        </li>
        <li>
          <div>{t("Patrimony")}:</div>
          <div><span><strong>{getDescriptionPatrimony(position.patrimony)}</strong></span></div>
        </li>
        {
          isAutomobile(position.patrimony) ? (
            <>
              <li>
                <div>{t("Title.Brand")}:</div>
                <div><span><strong>{position.patrimony.automobile.brand}</strong></span></div>
              </li>
              <li>
                <div>{t("Title.Model")}:</div>
                <div><span><strong>{position.patrimony.automobile.model}</strong></span></div>
              </li>
            </>
          ) : null
        }
        {
          isAutomobileBoardAutoVaccum(position.patrimony) ? (
            <li>
              <div>{t("Title.LAO")}:</div>
              <div><span style={{ color: position.patrimony.automobile.board.autoVaccum.license === "000000/0000" ? "#ff0000" : "#000" }}><strong>{position.patrimony.automobile.board.autoVaccum.license}</strong></span></div>
            </li>
          ) : null
        }
        <li>
          <div>{t("Date")}:</div>
          <div><span>{DateUtil.to({ date: new Date(position.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
        </li>
        <li>
          <div>{t("Address")}:</div>
          <div><span>{position.gps.address}</span></div>
        </li>
        <li>
          <div>{t("Speed")}:</div>
          <div><span>{position.gps.speed} Km/h</span></div>
        </li>
        {
          (position.tracker.anchor && (
            <li>
              <div>{t("Anchor")}:</div>
              {
                (position.tracker.anchorViolated && (
                  <div><span style={{ color: "#ff0000" }}><strong>{t("Title.Anchor.Violated") }</strong></span></div>
                ))
              }
              {
                (!position.tracker.anchorViolated && (
                  <div><span>{t("Active")}</span></div>
                ))
              }
            </li>
          ))
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:basic", "read:patrimonies:description"], every: false }) && niples.map((niple, index) => {
            return (
              <li key={`lpl-niple-op-${index}`}>
                <div>{t("Title.Niple")} {(index + 1)}:</div>
                <div><span>{t(niple.operationBoard ? niple.operationBoard : niple.operationBoardFraud)}</span></div>
              </li>
            );
          })
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && hatchs.map((hatch, index) => {
            return (
              <li key={`lpl-hatch-op-${index}`}>
                <div>{t("Title.Hatch")} {(index + 1)}:</div>
                <div><span>{t(hatch.operation)}</span></div>
              </li>
            );
          })
        }
        {
          (pump &&
            <li>
              <div>{t("Title.Pump")}:</div>
              <div><span>{t(pump.operation)}</span></div>
            </li>
          )
        }
      </ul>
      <div className={`positions-item-ignition ${position.tracker.ignition ? "ignition-on" : "ignition-off"}`}><i className="fas fa-key"></i></div>
    </li>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastPositionListItem);
