import React from "react";
import DateUtil from "./../../../utils/Date";

import SchemaTitle, { schemaDate as schemaDateBase } from "./../SchemaTitle";

const prefix = "support-point";

export function getCols() {
  return [{
    width: "50%",
    type: "string",
    label: "Title.Changed"
  }, {
    width: "25%",
    type: "string",
    label: "Title.Date"
  }, {
    width: "25%",
    type: "string",
    label: "Title.User"
  }];
}

export function getIgnore(parent) {
  return parent !== "createdAt" && parent !== "updatedAt";
}

export function getRows(docs, t) {
  const rows = [];
  const schema = JSON.parse(JSON.stringify(SchemaTitle));
  const schemaDate = JSON.parse(JSON.stringify(schemaDateBase));
  docs.map(item => {
    const itemChanged = (item.diff ? (Array.isArray(item.diff) ? item.diff : []) : []);
    const itemChangedNew = itemChanged.filter(df => (df.item === "__new" && getIgnore(df.parent)));
    const itemChangedOld = itemChanged.filter(df => (df.item === "__old" && getIgnore(df.parent)));
    const itemChangedTitle = itemChangedNew.map(df => findAllGridTranslateItem({ data: schema, item: df.parent, cancelObject: true }));
    return rows.push([{
      id: item.id,
      value: itemChangedTitle.length > 0 ? itemChangedTitle.sort((a, b) => a.localeCompare(b)).join(", ") : t("Title.Changed.Nothing"),
      valueExpand: (
        <>
          <ul>
            <li key={`${prefix}-log-ic-${item.id}-user`}>
              <div><span>Alterações feitas por <strong>{findAllGridTranslateItem({ data: item.user, item: "name" })}:</strong></span></div>
            </li>
            {
              itemChangedOld.map((ic, ici) => {
                const newChange = itemChangedNew.find(icn => icn.parent === ic.parent);
                const oldValueDate = findAllGridTranslateItem({ data: schemaDate, item: ic.parent });
                let oldValue = (typeof oldValueDate === "string") ? (oldValueDate === "datetime" ? DateUtil.to({ date: new Date(ic.value), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"}) : DateUtil.to({ date: new Date(ic.value), format: "DD/MM/YYYY", tz: "UTC"})) : ic.value;
                let newValue = newChange ? ((typeof oldValueDate === "string") ? (oldValueDate === "datetime" ? DateUtil.to({ date: new Date(newChange.value), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"}) : DateUtil.to({ date: new Date(newChange.value), format: "DD/MM/YYYY", tz: "UTC"})) : newChange.value) : "Vazio";

                if (oldValue === true) oldValue = "Ativo";
                if (oldValue === false) oldValue = "Inativo";

                if (newValue === true) newValue = "Ativo";
                if (newValue === false) newValue = "Inativo";
                return (
                  <li key={`${prefix}-log-ic-${item.id}-${ic.parent}-${ici}`}>
                    {
                      (((typeof oldValue !== "undefined" && oldValue !== null) && (typeof newValue !== "undefined" && newValue !== null)) && (
                          <>
                            {
                              ((Array.isArray(oldValue) && Array.isArray(newValue)) && (
                                <>
                                  <div><span><strong>{findAllGridTranslateItem({ data: schema, item: ic.parent, cancelObject: true })}</strong>:</span></div>
                                  <div>
                                    <span>
                                      <ul style={{ paddingLeft: "15px" }}>
                                        {
                                          oldValue.map((oldValueItem, oldValueItemIndex) => {
                                            const haveTitle = findAllGridTranslateItem({ data: schema, item: oldValueItem[0] });
                                            return (
                                              <li key={`${prefix}-log-ic-${item.id}-${ic.parent}-${ici}-subitem-old-${oldValueItemIndex}`}>
                                                {
                                                  ((haveTitle) && (
                                                    <><strong>{haveTitle}</strong> de <strong>{oldValueItem[1]}</strong></>
                                                  ))
                                                }
                                                {
                                                  ((!haveTitle) && (
                                                    <>{oldValueItem[1]}</>
                                                  ))
                                                }
                                              </li>
                                            )
                                          })
                                        }
                                      </ul>
                                      <ul style={{ paddingLeft: "15px" }}>
                                        {
                                          newValue.map((newValueItem, newValueItemIndex) => {
                                            const haveTitle = findAllGridTranslateItem({ data: schema, item: newValueItem[0] });
                                            return (
                                              <li key={`${prefix}-log-ic-${item.id}-${ic.parent}-${ici}-subitem-new-${newValueItemIndex}`}>
                                                {
                                                  ((haveTitle) && (
                                                    <><strong>{haveTitle}</strong> para <strong>{newValueItem[1]}</strong></>
                                                  ))
                                                }
                                                {
                                                  ((!haveTitle) && (
                                                    <>{newValueItem[1]}</>
                                                  ))
                                                }
                                              </li>
                                            )
                                          })
                                        }
                                      </ul>
                                    </span>
                                  </div>
                                </>
                              ))
                            }
                            {
                              ((!Array.isArray(oldValue) && !Array.isArray(newValue)) && (
                                <div><span><strong>{findAllGridTranslateItem({ data: schema, item: ic.parent, cancelObject: true })}</strong> de <strong>{oldValue}</strong> para <strong>{newValue}</strong></span></div>
                              ))
                            }
                          </>
                      ))
                    }
                    {
                      (((typeof oldValue === "undefined" || oldValue === null) && (typeof newValue !== "undefined" && newValue !== null)) && (
                        <>
                          {
                            (Array.isArray(newValue) && (
                              <>
                                <div><span><strong>{findAllGridTranslateItem({ data: schema, item: ic.parent, cancelObject: true })}</strong>:</span></div>
                                <div>
                                  <span>
                                    <ul style={{ paddingLeft: "15px" }}>
                                      {
                                        newValue.map((newValueItem, newValueItemIndex) => {
                                          const haveTitle = findAllGridTranslateItem({ data: schema, item: newValueItem[0] });
                                          return (
                                            <li key={`${prefix}-log-ic-${item.id}-${ic.parent}-${ici}-subitem-new-${newValueItemIndex}`}>
                                              {
                                                ((haveTitle) && (
                                                  <><strong>{haveTitle}</strong> de <strong>Nulo</strong> para <strong>{newValueItem[1]}</strong></>
                                                ))
                                              }
                                              {
                                                ((!haveTitle) && (
                                                  <>{newValueItem[1]}</>
                                                ))
                                              }
                                            </li>
                                          )
                                        })
                                      }
                                    </ul>
                                  </span>
                                </div>
                              </>
                            ))
                          }
                          {
                            (!Array.isArray(newValue) && (
                              <div><span><strong>{findAllGridTranslateItem({ data: schema, item: ic.parent, cancelObject: true })}</strong> de <strong>Nulo</strong> para <strong>{newValue}</strong></span></div>
                            ))
                          }
                        </>
                      ))
                    }
                    {
                      (((typeof oldValue !== "undefined" && oldValue !== null) && (typeof newValue === "undefined" || newValue === null)) && (
                        <>
                          {
                            (Array.isArray(oldValue) && (
                              <>
                                <div><span><strong>{findAllGridTranslateItem({ data: schema, item: ic.parent, cancelObject: true })}</strong>:</span></div>
                                <div>
                                  <span>
                                    <ul style={{ paddingLeft: "15px" }}>
                                      {
                                        oldValue.map((oldValueItem, oldValueItemIndex) => {
                                          const haveTitle = findAllGridTranslateItem({ data: schema, item: oldValueItem[0] });
                                          return (
                                            <li key={`${prefix}-log-ic-${item.id}-${ic.parent}-${ici}-subitem-old-${oldValueItemIndex}`}>
                                              {
                                                ((haveTitle) && (
                                                  <><strong>{haveTitle}</strong> de <strong>{oldValueItem[1]}</strong> para <strong>Nulo</strong></>
                                                ))
                                              }
                                              {
                                                ((!haveTitle) && (
                                                  <>{oldValueItem[1]}</>
                                                ))
                                              }
                                            </li>
                                          )
                                        })
                                      }
                                    </ul>
                                  </span>
                                </div>
                              </>
                            ))
                          }
                          {
                            (!Array.isArray(oldValue) && (
                              <div><span><strong>{findAllGridTranslateItem({ data: schema, item: ic.parent, cancelObject: true })}</strong> de <strong>{oldValue}</strong> para <strong>Nulo</strong></span></div>
                            ))
                          }
                        </>
                      ))
                    }
                  </li>
                )
              })
            }
            {
              ((itemChangedOld.length === 0) && (
                <li key={`${prefix}-log-ic-${item.id}-nothing`}>
                  <div><span>{t("Title.Changed.Nothing")}</span></div>
                </li>
              ))
            }
          </ul>
        </>
      )
    }, {
      value: findAllGridTranslateItem({ data: item, item: "createdAt" }) !== undefined ? DateUtil.to({ date: new Date(item.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"}) : "-"
    }, {
      value: findAllGridTranslateItem({ data: item.user, item: "name" })
    }]);
  });
  return rows;
}

export function findAllGridTranslateItem({ data, item, cancelObject }) {
  try {
    var itemAux = item.split(".");
    var dataAux = data;
    itemAux.map(it => {
      return dataAux = dataAux[it];
    });
    if (cancelObject && (typeof dataAux === "object")) return item;
    return dataAux;
  }
  catch (e) {
    return false;
  }
}
