import { createActions, createReducer } from "reduxsauce";
import { filterByClientAndWord, filterByClient, sortVirtualFences } from "./../../utils/VirtualFence";

import ClientSchemaReduced from "./../../services/Client/SchemaReduced";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addVirtualFences: ["virtualFences"],
  updateVirtualFences: ["id", "onClick"],
  searchVirtualFences: ["search"],
  removeVirtualFences: [],
  setVirtualFences: ["virtualFences"],
  setVirtualFencesSelected: ["virtualFences"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  virtualFences: [],
  virtualFencesSearch: [],
  virtualFencesSearchOnlyClient: [],
  virtualFencesSearchSelected: [],
  virtualFencesSearchQuery: {
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    word: "",
    active: -1
  },
  virtualFencesSearchQueryEnabled: false,
  virtualFencesSelected: false,
  virtualFencesLoad: false
};

const addVirtualFences = (state = INITIAL_STATE, action) => {
  const { virtualFences } = action;
  return {
    ...state,
    virtualFences
  };
};

const updateVirtualFences = (state = INITIAL_STATE, action) => {
  const { id, onClick } = action;
  if (state.virtualFencesLoad && (id !== null && onClick !== null)) {
    const index = state.virtualFences.findIndex(x => x.id === id);
    if (index !== -1) {
      const virtualFences = state.virtualFences;
      virtualFences[index].onClick = onClick;
      const { virtualFencesSearch } = searchVirtualFences({
        ...state,
        virtualFences,
        virtualFencesSelected: false
      }, { search: state.virtualFencesSearchQuery });

      return {
        ...state,
        virtualFences: sortVirtualFences(virtualFences),
        virtualFencesSearch: sortVirtualFences(virtualFencesSearch)
      }
    }
  }
  return state;
};

const removeVirtualFences = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    virtualFences: [],
    virtualFencesSearch: [],
    virtualFencesSearchOnlyClient: [],
    virtualFencesSearchQuery: {
      client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
      word: "",
      active: -1
    },
    virtualFencesSearchQueryEnabled: false,
    virtualFencesSelected: false,
    virtualFencesLoad: false
  };
};

const searchVirtualFences = (state = INITIAL_STATE, action) => {
  const { search } = action;
  const { enabled: virtualFencesSearchEnabled, results: virtualFencesSearchResults } = filterByClientAndWord(state.virtualFences, search);
  const { results: virtualFencesSearchOnlyClientResults } = filterByClient(state.virtualFences, search);
  return {
    ...state,
    virtualFencesSearch: virtualFencesSearchResults,
    virtualFencesSearchOnlyClient: virtualFencesSearchOnlyClientResults,
    virtualFencesSearchQuery: search,
    virtualFencesSearchQueryEnabled: virtualFencesSearchEnabled
  };
};

const setVirtualFences = (state = INITIAL_STATE, action) => {
  if(state.virtualFencesLoad === false) {
    const { virtualFences } = action;
    return {
      ...state,
      virtualFencesLoad: true,
      virtualFences: sortVirtualFences(virtualFences.map(virtualFence => {
        virtualFence.patrimonies = [];
        return { id: virtualFence.id, virtualFence, selected: false };
      })),
      virtualFencesSearch: INITIAL_STATE.virtualFencesSearch,
      virtualFencesSearchOnlyClient: sortVirtualFences(virtualFences.map(virtualFence => {
        virtualFence.patrimonies = [];
        return { id: virtualFence.id, virtualFence, selected: false };
      })),
      virtualFencesSearchSelected: INITIAL_STATE.virtualFencesSearchSelected,
      virtualFencesSearchQuery: INITIAL_STATE.virtualFencesSearchQuery,
      virtualFencesSearchQueryEnabled: INITIAL_STATE.virtualFencesSearchQueryEnabled
    };
  }
  return state;
};

const setVirtualFencesSelected = (state = INITIAL_STATE, action) => {
  let { virtualFences } = action;
  return {
    ...state,
    virtualFencesSearchSelected: virtualFences
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_VIRTUAL_FENCES]: addVirtualFences,
  [Types.UPDATE_VIRTUAL_FENCES]: updateVirtualFences,
  [Types.SEARCH_VIRTUAL_FENCES]: searchVirtualFences,
  [Types.REMOVE_VIRTUAL_FENCES]: removeVirtualFences,
  [Types.SET_VIRTUAL_FENCES]: setVirtualFences,
  [Types.SET_VIRTUAL_FENCES_SELECTED]: setVirtualFencesSelected
});
