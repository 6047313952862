import ClientSchemaReduced, { schemaDate as schemaDateClient } from "../Client/SchemaReducedTitle";

const schema = {
  active: "Status",
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  createdAt: "Criado em",
  id: "Id",
  name: "Nome",
  scopes: "Permissões",
  updatedAt: "Atualizado em"
};

export const schemaDate = {
  createdAt: "datetime",
  client: JSON.parse(JSON.stringify(schemaDateClient)),
  updatedAt: "datetime"
};

export default schema;
