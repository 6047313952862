import Api, { ApiV2 } from "../Api";

export default class Patrimony {
  async create({ data, token }) {
    const response = await Api.post("/patrimony", data, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async findAll({ data, token }) {
    const response = await Api.post("/patrimony/find-all", data, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return response.data;
    }
    return {
      docs: [],
      limit: 1,
      page: 1,
      pages: false
    };
  }

  async findAllLog({ data, token }) {
    const response = await Api.post("/patrimony/find-all-log", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) return response.data;
    return {
      docs: [],
      limit: 1,
      page: 1,
      pages: false
    };
  }

  async findAllGrid({ data, token }) {
    const response = await Api.post("/patrimony/find-all", data, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return this.findAllGridTranslate({ data: response.data });
    }
    return this.findAllGridTranslate({ data: {
      docs: [],
      limit: 1,
      page: 1,
      pages: false
    }});
  }

  findAllGridTranslate({ data }) {
    data.cols = [{
      width: "30%",
      type: "string",
      label: "Title.Client"
    }, {
      width: "30%",
      type: "string",
      label: "Title.BoardOrName"
    }, {
      width: "30%",
      type: "string",
      label: "Title.Tracker"
    }, {
      width: "10%",
      type: "string",
      label: "Title.Active"
    }];
    data.rows = [];
    data.docs.map(item => {
      var boardName = "";
      if(item.type === "AUT") {
        if (item.automobile.type === "BOA") {
          boardName = this.findAllGridTranslateItem({ data: item, item: "automobile.board.board" }) !== undefined ? item.automobile.board.board : "";
        }
        else {
          boardName = this.findAllGridTranslateItem({ data: item, item: "automobile.card.number" }) !== undefined ? item.automobile.card.number : "";
        }
      }
      else if(item.type === "OBJ") {
        boardName = this.findAllGridTranslateItem({ data: item, item: "object.name" }) !== undefined ? item.object.name : "";
      }
      else {
        boardName = this.findAllGridTranslateItem({ data: item, item: "vessel.name" }) !== undefined ? item.vessel.name : "";
      }

      var arrTrackers = [];
      if(this.findAllGridTranslateItem({ data: item, item: "trackers" }) !== undefined) {
        item.trackers.map(tracker => {
          return arrTrackers.push(tracker.serial);
        });
      }

      const itemAux = [{
        value: this.findAllGridTranslateItem({ data: item, item: "client" }) !== undefined ? (item.client.type === "LEG" ? item.client.legal.socialReason : item.client.personal.name) : ""
      }, {
        value: boardName
      }, {
        value: this.findAllGridTranslateItem({ data: item, item: "trackers" }) !== undefined ? arrTrackers.join(", ") : ""
      }, {
        value: this.findAllGridTranslateItem({ data: item, item: "active" }) !== undefined ? (item.active ? "%{Title.Active}%" : "%{Title.Inactive}%") : ""
      }];
      return data.rows.push(itemAux);
    });
    return data;
  }

  findAllGridTranslateItem({ data, item }) {
    try {
      var itemAux = item.split(".");
      var dataAux = data;
      itemAux.map(it => {
        return dataAux = dataAux[it];
      });
      return dataAux;
    }
    catch (e) {
      return false;
    }
  }

  async findAutocomplete({ data, token }) {
    const response = await Api.post("/patrimony/find-autocomplete", data, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return response.data.docs;
    }
    return [];
  }

  async imaCertificate({ data, id, token }) {
    const response = await ApiV2.post(`/patrimony/ima/certificate/${id}`, data, {
      headers: {"Authorization": "Bearer " + token},
      responseType: "blob"
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async jammerAndBlockCertificate({ data, id, token }) {
    const response = await ApiV2.post(`/patrimony/jammer/certificate/${id}`, data, {
      headers: {"Authorization": "Bearer " + token},
      responseType: "blob"
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async installationStatement({ data, id, token }) {
    const response = await ApiV2.post(`/patrimony/installation/statement/${id}`, data, {
      headers: {"Authorization": "Bearer " + token},
      responseType: "blob"
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async maintenanceSchedulingTerm({ data, token }) {
    const response = await ApiV2.post("/patrimony/maintenance/scheduling/term", data, {
      headers: {"Authorization": "Bearer " + token},
      responseType: "blob"
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async report({ endAt, freqAt, id, drivers, patrimonies, routeAt, routeFix, speedAt, speeding, startAt, token, virtualFences }) {
    const response = await Api.post("/patrimony/report", {
      drivers,
      endAt,
      freqAt,
      id,
      patrimonies,
      routeAt,
      routeFix,
      speedAt,
      speeding,
      startAt,
      virtualFences
    }, {
      headers: {"Authorization": "Bearer " + token},
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async update({ id, data, token }) {
    const response = await Api.put(`/patrimony/${id}`, data, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async updateDriver({ id, data, token }) {
    const response = await Api.post(`/patrimony/update-driver/${id}`, data, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }
}
