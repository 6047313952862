export function getTypeVirtualFence(virtualFence) {
  switch(virtualFence.type) {
    case "DEF":
      return "Title.VirtualFence.Default";
    case "RES":
      return  "Title.VirtualFence.Rest";
    case "RIS":
      return "Title.VirtualFence.Risk";
    case "WAI":
      return "Title.VirtualFence.Wait";
    default:
      return "Title.VirtualFence.Default";
  }
}

export function filterByClient(virtualFences, search) {
  let filter = virtualFences;
  let enabled = false;
  if(typeof search !== "undefined") {
    if(typeof search.client !== "undefined" && search.client.id !== "") {
      enabled = true;
      filter = filter.filter((n) => typeof n.virtualFence.client !== "undefined" && n.virtualFence.client.id === search.client.id);
    }
    if(typeof search.active !== "undefined" && search.active !== -1) {
      enabled = true;
      filter = filter.filter((n) => typeof n.virtualFence.active !== "undefined" && n.virtualFence.active === search.active);
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function filterByClientAndWord(virtualFences, search) {
  let filter = virtualFences;
  let enabled = false;
  if(typeof search !== "undefined") {
    if(typeof search.client !== "undefined" && search.client.id !== "") {
      enabled = true;
      filter = filter.filter((n) => typeof n.virtualFence.client !== "undefined" && n.virtualFence.client.id === search.client.id);
    }
    if(typeof search.word !== "undefined" && search.word !== "") {
      enabled = true;
      filter = filter.filter((n) => n.virtualFence.name.toLowerCase().indexOf(search.word.toLowerCase()) !== -1);
    }
    if(typeof search.active !== "undefined" && search.active !== -1) {
      enabled = true;
      filter = filter.filter((n) => typeof n.virtualFence.active !== "undefined" && n.virtualFence.active === search.active);
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function filterByWord(virtualFences, search) {
  let filter = virtualFences;
  let enabled = false;
  if(typeof search !== "undefined") {
    if(typeof search.word !== "undefined" && search.word !== "") {
      enabled = true;
      filter = filter.filter((n) => n.virtualFence.name.toLowerCase().indexOf(search.word.toLowerCase()) !== -1);
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function sortVirtualFences(virtualFences, asc = false) {
  if(asc) {
    return virtualFences.sort((a, b) => a.virtualFence.name.localeCompare(b.virtualFence.name));
  }
  return virtualFences.sort((a, b) => b.virtualFence.name.localeCompare(a.virtualFence.name));
}
