import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Tab,
  Tabs,
  Row,
  InputGroup,
  Container,
  Spinner
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import DatePicker, { registerLocale } from "react-datepicker";
import useSupercluster from "use-supercluster";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import "./index.css";

import GoogleMapReact from "google-map-react";

import ClientService from "../../services/Client";
import PositionService from "../../services/Position";
import SwitchDefault from "./../../components/Switch/Default";

import DiagnosisClusterMarker from "./../../components/Marker/DiagnosisCluster";
import DiagnosisMarker from "./../../components/Marker/Diagnosis";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";

import DateUtil from "./../../utils/Date";
import { niplePositions } from "./../../utils/Position";

registerLocale("pt-BR", ptBR);

const DiagnosisMap = ({
  props: {
    currentUser
  },
  funcs: {
    disableLoader,
    enableLoader
  }
}) => {
  const { t } = useTranslation();
  const clientService = new ClientService();
  const positionService = PositionService;

  const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;

  const __default_center__ = { lat: -14.2800653, lng: -46.9647527 };
  const __default_zoom__ = 4.78;
  const __default_min_zoom__ = 3;
  const __default_max_zoom__ = 19;
  const __default_cluster_max_zoom__ = 18;

  const mapRef = useRef();
  const mapsRef = useRef();

  const [bounds, setBounds] = useState(null); /* eslint-disable-line */
  const [mapReady, setMapReady] = useState(false);

  const [menuToggled, setMenuToggled] = useState(true);
  const [menuActionType, setMenuActionType] = useState("filterParams");

  const [openClient, setOpenClient] = useState(null);

  const [clients, setClients] = useState([]);
  const [positions, setPositions] = useState([]);
  const [virtualFences, setVirtualFences] = useState([]);

  const [patrimoniesRemoveds, setPatrimoniesRemoveds] = useState([]);
  const [search, setSearch] = useState("");

  const [discharge, setDischarge] = useState(true);
  const [dischargeTest, setDischargeTest] = useState(true);
  const [charge, setCharge] = useState(false);
  const [chargeTest, setChargeTest] = useState(false);

  const [startDate, setStartDate] = useState(new Date(`${todayDate} 00:00:00`));
  const [endDate, setEndDate] = useState(new Date(`${todayDate} 23:59:59`));

  const [satellite, setSatellite] = useState(currentUser.profile.map.type === "SAT" ? true : false);

  const [running, setRunning] = useState(false);

  const [virtualFencesPolygon, setVirtualFencesPolygon] = useState(false);
  const [virtualFencesLoad, setVirtualFencesLoad] = useState(false);


  const [draggable, setDraggable] = useState(true);

  const [options, setOptions] = useState({
    mapTypeId: satellite ? "SAT" : "DEF",
    minZoom: __default_min_zoom__,
    maxZoom: __default_max_zoom__,
    restriction: null,
    styles: [{
      stylers: [{
        saturation: -10
      }]
    }]
  });
  const [zoom, setZoom] = useState(12); /* eslint-disable-line */

  useEffect(() => {
    if (mapReady) {
      reloadBounds();
      findList();
    }
  }, [mapReady]); /* eslint-disable-line */

  useEffect(() => {
    setOptions(prevState => ({
     ...prevState,
     mapTypeId: satellite ? "SAT" : "DEF"
    }));
  }, [satellite]);

  useEffect(() => {
    if (virtualFencesLoad && !virtualFencesPolygon) generateVirtualFencesPolygonToMap();
    else if(!virtualFencesLoad && virtualFencesPolygon) {
      removeVirtualFencesPolygonFromMap();
      setTimeout(() => {
        setVirtualFencesPolygon(false);
      }, 100);
    }
  }, [virtualFencesLoad, virtualFencesPolygon]); /* eslint-disable-line */

  const getMapTypeId = ({ type }) => {
    switch(type) {
    case "SAT":
      return "hybrid";
    default:
      return "roadmap";
    }
  };

  const getBounds = () => {
    if (mapReady) {
      // const pointsBounds = new mapsRef.current.LatLngBounds();
      mapRef.current.setCenter(__default_center__);
      mapRef.current.setZoom(__default_zoom__);
      // if ([].length > 0) {
      //   getPolygonPath().map(rs => pointsBounds.extend(rs));
      //   return pointsBounds;
      // }
      // else {
      //   mapRef.current.setCenter(__default_center__);
      //   mapRef.current.setZoom(__default_zoom__);
      // }
    }
    return false;
  };

  const reloadBounds = () => {
    const bounds = getBounds();
    if (bounds) {
      mapRef.current.fitBounds(bounds);
    }
  };

  const findList = async () => {
    if (running) return;
    setRunning(true);
    enableLoader();
    const clients = await clientService.findList({ token: currentUser.token });
    if (clients) setClients(clients);
    disableLoader();
    setRunning(false);
  };

  const findDiagnosis = async () => {
    if (running) return;
    setRunning(true);
    enableLoader();
    const { positions, virtualFences } = await positionService.findDiagnosis({ data: { endDate, startDate, patrimonies: getPatrimoniesSelected(), operations: { discharge, dischargeTest, charge, chargeTest } }, token: currentUser.token });
    setPositions(niplePositions(JSON.parse(JSON.stringify(positions))));
    setVirtualFences(virtualFences);
    disableLoader();
    setRunning(false);
  };

  const getPatrimoniesSelected = () => {
    const patrimonies = [];
    if (patrimoniesRemoveds.length > 0) {
      for (const client of clients) {
        for (const patrimony of client.patrimonies) {
          if (patrimoniesRemoveds.findIndex(patrimoniesRemoved => patrimoniesRemoved === patrimony.id) === -1) patrimonies.push(patrimony.id);
        }
      }
    }
    return patrimonies;
  };

  const handleMenuClick = (e) => {
    setMenuToggled(prevState => !prevState);
  };

  const handleMenuActionType = (e) => {
    setMenuActionType(e);
  };

  const handleOpenClient = (clientId) => {
    if (openClient !== clientId) {
      setOpenClient(clientId);
      return;
    }
    setOpenClient(null);
  };

  const isClientSelected = (patrimonies) => {
    const patrimoniesOfClient = patrimonies.filter(patrimony => patrimoniesRemoveds.findIndex(patrimoniesRemoved => patrimoniesRemoved === patrimony.id) !== -1);
    if (patrimoniesOfClient.length > 0) return false;
    return true;
  };

  const toggleClientSelected = (patrimonies) => {
    let patrimoniesRemovedsAux = patrimoniesRemoveds.slice();
    if (isClientSelected(patrimonies)) {
      for (const patrimony of patrimonies) {
        const index = patrimoniesRemovedsAux.findIndex(patrimonyRemovedAux => patrimonyRemovedAux === patrimony.id);
        if (index === -1) patrimoniesRemovedsAux.push(patrimony.id);
      }
    }
    else {
      for (const patrimony of patrimonies) {
        const index = patrimoniesRemovedsAux.findIndex(patrimonyRemovedAux => patrimonyRemovedAux === patrimony.id);
        if (index !== -1) patrimoniesRemovedsAux.splice(index, 1);
      }
    }
    setPatrimoniesRemoveds(patrimoniesRemovedsAux);
  };

  const isPatrimonySelected = (patrimonyId, returnIndex = false) => {
    const index = patrimoniesRemoveds.findIndex(patrimoniesRemoved => patrimoniesRemoved === patrimonyId);
    if (index !== -1) return returnIndex ? index : false;
    return returnIndex ? index: true;
  };

  const togglePatrimonySelected = (patrimonyId) => {
    let patrimoniesRemovedsAux = patrimoniesRemoveds.slice();
    const index = isPatrimonySelected(patrimonyId, true);
    if (index === -1) patrimoniesRemovedsAux.push(patrimonyId);
    else patrimoniesRemovedsAux.splice(index, 1);
    setPatrimoniesRemoveds(patrimoniesRemovedsAux);
  };

  const isAllSelected = () => {
    if (patrimoniesRemoveds.length === 0) return true;
    return false;
  }

  const toggleAll = () => {
    if (isAllSelected()) {
      const patrimoniesAux = [];
      clients.map(client => client.patrimonies.map(patrimony => patrimoniesAux.push(patrimony.id)));
      setPatrimoniesRemoveds(patrimoniesAux);
      return;
    }
    setPatrimoniesRemoveds([]);
  };

  const arrMenus = [
    {
      count: 0,
      id: "filterParams",
      icon: <i className="fa-solid fa-gears" />,
      render: (id, key) => {
        return (
          <Tab tabClassName={`${menuActionType === id ? "" : "hide"}`} eventKey="filterParams" title={<span>{t("Title.Parameters")}</span>} key={key}>
            <div className="menu-diagnosis-content-box">
              <Container style={{ padding: 0 }} fluid>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="filter-date-fields">
                      <Form.Group controlId="forms-patrimony-test-start-at">
                        <Form.Label>{t("Title.Date.Start")}:</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                          </InputGroup.Prepend>
                          <DatePicker
                            disabled={false}
                            selected={DateUtil.toDate({ date: startDate })}
                            onChange={date => {
                              const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
                              const startDate = date === null ? new Date(`${todayDate} 00:00:00`) : date;
                              setStartDate(startDate);
                            }}
                            selectsStart
                            startDate={DateUtil.toDate({ date: startDate })}
                            endDate={DateUtil.toDate({ date: endDate })}
                            maxDate={DateUtil.toDate({ date: endDate })}
                            timeFormat="HH:mm"
                            timeIntervals={5}
                            showTimeSelect
                            showMonthDropdown
                            showYearDropdown
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            className="react-datepicker-form-control"
                            id="forms-patrimony-test-start-at"
                            popperPlacement="top-center"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: "5px, 10px"
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport"
                              }
                            }}
                            locale="pt-BR"
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="filter-date-fields">
                      <Form.Group controlId="forms-patrimony-test-end-at">
                        <Form.Label>{t("Title.Date.End")}:</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                          </InputGroup.Prepend>
                          <DatePicker
                            disabled={false}
                            selected={DateUtil.toDate({ date: endDate })}
                            onChange={date => {
                              const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
                              const endDate = date === null ? new Date(`${todayDate} 23:55:00`) : date;
                              setEndDate(endDate);
                            }}
                            selectsEnd
                            startDate={DateUtil.toDate({ date: startDate })}
                            endDate={DateUtil.toDate({ date: endDate })}
                            minDate={DateUtil.toDate({ date: startDate })}
                            timeFormat="HH:mm"
                            timeIntervals={5}
                            showTimeSelect
                            showMonthDropdown
                            showYearDropdown
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            className="react-datepicker-form-control"
                            id="forms-patrimony-test-end-at"
                            popperPlacement="top-center"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                offset: "5px, 10px"
                              },
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport"
                              }
                            }}
                            locale="pt-BR"
                          />
                        </InputGroup>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="menu-diagnosis-content-box">
              <p>{t("Title.Operation")}:</p>
            </div>

            <div className="menu-diagnosis-content-box">
              <div className="filter-accordion-box">
                <div className="filter-accordion-box-check">
                  <SwitchDefault disabled={false} name={`operation`} selected={discharge} small={true} onClick={(e) => {
                    e.stopPropagation();
                    // if (!discharge) {
                    //   setCharge(false);
                    //   setChargeTest(false);
                    //   setDischargeTest(true);
                    // }
                    // else setDischargeTest(false);
                    setDischarge(prevState => !prevState);
                  }} />
                </div>
                <div className="filter-accordion-box-title"><span>{t("Title.Niple.Discharge")}</span></div>
              </div>

              <div className="filter-accordion-box">
                <div className="filter-accordion-box-check">
                  <SwitchDefault disabled={false} name={`operation`} selected={dischargeTest} small={true} onClick={(e) => {
                    e.stopPropagation();
                    setDischargeTest(prevState => !prevState);
                  }} />
                </div>
                <div className="filter-accordion-box-title"><span>{t("Title.Niple.DischargeTest")}</span></div>
              </div>
            </div>

            <div className="menu-diagnosis-content-box">
              <div className="filter-accordion-box">
                <div className="filter-accordion-box-check">
                  <SwitchDefault disabled={false} name={`operation`} selected={charge} small={true} onClick={(e) => {
                    e.stopPropagation();
                    // if (!charge) {
                    //   setDischarge(false);
                    //   setDischargeTest(false);
                    //   setChargeTest(true);
                    // }
                    // else setChargeTest(false);
                    setCharge(prevState => !prevState);
                  }} />
                </div>
                <div className="filter-accordion-box-title"><span>{t("Title.Niple.Charge")}</span></div>
              </div>

              <div className="filter-accordion-box">
                <div className="filter-accordion-box-check">
                  <SwitchDefault disabled={false} name={`operation`} selected={chargeTest} small={true} onClick={(e) => {
                    e.stopPropagation();
                    setChargeTest(prevState => !prevState);
                  }} />
                </div>
                <div className="filter-accordion-box-title"><span>{t("Title.Niple.ChargeTest")}</span></div>
              </div>
            </div>


            <div className="menu-diagnosis-content-box">
              <p>{t("Title.Client")}:</p>
            </div>

            <div className="menu-diagnosis-content-box content-fixed">
              <Form.Group className="filter-accordion-box-input-group" controlId="diagnosis-item-form-search">
                <Form.Control
                  className="filter-accordion-box-input"
                  type="text"
                  placeholder={t("Placeholder.Search")}
                  autoComplete={"off"}
                  onChange={e => setSearch(e.target.value)}
                  value={search}
                  disabled={false}
                />
                <div className="filter-accordion-box filter-accordion-box-all">
                  <div className="filter-accordion-box-check">
                    <SwitchDefault disabled={false} name={`client`} selected={isAllSelected()} small={true} onClick={(e) => {
                      e.stopPropagation();
                      toggleAll();
                    }} />
                  </div>
                  <div className="filter-accordion-box-title"><span>Todos</span></div>
                </div>
              </Form.Group>
            </div>

            <div className="menu-diagnosis-content-box content-overflow">
              <Accordion defaultActiveKey={null} activeKey={openClient}>
                {
                  ((search && (String(search).trim() !== "")) ? clients.filter(client => client.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) : clients).map((client, cindex) => {
                    return (
                      <Card key={`filter-client-item-${client.id}-${cindex}`}>
                        <Accordion.Toggle as={Card.Header} eventKey={client.id} onClick={e => handleOpenClient(client.id)}>
                          <div className="filter-accordion-box">
                            <div className="filter-accordion-box-check">
                              <SwitchDefault disabled={false} name={`client`} selected={isClientSelected(client.patrimonies)} small={true} onClick={(e) => {
                                e.stopPropagation();
                                toggleClientSelected(client.patrimonies);
                              }} />
                            </div>
                            <div className="filter-accordion-box-title"><span>{client.name}</span></div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={client.id}>
                          <Card.Body>
                              {
                                client.patrimonies.map((patrimony, pindex) => {
                                  return (
                                    <div className="filter-accordion-box" key={`filter-client-item-patrimony-${patrimony.id}-${pindex}`}>
                                      <div className="filter-accordion-box-check">
                                        <SwitchDefault disabled={false} name={`client`} selected={isPatrimonySelected(patrimony.id)} small={true} onClick={(e) => {
                                          e.stopPropagation();
                                          togglePatrimonySelected(patrimony.id);
                                        }} />
                                      </div>
                                      <div className="filter-accordion-box-title"><span>{patrimony.identifier}</span></div>
                                    </div>
                                  )
                                })
                              }
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )
                  })
                }
                {
                  ((((search && (String(search).trim() !== "")) ? clients.filter(client => client.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) : clients).length === 0) && (
                    <p>{t("React.Select.NoOptions")}</p>
                  ))
                }
              </Accordion>
            </div>

            <div className="menu-diagnosis-content-box" style={{ justifyContent: "flex-end" }}>
              <Button
                variant="dark"
                type="button"
                disabled={isDisabled()}
                onClick={findDiagnosis}
              >
                {running ? <Spinner animation="border" size="sm" /> : "Gerar"}
              </Button>
            </div>
          </Tab>
        );
      },
      title: t("Title.Parameters"),
      scope: true
    }
  ];

  const isDisabled = () => {
    return running;
  };

  const generateVirtualFencesPolygonToMap = () => {
    const virtualFencesColor = (type) => {
      switch (type) {
      case "DEF":
        return "#820404";
      case "RES":
        return "#008c23";
      case "RIS":
        return "#000000";
      case "WAI":
        return "#0453A2";
      default:
        return "#820404";
      }
    };

    const polygons = [];
    const newVirtualFences = JSON.parse(JSON.stringify(virtualFences));
    newVirtualFences.map(vf => {
      vf.getBounds = () => {
        const path = vf.location.coordinates[0].map(latLng => new mapsRef.current.LatLng(latLng[1], latLng[0]));
        const pointsBounds = new mapsRef.current.LatLngBounds();
        if (path.length > 0) {
          path.map(rs => pointsBounds.extend(rs));
          return pointsBounds;
        }
      };
      vf.onClick = (e) => {
        // mapRef.current.fitBounds(vf.getBounds());
      };
      const path = vf.location.coordinates[0].map(latLng => new mapsRef.current.LatLng(latLng[1], latLng[0]));
      let polygonMap = new mapsRef.current.Polygon({
        path: path,
        strokeColor: virtualFencesColor(vf.type),
        strokeOpacity: 0.5,
        strokeWeight: 1,
        fillColor: virtualFencesColor(vf.type),
        fillOpacity: 0.35,
        indexID: vf
      });
      polygonMap.setMap(mapRef.current);
      mapsRef.current.event.addListener(polygonMap, "click", e => vf.onClick(e));
      polygons.push(polygonMap);
      return true;
    });
    setVirtualFencesPolygon(polygons);
  };

  const removeVirtualFencesPolygonFromMap = () => {
    if (virtualFencesPolygon) {
      virtualFencesPolygon.map(polygon => {
        return polygon.setMap(null);
      });
    }
  };

  const getEventsSource = () => {
    return {
      id: "route",
      value: positions
    };
  };

  const getEventsSourceSelected = () => {
    //if (singleRouteMenuPositionsMarkersSelected) return singleRouteMenuPositionsMarkersSelected;
    return [];
  };

  const getEventPoints = () => {
    let  { id, value } = getEventsSource();
    let selected = getEventsSourceSelected();
    if (selected.length > 0) value = selected;
    return value.map(ep => ({
      type: "Feature",
      properties: { cluster: false, position: ep, type: id },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(ep.gps.coordinate.longitude),
          parseFloat(ep.gps.coordinate.latitude)
        ]
      }
    }));
  };

  const getPositionType = (position) => {
    let type = "route";
    if (position.nipleNetwork) {
      position.nipleNetwork.niples.map(niple => {
        if (niple.operation === "CHA") {
          if (position.gps.reason === 8) type = "nipleCharge";
          else if (position.gps.reason === 11) type = "nipleChargeTest";
        }
        else if (niple.operation === "DIS") {
          if (position.gps.reason === 8) type = "nipleDischarge";
          else if (position.gps.reason === 11) type = "nipleDischargeTest";
        }
      });
    }
    return type;
  }

  const eventPoints = mapReady ? getEventPoints() : [];
  const { clusters: eventClusters, supercluster: eventSupercluster } = useSupercluster({
    points: eventPoints,
    bounds,
    zoom,
    options: {
      radius: 75,
      maxZoom: __default_cluster_max_zoom__
    }
  });

  return (
    <div className="diagnosis-map-container">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_APP_GOOGLE_MAP_KEY,
          libraries: ["places", "geometry"]
        }}
        defaultCenter={__default_center__}
        defaultZoom={__default_zoom__}
        draggable={draggable}
        options={{
          disableDefaultUI: true,
          mapTypeId: getMapTypeId({ type: options.mapTypeId }),
          minZoom: options.minZoom,
          maxZoom: options.maxZoom,
          restriction: options.restriction,
          styles: options.styles
        }}
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
          mapsRef.current = maps;
          setMapReady(true);
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}
        onClick={(e) => {
          // if (add) {
          //   const { lat, lng } = e;
          //   addPointAux(lat, lng);
          // }
        }}
        onChildMouseDown={(childKey, childProps, mouse) => {
          // if (edit === childProps.index) {
          //   setDraggable(false);
          //   if (!isNaN(mouse.lat) && !isNaN(mouse.lng)) {
          //     if (addAux) {
          //       setAddAux(new mapsRef.current.LatLng(mouse.lat, mouse.lng));
          //     }
          //     else {
          //       setPolygonPath(childProps.index, mouse.lat, mouse.lng);
          //     }
          //   }
          // }
        }}
        onChildMouseUp={(childKey, childProps, mouse) => {
          // setDraggable(true);
        }}
        onChildMouseMove={(childKey, childProps, mouse) => {
          // if (edit === childProps.index) {
          //   setDraggable(false);
          //   if (!isNaN(mouse.lat) && !isNaN(mouse.lng)) {
          //     if (addAux) {
          //       setAddAux(new mapsRef.current.LatLng(mouse.lat, mouse.lng));
          //     }
          //     else {
          //       setPolygonPath(childProps.index, mouse.lat, mouse.lng);
          //     }
          //   }
          // }
        }}
        yesIWantToUseGoogleMapApiInternals>

        {
          eventClusters.map((eCluster, eIndex) => {
            const [longitude, latitude] = eCluster.geometry.coordinates;
            const eType = () => getEventsSource().id;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = eCluster.properties;
            if (isCluster) {
              return (
                <DiagnosisClusterMarker
                  key={`event-cluster-${eCluster.id}`}
                  lat={latitude}
                  lng={longitude}
                  size={(20 + (pointCount / eventPoints.length) * 20)}
                  count={pointCount}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      eventSupercluster.getClusterExpansionZoom(eCluster.id),
                      __default_max_zoom__
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                  eType={eType}
                />
              );
            }
            return (
              <DiagnosisMarker
                key={`event-position-${eCluster.properties.position.id}`}
                lat={latitude}
                lng={longitude}
                onClick={() => {
                  mapRef.current.setZoom(__default_max_zoom__);
                  mapRef.current.panTo({ lat: latitude, lng: longitude });
                }}
                eType={() => getPositionType(eCluster.properties.position)}
                ePosition={eCluster.properties.position}
                profile={{
                  currentUser,
                  miniMarkers: false
                }}
              />
            );
          })
        }

      </GoogleMapReact>
      <>
        <aside className={`diagnosis-aside-menu ${menuToggled ? "toggled" : ""}`}>
          <div className="menu-diagnosis-itens">
            <ul>
              <li>
                <div className="menu-diagnosis-icon active" onClick={handleMenuClick}>
                  {
                    menuToggled ? (
                      <i className="fa-solid fa-xmark" key="menu-icon-open" />
                    ) : (
                      <i className="fa-solid fa-bars" key="menu-icon-closed" />
                    )
                  }
                </div>
              </li>
              {
                menuToggled && arrMenus.map((m, i) => {
                  if (m.scope) {
                    return (
                      <li key={`menu-diagnosis-icon-${i}`}>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>{m.title}</Tooltip>}
                          trigger={isMobile ? null : ["hover", "focus"]}
                        >
                          <div className={`menu-diagnosis-icon ${menuActionType === m.id ? "active" : ""}`} onClick={e => handleMenuActionType(m.id)}>{m.icon}</div>
                        </OverlayTrigger>
                      </li>
                    );
                  }
                  return null;
                })
              }
            </ul>
          </div>
          <div className="menu-diagnosis-content">
            <Tabs defaultActiveKey="filterParams" activeKey={menuActionType} onSelect={e => handleMenuActionType(e)}>
              {
                arrMenus.map((m, i) => {
                  if (m.scope) return m.render(m.id, `menu-diagnosis-content-${i}`);
                  return null;
                })
              }
            </Tabs>
          </div>
        </aside>
        <div className="diagnosis-aside-menu-backdrop" onClick={handleMenuClick}></div>

        <aside className={`diagnosis-aside-menu-options ${menuToggled ? "" : "active"}`}>
          <ul>
            <li>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip>{t("Title.Satellite")}</Tooltip>
                }
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <button
                  className={`moi-icon ${satellite ? "active" : ""} moi-satellite`}
                  type="button"
                  onClick={() => setSatellite(prevState => !prevState)}
                  disabled={false}
                >
                  <i className="fas fa-satellite" />
                </button>
              </OverlayTrigger>
            </li>
            <li>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip>{t("Title.VirtualFences")}</Tooltip>
                }
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <button
                  className={`moi-icon ${virtualFencesLoad ? "active" : ""} moi-virtual-fence`}
                  type="button"
                  onClick={() => setVirtualFencesLoad(prevState => !prevState)}
                  disabled={false}
                >
                  <i className="fas fa-sign" />
                </button>
              </OverlayTrigger>
            </li>
          </ul>
        </aside>
      </>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosisMap);
