import ClientSchemaReduced, { schemaDate as schemaDateClient } from "../Client/SchemaReducedTitle";
import DriverSchemaReduced, { schemaDate as schemaDateDriver } from "../Driver/SchemaReducedTitle";
import ProfileSchemaReduced, { schemaDate as schemaDateProfile } from "../Profile/SchemaReducedTitle";

const schema = {
  active: "Status",
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  contact: {
    cellphone: "Celular",
    email: "E-mail",
    telephone: "Telefone"
  },
  createdAt: "Criado em",
  driver: JSON.parse(JSON.stringify(DriverSchemaReduced)),
  id: "Id",
  cpf: "CPF",
  name: "Nome",
  password: "Senha",
  passwordPin: "PIN",
  patrimonies: "Patrimônios",
  profile: {
    lang: "Idioma",
    map: {
      cluster: "Cluster do mapa",
      markers: "Marcas do mapa",
      miniMarkers: "Mini-marcas do mapa",
      miniMap: "Mini-mapa do mapa",
      traffic: "Tráfego do mapa",
      type: "Tipo de mapa"
    },
    notification: {
      group: "Grupo de notificações",
      mode: {
        mail: "Notificações por e-mail",
        push: "Notificações por push"
      },
      warning: {
        sound: "Notificações com som",
        voice: "Notificações com voz"
      }
    },
    timezone: "Fuso horário"
  },
  profilePredefined: JSON.parse(JSON.stringify(ProfileSchemaReduced)),
  scopes: "Permissões"
};

export const schemaDate = {
  createdAt: "datetime",
  client: JSON.parse(JSON.stringify(schemaDateClient)),
  driver: JSON.parse(JSON.stringify(schemaDateDriver)),
  profilePredefined: JSON.parse(JSON.stringify(schemaDateProfile)),
  updatedAt: "datetime"
};

export default schema;
