import React from "react";

import FilterDate from "./../../components/FilterDate";
import FilterPatrimony from "./../../components/FilterPatrimony";
import FilterUser from "./../../components/FilterUser";

const Option = ({ options }) => {
  const {
    dateEnd,
    dateStart,
    filterActive,
    filterMultiple,
    filterNiple,
    filterPatrimony,
    filterPreSelected,
    filterUser,
    currentUser
  } = options;

  const renderFilterDate = () => {
    if(dateEnd || dateStart) {
      return <FilterDate options={{ dateEnd, dateStart }} />;
    }
    return null;
  };

  const renderFilterPatrimony = () => {
    if(filterPatrimony) {
      return <FilterPatrimony options={{ filterActive, filterMultiple, filterNiple, filterPreSelected, currentUser }} />;
    }
    return null;
  };

  const renderFilterUser = () => {
    if(filterUser) {
      return <FilterUser options={{ filterMultiple }} />;
    }
    return null;
  };

  return (
    <>
      {renderFilterDate()}
      {renderFilterPatrimony()}
      {renderFilterUser()}
    </>
  );
};

export default Option;
