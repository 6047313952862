import Api from "../Api";

export default class Gyroscope {
  async create({ data, passwordPin, token }) {
    const response = await Api.post("/gyroscope", data, {
      headers: {
        "Authorization": "bearer " + token,
        "pin": passwordPin
      }
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }
}
