import { getDistance } from "geolib";
import DateUtil from "./Date";
import { getDescriptionClient } from "./Client";
import { getDescriptionPatrimony, isAutomobileBoardTruck } from "./Patrimony";

export function cropPositions({ positions }) {
  const routes = [];
  const newPositions = sortPositionsPolyline(JSON.parse(JSON.stringify(positions)), true);
  for (let i = 0; i < newPositions.length; i++) {
    const posNow = newPositions[i];
    if (i === 0) {
      routes[0] = {
        allSpeed: posNow.gps.speed,
        countSpeed: posNow.gps.speed > 0 ? 1 : 0,
        id: 0,
        ignition: posNow.tracker.ignition,
        km: 0,
        maxSpeed: posNow.gps.speed,
        medianSpeed: posNow.gps.speed,
        minSpeed: posNow.gps.speed,
        positions: [posNow],
        positionsValid: posNow.gps.gpsSignal ? [[Number(posNow.gps.coordinate.latitude), Number(posNow.gps.coordinate.longitude)]] : [],
        positionsValidBounds: posNow.gps.gpsSignal ? [{ latitude: Number(posNow.gps.coordinate.latitude), longitude: Number(posNow.gps.coordinate.longitude) }] : []
      };
      routes.push({
        ...JSON.parse(JSON.stringify(routes[0])),
        id: 1
      });
    }
    if (i > 0) {
      const posBack = newPositions[i-1];
      const route = routes[routes.length-1];
      const routeFirst = routes[0];

      const distance = getDistance({
        latitude: Number(posNow.gps.coordinate.latitude),
        longitude: Number(posNow.gps.coordinate.longitude)
      }, {
        latitude: Number(posBack.gps.coordinate.latitude),
        longitude: Number(posBack.gps.coordinate.longitude)
      }, 0.01) / 1000;

      if (posNow.tracker.ignition !== posBack.tracker.ignition) {
        routes.push({
          allSpeed: posNow.gps.speed,
          countSpeed: posNow.gps.speed > 0 ? 1 : 0,
          id: routes[routes.length-1].id + 1,
          ignition: posNow.tracker.ignition,
          km: 0,
          maxSpeed: posNow.gps.speed,
          medianSpeed: posNow.gps.speed,
          minSpeed: posNow.gps.speed,
          positions: [posNow],
          positionsValid: posNow.gps.gpsSignal ? [[Number(posNow.gps.coordinate.latitude), Number(posNow.gps.coordinate.longitude)]] : [],
          positionsValidBounds: posNow.gps.gpsSignal ? [{ latitude: Number(posNow.gps.coordinate.latitude), longitude: Number(posNow.gps.coordinate.longitude) }] : []
        });
      }
      else {
        route.km = route.km + distance;
        route.positions.push(posNow);
        if (posNow.gps.gpsSignal) {
          route.positionsValid.push([Number(posNow.gps.coordinate.latitude), Number(posNow.gps.coordinate.longitude)]);
          route.positionsValidBounds.push({ latitude: Number(posNow.gps.coordinate.latitude), longitude: Number(posNow.gps.coordinate.longitude) });
        }
        if (((posNow.gps.speed > 0) && (posNow.gps.speed < route.minSpeed)) || route.minSpeed === 0) route.minSpeed = posNow.gps.speed;
        if ((posNow.gps.speed > 0) && (posNow.gps.speed > route.maxSpeed)) route.maxSpeed = posNow.gps.speed;
        if (posNow.gps.speed > 0) {
          route.allSpeed = route.allSpeed + posNow.gps.speed;
          route.countSpeed = route.countSpeed + 1;
        }
        if (route.allSpeed > 0 && route.countSpeed > 0) route.medianSpeed = route.allSpeed / route.countSpeed;
      }
      routeFirst.km = routeFirst.km + distance;
      routeFirst.positions.push(posNow);
      if (posNow.gps.gpsSignal) {
        routeFirst.positionsValid.push([Number(posNow.gps.coordinate.latitude), Number(posNow.gps.coordinate.longitude)]);
        routeFirst.positionsValidBounds.push({ latitude: Number(posNow.gps.coordinate.latitude), longitude: Number(posNow.gps.coordinate.longitude) });
      }
      if (((posNow.gps.speed > 0) && (posNow.gps.speed < routeFirst.minSpeed)) || routeFirst.minSpeed === 0) routeFirst.minSpeed = posNow.gps.speed;
      if ((posNow.gps.speed > 0) && (posNow.gps.speed > routeFirst.maxSpeed)) routeFirst.maxSpeed = posNow.gps.speed;
      if (posNow.gps.speed > 0) {
        routeFirst.allSpeed = routeFirst.allSpeed + posNow.gps.speed;
        routeFirst.countSpeed = routeFirst.countSpeed + 1;
      }
      if (routeFirst.allSpeed > 0 && routeFirst.countSpeed > 0) routeFirst.medianSpeed = routeFirst.allSpeed / routeFirst.countSpeed;
    }
  }
  return routes;
}

export function filterByClientAndWord(positions, search) {
  let filter = positions;
  let enabled = false;
  if (typeof search !== "undefined") {
    if (typeof search.client !== "undefined" && search.client.id !== "") {
      enabled = true;
      filter = filter.filter((p) => typeof p.position.patrimony.client !== "undefined" && p.position.patrimony.client.id === search.client.id);
    }
    if (typeof search.niple !== "undefined" && search.niple !== "") {
      enabled = true;
      filter = filter.filter(
        (p) => search.niple ? (
          p.position.patrimony.automobile && p.position.patrimony.automobile.board && p.position.patrimony.automobile.board.type === "ATV"
        ) : (
          (p.position.patrimony.automobile && p.position.patrimony.automobile.board && p.position.patrimony.automobile.board.type !== "ATV") ||
          (p.position.patrimony.automobile && p.position.patrimony.automobile.card) ||
          (p.position.patrimony.object) ||
          (p.position.patrimony.vessel)
        ));

      if (search.niple) {
        let nipleType = "ALL";
        if (typeof search.nipleType !== "undefined" && search.nipleType !== "" && search.nipleType !== "ALL") nipleType = search.nipleType;
        if (nipleType !== "ALL") {
          filter = filter.filter((p) => {
            let nipleTypeSensors = "MEC";
            if (typeof p.position.nipleNetwork !== "undefined") {
              p.position.nipleNetwork.niples.map((niple, index) => {
                if (niple.version === "3.0.0") nipleTypeSensors = "PIE";
                else if (niple.sensors && ((niple.sensors.s1 > 0) || (niple.sensors.s2 > 0) || (niple.sensors.s3 > 0) || (niple.sensors.s4 > 0) || (niple.sensors.s5 > 0) || (niple.sensors.s6> 0))) nipleTypeSensors = "ELE";
              });
            }
            return nipleType === "ALL" ? true : (nipleType === nipleTypeSensors);
          });
        }
      }
    }
    if (typeof search.events !== "undefined" && Array.isArray(search.events) && search.events.length > 0) {
      enabled = true;
      filter = filter.filter((p) => {
        var returnFilter = false;
        if (typeof p.position._events !== "undefined") {
          for (let idx in search.events) {
            const event = search.events[idx];
            if (typeof p.position._events[event] !== "undefined" && p.position._events[event] > 0) {
              returnFilter = true;
            }
          }
        }
        return returnFilter;
      });
    }
    if (typeof search.word !== "undefined" && search.word !== "") {
      enabled = true;
      filter = filter.filter((p) =>
        (p.position.tracker.serial.toLowerCase().indexOf(search.word.toLowerCase()) !== -1) ||
        (p.position.patrimony.automobile && p.position.patrimony.automobile.board && p.position.patrimony.automobile.board.board.toLowerCase().indexOf(search.word.toLowerCase()) !== -1) ||
        (p.position.patrimony.vessel && p.position.patrimony.vessel.name && p.position.patrimony.vessel.name.toLowerCase().indexOf(search.word.toLowerCase()) !== -1) ||
        (p.position.patrimony.vessel && p.position.patrimony.vessel.registrationNumber && p.position.patrimony.vessel.registrationNumber.toLowerCase().indexOf(search.word.toLowerCase()) !== -1)
      );
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function getBattery(position) {
  let battery = position.tracker.battery;
  if (typeof position.trackersBackup !== "undefined") {
  	position.trackersBackup.map(tb => {
  	  if (battery === false) {
  	    battery = tb.battery;
  	  }
      return battery;
  	});
  }
  return battery;
}

export function getHatchBackCover(position) {
  let hatchBackCover = false;
  if (typeof position.nipleNetwork !== "undefined") {
    position.nipleNetwork.hatchs.map(hatch => {
      const type = position.patrimony.trackers.find(x => x.serial === hatch.serial);
      if (getHatchStatus(hatch) && !getValidMoving(position)) {
        if (getHatchType(type) === "BCO") {
          hatchBackCover = true;
        }
      }
      return true;
    });
  }
  return hatchBackCover;
}

export function getHatchBackCoverNoGyroscope(position) {
  let hatchBackCover = -1;
  const typeGroundingSensor = position.patrimony.trackers.find(x => x.type === "NIP" && x.niple.gyroscope === false && x.niple.groundingSensor === true);
  if (typeGroundingSensor) {
    if (position.tracker.input1 && position.tracker.input2) {
      hatchBackCover = 0;
    }
    else if (!position.tracker.input1 && !position.tracker.input2) {
      hatchBackCover = 1;
    }
    else if (!position.tracker.input1 && position.tracker.input2) {
      hatchBackCover = 2;
    }
    else if (position.tracker.input1 && !position.tracker.input2) {
      hatchBackCover = 3;
    }
  }
  const typeNoGroundingSensor = position.patrimony.trackers.find(x => x.type === "NIP" && x.niple.gyroscope === false && x.niple.groundingSensor === false);
  if (typeNoGroundingSensor) {
    if (position.tracker.input2) {
      hatchBackCover = 0;
    }
    else if (!position.tracker.input2) {
      hatchBackCover = 1;
    }
  }
  return hatchBackCover;
}

export function getHatchStatusTolerance(distance) {
  const tolerance = distance * 0.5;
  return tolerance;
}

export function getHatchStatus(hatch, datail = false) {
  const min = hatch.sensors.shortestDistance - getHatchStatusTolerance(hatch.sensors.shortestDistance);
  const max = hatch.sensors.greaterDistance + getHatchStatusTolerance(hatch.sensors.greaterDistance);
  const now = hatch.sensors.distance;
  if ((hatch.operation === "FRA" || (now <= 0) || (now < min) || (now > max)) && (hatch.sensors.shortestDistance > 0 && hatch.sensors.greaterDistance > 0)) {
    if (datail) {
      return {
        status: true,
        min,
        max,
        now
      };
    }
    return true;
  }
  if (datail) {
    return {
      status: false,
      min,
      max,
      now
    };
  }
  return false;
}

export function getHatchType(type, detail = false) {
  if (typeof type !== "undefined" && typeof type.hatch !== "undefined") {
    switch(type.hatch.type) {
    case "BCO":
      if (!detail) {
        return "BCO";
      }
      return "Title.Hatch.Type.BackCover";
    default:
      if (!detail) {
        return "DEF";
      }
      return "Title.Hatch.Type.Default";
    }
  }
  if (!detail) {
    return "DEF";
  }
  return "Title.Hatch.Type.Default";
}

export function getValidGyroscope(item) {
  if (!item.gyroscope || (item.gyroscope && (item.gyroscope.x === 0 && item.gyroscope.y === 0 && item.gyroscope.z === 0))) {
    return false;
  }
  return true;
}

export function getValidMoving(position) {
  if (position.tracker.ignition && position.gps.speed > 0) {
    return true;
  }
  return false;
}

export function getNiple(position, abbreviation = true) {
  /*
    M -> MANUTENÇÃO
    T -> TESTE
    B -> BURLA
    D -> DESCARGA
    C -> CARGA
    F -> FECHADO

    Niple
    "CHA", "CLO", "DIS", "FRA", "TES"

    Escotilha
    "CLO", "FRA", "OPE"

    Title.Hatch.Closed
    Title.Hatch.Fraud
    Title.Hatch.Maintenance
    Title.Hatch.Open

    Title.Niple.Charge
    Title.Niple.Closed
    Title.Niple.Discharge
    Title.Niple.Fraud
    Title.Niple.Maintenance
    Title.Niple.Test
  */
  const hatchs = [];
  const niples = [];
  let fraud = false;
  if (typeof position.nipleNetwork !== "undefined") {
    position.nipleNetwork.hatchs.map(hatch => {
      const type = position.patrimony.trackers.find(x => x.serial === hatch.serial);
      let operation = abbreviation ? "F" : "Title.Hatch.Closed";
      let check = true;
      const now = hatch.sensors.distance;
      const greaterDistance = hatch.sensors.greaterDistance;

      if (getHatchStatus(hatch) && !getValidMoving(position)) {
        if (getHatchType(type) === "BCO") {
          operation = abbreviation ? "B" : "Title.Hatch.Fraud";
          fraud = true;
        }
        else {
          operation = abbreviation ? "A" : "Title.Hatch.Open";
        }
      }
      else if (hatch.operation === "CLO" || now > 0 || getValidMoving(position)) {
        operation = abbreviation ? "F" : "Title.Hatch.Closed";
        check = false;
      }
      else if (hatch.operation === "FRA") {
        operation = abbreviation ? "B" : "Title.Hatch.Fraud";
        fraud = true;
      }
      else if (greaterDistance === 0) {
        operation = abbreviation ? "C" : "Title.Hatch.Calibrating";
        check = false;
      }
      const { connection } = hatch.niple;
      if (getBattery(position) || connection === false) {
        operation = abbreviation ? "B" : "Title.Hatch.Fraud";
        check = true;
        fraud = true;
      }
      return hatchs.push({
        check,
        fraud,
        hatch,
        operation
      });
    });

    const hatchBackCoverNoGyroscope = getHatchBackCoverNoGyroscope(position);
    if (hatchBackCoverNoGyroscope === 1 || hatchBackCoverNoGyroscope === 3) {
      fraud = true;
    }

    position.nipleNetwork.niples.map(niple => {
      let operation = abbreviation ? "F" : "Title.Niple.Closed";
      let operationIgFraud = abbreviation ? "F" : "Title.Niple.Closed";
      let operationBoard = abbreviation ? "F" : "Title.Niple.Closed";
      let operationBoardFraud = false;
      if (niple.operation === "CLO") {
        operation = abbreviation ? "F" : "Title.Niple.Closed";
        operationIgFraud = abbreviation ? "F" : "Title.Niple.Closed";
        operationBoard = abbreviation ? "F" : "Title.Niple.Closed";
      }
      else if (niple.operation === "CHA") {
        operation = abbreviation ? "C" : "Title.Niple.Charge";
        operationIgFraud = abbreviation ? "C" : "Title.Niple.Charge";
        if (position.gps.reason === 8 && !getValidMoving(position)) {
          operationBoard = abbreviation ? "C" : "Title.Niple.Charge";
        }
      }
      else if (niple.operation === "DIS") {
        operation = abbreviation ? "D" : "Title.Niple.Discharge";
        operationIgFraud = abbreviation ? "D" : "Title.Niple.Discharge";
        if (position.gps.reason === 8 && !getValidMoving(position)) {
          operationBoard = abbreviation ? "D" : "Title.Niple.Discharge";
        }
      }
      else if (niple.operation === "FRA") {
        operation = abbreviation ? "B" : "Title.Niple.Fraud";
        operationIgFraud = abbreviation ? "B" : "Title.Niple.Fraud";
        operationBoardFraud = abbreviation ? "B" : "Title.Niple.Fraud";
      }
      if (niple.sensors.defaultHead || position.tracker.charging || getBattery(position) || fraud || ((niple.gyroscope && (niple.gyroscope.y > 90 || niple.gyroscope.y < -90)) && getValidGyroscope(niple) && !getValidMoving(position)) || (position.gps.reason === 12 && !getValidMoving(position))) {
        operation = abbreviation ? "B" : "Title.Niple.Fraud";
        operationBoardFraud = abbreviation ? "B" : "Title.Niple.Fraud";
        if (niple.sensors.defaultHead) {
          operationBoard = false;
        }
      }
      return niples.push({
        check: true,
        niple,
        operation,
        operationIgFraud,
        operationBoard,
        operationBoardFraud
      });
    });

    return {
      hatchs,
      niples,
      position
    };
  }
}

export function getNipleOperationExported(operation) {
  switch(operation) {
    case "B":
      return "Burla";
    case "D":
      return "Descarga";
    case "E":
      return "Em movimento";
    case "M":
      return "Manutenção";
    default:
      return "Em movimento";
  }
}

export function getNipleBasic(position, abbreviation = true) {
  /*
    M -> MANUTENÇÃO
    T -> TESTE
    B -> BURLA
    D -> DESCARGA
    C -> CARGA
    F -> FECHADO

    Niple
    "CHA", "CLO", "DIS", "FRA", "TES"

    Escotilha
    "CLO", "FRA", "OPE"

    Title.Hatch.Closed
    Title.Hatch.Fraud
    Title.Hatch.Maintenance
    Title.Hatch.Open

    Title.Niple.Charge
    Title.Niple.Closed
    Title.Niple.Discharge
    Title.Niple.Fraud
    Title.Niple.Maintenance
    Title.Niple.Test
  */
  const hatchs = [];
  const niples = [];
  if (typeof position.nipleNetwork !== "undefined") {
    position.nipleNetwork.hatchs.map(hatch => {
      let operation = abbreviation ? "F" : "Title.Hatch.Closed";
      let check = true;
      if (getHatchStatus(hatch) && !getValidMoving(position)) {
        operation = abbreviation ? "A" : "Title.Hatch.Open";
      }
      else {
        operation = abbreviation ? "F" : "Title.Hatch.Closed";
        check = false;
      }
      return hatchs.push({
        check,
        fraud: false,
        hatch,
        operation
      });
    });

    position.nipleNetwork.niples.map(niple => {
      let operation = abbreviation ? "F" : "Title.Niple.Closed";
      let operationIgFraud = abbreviation ? "F" : "Title.Niple.Closed";
      if (niple.operation === "CHA" && position.gps.reason === 8 && !getValidMoving(position)) {
        operation = abbreviation ? "C" : "Title.Niple.Charge";
        operationIgFraud = abbreviation ? "C" : "Title.Niple.Charge";
      }
      else if (niple.operation === "DIS" && position.gps.reason === 8 && !getValidMoving(position)) {
        operation = abbreviation ? "D" : "Title.Niple.Discharge";
        operationIgFraud = abbreviation ? "D" : "Title.Niple.Discharge";
      }
      return niples.push({
        check: true,
        niple,
        operation,
        operationIgFraud,
        operationBoard: operation,
        operationBoardFraud: false
      });
    });

    return {
      hatchs,
      niples,
      position
    };
  }
}

export function getNipleFraud(position, niple) {
  /*
    Title.Niple.Fraud.BackCover
    Title.Niple.Fraud.Center
    Title.Niple.Fraud.ExternalPower
    Title.Niple.Fraud.NotDetected
    Title.Niple.Fraud.OpenHead
    Title.Niple.Fraud.Rotation
  */
  const hatchBackCover = getHatchBackCover(position);
  const hatchBackCoverNoGyroscope = getHatchBackCoverNoGyroscope(position);
  let fraud = "Title.Niple.Fraud.OpenHead";
  if ((niple.gyroscope && (niple.gyroscope.y > 90 || niple.gyroscope.y < -90)) && getValidGyroscope(niple) && !getValidMoving(position)) fraud = "Title.Niple.Fraud.Rotation";
  else if ((position.gps.reason === 12 && !getValidMoving(position)) || hatchBackCover || hatchBackCoverNoGyroscope === 1 || hatchBackCoverNoGyroscope === 3) fraud = "Title.Niple.Fraud.BackCover";
  else if (hatchBackCoverNoGyroscope === 2) fraud = "Title.Niple.Fraud.GroundSensor";
  else if (niple.fraud === "EPF" || position.tracker.charging || getBattery(position)) fraud = "Title.Niple.Fraud.ExternalPower";
  else if (niple.fraud === "WFL" || niple.fraud === "RLD" || niple.fraud === "SAS") fraud = "Title.Niple.Fraud.NotDetected";
  return fraud;
}

export function getNipleTest(position, abbreviation = true) {
  /*
    T -> TESTE
    ERR - Error
    TES - Testing
    TNP - Test not performed
    TPE - Test performed error
    TPW - Test performed workin
  */
  const niples = [];
  if (typeof position.nipleNetwork !== "undefined") {
    position.nipleNetwork.niples.map(niple => {
      let test = abbreviation ? "TNP" : "Title.Niple.Test.TestNotPerformed";
      if (niple.test === "TES") {
        test = abbreviation ? "TES" : "Title.Niple.Test.Testing";
      }
      else if (niple.test === "TPE") {
        test = abbreviation ? "TPE" : "Title.Niple.Test.TestPerformedError";
      }
      else if (niple.test === "TPW") {
        test = abbreviation ? "TPW" : "Title.Niple.Test.TestPerformedWorking";
      }
      return niples.push({
        niple,
        test
      });
    });

    return {
      niples,
      position
    };
  }
}

export function getNipleTestCheck(position, niple) {
  /*
    Title.Niple.Test.TestNotPerformed
    Title.Niple.Test.Testing
    Title.Niple.Test.TestPerformedError
    Title.Niple.Test.TestPerformedWorking
  */
  let test = "Title.Niple.Test.TestNotPerformed";
  if (niple.test === "TES") test = "Title.Niple.Test.Testing";
  else if (niple.test === "TPE") test = "Title.Niple.Test.TestPerformedError";
  else if (niple.test === "TPW") test = "Title.Niple.Test.TestPerformedWorking";
  return test;
}

export function getNipleOperation(position, niple) {
  /*
    Title.Niple.Charge
    Title.Niple.Closed
    Title.Niple.Discharge
    Title.Niple.Fraud
  */
  let operation = "Title.Niple.Fraud";
  if (niple.operation === "CLO" || (niple.operation === "CHA" && getValidMoving(position))) operation = "Title.Niple.Closed";
  else if (niple.operation === "CHA" && !getValidMoving(position)) operation = "Title.Niple.Charge";
  else if (niple.operation === "DIS") operation = "Title.Niple.Discharge";
  return operation;
}

export function getNipleOperationBasic(position, niple) {
  /*
    Title.Niple.Charge
    Title.Niple.Closed
    Title.Niple.Discharge
  */
  let operation = "Title.Niple.Closed";
  if (niple.operation === "CHA" && !getValidMoving(position)) operation = "Title.Niple.Charge";
  else if (niple.operation === "DIS" && !getValidMoving(position)) operation = "Title.Niple.Discharge";
  return operation;
}

export function getJammer(position) {
  let jammer = position.tracker.jammer;
  if (typeof position.trackersBackup !== "undefined") {
  	position.trackersBackup.map(tb => {
  	  if (jammer === false) {
  	    jammer = tb.jammer;
  	  }
      return jammer;
  	});
  }
  return jammer;
}

export function getPowerSupply(position) {
  return position.tracker.powerSupply;
}

export function getPump(position, abbreviation = true) {
  /*
    Water Drop -> ATIVO

    Title.Pump.On
    Title.Pump.Off
  */
  if (isAutomobileBoardTruck(position.patrimony)) {
    return {
      operation: position.tracker.input1 ? (abbreviation ? "ON" : "Title.Pump.On") : (abbreviation ? "OFF" : "Title.Pump.Off")
    };
  }
  return false;
}

export function getReason(position) {
  /*
    1 -> Time interval
    2 -> Curve
    3 -> Command sent
    4 -> Feed event
    5 -> Force test
    6 -> Force position
    7 -> Ignition event
    8 -> Niple event
    9 -> Test event
    10 -> ESP restarted
    11 -> Operation test event
    12 -> Back cover
    13 -> Hatch
    14 -> Device (Tracker)
    15 -> Operation test failure event
    95 -> Anchor
    96 -> Reverse geocode
    97 -> Driver
    98 -> Maintenance
    99 -> Gyroscope
    100 -> Delayed Location
  */
  switch(position.gps.reason) {
  case 1:
    return "Title.Reason.TimeInterval";
  case 2:
    return "Title.Reason.Curve";
  case 3:
    return "Title.Reason.CommandSent";
  case 4:
    return "Title.Reason.FeedEvent";
  case 5:
    return "Title.Reason.ForceTest";
  case 6:
    return "Title.Reason.ForcePosition";
  case 7:
    return "Title.Reason.IgnitionEvent";
  case 8:
    return "Title.Reason.NipleEvent";
  case 9:
    return "Title.Reason.TestEvent";
  case 10:
    return "Title.Reason.ESPRestarted";
  case 11:
    return "Title.Reason.OperationTestEvent";
  case 12:
    return "Title.Reason.BackCover";
  case 13:
    return "Title.Reason.Hatch";
  case 14:
    return "Title.Reason.Device";
  case 15:
    return "Title.Reason.OperationTestFailureEvent";
  case 95:
    return "Title.Reason.Anchor";
  case 96:
    return "Title.Reason.ReverseGeocode";
  case 97:
    return "Title.Reason.Driver";
  case 98:
    return "Title.Reason.Maintenance";
  case 99:
    return "Title.Reason.Gyroscope";
  case 100:
    return "Title.Reason.DelayedLocation";
  default:
    return "Title.Reason.TimeInterval";
  }
}

export function hatchPositions(positions) {
  const sortedPositions = sortPositionsPolyline(positions, true);
  const operations = [];
  for(var i = 0; i < sortedPositions.length; i++) {
    let operationNow = getNiple(sortedPositions[i]);
    if (operationNow.hatchs.length > 0) {
      const pos = sortedPositions[i];
      let hatchOperation = "F";
      operationNow.hatchs.map(hatch => {
        if (hatch.operation !== "F") hatchOperation = hatch.operation;
        return hatch;
      });
      pos.operation = {
        type: hatchOperation
      };
      operations.push(pos);
    }
  }
  return operations;
}

export function niplePositions(positions) {
  const sortedPositions = sortPositionsPolyline(positions, true);
  const operations = [];
  for(var i = 0; i < sortedPositions.length; i++) {
    const operationNow = getNiple(sortedPositions[i]);
    if (operationNow.niples.length > 0) {
      const pos = sortedPositions[i];
      pos.operation = {
        type: operationNow.niples[0].operation,
        typeIgFraud: operationNow.niples[0].operationIgFraud
      };
      operations.push(pos);
    }
  }
  return operations;
}

export function niplePositionsBasic(positions) {
  const sortedPositions = sortPositionsPolyline(positions, true);
  const operations = [];
  for(var i = 0; i < sortedPositions.length; i++) {
    const operationNow = getNipleBasic(sortedPositions[i]);
    if (operationNow.niples.length > 0) {
      const pos = sortedPositions[i];
      pos.operation = {
        type: operationNow.niples[0].operation,
        typeIgFraud: operationNow.niples[0].operationIgFraud
      };
      operations.push(pos);
    }
  }
  return operations;
}

export function nipleTestPositions(positions) {
  let sortedPositions = sortPositionsPolyline(positions, true);
  sortedPositions = sortedPositions.filter(x => x.gps.reason === 9);
  const testsTpw = [];
  const testsTpe = [];
  for(var i = 0; i < sortedPositions.length; i++) {
    if ((sortedPositions.length - 1) > i) {
      let testNow = getNipleTest(sortedPositions[i]);
      let testNext = getNipleTest(sortedPositions[i + 1]);
      if (testNow.niples.length > 0 && testNext.niples.length > 0) {
        if (testNow.niples[0].test === "TES" && testNext.niples[0].test === "TPW") {
          testsTpw.push(sortedPositions[i]);
        }
        else if (testNow.niples[0].test === "TES" && testNext.niples[0].test === "TPE") {
          testsTpe.push(sortedPositions[i]);
        }
      }
    }
  }
  return {
    testsTpw,
    testsTpe
  };
}

export function pumpedPositions(positions) {
  const minTime = 0;
  const sortedPositions = sortPositionsPolyline(positions, true);

  let start = false;
  let pumps = [];

  for(var i = 0; i < sortedPositions.length; i++) {
    if ((sortedPositions.length - 1) > i) {
      let posNow = sortedPositions[i];
      let posNext = sortedPositions[i + 1];
      let posNowPump = posNow.tracker.input1;

      let diff = DateUtil.diff({ dateA: new Date(posNext.gps.date), dateB: new Date(posNow.gps.date), format: "seconds" });
      if (posNowPump && !start) {
        start = JSON.parse(JSON.stringify(posNow));
        start.pump = {
          dateEnd: false,
          dateStart: posNow.gps.date,
          pumps: [diff],
          sum: 0,
          all: false
        };
      }
      else if (posNowPump && start) {
        start.pump.pumps.push(diff);
      }
      else if (!posNowPump && start && !start.pump.dateEnd) {
        const end = JSON.parse(JSON.stringify(posNow));
        start.pump.dateEnd = posNow.gps.date;
        let sum = 0;
        start.pump.pumps.map(d => {
          return sum += d;
        });
        if (sum > minTime) {
          start.pump.sum = sum;
          start.pump.all = DateUtil.secondsToAll({ seconds: sum });
          end.pump = start.pump;
          pumps.push(JSON.parse(JSON.stringify(end)));
        }
        start = false;
      }
    }
  }

  return pumps;
}

/*
  1 -> Time interval
  2 -> Curve
  3 -> Command sent
  4 -> Feed event
  5 -> Force test
  6 -> Force position
  7 -> Ignition event
  8 -> Niple event
  9 -> Test event
  10 -> ESP restarted
  11 -> Operation test event
  12 -> Back cover
  13 -> Hatch
  14 -> Device (Tracker)
  15 -> Operation test failure event
  95 -> Anchor
  96 -> Reverse geocode
  97 -> Driver
  98 -> Maintenance
  99 -> Gyroscope
  100 -> Delayed Location
*/

export function singleRouteAnchor(positions, violated = false) {
  return positions.filter(x => {
    const anchor = x.tracker.anchor === true;
    const reason = x.gps.reason !== 100;
    const anchorViolated = x.tracker.anchorViolated === violated;
    return anchor && reason && anchorViolated;
  });
}

export function singleRouteBattery(positions) {
  return positions.filter(x => {
    const battery = x.tracker.battery === true;
    const reason = x.gps.reason !== 100;
    return battery && reason;
  });
}

export function singleRouteBlock(positions) {
  return positions.filter(x => {
    const block = x.tracker.block === true;
    const reason = x.gps.reason !== 100;
    return block && reason;
  });
}

export function singleRouteCourse(positions) {
  return positions.filter(x => {
    const course = x.gps.course >= 0 && x.gps.course <= 360;
    const reason = x.gps.reason === 2;
    return course && reason;
  });
}

export function singleRouteEspRestarted(positions) {
  return positions.filter(x => {
    const reason = x.gps.reason === 10;
    return reason;
  });
}

export function singleRouteHatchOpen(positions) {
  return positions.filter(x => {
    const operation = x.operation.type === "A";
    const reason = x.gps.reason === 13;
    return operation && reason;
  });
}

export function singleRouteIgnitionOn(positions) {
  return positions.filter(x => {
    const ignition = x.tracker.ignition === true;
    const reason = x.gps.reason !== 100;
    return ignition && reason;
  });
}

export function singleRouteIgnitionOff(positions) {
  return positions.filter(x => {
    const ignition = x.tracker.ignition === false;
    const reason = x.gps.reason !== 100;
    return ignition && reason;
  });
}

export function singleRouteJammer(positions) {
  return positions.filter(x => {
    const jammer = x.tracker.jammer === true;
    const reason = x.gps.reason !== 100;
    return jammer && reason;
  });
}

export function singleRouteNipleCharge(positions) {
  return positions.filter(x => {
    const operation = x.operation.typeIgFraud === "C";
    const reason = x.gps.reason === 8;
    return operation && reason;
  });
}

export function singleRouteNipleChargeTest(positions) {
  return positions.filter(x => {
    const operation = x.operation.typeIgFraud === "C";
    const reason = x.gps.reason === 11 || x.gps.reason === 15;
    return operation && reason;
  });
}

export function singleRouteNipleClosed(positions) {
  return positions.filter(x => {
    const operation = x.operation.typeIgFraud === "F";
    const reason = x.gps.reason !== 13 && x.gps.reason !== 14 && x.gps.reason !== 100;
    return operation && reason;
  });
}

export function singleRouteNipleDischarge(positions, exported = false) {
  if (exported) {
    return positions.filter(x => x.vehicle.operation === "D");
  }
  return positions.filter(x => {
    const operation = x.operation.typeIgFraud === "D";
    const reason = x.gps.reason === 8;
    return operation && reason;
  });
}

export function singleRouteNipleDischargeTest(positions, exported = false) {
  if (exported) {
    return positions.filter(x => x.vehicle.operation === "D");
  }
  return positions.filter(x => {
    const operation = x.operation.typeIgFraud === "D";
    const reason = x.gps.reason === 11 || x.gps.reason === 15;
    return operation && reason;
  });
}

export function singleRouteNipleFraud(positions, exported = false) {
  if (exported) {
    return positions.filter(x => x.vehicle.operation === "B");
  }
  return positions.filter(x => {
    const operation = x.operation.type === "B";
    const reason = x.gps.reason !== 100;
    return operation && reason;
  });
}

export function singleRouteMaintenance(positions, exported = false) {
  if (exported) {
    return positions.filter(x => x.vehicle.operation === "M");
  }
  return positions.filter(x => {
    const maintenance = x.tracker.maintenance === true || x.gps.reason === 98;
    const reason = x.gps.reason !== 100;
    return maintenance && reason;
  });
}

export function singleRouteMoving(positions, exported = false) {
  if (exported) {
    return positions.filter(x => x.vehicle.operation === "E");
  }
  return positions.filter(x => x.tracker.moving === true);
}

export function singleRoutePanic(positions) {
  return positions.filter(x => {
    const panic = x.tracker.panic === true;
    const reason = x.gps.reason !== 100;
    return panic && reason;
  });
}

export function singleRouteSpeed(positions, speedTolerance = 100) {
  return positions.filter(x => {
    const speed = x.gps.speed >= speedTolerance;
    const reason = x.gps.reason !== 100;
    return speed && reason;
  });
}

export function stopedPositions(positions, downtimeTolerance = false) {
  const minDistance = 0.03;
  var minTime = 300;

  const sortedPositions = sortPositionsPolyline(positions, true);

  if (downtimeTolerance) {
    minTime = DateUtil.timeToSeconds(downtimeTolerance);
  }
  else {
    if (sortedPositions.length > 0) {
      const lastPositionSorted = sortedPositions[sortedPositions.length-1];
      if (typeof lastPositionSorted.patrimony.parameters !== "undefined" && typeof lastPositionSorted.patrimony.parameters.downtimeTolerance !== "undefined") {
        minTime = DateUtil.timeToSeconds(lastPositionSorted.patrimony.parameters.downtimeTolerance);
      }
    }
  }

  let start = false;
  let posNow;
  let downtimes = [];

  for(var i = 0; i < sortedPositions.length; i++) {
    if ((sortedPositions.length - 1) > i) {
      posNow = sortedPositions[i];
      let posNext = sortedPositions[i + 1];
      let posDistance = getDistance(posNow.gps.coordinate, posNext.gps.coordinate, 0.01) / 1000;

      let diff = DateUtil.diff({ dateA: new Date(posNext.gps.date), dateB: new Date(posNow.gps.date), format: "seconds" });
      if (posDistance <= minDistance && !start) {
        start = JSON.parse(JSON.stringify(posNow));
        start.downtime = {
          dateEnd: false,
          dateStart: posNow.gps.date,
          downtimes: [diff],
          sum: 0,
          all: false
        };
      }
      else if (posDistance <= minDistance && start) {
        start.downtime.downtimes.push(diff);
      }
      else if (posDistance > minDistance && start && !start.downtime.dateEnd) {
        const end = JSON.parse(JSON.stringify(posNow));
        start.downtime.dateEnd = posNow.gps.date;
        const sum = start.downtime.downtimes.reduce((acc, cur) => acc + cur, 0);
        if (sum > minTime) {
          start.downtime.sum = sum;
          start.downtime.all = DateUtil.secondsToAll({ seconds: sum });
          end.downtime = start.downtime;
          downtimes.push(JSON.parse(JSON.stringify(end)));
        }
        start = false;
      }
    }
  }

  if (start) {
    const end = JSON.parse(JSON.stringify(posNow));
    start.downtime.dateEnd = posNow.gps.date;
    const sum = start.downtime.downtimes.reduce((acc, cur) => acc + cur, 0);
    if (sum > minTime) {
      start.downtime.sum = sum;
      start.downtime.all = DateUtil.secondsToAll({ seconds: sum });
      end.downtime = start.downtime;
      downtimes.push(JSON.parse(JSON.stringify(end)));
    }
    start = false;
  }

  return downtimes;
}

export function stopedPositionsRework(positions, downtimeTolerance = false) {
  const minDistance = 0.03;
  var minTime = 300;

  const sortedPositions = sortPositionsPolyline(positions, true);

  if (downtimeTolerance) {
    minTime = DateUtil.timeToSeconds(downtimeTolerance);
  }
  else {
    if (sortedPositions.length > 0) {
      const lastPositionSorted = sortedPositions[sortedPositions.length-1];
      if (typeof lastPositionSorted.patrimony.parameters !== "undefined" && typeof lastPositionSorted.patrimony.parameters.downtimeTolerance !== "undefined") {
        minTime = DateUtil.timeToSeconds(lastPositionSorted.patrimony.parameters.downtimeTolerance);
      }
    }
  }

  let start = false;
  let downtimes = [];

  let posOld;
  let posCoordinate;
  let posGps;
  for (var i = 0; i < sortedPositions.length; i++) {
    let posNow = sortedPositions[i];
    if (!posCoordinate) posCoordinate = posNow.gps.coordinate;
    if (!posGps) posGps = posNow.gps.date;
    if (!posOld) posOld = JSON.parse(JSON.stringify(posNow));

    const distanceFromBackMove = getDistance(posNow.gps.coordinate, posCoordinate, 0.01) / 1000;

    if (distanceFromBackMove <= minDistance) {
      if (!start) {
        start = JSON.parse(JSON.stringify(posNow));
        start.downtime = {
          dateEnd: undefined,
          dateStart: posGps,
          downtimes: [],
          sum: 0,
          all: false
        };
      }
      const diff = DateUtil.diff({ dateA: new Date(posNow.gps.date), dateB: new Date(start.downtime.dateEnd ?? start.downtime.dateStart), format: "seconds" });
      start.downtime.downtimes.push(diff);
      start.downtime.dateEnd = posNow.gps.date;
    }
    else {
      if (start) {
        const end = JSON.parse(JSON.stringify(posOld));
        start.downtime.sum = start.downtime.downtimes.reduce((acc, cur) => acc + cur, 0);
        if (start.downtime.sum > minTime) {
          start.downtime.all = DateUtil.secondsToAll({ seconds: start.downtime.sum });
          end.downtime = start.downtime;
          downtimes.push(JSON.parse(JSON.stringify(end)));
        }
        start = false;
      }
      posCoordinate = posNow.gps.coordinate;
      posGps = posNow.gps.date;
    }
    posOld = JSON.parse(JSON.stringify(posNow));
  }

  if (start) {
    const end = JSON.parse(JSON.stringify(posOld));
    start.downtime.sum = start.downtime.downtimes.reduce((acc, cur) => acc + cur, 0);
    if (start.downtime.sum > minTime) {
      start.downtime.all = DateUtil.secondsToAll({ seconds: start.downtime.sum });
      end.downtime = start.downtime;
      downtimes.push(JSON.parse(JSON.stringify(end)));
    }
    start = false;
  }
  return downtimes;
}

export function sortPositions(positions, asc = false, date = true, discharge = false, name = false) {
  if (date) return sortPositionsDate(positions, asc);
  else if (discharge) return sortPositionsDischarge(positions, asc);
  else if (name) return sortPositionsName(positions, asc);
  return positions;
}

export function sortPositionsDate(positions, asc = true) {
  if (asc) return positions.sort((a, b) => {
    if (a.position.createdAt && b.position.createdAt) return a.position.gps.date.localeCompare(b.position.gps.date) || a.position.createdAt.localeCompare(b.position.createdAt);
    return a.position.gps.date.localeCompare(b.position.gps.date);
  });
  return positions.sort((a, b) => {
    if (a.position.createdAt && b.position.createdAt) return b.position.gps.date.localeCompare(a.position.gps.date) || b.position.createdAt.localeCompare(a.position.createdAt);
    return b.position.gps.date.localeCompare(a.position.gps.date);
  });
}

export function sortPositionsDischarge(positions, asc = true) {
  if (asc) {
    return positions.sort((a, b) => {
      const AEvents = typeof a.position._events !== "undefined" ? a.position._events.lastNipleDischarge : false;
      const BEvents = typeof b.position._events !== "undefined" ? b.position._events.lastNipleDischarge : false;
      if (AEvents === BEvents) {
        return 0;
      }
      else if (AEvents === false && BEvents !== false) {
        return 1;
      }
      else if (AEvents !== false && BEvents === false) {
        return -1;
      }
      return AEvents.localeCompare(BEvents);
    });
  }
  return positions.sort((a, b) => {
    const AEvents = typeof b.position._events !== "undefined" ? b.position._events.lastNipleDischarge : false;
    const BEvents = typeof a.position._events !== "undefined" ? a.position._events.lastNipleDischarge : false;
    if (AEvents === BEvents) {
      return 0;
    }
    else if (AEvents === false && BEvents !== false) {
      return 1;
    }
    else if (AEvents !== false && BEvents === false) {
      return -1;
    }
    return AEvents.localeCompare(BEvents);
  });
}

export function sortPositionsName(positions, asc = true) {
  if (asc) {
    return positions.sort((a, b) => {
      const patrimonyA = getDescriptionPatrimony(a.position.patrimony);
      const patrimonyB = getDescriptionPatrimony(b.position.patrimony);
      const clientA = getDescriptionClient(a.position.patrimony.client);
      const clientB = getDescriptionClient(b.position.patrimony.client);
      return clientA.localeCompare(clientB) || patrimonyA.localeCompare(patrimonyB);
    });
  }
  return positions.sort((a, b) => {
    const patrimonyA = getDescriptionPatrimony(a.position.patrimony);
    const patrimonyB = getDescriptionPatrimony(b.position.patrimony);
    const clientA = getDescriptionClient(a.position.patrimony.client);
    const clientB = getDescriptionClient(b.position.patrimony.client);
    return clientB.localeCompare(clientA) || patrimonyB.localeCompare(patrimonyA);
  });
}

export function sortPositionsPolyline(positionsPolyline, asc = false) {
  if (asc) return positionsPolyline.sort((a, b) => {
    if (a.createdAt && b.createdAt) return a.gps.date.localeCompare(b.gps.date) || a.createdAt.localeCompare(b.createdAt);
    return a.gps.date.localeCompare(b.gps.date);
  });
  return positionsPolyline.sort((a, b) => {
    if (a.createdAt && b.createdAt) return b.gps.date.localeCompare(a.gps.date) || b.createdAt.localeCompare(a.createdAt);
    return b.gps.date.localeCompare(a.gps.date);
  });
}

export function sortPositionsPolylineDate(positionsPolyline, asc = false) {
  if (asc) return positionsPolyline.sort((a, b) => a.gps.date.localeCompare(b.gps.date));
  return positionsPolyline.sort((a, b) => b.gps.date.localeCompare(a.gps.date));
}

export function simplifyPath(points, tolerance) {
  return points;
  // var Line = function(p1, p2) {
  // 	this.p1 = p1;
  // 	this.p2 = p2;
  // 	this.distanceToPoint = function(point) {
  // 		var m = (this.p2.lat - this.p1.lat) / (this.p2.lng - this.p1.lng),
  // 		b = this.p1.lat - (m * this.p1.lng),
  // 		d = [];
  // 		d.push(Math.abs(point.lat - (m * point.lng) - b) / Math.sqrt(Math.pow(m, 2) + 1));
  // 		d.push(Math.sqrt(Math.pow((point.lng - this.p1.lng), 2) + Math.pow((point.lat - this.p1.lat), 2)));
  // 		d.push(Math.sqrt(Math.pow((point.lng - this.p2.lng), 2) + Math.pow((point.lat - this.p2.lat), 2)));
  // 		return d.sort(function(a, b) { return (a - b); })[0];
  // 	};
  // };

  // var douglasPeucker = function(points, tolerance) {
  // 	if (points.length <= 2) {
  // 	  return [points[0]];
  // 	}
  // 	var returnPoints = [],
  // 		line = new Line(points[0], points[points.length - 1]),
  // 		maxDistance = 0,
  // 		maxDistanceIndex = 0,
  // 		p;
  // 	for(var i = 1; i <= points.length - 2; i++) {
  // 		var distance = line.distanceToPoint(points[i]);
  // 		if (distance > maxDistance) {
  // 			maxDistance = distance;
  // 			maxDistanceIndex = i;
  // 		}
  // 	}
  // 	if (maxDistance >= tolerance) {
  // 		p = points[maxDistanceIndex];
  // 		line.distanceToPoint(p, true);
  // 		returnPoints = returnPoints.concat(douglasPeucker(points.slice(0, maxDistanceIndex + 1), tolerance));
  // 		returnPoints = returnPoints.concat(douglasPeucker(points.slice(maxDistanceIndex, points.length), tolerance));
  // 	} else {
  // 		p = points[maxDistanceIndex];
  // 		line.distanceToPoint(p, true);
  // 		returnPoints = [points[0]];
  // 	}
  // 	return returnPoints;
  // };
  // var arr = douglasPeucker(points, tolerance);
  // arr.push(points[points.length-1]);
  // return arr;
};
