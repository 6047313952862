import ClientSchemaReduced, { schemaDate as schemaDateClient } from "../Client/SchemaReducedTitle";

const schema = {
  body: "Mensagem",
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  createdAt: "Criado em",
  endAt: "Termino em",
  id: "id",
  global: "Mensagem global",
  startAt: "Começa em",
  title: "Título",
  updatedAt: "Atualizado em"
};

export const schemaDate = {
  createdAt: "datetime",
  client: JSON.parse(JSON.stringify(schemaDateClient)),
  updatedAt: "datetime"
};

export default schema;
