import React from "react";

import Api, { ApiV2 } from "./../Api";

class Position extends React.Component {
  async findDiagnosis({ data, token }) {
    const response = await ApiV2.post("/position/find/diagnosis", data, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if (error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if (response.success) return response.data;
    return {
      positions: [],
      virtualFences: []
    };
  }

  async findExportedRoute({ endDate, orderAsc, page, patrimony, startDate, token }) {
    const response = await ApiV2.post("/position/find/exported/route", {
      endDate,
      orderAsc,
      limit: 1500,
      page,
      patrimony,
      startDate
    }, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      const { patrimony, positions } = response.data;
      positions.docs.map(position => position.patrimony = patrimony);
      return response.data;
    }
    return {
      patrimony: false,
      positions: {
        docs: [],
        total: 0,
        limit: 1500,
        page: 1,
        pages: 1
      }
    };
  }

  async findExportedRouteTest({ endDate, orderAsc, page, patrimony, startDate, token }) {
    const response = await ApiV2.post("/positionTest/find/exported/route", {
      endDate,
      orderAsc,
      limit: 1500,
      page,
      patrimony,
      startDate
    }, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      const { patrimony, positions } = response.data;
      positions.docs.map(position => position.patrimony = patrimony);
      return response.data;
    }
    return {
      patrimony: false,
      positions: {
        docs: [],
        total: 0,
        limit: 1500,
        page: 1,
        pages: 1
      }
    };
  }

  async findRoute({ dateForCreated, endDate, orderAsc, page, patrimony, startDate, token }) {
    const response = await ApiV2.post("/position/find/route", {
      dateForCreated,
      endDate,
      orderAsc,
      limit: 1500,
      page,
      patrimony,
      startDate
    }, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      const { patrimony, positions } = response.data;
      positions.docs.map(position => position.patrimony = patrimony);
      return response.data;
    }
    return {
      patrimony: false,
      positions: {
        docs: [],
        total: 0,
        limit: 1500,
        page: 1,
        pages: 1
      }
    };
  }

  async findRouteGpx({ endDate, orderAsc, page, patrimony, startDate, token }) {
    const response = await ApiV2.post(
      "/position/find/route/gpx", {
        endDate,
        orderAsc,
        limit: 1500,
        page,
        patrimony,
        startDate
      }, {
        headers: {"Authorization": "Bearer " + token}
      }
    )
      .then(response => ({
        success: true,
        data: response.data
      }))
      .catch(error => {
        try {
          if(error.response.data === "Unauthorized") {
            return {
              success: false,
              error: {
                type: "UnauthorizedError",
                details: [{
                  message: "UnauthorizedError"
                }]
              }
            };
          }
          return error.response.data;
        }
        catch(error) {
          return {
            success: false,
            error: {
              type: "NetworkError",
              details: [{
                message: "NetworkError"
              }]
            }
          };
        }
      });

    if (response.success) return response.data;
    return false;
  }

  async findRouteTest({ dateForCreated, endDate, orderAsc, page, patrimony, startDate, token }) {
    const response = await ApiV2.post("/positionTest/find/route", {
      dateForCreated,
      endDate,
      orderAsc,
      limit: 1500,
      page,
      patrimony,
      startDate
    }, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      const { patrimony, positions } = response.data;
      positions.docs.map(position => position.patrimony = patrimony);
      return response.data;
    }
    return {
      patrimony: false,
      positions: {
        docs: [],
        total: 0,
        limit: 1500,
        page: 1,
        pages: 1
      }
    };
  }

  async findTranshipment({ endDate, patrimony, startDate, token }) {
    const response = await Api.post("/position/find-transhipment", {
      endDate,
      patrimony,
      startDate
    }, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return response.data.docs;
    }
    return [];
  }

  async findTranshipmentTest({ endDate, patrimony, startDate, token }) {
    const response = await Api.post("/position-test/find-transhipment", {
      endDate,
      patrimony,
      startDate
    }, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return response.data.docs;
    }
    return [];
  }

  async resendExporter({ id, token }) {
    const response = await Api.post("/position/resend-exporter", {
      id
    }, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async resendExporterTest({ id, token }) {
    const response = await Api.post("/position-test/resend-exporter", {
      id
    }, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async sendSimulator({ position, rs232, token }) {
    const response = await Api.post("/position/send-simulator", {
      position,
      rs232
    }, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }
}

export default new Position();
