import { createActions, createReducer } from "reduxsauce";
import DateUtil from "./../../utils/Date";
import { filterByClientAndWord, sortPositions } from "./../../utils/Position";
import { isAutomobileBoardAutoVaccum } from "./../../utils/Patrimony";

import ClientSchemaReduced from "./../../services/Client/SchemaReduced";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addLastPosition: ["lastPosition"],
  delLastPosition: ["id"],
  updateLastPosition: ["id", "lastPosition"],
  searchLastPosition: ["search"],
  selectLastPosition: ["id"],
  setLastPosition: ["lastPositions"],
  setLastPositionPatrimonyType: ["niple"],
  setLastPositionPatrimonyNipleType: ["nipleType"],
  setLastPositionSort: ["lastPositionSortAsc", "lastPositionSortDate", "lastPositionSortDischarge", "lastPositionSortName"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  positions: [],
  positionsSearch: [],
  positionsSearchQuery: {
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    events: [],
    word: "",
    niple: true,
    nipleType: "ALL" //ALL | ELE | MEC
  },
  positionsSearchQueryEnabled: true,
  positionSelected: false,
  positionsLoad: false,
  positionsNipleEnabled: true,
  positionsVehicleEnabled: true,
  positionsSortAsc: false,
  positionsSortDate: true,
  positionsSortDischarge: false,
  positionsSortName: false
};

const format = (lastPosition) => {
  const copy = JSON.parse(JSON.stringify(lastPosition));
  if(typeof copy.patrimony === "undefined") {
    copy.patrimony = {
      automobile: {
        board: {
          autoVaccum: {
            license: copy.tracker.serial
          },
          board: copy.tracker.serial,
          color: "GRAY",
          country: "BR",
          description: "SC - CHAPECÓ",
          layout: "DEF",
          type: "CAR"
        },
        brand: "TPX",
        card: {
          number: copy.tracker.serial
        },
        color: "WHITE",
        model: "OCTSAT",
        type: "BOA"
      },
      object: {
        name: copy.tracker.serial
      },
      type: "AUT",
      vessel: {
        name: copy.tracker.serial,
        registrationNumber: copy.tracker.serial
      }
    };
  }
  return copy;
};

const add = (state = INITIAL_STATE, action) => {
  const { lastPosition } = action;
  if(state.positionsLoad && lastPosition !== null) {
    const index = state.positions.findIndex(x => x.id === lastPosition.tracker.serial);
    if(index !== -1) {
      return update(
        state,
        {
          id: lastPosition.tracker.serial,
          lastPosition
        }
      );
    }

    const positions = state.positions;
    positions.push({ id: lastPosition.tracker.serial, position: format(lastPosition), selected: false });

    const checkNiple = positions.filter(x => isAutomobileBoardAutoVaccum(x.position.patrimony));
    if (checkNiple.length === 0) {
      state.positionsNipleEnabled = false;
      state.positionsSearchQuery.niple = false;
    }
    const checkVehicle = positions.filter(x => !isAutomobileBoardAutoVaccum(x.position.patrimony));
    if (checkVehicle.length === 0) {
      state.positionsVehicleEnabled = false;
      state.positionsSearchQuery.niple = true;
    }

    const { positionsSearch } = search({
      ...state,
      positions
    }, { search: state.positionsSearchQuery });

    return {
      ...state,
      positions: sortPositions(positions, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName),
      positionsSearch: sortPositions(positionsSearch, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName)
    };
  }
  return state;
};

const del = (state = INITIAL_STATE, action) => {
  const { id } = action;
  if(state.positionsLoad && (id !== null)) {
    const index = state.positions.findIndex(x => x.id === id);
    if(index !== -1) {
      let positionSelected = false;
      const positions = [...state.positions];
      positions.splice(index, 1);
      const checkNiple = positions.filter(x => isAutomobileBoardAutoVaccum(x.position.patrimony));
      if (checkNiple.length === 0) {
        state.positionsNipleEnabled = false;
        state.positionsSearchQuery.niple = false;
      }
      const checkVehicle = positions.filter(x => !isAutomobileBoardAutoVaccum(x.position.patrimony));
      if (checkVehicle.length === 0) {
        state.positionsVehicleEnabled = false;
        state.positionsSearchQuery.niple = true;
      }

      const { positionsSearch } = search({
        ...state,
        positions,
        positionSelected
      }, { search: state.positionsSearchQuery });

      return {
        ...state,
        positions: sortPositions(positions, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName),
        positionsSearch: sortPositions(positionsSearch, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName),
        positionSelected
      };
    }
  }
  return state;
};

const update = (state = INITIAL_STATE, action) => {
  const { id, lastPosition } = action;
  if(state.positionsLoad && (id !== null && lastPosition !== null)) {
    const index = state.positions.findIndex(x => x.id === id);
    if(index !== -1) {
      let positionSelected = state.positionSelected;
      const positions = state.positions.map(position => {
        if((position.id === id) && ((DateUtil.diff({ dateA: position.position.gps.date, dateB: lastPosition.gps.date }) < 0) || (DateUtil.diff({ dateA: position.position.gps.date, dateB: lastPosition.gps.date }) === 0 && DateUtil.diff({ dateA: position.position.createdAt, dateB: lastPosition.createdAt }) <= 0))) {
          if(position.selected) {
            positionSelected = { ...position, position: format(lastPosition) };
          }
          return { ...position, position: format(lastPosition) };
        }
        return { ...position };
      });

      const checkNiple = positions.filter(x => isAutomobileBoardAutoVaccum(x.position.patrimony));
      if (checkNiple.length === 0) {
        state.positionsNipleEnabled = false;
        state.positionsSearchQuery.niple = false;
      }
      const checkVehicle = positions.filter(x => !isAutomobileBoardAutoVaccum(x.position.patrimony));
      if (checkVehicle.length === 0) {
        state.positionsVehicleEnabled = false;
        state.positionsSearchQuery.niple = true;
      }

      const { positionsSearch } = search({
        ...state,
        positions,
        positionSelected
      }, { search: state.positionsSearchQuery });

      return {
        ...state,
        positions: sortPositions(positions, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName),
        positionsSearch: sortPositions(positionsSearch, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName),
        positionSelected
      };
    }
  }
  return state;
};

const search = (state = INITIAL_STATE, action) => {
  const { search } = action;
  search.niple = state.positionsSearchQuery.niple;
  search.nipleType = state.positionsSearchQuery.nipleType;
  const { enabled: positionsSearchEnabled, results: positionsSearchResults } = filterByClientAndWord(state.positions, search);
  return {
    ...state,
    positionsSearch: positionsSearchResults,
    positionsSearchQuery: search,
    positionsSearchQueryEnabled: positionsSearchEnabled
  };
};

const select = (state = INITIAL_STATE, action) => {
  const { id } = action;
  let positionSelected = state.positionSelected;
  const positions = state.positions.map(position => {
    if(position.id === id) {
      positionSelected = false;
      if(!position.selected) {
        positionSelected = { ...position, selected: !position.selected };
      }
      return { ...position, selected: !position.selected };
    }
    return { ...position, selected: false };
  });
  const positionsSearch = state.positionsSearch.map(position => position.id === id ? { ...position, selected: !position.selected } : { ...position, selected: false });
  return {
    ...state,
    positions,
    positionsSearch,
    positionSelected
  };
};

const set = (state = INITIAL_STATE, action) => {
  const { lastPositions } = action;
  if(state.positionsLoad === false && lastPositions !== null) {
    const positions = lastPositions.map(lastPosition => {
      return { id: lastPosition.tracker.serial, position: format(lastPosition), selected: false };
    });

    const checkNiple = positions.filter(x => isAutomobileBoardAutoVaccum(x.position.patrimony));
    if (checkNiple.length === 0) {
      state.positionsNipleEnabled = false;
      state.positionsSearchQuery.niple = false;
    }
    const checkVehicle = positions.filter(x => !isAutomobileBoardAutoVaccum(x.position.patrimony));
    if (checkVehicle.length === 0) {
      state.positionsVehicleEnabled = false;
      state.positionsSearchQuery.niple = true;
    }

    const { positionsSearch } = search({
      ...state,
      positions
    }, { search: state.positionsSearchQuery });

    return {
      ...state,
      positions: sortPositions(positions, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName),
      positionsSearch: sortPositions(positionsSearch, state.positionsSortAsc, state.positionsSortDate, state.positionsSortDischarge, state.positionsSortName),
      positionsLoad: true
    };
  }
  return state;
};

const setLastPositionPatrimonyType = (state = INITIAL_STATE, action) => {
  const { niple } = action;
  const positions = state.positions;
  const { positionsSearch } = search({
    ...state,
    positions,
    positionsSearchQuery: {
      ...state.positionsSearchQuery,
      niple
    }
  }, { search: state.positionsSearchQuery });

  return {
    ...state,
    positionsSearchQuery: {
      ...state.positionsSearchQuery,
      niple
    },
    positionsSearch
  };
};

const setLastPositionPatrimonyNipleType = (state = INITIAL_STATE, action) => {
  const { nipleType } = action;
  const positions = state.positions;
  const { positionsSearch } = search({
    ...state,
    positions,
    positionsSearchQuery: {
      ...state.positionsSearchQuery,
      nipleType
    }
  }, { search: state.positionsSearchQuery });

  return {
    ...state,
    positionsSearchQuery: {
      ...state.positionsSearchQuery,
      nipleType
    },
    positionsSearch
  };
};

const setLastPositionSort = (state = INITIAL_STATE, action) => {
  const { lastPositionSortAsc, lastPositionSortDate, lastPositionSortDischarge, lastPositionSortName } = action;
  return {
    ...state,
    positionsSortAsc: lastPositionSortAsc,
    positionsSortDate: lastPositionSortDate,
    positionsSortDischarge: lastPositionSortDischarge,
    positionsSortName: lastPositionSortName,
    positions: sortPositions(state.positions, lastPositionSortAsc, lastPositionSortDate, lastPositionSortDischarge, lastPositionSortName),
    positionsSearch: sortPositions(state.positionsSearch, lastPositionSortAsc, lastPositionSortDate, lastPositionSortDischarge, lastPositionSortName)
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_LAST_POSITION]: add,
  [Types.DEL_LAST_POSITION]: del,
  [Types.UPDATE_LAST_POSITION]: update,
  [Types.SEARCH_LAST_POSITION]: search,
  [Types.SELECT_LAST_POSITION]: select,
  [Types.SET_LAST_POSITION]: set,
  [Types.SET_LAST_POSITION_PATRIMONY_TYPE]: setLastPositionPatrimonyType,
  [Types.SET_LAST_POSITION_PATRIMONY_NIPLE_TYPE]: setLastPositionPatrimonyNipleType,
  [Types.SET_LAST_POSITION_SORT]: setLastPositionSort
});
