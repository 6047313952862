import React, { memo, useEffect, useState } from "react";
import {
  Button
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "./index.css";

import { isScopes } from "../../../services/Auth";
import DateUtil from "../../../utils/Date";

import PatrimonyMarker from "../Patrimony";

import { getDescriptionPatrimony } from "../../../utils/Patrimony";
import { getDescriptionClient } from "../../../utils/Client";

import { getHatchStatus, getNipleOperationExported } from "../../../utils/Position";
import { mergeObject } from "../../../utils/Object";

import SupportPointSchema from "../../../services/SupportPoint/Schema";

import { Creators as MenusActions } from "../../../store/ducks/menus";

const DEFAULT_ZINDEX = 1000;
const SCALE_HOVER = 1;
const SCALE_NORMAL = 0.65;

const Diagnosis = ({ onClick, ePosition, eType, profile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [diagnosisHover, setDiagnosisHover] = useState(false);
  const [popupShow, setPopupShow] = useState(false);
  const [transhipmentChargeShow, setTranshipmentChargeShow] = useState(false);
  const [transhipmentDischargeShow, setTranshipmentDischargeShow] = useState(false);

  const [scale, setScale] = useState(SCALE_NORMAL);
  const [zIndex, setZIndex] = useState(DEFAULT_ZINDEX);

  useEffect(() => {
    setScale(diagnosisHover || popupShow ? SCALE_HOVER : SCALE_NORMAL);
    setZIndex(Math.round(scale * DEFAULT_ZINDEX) - (popupShow ? 20 : 0) + (diagnosisHover ? DEFAULT_ZINDEX : 0));
  }, [diagnosisHover, popupShow, scale]);

  const getDiagnosisClusterType = () => {
    const eTypes = [
      {
        id: "nipleDischarge",
        title: () => t("Title.Niple.Discharge"),
        icon: () => <span>D</span>,
        content: () => {
          if(typeof ePosition.operation !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Client")}:</div>
                    <div><span>{getDescriptionClient(ePosition.patrimony.client)}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Patrimony")}:</div>
                    <div><span>{getDescriptionPatrimony(ePosition.patrimony)}</span></div>
                  </li>
                  <li>
                    <div>{t("Date")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  {
                    (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                      <li>
                        <div>{t("Enter")}:</div>
                        <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    ))
                  }
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                  <li>
                    <div>{t("Ignition")}:</div>
                    <div><span>{ePosition.tracker.ignition ? t("IgnitionOn") : t("IgnitionOff")}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Operation")}:</div>
                    <div><span>{t("Title.Niple.Discharge")}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.OperationExported")}:</div>
                    {
                      ((ePosition.positionExporter) && (
                        <div>
                          <span>{getNipleOperationExported(ePosition.positionExporter.vehicle.operation)} ({ePosition.positionExporter.vehicle.operation}) {ePosition.positionExporter.response.status ? <i className="fas fa-check" style={{ color: "#087d06" }} /> : <i className="fas fa-times" style={{ color: "#ff0000" }} />}</span>
                        </div>
                      ))
                    }
                    {
                      ((!ePosition.positionExporter) && (
                        <div>
                          <span>Nenhuma exportação vinculada</span>
                        </div>
                      ))
                    }
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
              </>
            );
          }
        }
      },
      {
        id: "nipleDischargeTest",
        title: () => t("Title.Niple.DischargeTest"),
        icon: () => <span style={{ color: "#999999"}}>D</span>,
        content: () => {
          if(typeof ePosition.operation !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Client")}:</div>
                    <div><span>{getDescriptionClient(ePosition.patrimony.client)}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Patrimony")}:</div>
                    <div><span>{getDescriptionPatrimony(ePosition.patrimony)}</span></div>
                  </li>
                  <li>
                    <div>{t("Date")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  {
                    (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                      <li>
                        <div>{t("Enter")}:</div>
                        <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    ))
                  }
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Operation")}:</div>
                    <div><span>{t("Title.Niple.DischargeTest")}</span></div>
                  </li>
                  <li>
                    <div>{t("Ignition")}:</div>
                    <div><span>{ePosition.tracker.ignition ? t("IgnitionOn") : t("IgnitionOff")}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.OperationExported")}:</div>
                    {
                      ((ePosition.positionExporter) && (
                        <div>
                          <span>{getNipleOperationExported(ePosition.positionExporter.vehicle.operation)} ({ePosition.positionExporter.vehicle.operation}) {ePosition.positionExporter.response.status ? <i className="fas fa-check" style={{ color: "#087d06" }} /> : <i className="fas fa-times" style={{ color: "#ff0000" }} />}</span>
                        </div>
                      ))
                    }
                    {
                      ((!ePosition.positionExporter) && (
                        <div>
                          <span>Nenhuma exportação vinculada</span>
                        </div>
                      ))
                    }
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
              </>
            );
          }
        }
      },
      {
        id: "nipleCharge",
        title: () => t("Title.Niple.Charge"),
        icon: () => <span>C</span>,
        content: () => {
          if(typeof ePosition.operation !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Client")}:</div>
                    <div><span>{getDescriptionClient(ePosition.patrimony.client)}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Patrimony")}:</div>
                    <div><span>{getDescriptionPatrimony(ePosition.patrimony)}</span></div>
                  </li>
                  <li>
                    <div>{t("Date")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  {
                    (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                      <li>
                        <div>{t("Enter")}:</div>
                        <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    ))
                  }
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Operation")}:</div>
                    <div><span>{t("Title.Niple.Charge")}</span></div>
                  </li>
                  <li>
                    <div>{t("Ignition")}:</div>
                    <div><span>{ePosition.tracker.ignition ? t("IgnitionOn") : t("IgnitionOff")}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.OperationExported")}:</div>
                    {
                      ((ePosition.positionExporter) && (
                        <div>
                          <span>{getNipleOperationExported(ePosition.positionExporter.vehicle.operation)} ({ePosition.positionExporter.vehicle.operation}) {ePosition.positionExporter.response.status ? <i className="fas fa-check" style={{ color: "#087d06" }} /> : <i className="fas fa-times" style={{ color: "#ff0000" }} />}</span>
                        </div>
                      ))
                    }
                    {
                      ((!ePosition.positionExporter) && (
                        <div>
                          <span>Nenhuma exportação vinculada</span>
                        </div>
                      ))
                    }
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
              </>
            );
          }
        }
      },
      {
        id: "nipleChargeTest",
        title: () => t("Title.Niple.ChargeTest"),
        icon: () => <span style={{ color: "#999999" }}>C</span>,
        content: () => {
          if(typeof ePosition.operation !== "undefined") {
            return (
              <>
                <ul>
                  <li>
                    <div>{t("id")}:</div>
                    <div><span>{ePosition.id}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Client")}:</div>
                    <div><span>{getDescriptionClient(ePosition.patrimony.client)}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Patrimony")}:</div>
                    <div><span>{getDescriptionPatrimony(ePosition.patrimony)}</span></div>
                  </li>
                  <li>
                    <div>{t("Date")}:</div>
                    <div><span>{DateUtil.to({ date: new Date(ePosition.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  {
                    (isScopes({ currentUser: profile.currentUser, scopes: ["is:master"], every: false }) && (
                      <li>
                        <div>{t("Enter")}:</div>
                        <div><span>{DateUtil.to({ date: new Date(ePosition.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                      </li>
                    ))
                  }
                  <li>
                    <div>{t("Coordinate")}:</div>
                    <div><span>{ePosition.gps.coordinate.latitude} | {ePosition.gps.coordinate.longitude}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Operation")}:</div>
                    <div><span>{t("Title.Niple.ChargeTest")}</span></div>
                  </li>
                  <li>
                    <div>{t("Ignition")}:</div>
                    <div><span>{ePosition.tracker.ignition ? t("IgnitionOn") : t("IgnitionOff")}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.OperationExported")}:</div>
                    {
                      ((ePosition.positionExporter) && (
                        <div>
                          <span>{getNipleOperationExported(ePosition.positionExporter.vehicle.operation)} ({ePosition.positionExporter.vehicle.operation}) {ePosition.positionExporter.response.status ? <i className="fas fa-check" style={{ color: "#087d06" }} /> : <i className="fas fa-times" style={{ color: "#ff0000" }} />}</span>
                        </div>
                      ))
                    }
                    {
                      ((!ePosition.positionExporter) && (
                        <div>
                          <span>Nenhuma exportação vinculada</span>
                        </div>
                      ))
                    }
                  </li>
                </ul>
                <div className="address">
                  { ePosition.gps.address }
                </div>
              </>
            );
          }
        }
      }
    ];

    const eTypeFilter = eTypes.filter(x => x.id === eType());
    if (eTypeFilter.length > 0) return eTypeFilter[0];
    return false;
  };

  const diagnosisDetails = getDiagnosisClusterType();
  const transform = eType() === "course" ? `rotate(${ePosition.gps.course}deg)` : "rotate(0deg)";

  const popup = (
    <div
      id={`diagnosis-marker-popup-${ePosition.index}`}
      className="marker-diagnosis-popup"
      onClick={e => e.stopPropagation()}
    >
      <h3 className="mep-title">{diagnosisDetails && diagnosisDetails.title()}</h3>
      <div className="mep-content">{diagnosisDetails && diagnosisDetails.content()}</div>
    </div>
  );

  return (
    <div
      className="marker-diagnosis-all"
      onClick={e => {
        setPopupShow(!popupShow);
        setTranshipmentChargeShow(false);
        setTranshipmentDischargeShow(false);
      }}
      onMouseEnter={e => setDiagnosisHover(true)}
      onMouseLeave={e => setDiagnosisHover(false)}
      style={{
        transform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        MozTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        WebkitTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        zIndex: zIndex
      }}
    >
      <div className="marker-diagnosis">
        <div className="icon" style={{
          transform,
          MozTransform: transform,
          WebkitTransform: transform }}>
          {
            diagnosisDetails && eType() !== "speed" && diagnosisDetails.icon()
          }
          {
            diagnosisDetails && eType() === "speed" &&
            <>
              <span className="icon-km">{parseInt(ePosition.gps.speed)}</span>
              <small>Km/h</small>
            </>
          }
        </div>
        {popupShow && !transhipmentChargeShow && !transhipmentDischargeShow && popup}
        {
          transhipmentChargeShow && <div className="diagnosis-transhipment-box"><PatrimonyMarker
            key={`position-charge-${ePosition.transhipment.charges[0].id}-${ePosition.transhipment.charges[0].patrimony.id}`}
            lat={ePosition.transhipment.charges[0].gps.latitude}
            lng={ePosition.transhipment.charges[0].gps.longitude}
            onClick={e => {
              e.prdiagnosisDefault();
              e.stopPropagation();
              setTranshipmentChargeShow(false);
              setTranshipmentDischargeShow(false);
            }}
            patrimony={ePosition.transhipment.charges[0].patrimony}
            position={ePosition.transhipment.charges[0]}
            profile={{
              currentUser: profile.currentUser,
              miniMarkers: profile.miniMarkers
            }}
            selected={false}
          /></div>
        }
        {
          transhipmentDischargeShow && <div className="diagnosis-transhipment-box"><PatrimonyMarker
            key={`position-discharge-${ePosition.id}-${ePosition.patrimony.id}`}
            lat={ePosition.gps.latitude}
            lng={ePosition.gps.longitude}
            onClick={e => {
              e.prdiagnosisDefault();
              e.stopPropagation();
              setTranshipmentChargeShow(false);
              setTranshipmentDischargeShow(false);
            }}
            patrimony={ePosition.patrimony}
            position={ePosition}
            profile={{
              currentUser: profile.currentUser,
              miniMarkers: profile.miniMarkers
            }}
            selected={false}
          /></div>
        }
      </div>
    </div>
  );
};

export default memo(Diagnosis);
