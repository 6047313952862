import { combineReducers } from "redux";
import chips from "./chips";
import cron from "./cron";
import databaseTest from "./databaseTest";
import drivers from "./drivers";
import exporteds from "./exporteds";
import frequency from "./frequency";
import lastDateCorrections from "./lastDateCorrections";
import lastDelayedLocations from "./lastDelayedLocations";
import lastExporterErrors from "./lastExporterErrors";
import lastNotifications from "./lastNotifications";
import lastPatrimonyTests from "./lastPatrimonyTests";
import lastPositions from "./lastPositions";
import lastPositionsProblems from "./lastPositionsProblems";
import loaders from "./loaders";
import menus from "./menus";
import notifications from "./notifications";
import patrimonies from "./patrimonies";
import period from "./period";
import pinboard from "./pinboard";
import qrcodes from "./qrcodes";
import regions from "./regions";
import routes from "./routes";
import route from "./route";
import simulatorsMaps from "./simulatorsMaps";
import speeding from "./speeding";
import transhipments from "./transhipments";
import users from "./users";
import virtualFences from "./virtualFences";
const appReducer = combineReducers({
  chips,
  cron,
  databaseTest,
  drivers,
  exporteds,
  frequency,
  lastDateCorrections,
  lastDelayedLocations,
  lastExporterErrors,
  lastNotifications,
  lastPatrimonyTests,
  lastPositions,
  lastPositionsProblems,
  loaders,
  menus,
  notifications,
  patrimonies,
  period,
  pinboard,
  qrcodes,
  regions,
  routes,
  route,
  simulatorsMaps,
  speeding,
  transhipments,
  users,
  virtualFences
});

const rootReducer = (state, action) => {
  if(action.type === "SET_CURRENT_USER") {
    const { currentToken } = state.users;
    state = {
      users: {
        ...state.users,
        currentToken,
        currentUser: false
      }
    };
  }
  if(action.type === "DISABLE_DATABASE_MOUNTED" ||
     action.type === "DISABLE_DATABASE_TEST" ||
     action.type === "ENABLE_DATABASE_MOUNTED" ||
     action.type === "ENABLE_DATABASE_REFRESH" ||
     action.type === "ENABLE_DATABASE_TEST") {
    if(action.type === "DISABLE_DATABASE_TEST" ||
      action.type === "ENABLE_DATABASE_TEST") {
      state = {
        chips: state.chips,
        databaseTest: {
          ...state.databaseTest,
          databaseMounted: true
        },
        drivers: state.drivers,
        menus: state.menus,
        patrimonies: state.patrimonies,
        regions: state.regions,
        users: state.users
      };
    }
    else {
      state = {
        chips: state.chips,
        databaseTest: state.databaseTest,
        drivers: state.drivers,
        menus: state.menus,
        patrimonies: state.patrimonies,
        regions: state.regions,
        users: state.users
      };
    }
  }
  return appReducer(state, action);
};

export default rootReducer;
