import React, { memo, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner
} from "react-bootstrap";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import { Creators as NotificationsActions } from "../../store/ducks/notifications";

import { isScopes } from "../../services/Auth";
import { maskCPF } from "../../utils/CPF";
import { maskCNPJ } from "../../utils/CNPJ";
import { isValidForm } from "../../utils/Form";
import { clearObject, mergeObject } from "../../utils/Object";
import { isEmpty, ucFirstAll } from "../../utils/String";
import { getCronOptions, jsonToForm } from "../../utils/Command";

import { getDefaultOptionsPatrimony } from "../../utils/Notification";
import FilterPatrimonyCommand from "../../components/FilterPatrimonyCommand";

import ClientService from "../../services/Client";
import ClientSchemaReduced from "../../services/Client/SchemaReduced";
import CommandService from "../../services/Command";
import CommandSchema from "../../services/Command/Schema";

const ACTIVE_KEY_GENERAL_DATA = "0";
const ACTIVE_KEY_PATRIMONIES = "3";

const Command = ({
  options,
  props: {
    currentUser,
    patrimonies,
    patrimoniesSearchSelected
  },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const clientService = new ClientService();
  const commandService = new CommandService();

  const {
    command: __command = {},
    close: __close = () => {}
  } = options;

  const [command, setCommand] = useState(jsonToForm(mergeObject(JSON.parse(JSON.stringify(CommandSchema)), { ...__command })));

  useEffect(() => {
    setTimeout(() => {
      if(!isScopes({ currentUser, scopes: [
        "is:master",
        "read:commands:all"
      ], every: false })) {
        setClientSelectedOption({
          dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client),
          label: currentUser.client.type === "LEG" ? (`${currentUser.client.legal.socialReason} | CNPJ: ${maskCNPJ(currentUser.client.legal.cnpj)}`) : (`${currentUser.client.personal.name} | CPF: ${maskCPF(currentUser.client.personal.cpf)}`),
          value: currentUser.client.id
        });
        setCommand(prevState => ({
          ...prevState,
          client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client)
        }));
      }
    }, 1000);
  }, []); /* eslint-disable-line */

  const [activeKey, setActiveKey] = useState("0");
  const [patrimoniesOptions, setPatrimoniesOptions] = useState({ /* eslint-disable-line */
    ...getDefaultOptionsPatrimony(),
    filterMultiple: true,
    filterPreSelected: command.patrimonies ?? []
  });

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [cronOptions] = useState(getCronOptions());

  const [repeatOptions] = useState([
    { value: true, label: "Repetir" },
    { value: false, label: "Não repetir" }
  ]);

  const [typeOptions] = useState([
    { value: "AACT", label: "Ancorar" },
    { value: "ADIS", label: "Desancorar" },
    { value: "BACT", label: "Bloqueio" },
    { value: "BDIS", label: "Desbloqueio" }
  ]);

  const [clientSelectedOption, setClientSelectedOption] = useState(command.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), command.client),
    label: command.client.type === "LEG" ? (`${command.client.legal.socialReason} | CNPJ: ${maskCNPJ(command.client.legal.cnpj)}`) : (`${command.client.personal.name} | CPF: ${maskCPF(command.client.personal.cpf)}`),
    value: command.client.id
  });

  const [running, setRunning] = useState(false);

  const [patrimoniesSelected, setPatrimoniesSelected] = useState(command.patrimonies ?? []);

  const buttonDisabled = () => {
    if (!running) return false;
    return true;
  };

  const changeActiveKey = (e) => {
    if (activeKey === e) return setActiveKey("-1");
    setActiveKey(e);
  };

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? (`${e.legal.socialReason} | CNPJ: ${maskCNPJ(e.legal.cnpj)}`) : (`${e.personal.name} | CPF: ${maskCPF(e.personal.cpf)}`),
        value: e.id
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { eventKey, message, valid } = isValidForm(e);
      if(!valid) {
        if (!message) {
          message = t("Error.Fields.Empty");
        }
        if(eventKey !== -1) {
          setActiveKey(eventKey);
        }
        addNotice({
          title: t("Title.Command"),
          content: message
        });
      }
      else {
        let response = false;
        if(isCreate()) {
          response = await commandService.create({
            data: handleSubmitForm({ command }),
            token: currentUser.token
          });
        }
        else {
          response = await commandService.update({
            id: command.id,
            data: handleSubmitForm({ command }),
            token: currentUser.token
          });
        }

        if(response.success) {
          addNotice({
            title: t("Title.Command"),
            content: isCreate() ? t("Success.Insert") : t("Success.Update")
          });
          setTimeout(() => {
            __close();
          }, 200);
        }
        else {
          addNotice({
            title: t("Title.Command"),
            content: t(`Error.Command.${response.error.type}.${ucFirstAll(response.error.details[0].path)}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ command }) => {
    let newCommand = Object.assign({}, JSON.parse(JSON.stringify(command)));
    newCommand = clearObject({ data: newCommand });
    newCommand.id = undefined;
    newCommand.patrimonies = patrimoniesSelected ? patrimoniesSelected.map(command => command.id) : [];
    newCommand.clientId = newCommand.client.id;
    newCommand.client = undefined;
    return newCommand;
  };

  const inputDisabled = () => {
    if (!running) return false;
    return true;
  };

  const isCreate = () => {
    return command.id === "";
  };

  useEffect(() => {
    console.log(patrimoniesSelected);
  }, [patrimoniesSelected]);

  return (
    <Form id="forms-command" noValidate onSubmit={handleSubmit}>
      <Accordion defaultActiveKey={ACTIVE_KEY_GENERAL_DATA} activeKey={activeKey}>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_GENERAL_DATA} onClick={e => changeActiveKey(ACTIVE_KEY_GENERAL_DATA)}>{t("Title.GeneralData")}</Accordion.Toggle>
          <Accordion.Collapse eventKey={ACTIVE_KEY_GENERAL_DATA}>
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-command-active">
                    <Form.Label>{t("Label.Active")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        const active = e.value;
                        setCommand(prevState => ({
                          ...prevState,
                          active
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      options={activeOptions}
                      value={activeOptions.find(x => x.value === command.active)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-command-client">
                    <Form.Label>{t("Title.Client")}:</Form.Label>
                    <AsyncSelect
                      className={`menu-outer-bottom ${!isEmpty(command.client.id) ? "is-valid" : "is-invalid"}`}
                      classNamePrefix="select"
                      cacheOptions
                      defaultOptions
                      isDisabled={inputDisabled() || !isScopes({
                        currentUser,
                        scopes: [
                          "is:master",
                          "read:commands:all"
                        ],
                        every: false
                      })}
                      loadOptions={clientLoadOptions}
                      loadingMessage={() => t("React.Select.Wait")}
                      noOptionsMessage={() => t("React.Select.NoOptions")}
                      onChange={e => {
                        if(e === null) {
                          setClientSelectedOption(e);
                          setCommand(prevState => ({
                            ...prevState,
                            client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                          }));
                        }
                        else {
                          const { dataAux, label, value } = e;
                          setClientSelectedOption({ dataAux, label, value });
                          setCommand(prevState => ({
                            ...prevState,
                            client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                          }));
                        }
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      placeholder={t("Title.Client.Select")}
                      value={clientSelectedOption}
                      required={true}
                    />
                    <Form.Control.Feedback className={`${!isEmpty(command.client.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Client")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-command-cron">
                    <Form.Label>{t("Label.Cron")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        const cron = e.value;
                        setCommand(prevState => ({
                          ...prevState,
                          cron
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      options={cronOptions}
                      value={cronOptions.find(x => x.value === command.cron)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-command-repeat">
                    <Form.Label>{t("Label.Repeat")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        const repeat = e.value;
                        setCommand(prevState => ({
                          ...prevState,
                          repeat
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      options={repeatOptions}
                      value={repeatOptions.find(x => x.value === command.repeat)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-command-name">
                    <Form.Label>{t("Label.Name")}:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Placeholder.Name")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const name = e.target.value;
                        setCommand(prevState => ({
                          ...prevState,
                          name: name.toUpperCase()
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      autoComplete="off"
                      value={command.name}
                      isValid={!isEmpty(command.name)}
                      isInvalid={isEmpty(command.name)}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">{t("Feedback.Name")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-command-type">
                    <Form.Label>{t("Label.Type")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        const type = e.value;
                        setCommand(prevState => ({
                          ...prevState,
                          type
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      options={typeOptions}
                      value={typeOptions.find(x => x.value === command.type)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_PATRIMONIES} onClick={e => changeActiveKey(ACTIVE_KEY_PATRIMONIES)}>{t("Title.Patrimony")}</Accordion.Toggle>
          <Accordion.Collapse eventKey={ACTIVE_KEY_PATRIMONIES}>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group controlId="forms-command-patrimonies">
                  {(clientSelectedOption !== null) ? null : (<Form.Label>Selecione o cliente primeiro</Form.Label>)}
                    {
                      ((clientSelectedOption !== null) && (
                        <FilterPatrimonyCommand options={{...patrimoniesOptions, client: clientSelectedOption.value, filterPreSelected: isCreate() ? [] : command.patrimonies, setPatrimoniesSelected }} />
                      ))
                    }
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Form.Group className="default-form-button">
        <Button
          variant="dark"
          type="submit"
          disabled={buttonDisabled()}
        >
          {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
        </Button>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    patrimonies: state.patrimonies.patrimonies,
    patrimoniesSearchSelected: state.patrimonies.patrimoniesSearchSelected
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Command));
