import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  menuHandleActionType: ["actionType"],
  menuHandleClose: [],
  menuHandleOpen: [],
  menuHandleToggle: [],
  menuActionsHandleClose: [],
  menuActionsHandleOpen: ["selected", "title", "content", "props"],
  menuCalendarsHandleClose: [],
  menuCalendarsHandleOpen: ["selected"],
  menuCommandsHandleClose: [],
  menuCommandsHandleOpen: ["selected"],
  menuDiagnosisHandleClose: [],
  menuDiagnosisHandleOpen: ["selected"],
  menuProfilesHandleClose: [],
  menuProfilesHandleOpen: ["selected"],
  menuRegistersHandleClose: [],
  menuRegistersHandleOpen: [],
  menuReportsHandleClose: [],
  menuReportsHandleOpen: [],
  menuSimulatorsHandleClose: [],
  menuSimulatorsHandleOpen: []
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  menu: {
    actionType: "positions",
    toggled: false,
    toggledContent: true,
    toggledDepends: true
  },
  actions: {
    content: null,
    props: {},
    title: null,
    toggled: false,
    selected: false
  },
  calendars: {
    toggled: false,
    selected: false
  },
  commands: {
    toggled: false,
    selected: false
  },
  diagnosis: {
    toggled: false,
    selected: false
  },
  profiles: {
    toggled: false,
    selected: false
  },
  registers: {
    toggled: false
  },
  reports: {
    toggled: false
  },
  simulators: {
    toggled: false
  }
};

const menuHandleActionType = (state = INITIAL_STATE, action) => {
  const { actionType } = action;
  return {
    ...state,
    menu: {
      ...state.menu,
      actionType
    }
  };
};

const menuHandleClose = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    menu: {
      ...state.menu,
      toggled: false,
      toggledContent: true,
      toggledDepends: true
    }
  };
};

const menuHandleOpen = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    menu: {
      ...state.menu,
      toggled: true,
      toggledContent: false,
      toggledDepends: false
    }
  };
};

const menuHandleToggle = (state = INITIAL_STATE, action) => {
  const menu = state.menu;
  if(menu.toggledDepends) {
    menu.toggled = !menu.toggled;
  }
  else {
    menu.toggled = !menu.toggled;
    menu.toggledContent = menu.toggled;
    menu.toggledDepends = !menu.toggledDepends;
  }
  return {
    ...state,
    menu
  };
};

const menuActionsHandleClose = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    actions: {
      content: null,
      props: {},
      title: null,
      toggled: false,
      selected: false
    }
  };
};

const menuActionsHandleOpen = (state = INITIAL_STATE, action) => {
  const { content, props, selected, title } = action;
  return {
    ...state,
    actions: {
      content,
      props,
      title,
      toggled: true,
      selected: selected || false
    }
  };
};

const menuCalendarsHandleClose = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    calendars: {
      toggled: false,
      selected: false
    }
  };
};

const menuCalendarsHandleOpen = (state = INITIAL_STATE, action) => {
  const { selected } = action;
  return {
    ...state,
    calendars: {
      toggled: true,
      selected: selected || false
    }
  };
};

const menuCommandsHandleClose = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    commands: {
      toggled: false,
      selected: false
    }
  };
};

const menuCommandsHandleOpen = (state = INITIAL_STATE, action) => {
  const { selected } = action;
  return {
    ...state,
    commands: {
      toggled: true,
      selected: selected || false
    }
  };
};

const menuDiagnosisHandleClose = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    diagnosis: {
      toggled: false,
      selected: false
    }
  };
};

const menuDiagnosisHandleOpen = (state = INITIAL_STATE, action) => {
  const { selected } = action;
  return {
    ...state,
    diagnosis: {
      toggled: true,
      selected: selected || false
    }
  };
};

const menuProfilesHandleClose = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    profiles: {
      toggled: false,
      selected: false
    }
  };
};

const menuProfilesHandleOpen = (state = INITIAL_STATE, action) => {
  const { selected } = action;
  return {
    ...state,
    profiles: {
      toggled: true,
      selected: selected || false
    }
  };
};

const menuRegistersHandleClose = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    registers: {
      toggled: false
    }
  };
};

const menuRegistersHandleOpen = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    registers: {
      toggled: true
    }
  };
};

const menuReportsHandleClose = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    reports: {
      toggled: false
    }
  };
};

const menuReportsHandleOpen = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    reports: {
      toggled: true
    }
  };
};

const menuSimulatorsHandleClose = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    simulators: {
      toggled: false
    }
  };
};

const menuSimulatorsHandleOpen = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    simulators: {
      toggled: true
    }
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.MENU_HANDLE_ACTION_TYPE]: menuHandleActionType,
  [Types.MENU_HANDLE_CLOSE]: menuHandleClose,
  [Types.MENU_HANDLE_OPEN]: menuHandleOpen,
  [Types.MENU_HANDLE_TOGGLE]: menuHandleToggle,
  [Types.MENU_ACTIONS_HANDLE_CLOSE]: menuActionsHandleClose,
  [Types.MENU_ACTIONS_HANDLE_OPEN]: menuActionsHandleOpen,
  [Types.MENU_CALENDARS_HANDLE_CLOSE]: menuCalendarsHandleClose,
  [Types.MENU_CALENDARS_HANDLE_OPEN]: menuCalendarsHandleOpen,
  [Types.MENU_COMMANDS_HANDLE_CLOSE]: menuCommandsHandleClose,
  [Types.MENU_COMMANDS_HANDLE_OPEN]: menuCommandsHandleOpen,
  [Types.MENU_DIAGNOSIS_HANDLE_CLOSE]: menuDiagnosisHandleClose,
  [Types.MENU_DIAGNOSIS_HANDLE_OPEN]: menuDiagnosisHandleOpen,
  [Types.MENU_PROFILES_HANDLE_CLOSE]: menuProfilesHandleClose,
  [Types.MENU_PROFILES_HANDLE_OPEN]: menuProfilesHandleOpen,
  [Types.MENU_REGISTERS_HANDLE_CLOSE]: menuRegistersHandleClose,
  [Types.MENU_REGISTERS_HANDLE_OPEN]: menuRegistersHandleOpen,
  [Types.MENU_REPORTS_HANDLE_CLOSE]: menuReportsHandleClose,
  [Types.MENU_REPORTS_HANDLE_OPEN]: menuReportsHandleOpen,
  [Types.MENU_SIMULATORS_HANDLE_CLOSE]: menuSimulatorsHandleClose,
  [Types.MENU_SIMULATORS_HANDLE_OPEN]: menuSimulatorsHandleOpen
});
