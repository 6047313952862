import ClientSchemaReduced, { schemaDate as schemaDateClient } from "../Client/SchemaReducedTitle";

const schema = {
  active: "Status",
  baseFineAmount: "Valor base para multa",
  businessType: "Tipo de negócio",
  chargeType: "Tipo de pagamento",
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  contractualFine: "Multa contratual",
  createdAt: "Criado em",
  dueDate: "Dia de vencimento",
  id: "Id",
  installationValue: "Valor de instalação",
  kmTraveled: "Valor do KM rodado",
  loyaltyPeriod: "Período de fidelidade",
  monthlyValue: "Valor mensal",
  name: "Nome",
  sequence: "Sequência",
  technicalTime: "Hora técnica",
  uninstallValue: "Valor de desinstalação",
  updatedAt: "Atualizado em"
};

export const schemaDate = {
  createdAt: "datetime",
  client: JSON.parse(JSON.stringify(schemaDateClient)),
  updatedAt: "datetime"
};

export default schema;
