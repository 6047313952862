import React from "react";
import "./index.css";

import SwitchDriver from "../Switch/Driver";

const FilterDriverItem = ({ checkbox = true, name, driver, onClick, selected } = {}) => {
  return (
    <li className={"filter-driver-list-item"} onClick={onClick}>
      <SwitchDriver checkbox={checkbox} name={name} driver={driver} selected={selected} />
    </li>
  );
};

export default FilterDriverItem;
