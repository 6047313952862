import { createActions, createReducer } from "reduxsauce";
import { filterByClient, filterByClientAndWord, sortDrivers } from "../../utils/Driver";

import ClientSchemaReduced from "../../services/Client/SchemaReduced";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  searchDrivers: ["search"],
  setDrivers: ["drivers"],
  setDriversSelected: ["drivers"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  drivers: [],
  driversSearch: [],
  driversSearchOnlyClient: [],
  driversSearchSelected: [],
  driversSearchQuery: {
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    word: "",
    active: -1
  },
  driversSearchQueryEnabled: false
};

const searchDrivers = (state = INITIAL_STATE, action) => {
  const { search } = action;
  const { enabled: driversSearchEnabled, results: driversSearchResults } = filterByClientAndWord(state.drivers, search);
  const { results: driversSearchOnlyClientResults } = filterByClient(state.drivers, search);
  return {
    ...state,
    driversSearch: driversSearchResults,
    driversSearchOnlyClient: driversSearchOnlyClientResults,
    driversSearchQuery: search,
    driversSearchQueryEnabled: driversSearchEnabled
  };
};

const setDrivers = (state = INITIAL_STATE, action) => {
  let { drivers } = action;
  return {
    ...state,
    drivers: sortDrivers(drivers),
    driversSearch: INITIAL_STATE.driversSearch,
    driversSearchOnlyClient: sortDrivers(drivers),
    driversSearchSelected: INITIAL_STATE.driversSearchSelected,
    driversSearchQuery: INITIAL_STATE.driversSearchQuery,
    driversSearchQueryEnabled: INITIAL_STATE.driversSearchQueryEnabled
  };
};

const setDriversSelected = (state = INITIAL_STATE, action) => {
  let { drivers } = action;
  return {
    ...state,
    driversSearchSelected: drivers
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SEARCH_DRIVERS]: searchDrivers,
  [Types.SET_DRIVERS]: setDrivers,
  [Types.SET_DRIVERS_SELECTED]: setDriversSelected
});
