import ClientSchemaReduced from "./../Client/SchemaReduced";
import WorkingWeekSchemaReduced from "./../WorkingWeek/SchemaReduced";

const schema = {
  body: "",
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  endAt: new Date(),
  global: false,
  id: "",
  startAt: new Date(),
  title: ""
};

export default schema;
