import React, { memo, useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  InputGroup,
  Row
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import DateUtil from "../../utils/Date";

import { Creators as PeriodActions } from "./../../store/ducks/period";

registerLocale("pt-BR", ptBR);

const FilterDate = ({
  props: { currentUser },
  funcs: { setPeriodDateEnd, setPeriodDateStart },
  options: { dateEnd, dateStart, limitDays }
}) => {
  const { t } = useTranslation();
  const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
  const [startDate, setStartDate] = useState(new Date(`${todayDate} 00:00:00`));
  const [endDate, setEndDate] = useState(new Date(`${todayDate} 23:55:00`));
  const [maxDate, setMaxDate] = useState(new Date());
  const [limit] = useState(limitDays ?? 7);

  useEffect(() => {
    setPeriodDateEnd(endDate);
  }, [endDate]); /* eslint-disable-line */

  useEffect(() => {
    const maxDateAux = DateUtil.add({ add: limit, date: startDate, tz: "America/Sao_Paulo", format: "days" });
    const maxDateNow = new Date();
    let maxDateCurrent;
    if (maxDateAux.getTime() > maxDateNow.getTime()) {
      maxDateCurrent = new Date(`${todayDate} 23:55:00`);
      setMaxDate(maxDateNow);
    }
    else {
      const maxDateAuxCheck = `${maxDateAux.getFullYear()}-${maxDateAux.getMonth()+1}-${maxDateAux.getDate()}`;
      maxDateCurrent = new Date(`${maxDateAuxCheck} 23:55:00`);
      setMaxDate(maxDateAux);
    }
    if (endDate.getTime() > maxDateCurrent.getTime()) setEndDate(maxDateCurrent);
    setPeriodDateStart(startDate);
  }, [startDate]); /* eslint-disable-line */

  return (
    <Card>
      <Card.Header>{t("Title.Date.StartEnd")}</Card.Header>
      <Card.Body>
        <Row>
          {
            (dateStart && dateEnd && (
              <>
                <Col>
                  <div className="filter-date-fields">
                    <Form.Group>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <DatePicker
                          selected={startDate}
                          onChange={date => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          minDate={new Date(`${process.env.REACT_APP_APP_START_YEAR}-01-02`)}
                          maxDate={new Date()}
                          timeFormat="HH:mm"
                          timeIntervals={5}
                          showTimeSelect
                          showMonthDropdown
                          showYearDropdown
                          dateFormat="dd/MM/yyyy HH:mm:ss"
                          className="react-datepicker-form-control"
                          popperPlacement="bottom-center"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "5px, 10px"
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport"
                            }
                          }}
                          locale="pt-BR"
                        />
                        <div className="react-datepicker-form-control-shaddow" />
                      </InputGroup>
                    </Form.Group>
                  </div>
                </Col>
                <Col>
                  <div className="filter-date-fields">
                    <Form.Group>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <DatePicker
                          selected={endDate}
                          onChange={date => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          maxDate={maxDate}
                          timeFormat="HH:mm"
                          timeIntervals={5}
                          showTimeSelect
                          showMonthDropdown
                          showYearDropdown
                          dateFormat="dd/MM/yyyy HH:mm:ss"
                          className="react-datepicker-form-control"
                          popperPlacement="bottom-center"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "5px, 10px"
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport"
                            }
                          }}
                          locale="pt-BR"
                        />
                        <div className="react-datepicker-form-control-shaddow" />
                      </InputGroup>
                    </Form.Group>
                  </div>
                </Col>
              </>
            ))
          }
          {
            (dateStart && !dateEnd && (
              <Col>
                <div className="filter-date-fields">
                  <Form.Group>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                      </InputGroup.Prepend>
                      <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={startDate}
                        minDate={new Date(`${process.env.REACT_APP_APP_START_YEAR}-01-02`)}
                        maxDate={new Date()}
                        timeFormat="HH:mm"
                        timeIntervals={5}
                        showTimeSelect
                        showMonthDropdown
                        showYearDropdown
                        dateFormat="dd/MM/yyyy HH:mm:ss"
                        className="react-datepicker-form-control"
                        popperPlacement="bottom-center"
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: "5px, 10px"
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: "viewport"
                          }
                        }}
                        locale="pt-BR"
                      />
                      <div className="react-datepicker-form-control-shaddow" />
                    </InputGroup>
                  </Form.Group>
                </div>
              </Col>
            ))
          }
        </Row>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, PeriodActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(FilterDate));
