const scopes = {
  default: "access",
  types: [
    {
      icon: "fas fa-eye",
      id: "access",
      name: "Acessos",
      items: [
        {
          id: "access:users",
          name: "Usuário",
          items: [
            {
              id: "access:users:guest",
              checkId: "access:users:read",
              name: "Usuário convidado",
              scopes: [
                {
                  scope: "is:guest"
                }
              ]
            },
            {
              id: "access:users:default",
              checkId: "access:users:read",
              name: "Usuário padrão",
              scopes: [
                {
                  scope: "is:default"
                }
              ]
            },
            {
              id: "access:users:driver",
              checkId: "access:users:read",
              name: "Usuário motorista",
              scopes: [
                {
                  scope: "is:driver"
                }
              ]
            },
            {
              id: "access:users:installer",
              checkId: "access:users:read",
              name: "Usuário instalador",
              scopes: [
                {
                  scope: "is:installer"
                }
              ]
            },
            {
              id: "access:users:master",
              checkId: "access:users:read",
              name: "Usuário master",
              scopes: [
                {
                  scope: "is:master"
                }
              ]
            }
          ]
        },
        {
          id: "access:patrimonies",
          name: "Bem",
          items: [
            {
              id: "access:patrimonies:basic",
              checkId: "access:patrimonies:read",
              name: "Visualização básica",
              scopes: [
                {
                  scope: "read:patrimonies:basic"
                }
              ]
            },
            {
              id: "access:patrimonies:description",
              checkId: "access:patrimonies:read",
              name: "Visualização completa",
              scopes: [
                {
                  scope: "read:patrimonies:description"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      icon: "far fa-clipboard",
      id: "registers",
      name: "Cadastros",
      items: [
        {
          id: "registers:clients",
          name: "Cadastro de clientes",
          items: [
            {
              id: "registers:clients:read:all",
              checkId: "registers:clients:create",
              name: "Ler (todos os clientes)",
              scopes: [
                {
                  scope: "read:clients:all"
                }
              ]
            },
            {
              id: "registers:clients:create:all",
              checkId: "registers:clients:create",
              name: "Ler, criar e editar (todos os clientes)",
              scopes: [
                {
                  scope: "create:clients:all"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:clients"
                },
                {
                  scope: "read:clients:all"
                },
                {
                  scope: "update:clients:all"
                }
              ]
            },
            {
              id: "registers:clients:create",
              checkId: "registers:clients:create",
              name: "Ler e editar (apenas o próprio cliente)",
              scopes: [
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:clients"
                },
                {
                  scope: "read:clients"
                },
                {
                  scope: "update:clients"
                }
              ]
            }
          ]
        },
        {
          id: "registers:profiles",
          name: "Cadastro de perfis",
          items: [
            {
              id: "registers:profiles:read:all",
              checkId: "registers:profiles:create",
              name: "Ler (todos os clientes)",
              scopes: [
                {
                  scope: "read:profiles:all"
                }
              ]
            },
            {
              id: "registers:profiles:create:all",
              checkId: "registers:profiles:create",
              name: "Ler, criar e editar (todos os clientes)",
              scopes: [
                {
                  scope: "create:profiles:all"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:profiles"
                },
                {
                  scope: "read:profiles:all"
                },
                {
                  scope: "update:profiles:all"
                }
              ]
            },
            {
              id: "registers:profiles:create",
              checkId: "registers:profiles:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:profiles"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:profiles"
                },
                {
                  scope: "read:profiles"
                },
                {
                  scope: "update:profiles"
                }
              ]
            }
          ]
        },
        {
          id: "registers:users",
          name: "Cadastro de usuários",
          items: [
            {
              id: "registers:users:read:all",
              checkId: "registers:users:create",
              name: "Ler (todos os clientes)",
              scopes: [
                {
                  scope: "read:users:all"
                }
              ]
            },
            {
              id: "registers:users:create:all",
              checkId: "registers:users:create",
              name: "Ler, criar e editar (todos os clientes)",
              scopes: [
                {
                  scope: "create:users:all"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:users"
                },
                {
                  scope: "read:users:all"
                },
                {
                  scope: "update:users:all"
                }
              ]
            },
            {
              id: "registers:users:create",
              checkId: "registers:users:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:users"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:users"
                },
                {
                  scope: "read:users"
                },
                {
                  scope: "update:users"
                }
              ]
            }
          ]
        },
        {
          id: "registers:workingWeeks",
          name: "Cadastro de jornada de trabalho",
          items: [
            {
              id: "registers:workingWeeks:read:all",
              checkId: "registers:workingWeeks:create",
              name: "Ler e vincular (todos os clientes)",
              scopes: [
                {
                  scope: "read:workingWeeks:all"
                },
                {
                  scope: "update:patrimonies:workingWeeks:all"
                }
              ]
            },
            {
              id: "registers:workingWeeks:create:all",
              checkId: "registers:workingWeeks:create",
              name: "Ler, criar, editar e vincular (todos os clientes)",
              scopes: [
                {
                  scope: "create:workingWeeks:all"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:workingWeeks"
                },
                {
                  scope: "read:workingWeeks:all"
                },
                {
                  scope: "update:workingWeeks:all"
                },
                {
                  scope: "update:patrimonies:workingWeeks:all"
                }
              ]
            },
            {
              id: "registers:workingWeeks:read",
              checkId: "registers:workingWeeks:create",
              name: "Ler e vincular (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "read:workingWeeks"
                },
                {
                  scope: "update:patrimonies:workingWeeks"
                }
              ]
            },
            {
              id: "registers:workingWeeks:create",
              checkId: "registers:workingWeeks:create",
              name: "Ler, criar, editar e vincular (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:workingWeeks"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:workingWeeks"
                },
                {
                  scope: "read:workingWeeks"
                },
                {
                  scope: "update:workingWeeks"
                },
                {
                  scope: "update:patrimonies:workingWeeks"
                }
              ]
            }
          ]
        },
        {
          id: "registers:drivers",
          name: "Cadastro de motoristas",
          items: [
            {
              id: "registers:drivers:read:all",
              checkId: "registers:drivers:create",
              name: "Ler e vincular (todos os clientes)",
              scopes: [
                {
                  scope: "read:drivers:all"
                },
                {
                  scope: "update:patrimonies:drivers:all"
                }
              ]
            },
            {
              id: "registers:drivers:create:all",
              checkId: "registers:drivers:create",
              name: "Ler, criar, editar e vincular (todos os clientes)",
              scopes: [
                {
                  scope: "create:drivers:all"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:drivers"
                },
                {
                  scope: "read:drivers:all"
                },
                {
                  scope: "update:drivers:all"
                },
                {
                  scope: "update:patrimonies:drivers:all"
                }
              ]
            },
            {
              id: "registers:drivers:read",
              checkId: "registers:drivers:create",
              name: "Ler e vincular (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "read:drivers"
                },
                {
                  scope: "update:patrimonies:drivers"
                }
              ]
            },
            {
              id: "registers:drivers:create",
              checkId: "registers:drivers:create",
              name: "Ler, criar, editar e vincular (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:drivers"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:drivers"
                },
                {
                  scope: "read:drivers"
                },
                {
                  scope: "update:drivers"
                },
                {
                  scope: "update:patrimonies:drivers"
                }
              ]
            }
          ]
        },
        {
          id: "registers:chips",
          name: "Cadastro de chips",
          items: [
            {
              id: "registers:chips:read:all",
              checkId: "registers:chips:create",
              name: "Ler (todos os clientes)",
              scopes: [
                {
                  scope: "read:chips:all"
                }
              ]
            },
            {
              id: "registers:chips:create:all",
              checkId: "registers:chips:create",
              name: "Ler, criar e editar (todos os clientes)",
              scopes: [
                {
                  scope: "create:chips:all"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:chips"
                },
                {
                  scope: "read:chips:all"
                },
                {
                  scope: "update:chips:all"
                }
              ]
            },
            {
              id: "registers:chips:create",
              checkId: "registers:chips:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:chips"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:chips"
                },
                {
                  scope: "read:chips"
                },
                {
                  scope: "update:chips"
                }
              ]
            }
          ]
        },
        {
          id: "registers:rfids",
          name: "Cadastro de rfids",
          items: [
            {
              id: "registers:rfids:read:all",
              checkId: "registers:rfids:create",
              name: "Ler (todos os clientes)",
              scopes: [
                {
                  scope: "read:rfids:all"
                }
              ]
            },
            {
              id: "registers:rfids:create:all",
              checkId: "registers:rfids:create",
              name: "Ler, criar e editar (todos os clientes)",
              scopes: [
                {
                  scope: "create:rfids:all"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:rfids"
                },
                {
                  scope: "read:rfids:all"
                },
                {
                  scope: "update:rfids:all"
                }
              ]
            },
            {
              id: "registers:rfids:create",
              checkId: "registers:rfids:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:rfids"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:rfids"
                },
                {
                  scope: "read:rfids"
                },
                {
                  scope: "update:rfids"
                }
              ]
            }
          ]
        },
        {
          id: "registers:chassis",
          name: "Cadastro de chassis",
          items: [
            {
              id: "registers:chassis:read:all",
              checkId: "registers:chassis:create",
              name: "Ler (todos os clientes)",
              scopes: [
                {
                  scope: "read:chassis:all"
                }
              ]
            },
            {
              id: "registers:chassis:create:all",
              checkId: "registers:chassis:create",
              name: "Ler, criar e editar (todos os clientes)",
              scopes: [
                {
                  scope: "create:chassis:all"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:chassis"
                },
                {
                  scope: "read:chassis:all"
                },
                {
                  scope: "update:chassis:all"
                }
              ]
            },
            {
              id: "registers:chassis:create",
              checkId: "registers:chassis:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:chassis"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:chassis"
                },
                {
                  scope: "read:chassis"
                },
                {
                  scope: "update:chassis"
                }
              ]
            }
          ]
        },
        {
          id: "registers:trackers",
          name: "Cadastro de rastreadores",
          items: [
            {
              id: "registers:trackers:read:all",
              checkId: "registers:trackers:create",
              name: "Ler (todos os clientes)",
              scopes: [
                {
                  scope: "read:trackers:all"
                }
              ]
            },
            {
              id: "registers:trackers:create:all",
              checkId: "registers:trackers:create",
              name: "Ler, criar e editar (todos os clientes)",
              scopes: [
                {
                  scope: "create:trackers:all"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:trackers"
                },
                {
                  scope: "read:trackers:all"
                },
                {
                  scope: "update:trackers:all"
                }
              ]
            },
            {
              id: "registers:trackers:create",
              checkId: "registers:trackers:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:trackers"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:trackers"
                },
                {
                  scope: "read:trackers"
                },
                {
                  scope: "update:trackers"
                }
              ]
            }
          ]
        },
        {
          id: "registers:contracts",
          name: "Cadastro de contratos",
          items: [
            {
              id: "registers:contracts:read:all",
              checkId: "registers:contracts:create",
              name: "Ler (todos os clientes)",
              scopes: [
                {
                  scope: "read:contracts:all"
                }
              ]
            },
            {
              id: "registers:contracts:create:all",
              checkId: "registers:contracts:create",
              name: "Ler, criar e editar (todos os clientes)",
              scopes: [
                {
                  scope: "create:contracts:all"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:contracts"
                },
                {
                  scope: "read:contracts:all"
                },
                {
                  scope: "update:contracts:all"
                }
              ]
            },
            {
              id: "registers:contracts:create",
              checkId: "registers:contracts:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:contracts"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:contracts"
                },
                {
                  scope: "read:contracts"
                },
                {
                  scope: "update:contracts"
                }
              ]
            }
          ]
        },
        {
          id: "registers:patrimonies",
          name: "Cadastro de bens",
          items: [
            {
              id: "registers:patrimonies:read:all",
              checkId: "registers:patrimonies:create",
              name: "Ler (todos os clientes)",
              scopes: [
                {
                  scope: "read:patrimonies:all"
                }
              ]
            },
            {
              id: "registers:patrimonies:create:all",
              checkId: "registers:patrimonies:create",
              name: "Ler, criar e editar (todos os clientes)",
              scopes: [
                {
                  scope: "create:patrimonies:all"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:patrimonies"
                },
                {
                  scope: "read:patrimonies:all"
                },
                {
                  scope: "update:patrimonies:all"
                }
              ]
            },
            {
              id: "registers:patrimonies:create",
              checkId: "registers:patrimonies:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:patrimonies"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "update:patrimonies"
                }
              ]
            }
          ]
        },
        {
          id: "registers:patrimonyTests",
          name: "Cadastro de testes do bem",
          items: [
            {
              id: "registers:patrimonyTests:read:all",
              checkId: "registers:patrimonyTests:create",
              name: "Ler (todos os clientes)",
              scopes: [
                {
                  scope: "read:patrimonyTests:all"
                }
              ]
            },
            {
              id: "registers:patrimonyTests:create:all",
              checkId: "registers:patrimonyTests:create",
              name: "Ler, criar e editar (todos os clientes)",
              scopes: [
                {
                  scope: "create:patrimonyTests"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:patrimonyTests"
                },
                {
                  scope: "read:patrimonyTests:all"
                },
                {
                  scope: "update:patrimonyTests"
                }
              ]
            },
            {
              id: "registers:patrimonyTests:create",
              checkId: "registers:patrimonyTests:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:patrimonyTests"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:patrimonyTests"
                },
                {
                  scope: "read:patrimonyTests"
                },
                {
                  scope: "update:patrimonyTests"
                }
              ]
            }
          ]
        },
        {
          id: "registers:virtualFences",
          name: "Cadastro de cercas virtuais",
          items: [
            {
              id: "registers:virtualFences:read:all",
              checkId: "registers:virtualFences:create",
              name: "Ler (todos os clientes)",
              scopes: [
                {
                  scope: "read:virtualFences:all"
                }
              ]
            },
            {
              id: "registers:virtualFences:create:all",
              checkId: "registers:virtualFences:create",
              name: "Ler, criar e editar (todos os clientes)",
              scopes: [
                {
                  scope: "create:virtualFences"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:virtualFences"
                },
                {
                  scope: "read:virtualFences:all"
                },
                {
                  scope: "update:virtualFences"
                }
              ]
            },
            {
              id: "registers:virtualFences:create",
              checkId: "registers:virtualFences:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:virtualFences"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:virtualFences"
                },
                {
                  scope: "read:virtualFences"
                },
                {
                  scope: "update:virtualFences"
                }
              ]
            }
          ]
        },
        {
          id: "registers:supportPoints",
          name: "Cadastro de pontos de apoio",
          items: [
            {
              id: "registers:supportPoints:read:all",
              checkId: "registers:supportPoints:create",
              name: "Ler (todos os clientes)",
              scopes: [
                {
                  scope: "read:supportPoints:all"
                }
              ]
            },
            {
              id: "registers:supportPoints:create:all",
              checkId: "registers:supportPoints:create",
              name: "Ler, criar e editar (todos os clientes)",
              scopes: [
                {
                  scope: "create:supportPoints"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:supportPoints"
                },
                {
                  scope: "read:supportPoints:all"
                },
                {
                  scope: "update:supportPoints"
                }
              ]
            },
            {
              id: "registers:supportPoints:create",
              checkId: "registers:supportPoints:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:supportPoints"
                },
                {
                  scope: "menu:registers"
                },
                {
                  scope: "menu:registers:supportPoints"
                },
                {
                  scope: "read:supportPoints"
                },
                {
                  scope: "update:supportPoints"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      icon: "fas fa-bell",
      id: "notifications",
      name: "Notificações",
      items: [
        {
          id: "notifications:administratives",
          name: "Notificações administrativas",
          items: [
            {
              id: "notifications:administratives:financial",
              checkId: "notifications:administratives:financial",
              name: "Financeiro",
              scopes: [
                {
                  scope: "notifications:administratives:financial"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            }
          ]
        },
        {
          id: "notifications:patrimonies:general",
          name: "Notificações gerais",
          items: [
            {
              id: "notifications:patrimonies:general:anchor",
              checkId: "notifications:patrimonies:general:anchor",
              name: "Âncora",
              scopes: [
                {
                  scope: "notifications:patrimonies:general:anchor"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:general:battery",
              checkId: "notifications:patrimonies:general:battery",
              name: "Bateria",
              scopes: [
                {
                  scope: "notifications:patrimonies:general:battery"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:general:block",
              checkId: "notifications:patrimonies:general:block",
              name: "Bloqueio",
              scopes: [
                {
                  scope: "notifications:patrimonies:general:block"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:general:virtualFence",
              checkId: "notifications:patrimonies:general:virtualFence",
              name: "Cerca virtual",
              scopes: [
                {
                  scope: "notifications:patrimonies:general:virtualFence"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:general:speeding",
              checkId: "notifications:patrimonies:general:speeding",
              name: "Excesso de velocidade",
              scopes: [
                {
                  scope: "notifications:patrimonies:general:speeding"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:general:ignition",
              checkId: "notifications:patrimonies:general:ignition",
              name: "Ignição",
              scopes: [
                {
                  scope: "notifications:patrimonies:general:ignition"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:general:ignitionOutHour",
              checkId: "notifications:patrimonies:general:ignitionOutHour",
              name: "Ignição fora de horário",
              scopes: [
                {
                  scope: "notifications:patrimonies:general:ignitionOutHour"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:general:jammer",
              checkId: "notifications:patrimonies:general:jammer",
              name: "Jammer",
              scopes: [
                {
                  scope: "notifications:patrimonies:general:jammer"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:general:delayedLocation",
              checkId: "notifications:patrimonies:general:delayedLocation",
              name: "Loc. atrasada",
              scopes: [
                {
                  scope: "notifications:patrimonies:general:delayedLocation"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:general:maintenance",
              checkId: "notifications:patrimonies:general:maintenance",
              name: "Manutenção",
              scopes: [
                {
                  scope: "notifications:patrimonies:general:maintenance"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:general:maintenanceNeed",
              checkId: "notifications:patrimonies:general:maintenanceNeed",
              name: "Manutenção necessária",
              scopes: [
                {
                  scope: "notifications:patrimonies:general:maintenanceNeed"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:general:driver",
              checkId: "notifications:patrimonies:general:driver",
              name: "Motorista",
              scopes: [
                {
                  scope: "notifications:patrimonies:general:driver"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:general:panic",
              checkId: "notifications:patrimonies:general:panic",
              name: "Pânico",
              scopes: [
                {
                  scope: "notifications:patrimonies:general:panic"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:general:speed",
              checkId: "notifications:patrimonies:general:speed",
              name: "Velocidade",
              scopes: [
                {
                  scope: "notifications:patrimonies:general:speed"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            }
          ]
        },
        {
          id: "notifications:patrimonies:niple",
          name: "Notificações de niple",
          items: [
            {
              id: "notifications:patrimonies:niple:hatch",
              checkId: "notifications:patrimonies:niple:hatch",
              name: "Escotilha",
              scopes: [
                {
                  scope: "notifications:patrimonies:niple:hatch"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:niple:fraud",
              checkId: "notifications:patrimonies:niple:fraud",
              name: "Niple (burla)",
              scopes: [
                {
                  scope: "notifications:patrimonies:niple:fraud"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:niple:operation",
              checkId: "notifications:patrimonies:niple:operation",
              name: "Niple (operação)",
              scopes: [
                {
                  scope: "notifications:patrimonies:niple:operation"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:niple:test",
              checkId: "notifications:patrimonies:niple:test",
              name: "Niple (teste)",
              scopes: [
                {
                  scope: "notifications:patrimonies:niple:test"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            },
            {
              id: "notifications:patrimonies:niple:failure",
              checkId: "notifications:patrimonies:niple:failure",
              name: "Niple (falhas)",
              scopes: [
                {
                  scope: "notifications:patrimonies:niple:failure"
                },
                {
                  scope: "menu:notifications"
                },
                {
                  scope: "read:patrimonies"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      icon: "far fa-file-pdf",
      id: "reports",
      name: "Relatórios",
      items: [
        {
          id: "reports:patrimonies",
          name: "Relatórios dos bens",
          items: [
            {
              id: "reports:patrimonies:delayedLocation",
              checkId: "reports:patrimonies:delayedLocation",
              name: "Loc. atrasada",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:delayedLocation"
                }
              ]
            },
            {
              id: "reports:patrimonies:downtime",
              checkId: "reports:patrimonies:downtime",
              name: "Tempo parado",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:downtime"
                }
              ]
            },
            {
              id: "reports:patrimonies:downtimeAddress",
              checkId: "reports:patrimonies:downtimeAddress",
              name: "Tempo parado com endereço",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:downtimeAddress"
                }
              ]
            },
            {
              id: "reports:patrimonies:downtimePlace",
              checkId: "reports:patrimonies:downtimePlace",
              name: "Tempo parado com lugares",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:downtimePlace"
                }
              ]
            },
            {
              id: "reports:patrimonies:downtimePoint",
              checkId: "reports:patrimonies:downtimePoint",
              name: "Pontos por rota",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:downtimePoint"
                }
              ]
            },
            {
              id: "reports:patrimonies:downtimePointVisit",
              checkId: "reports:patrimonies:downtimePointVisit",
              name: "Pontos não visitados",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:downtimePointVisit"
                }
              ]
            },
            {
              id: "reports:patrimonies:journeyTime",
              checkId: "reports:patrimonies:journeyTime",
              name: "Tempo de jornada",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:journeyTime"
                }
              ]
            },
            {
              id: "reports:patrimonies:journeyTimeSimple",
              checkId: "reports:patrimonies:journeyTimeSimple",
              name: "Tempo de jornada (simplificado)",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:journeyTimeSimple"
                }
              ]
            },
            {
              id: "reports:patrimonies:journeyTimeBee",
              checkId: "reports:patrimonies:journeyTimeBee",
              name: "Tempo de jornada (Bee)",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:journeyTimeBee"
                }
              ]
            },
            {
              id: "reports:patrimonies:lastPosition",
              checkId: "reports:patrimonies:lastPosition",
              name: "Últimas posições",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:lastPosition"
                }
              ]
            },
            {
              id: "reports:patrimonies:lastPositionSimple",
              checkId: "reports:patrimonies:lastPositionSimple",
              name: "Últimas posições (Simples)",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:lastPositionSimple"
                }
              ]
            },
            {
              id: "reports:patrimonies:nipleExporters",
              checkId: "reports:patrimonies:nipleExporters",
              name: "Exportações do niple",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:nipleExporters"
                }
              ]
            },
            {
              id: "reports:patrimonies:nipleOperation",
              checkId: "reports:patrimonies:nipleOperation",
              name: "Operações do niple",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:nipleOperation"
                }
              ]
            },
            {
              id: "reports:patrimonies:nipleOperationAddress",
              checkId: "reports:patrimonies:nipleOperationAddress",
              name: "Operações do niple (com endereço)",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:nipleOperationAddress"
                }
              ]
            },
            {
              id: "reports:patrimonies:hatchOpen",
              checkId: "reports:patrimonies:hatchOpen",
              name: "Escotilha aberta",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:hatchOpen"
                }
              ]
            },
            {
              id: "reports:patrimonies:hatchOpenAddress",
              checkId: "reports:patrimonies:hatchOpenAddress",
              name: "Escotilha aberta (com endereço)",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:hatchOpenAddress"
                }
              ]
            },
            {
              id: "reports:patrimonies:nipleMaintenance",
              checkId: "reports:patrimonies:nipleMaintenance",
              name: "Manutenções do niple",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:nipleMaintenance"
                }
              ]
            },
            {
              id: "reports:patrimonies:position",
              checkId: "reports:patrimonies:position",
              name: "Posições",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:position"
                }
              ]
            },
            {
              id: "reports:patrimonies:positionAddress",
              checkId: "reports:patrimonies:positionAddress",
              name: "Posições (com endereço)",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:positionAddress"
                }
              ]
            },
            {
              id: "reports:patrimonies:positionAddressTimed",
              checkId: "reports:patrimonies:positionAddressTimed",
              name: "Posições (com endereço, limitado por tempo)",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:positionAddress"
                },
                {
                  scope: "reports:patrimonies:positionAddressTimed"
                }
              ]
            },
            {
              id: "reports:patrimonies:positionuse",
              checkId: "reports:patrimonies:positionuse",
              name: "Períodos de utilização",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:positionUse"
                }
              ]
            },
            {
              id: "reports:patrimonies:pump",
              checkId: "reports:patrimonies:pump",
              name: "Acionamento de bomba",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:pump"
                }
              ]
            },
            {
              id: "reports:patrimonies:positionJourney",
              checkId: "reports:patrimonies:positionJourney",
              name: "Posições (por jornada de trabalho)",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:positionJourney"
                }
              ]
            },
            {
              id: "reports:patrimonies:virtualFences",
              checkId: "reports:patrimonies:virtualFences",
              name: "Cercas Virtuais (entrada/saída)",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "read:virtualFences"
                },
                {
                  scope: "reports:patrimonies:virtualFences"
                }
              ]
            },
            {
              id: "reports:patrimonies:speeding",
              checkId: "reports:patrimonies:speeding",
              name: "Excesso de velocidade",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:patrimonies"
                },
                {
                  scope: "read:patrimonies"
                },
                {
                  scope: "reports:patrimonies:speeding"
                }
              ]
            }
          ]
        },
        {
          id: "reports:users",
          name: "Relatórios dos usuários",
          items: [
            {
              id: "reports:users:access",
              checkId: "reports:users:access",
              name: "Acessos",
              scopes: [
                {
                  scope: "menu:reports"
                },
                {
                  scope: "menu:reports:users"
                },
                {
                  scope: "read:users"
                },
                {
                  scope: "reports:users:access"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      icon: "far fa-calendar",
      id: "calendars",
      name: "Calendários",
      items: [
        {
          id: "calendars:certificates",
          name: "Calendário de atestados",
          items: [
            {
              id: "calendars:certificates:read:all",
              checkId: "calendars:certificates:create",
              name: "Ler (todos os atestados)",
              scopes: [
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:certificates"
                },
                {
                  scope: "read:certificates:all"
                }
              ]
            },
            {
              id: "calendars:certificates:create:all",
              checkId: "calendars:certificates:create",
              name: "Ler, criar e editar (todos os atestados)",
              scopes: [
                {
                  scope: "create:certificates:all"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:certificates"
                },
                {
                  scope: "read:certificates:all"
                },
                {
                  scope: "update:certificates:all"
                }
              ]
            },
            {
              id: "calendars:certificates:create",
              checkId: "calendars:certificates:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:certificates"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:certificates"
                },
                {
                  scope: "read:certificates"
                },
                {
                  scope: "update:certificates"
                }
              ]
            }
          ]
        },
        {
          id: "calendars:dayoffs",
          name: "Calendário de folgas",
          items: [
            {
              id: "calendars:dayoffs:read:all",
              checkId: "calendars:dayoffs:create",
              name: "Ler (todos os folgas)",
              scopes: [
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:dayoffs"
                },
                {
                  scope: "read:dayoffs:all"
                }
              ]
            },
            {
              id: "calendars:dayoffs:create:all",
              checkId: "calendars:dayoffs:create",
              name: "Ler, criar e editar (todos os folgas)",
              scopes: [
                {
                  scope: "create:dayoffs:all"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:dayoffs"
                },
                {
                  scope: "read:dayoffs:all"
                },
                {
                  scope: "update:dayoffs:all"
                }
              ]
            },
            {
              id: "calendars:dayoffs:create",
              checkId: "calendars:dayoffs:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:dayoffs"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:dayoffs"
                },
                {
                  scope: "read:dayoffs"
                },
                {
                  scope: "update:dayoffs"
                }
              ]
            }
          ]
        },
        {
          id: "calendars:expenses",
          name: "Calendário de despesas",
          items: [
            {
              id: "calendars:expenses:read:all",
              checkId: "calendars:expenses:create",
              name: "Ler (todas as despesas)",
              scopes: [
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:expenses"
                },
                {
                  scope: "read:expenses:all"
                }
              ]
            },
            {
              id: "calendars:expenses:create:all",
              checkId: "calendars:expenses:create",
              name: "Ler, criar e editar (todas as despesas)",
              scopes: [
                {
                  scope: "create:expenses:all"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:expenses"
                },
                {
                  scope: "read:expenses:all"
                },
                {
                  scope: "update:expenses:all"
                }
              ]
            },
            {
              id: "calendars:expenses:create",
              checkId: "calendars:expenses:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:expenses"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:expenses"
                },
                {
                  scope: "read:expenses"
                },
                {
                  scope: "update:expenses"
                }
              ]
            }
          ]
        },
        {
          id: "calendars:expenseTypes",
          name: "Calendário de tipos de despesas",
          items: [
            {
              id: "calendars:expenseTypes:read:all",
              checkId: "calendars:expenseTypes:create",
              name: "Ler (todos os tipos de despesas)",
              scopes: [
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:expenseTypes"
                },
                {
                  scope: "read:expenseTypes:all"
                }
              ]
            },
            {
              id: "calendars:expenseTypes:create:all",
              checkId: "calendars:expenseTypes:create",
              name: "Ler, criar e editar (todos os tipos de despesas)",
              scopes: [
                {
                  scope: "create:expenseTypes:all"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:expenseTypes"
                },
                {
                  scope: "read:expenseTypes:all"
                },
                {
                  scope: "update:expenseTypes:all"
                }
              ]
            },
            {
              id: "calendars:expenseTypes:create",
              checkId: "calendars:expenseTypes:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:expenseTypes"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:expenseTypes"
                },
                {
                  scope: "read:expenseTypes"
                },
                {
                  scope: "update:expenseTypes"
                }
              ]
            }
          ]
        },
        {
          id: "calendars:holydays",
          name: "Calendário de feriados",
          items: [
            {
              id: "calendars:holydays:read:all",
              checkId: "calendars:holydays:create",
              name: "Ler (todos os feriados)",
              scopes: [
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:holydays"
                },
                {
                  scope: "read:holydays:all"
                }
              ]
            },
            {
              id: "calendars:holydays:create:all",
              checkId: "calendars:holydays:create",
              name: "Ler, criar e editar (todos os feriados)",
              scopes: [
                {
                  scope: "create:holydays:all"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:holydays"
                },
                {
                  scope: "read:holydays:all"
                },
                {
                  scope: "update:holydays:all"
                }
              ]
            },
            {
              id: "calendars:holydays:create",
              checkId: "calendars:holydays:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:holydays"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:holydays"
                },
                {
                  scope: "read:holydays"
                },
                {
                  scope: "update:holydays"
                }
              ]
            }
          ]
        },
        {
          id: "calendars:licenses",
          name: "Calendário de licenças",
          items: [
            {
              id: "calendars:licenses:read:all",
              checkId: "calendars:licenses:create",
              name: "Ler (todas as licenças)",
              scopes: [
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:licenses"
                },
                {
                  scope: "read:licenses:all"
                }
              ]
            },
            {
              id: "calendars:licenses:create:all",
              checkId: "calendars:licenses:create",
              name: "Ler, criar e editar (todas as licenças)",
              scopes: [
                {
                  scope: "create:licenses:all"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:licenses"
                },
                {
                  scope: "read:licenses:all"
                },
                {
                  scope: "update:licenses:all"
                }
              ]
            },
            {
              id: "calendars:licenses:create",
              checkId: "calendars:licenses:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:licenses"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:licenses"
                },
                {
                  scope: "read:licenses"
                },
                {
                  scope: "update:licenses"
                }
              ]
            }
          ]
        },
        {
          id: "calendars:maintenanceSchedules",
          name: "Calendário de manutenções agendadas",
          items: [
            {
              id: "calendars:maintenanceSchedules:read:all",
              checkId: "calendars:maintenanceSchedules:create",
              name: "Ler (todas as manutenções agendadas)",
              scopes: [
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:maintenanceSchedules"
                },
                {
                  scope: "read:maintenanceSchedules:all"
                }
              ]
            },
            {
              id: "calendars:maintenanceSchedules:create:all",
              checkId: "calendars:maintenanceSchedules:create",
              name: "Ler, criar e editar (todas as manutenções agendadas)",
              scopes: [
                {
                  scope: "create:maintenanceSchedules:all"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:maintenanceSchedules"
                },
                {
                  scope: "read:maintenanceSchedules:all"
                },
                {
                  scope: "update:maintenanceSchedules:all"
                }
              ]
            },
            {
              id: "calendars:maintenanceSchedules:create",
              checkId: "calendars:maintenanceSchedules:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:maintenanceSchedules"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:maintenanceSchedules"
                },
                {
                  scope: "read:maintenanceSchedules"
                },
                {
                  scope: "update:maintenanceSchedules"
                }
              ]
            }
          ]
        },
        {
          id: "calendars:sinisters",
          name: "Calendário de sinistros",
          items: [
            {
              id: "calendars:sinisters:read:all",
              checkId: "calendars:sinisters:create",
              name: "Ler (todos os sinistros)",
              scopes: [
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:sinisters"
                },
                {
                  scope: "read:sinisters:all"
                }
              ]
            },
            {
              id: "calendars:sinisters:create:all",
              checkId: "calendars:sinisters:create",
              name: "Ler, criar e editar (todos os sinistros)",
              scopes: [
                {
                  scope: "create:sinisters:all"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:sinisters"
                },
                {
                  scope: "read:sinisters:all"
                },
                {
                  scope: "update:sinisters:all"
                }
              ]
            },
            {
              id: "calendars:sinisters:create",
              checkId: "calendars:sinisters:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:sinisters"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:sinisters"
                },
                {
                  scope: "read:sinisters"
                },
                {
                  scope: "update:sinisters"
                }
              ]
            }
          ]
        },
        {
          id: "calendars:supplys",
          name: "Calendário de abastecimentos",
          items: [
            {
              id: "calendars:supplys:read:all",
              checkId: "calendars:supplys:create",
              name: "Ler (todos os abastecimentos)",
              scopes: [
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:supplys"
                },
                {
                  scope: "read:supplys:all"
                }
              ]
            },
            {
              id: "calendars:supplys:create:all",
              checkId: "calendars:supplys:create",
              name: "Ler, criar e editar (todos os abastecimentos)",
              scopes: [
                {
                  scope: "create:supplys:all"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:supplys"
                },
                {
                  scope: "read:supplys:all"
                },
                {
                  scope: "update:supplys:all"
                }
              ]
            },
            {
              id: "calendars:supplys:create",
              checkId: "calendars:supplys:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:supplys"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:supplys"
                },
                {
                  scope: "read:supplys"
                },
                {
                  scope: "update:supplys"
                }
              ]
            }
          ]
        },
        {
          id: "calendars:trafficTickets",
          name: "Calendário de multas",
          items: [
            {
              id: "calendars:trafficTickets:read:all",
              checkId: "calendars:trafficTickets:create",
              name: "Ler (todas as multas)",
              scopes: [
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:trafficTickets"
                },
                {
                  scope: "read:trafficTickets:all"
                }
              ]
            },
            {
              id: "calendars:trafficTickets:create:all",
              checkId: "calendars:trafficTickets:create",
              name: "Ler, criar e editar (todas as multas)",
              scopes: [
                {
                  scope: "create:trafficTickets:all"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:trafficTickets"
                },
                {
                  scope: "read:trafficTickets:all"
                },
                {
                  scope: "update:trafficTickets:all"
                }
              ]
            },
            {
              id: "calendars:trafficTickets:create",
              checkId: "calendars:trafficTickets:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:trafficTickets"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:trafficTickets"
                },
                {
                  scope: "read:trafficTickets"
                },
                {
                  scope: "update:trafficTickets"
                }
              ]
            }
          ]
        },
        {
          id: "calendars:vacations",
          name: "Calendário de férias",
          items: [
            {
              id: "calendars:vacations:read:all",
              checkId: "calendars:vacations:create",
              name: "Ler (todas as férias)",
              scopes: [
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:vacations"
                },
                {
                  scope: "read:vacations:all"
                }
              ]
            },
            {
              id: "calendars:vacations:create:all",
              checkId: "calendars:vacations:create",
              name: "Ler, criar e editar (todas as férias)",
              scopes: [
                {
                  scope: "create:vacations:all"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:vacations"
                },
                {
                  scope: "read:vacations:all"
                },
                {
                  scope: "update:vacations:all"
                }
              ]
            },
            {
              id: "calendars:vacations:create",
              checkId: "calendars:vacations:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:vacations"
                },
                {
                  scope: "menu:calendars"
                },
                {
                  scope: "menu:calendars:vacations"
                },
                {
                  scope: "read:vacations"
                },
                {
                  scope: "update:vacations"
                }
              ]
            }
          ]
        },
      ]
    },
    {
      icon: "far fa-rss",
      id: "commands",
      name: "Comandos",
      items: [
        {
          id: "commands:blocks",
          name: "Bloqueio",
          items: [
            {
              id: "commands:blocks:read:all",
              checkId: "commands:blocks:create",
              name: "Ler (todos os bloqueios)",
              scopes: [
                {
                  scope: "menu:commands"
                },
                {
                  scope: "menu:commands:blocks"
                },
                {
                  scope: "read:blocks:all"
                }
              ]
            },
            {
              id: "commands:blocks:create:all",
              checkId: "commands:blocks:create",
              name: "Ler, criar e editar (todos os bloqueios)",
              scopes: [
                {
                  scope: "create:blocks:all"
                },
                {
                  scope: "menu:commands"
                },
                {
                  scope: "menu:commands:blocks"
                },
                {
                  scope: "read:blocks:all"
                },
                {
                  scope: "update:blocks:all"
                }
              ]
            },
            {
              id: "commands:blocks:create",
              checkId: "commands:blocks:create",
              name: "Ler, criar e editar (apenas do próprio cliente)",
              scopes: [
                {
                  scope: "create:blocks"
                },
                {
                  scope: "menu:commands"
                },
                {
                  scope: "menu:commands:blocks"
                },
                {
                  scope: "read:blocks"
                },
                {
                  scope: "update:blocks"
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default scopes;
