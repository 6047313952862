export function getDefaultOptions() {
  return {
    dateStart: false,
    dateEnd: false,
    filterActive: false,
    filterMultiple: false,
    filterNiple: false,
    filterPatrimony: false,
    filterPreSelected: false,
    filterUser: false,
    layout: true,
    currentUser: undefined
  };
};

export function getDefaultOptionsPatrimony() {
  const basic = getDefaultOptions();
  return Object.assign({}, basic, {
    filterPatrimony: true
  });
}

export function filterByClientAndPatrimonyAndWord(notifications, search) {
  let filter = notifications;
  let enabled = false;
  if(typeof search !== "undefined") {
    if(typeof search.client !== "undefined" && search.client.id !== "") {
      enabled = true;
      filter = filter.filter((n) => typeof n.notification.clients !== "undefined" && typeof n.notification.clients.find(x => x.id === search.client.id) !== "undefined");
    }
    if(typeof search.patrimony !== "undefined" && search.patrimony !== "") {
      enabled = true;
      filter = filter.filter((n) => typeof n.notification.patrimony !== "undefined" && typeof n.notification.patrimony.id !== "undefined" && n.notification.patrimony.id === search.patrimony);
    }
    if(typeof search.word !== "undefined" && search.word !== "") {
      enabled = true;
      filter = filter.filter((n) => n.notification.title.toLowerCase().indexOf(search.word.toLowerCase()) !== -1 || n.notification.body.toLowerCase().indexOf(search.word.toLowerCase()) !== -1);
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function sortNotifications(notifications, asc = false) {
  if(asc) {
    return notifications.sort((a, b) => a.notification.date.localeCompare(b.notification.date) || a.notification.createdAt.localeCompare(b.notification.createdAt));
  }
  return notifications.sort((a, b) => b.notification.date.localeCompare(a.notification.date) || b.notification.createdAt.localeCompare(a.notification.createdAt));
}
