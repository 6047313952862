import ChassiSchemaReduced, { schemaDate as schemaDateChassi } from "../Chassi/SchemaReducedTitle";
import ChipSchemaReduced, { schemaDate as schemaDateChip } from "../Chip/SchemaReducedTitle";
import ClientSchemaReduced, { schemaDate as schemaDateClient } from "../Client/SchemaReducedTitle";
import RfidSchemaReduced, { schemaDate as schemaDateRfid } from "../Rfid/SchemaReducedTitle";

const schema = {
  active: "Status",
  chip: JSON.parse(JSON.stringify(ChipSchemaReduced)),
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  createdAt: "Criado em",
  hatch: {
    type: "Tipo de escotilha"
  },
  id: "Id",
  name: "Nome",
  niple: {
    chassi: JSON.parse(JSON.stringify(ChassiSchemaReduced)),
    gyroscope: "Giroscópio do niple",
    groundingSensor: "Sensor de aterramento do niple",
    main: "Niple principal",
    operation: "Tipo de operações",
    operationInverted: "Operações invertidas"
  },
  positionMain: "Rastreador principal",
  rfid: JSON.parse(JSON.stringify(RfidSchemaReduced)),
  serial: "Serial",
  type: "Tipo",
  updatedAt: "Atualizado em"
};

export const schemaDate = {
  createdAt: "datetime",
  chip: JSON.parse(JSON.stringify(schemaDateChip)),
  client: JSON.parse(JSON.stringify(schemaDateClient)),
  niple: {
    chassi: JSON.parse(JSON.stringify(schemaDateChassi)),
  },
  rfid: JSON.parse(JSON.stringify(schemaDateRfid)),
  updatedAt: "datetime"
};

export default schema;
