import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
  InputGroup
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

import { Creators as NotificationsActions } from "../../store/ducks/notifications";

import { isScopes } from "../../services/Auth";
import { maskCPF } from "../../utils/CPF";
import { maskCNPJ } from "../../utils/CNPJ";
import { isValidForm } from "../../utils/Form";
import { isEmpty, ucFirstAll } from "../../utils/String";
import { clearObject, mergeObject } from "../../utils/Object";

import ClientService from "../../services/Client";
import ClientSchemaReduced from "../../services/Client/SchemaReduced";
import NoticeService from "../../services/Notice";
import NoticeSchema from "../../services/Notice/Schema";

import DateUtil from "./../../utils/Date";

registerLocale("pt-BR", ptBR);

const Notice = ({
  options,
  props: { currentUser },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const clientService = new ClientService();
  const noticeService = new NoticeService();

  const {
    notice: __notice = {},
    close: __close = () => {}
  } = options;

  const [notice, setNotice] = useState(mergeObject(JSON.parse(JSON.stringify(NoticeSchema)), __notice));

  useEffect(() => {
    setTimeout(() => {
      if(!isScopes({ currentUser, scopes: [
        "is:master",
        "read:notices:all"
      ], every: false })) {
        setClientSelectedOption({
          dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client),
          label: currentUser.client.type === "LEG" ? (`${currentUser.client.legal.socialReason} | CNPJ: ${maskCNPJ(currentUser.client.legal.cnpj)}`) : (`${currentUser.client.personal.name} | CPF: ${maskCPF(currentUser.client.personal.cpf)}`),
          value: currentUser.client.id
        });
        setNotice(prevState => ({
          ...prevState,
          client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client)
        }));
      }
    }, 1000);
  }, []); /* eslint-disable-line */

  const [clientSelectedOption, setClientSelectedOption] = useState(notice.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), notice.client),
    label: notice.client.type === "LEG" ? (`${notice.client.legal.socialReason} | CNPJ: ${maskCNPJ(notice.client.legal.cnpj)}`) : (`${notice.client.personal.name} | CPF: ${maskCPF(notice.client.personal.cpf)}`),
    value: notice.client.id
  });

  const [running, setRunning] = useState(false);

  const buttonDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? (`${e.legal.socialReason} | CNPJ: ${maskCNPJ(e.legal.cnpj)}`) : `${e.personal.name} | CPF: ${maskCPF(e.personal.cpf)}`,
        value: e.id
      };
    });
  };

  const [globalOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { valid, message } = isValidForm(e);
      if(!valid) {
        if(!message) {
          message = t("Error.Fields.Empty");
        }
        addNotice({
          title: t("Title.Notice"),
          content: message
        });
      }
      else {
        let response = false;
        if(isCreate()) {
          response = await noticeService.create({
            data: handleSubmitForm({ notice }),
            token: currentUser.token
          });
        }
        else {
          response = await noticeService.update({
            id: notice.id,
            data: handleSubmitForm({ notice }),
            token: currentUser.token
          });
        }

        if(response.success) {
          addNotice({
            title: t("Title.Notice"),
            content: isCreate() ? t("Success.Insert") : t("Success.Update")
          });
          setTimeout(() => {
            __close();
          }, 200);
        }
        else {
          addNotice({
            title: t("Title.Notice"),
            content: t(`Error.Notice.${response.error.type}.${ucFirstAll(response.error.details[0].path)}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ notice }) => {
    let newNotice = Object.assign({}, JSON.parse(JSON.stringify(notice)));
    newNotice = clearObject({ data: newNotice });
    newNotice.id = undefined;
    return newNotice;
  };

  const inputDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const isCreate = () => {
    return notice.id === "";
  };

  return (
    <Form id="forms-notice" noValidate onSubmit={handleSubmit}>
      <Card>
        <Card.Header>{t("Title.GeneralData")}</Card.Header>
        <Card.Body>
        <Row>
            <Col xs={12} md={6}>
              <div className="filter-date-fields">
                <Form.Group controlId="forms-patrimony-test-start-at">
                  <Form.Label>{t("Title.Date.Start")}:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <DatePicker
                      disabled={inputDisabled()}
                      selected={DateUtil.toDate({ date: notice.startAt })}
                      onChange={date => {
                        const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
                        const startAt = date === null ? new Date(`${todayDate} 00:00:00`) : date;
                        setNotice(prevState => ({
                          ...prevState,
                          startAt
                        }));
                      }}
                      selectsStart
                      startDate={DateUtil.toDate({ date: notice.startAt })}
                      endDate={DateUtil.toDate({ date: notice.endAt })}
                      minDate={new Date()}
                      maxDate={DateUtil.toDate({ date: notice.endAt })}
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      showTimeSelect
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd/MM/yyyy HH:mm:ss"
                      className="react-datepicker-form-control"
                      id="forms-patrimony-test-start-at"
                      popperPlacement="top-center"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "5px, 10px"
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: "viewport"
                        }
                      }}
                      locale="pt-BR"
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="filter-date-fields">
                <Form.Group controlId="forms-patrimony-test-end-at">
                  <Form.Label>{t("Title.Date.End")}:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <DatePicker
                      disabled={inputDisabled()}
                      selected={DateUtil.toDate({ date: notice.endAt })}
                      onChange={date => {
                        const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
                        const endAt = date === null ? new Date(`${todayDate} 23:55:00`) : date;
                        setNotice(prevState => ({
                          ...prevState,
                          endAt
                        }));
                      }}
                      selectsEnd
                      startDate={DateUtil.toDate({ date: notice.startAt })}
                      endDate={DateUtil.toDate({ date: notice.endAt })}
                      minDate={DateUtil.toDate({ date: notice.startAt })}
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      showTimeSelect
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd/MM/yyyy HH:mm:ss"
                      className="react-datepicker-form-control"
                      id="forms-patrimony-test-end-at"
                      popperPlacement="top-center"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "5px, 10px"
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: "viewport"
                        }
                      }}
                      locale="pt-BR"
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-user-client">
                <Form.Label>{t("Title.Client")}:</Form.Label>
                <AsyncSelect
                  className={`menu-outer-bottom ${!isEmpty(notice.client.id) ? "is-valid" : "is-invalid"}`}
                  classNamePrefix="select"
                  cacheOptions
                  defaultOptions
                  isDisabled={inputDisabled() || !isScopes({
                    currentUser,
                    scopes: [
                      "is:master",
                      "read:notices:all"
                    ],
                    every: false
                  })}
                  loadOptions={clientLoadOptions}
                  loadingMessage={() => t("React.Select.Wait")}
                  noOptionsMessage={() => t("React.Select.NoOptions")}
                  onChange={e => {
                    if(e === null) {
                      setClientSelectedOption(e);
                      setNotice(prevState => ({
                        ...prevState,
                        client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                      }));
                    }
                    else {
                      const { dataAux, label, value } = e;
                      setClientSelectedOption({ dataAux, label, value });
                      setNotice(prevState => ({
                        ...prevState,
                        client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                      }));
                    }
                  }}
                  placeholder={t("Title.Client.Select")}
                  value={clientSelectedOption}
                  required={true}
                />
                <Form.Control.Feedback className={`${!isEmpty(notice.client.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Client")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-virtual-fence-global">
                <Form.Label>{t("Label.Global")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  isDisabled={inputDisabled()}
                  onChange={e => {
                    const global = e.value;
                    setNotice(prevState => ({
                      ...prevState,
                      global
                    }));
                  }}
                  options={globalOptions}
                  value={globalOptions.find(x => x.value === notice.global)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group controlId="forms-notice-title">
                <Form.Label>{t("Label.Title")}:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Placeholder.Title")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const title = e.target.value;
                    setNotice(prevState => ({
                      ...prevState,
                      title: title.toUpperCase()
                    }));
                  }}
                  autoComplete="off"
                  value={notice.title}
                  isValid={!isEmpty(notice.title)}
                  isInvalid={isEmpty(notice.title)}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.Title")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group controlId="forms-notice-body">
                <Form.Label>{t("Label.Body")}:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder={t("Placeholder.Body")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const body = e.target.value;
                    setNotice(prevState => ({
                      ...prevState,
                      body: body.toUpperCase()
                    }));
                  }}
                  autoComplete="off"
                  value={notice.body}
                  isValid={!isEmpty(notice.body)}
                  isInvalid={isEmpty(notice.body)}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.Body")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Form.Group className="default-form-button">
        <Button
          variant="dark"
          type="submit"
          disabled={buttonDisabled()}
        >
          {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
        </Button>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Notice));
