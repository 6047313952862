import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";

const DEFAULT_ZINDEX = 5000;
const SCALE_HOVER = 1;
const SCALE_NORMAL = 1;

const EventCluster = ({ count, size, onClick, eType }) => {
  const { t } = useTranslation();
  const [eventHover, setEventHover] = useState(false);
  const [scale, setScale] = useState(SCALE_NORMAL);
  const [sizeBox] = useState("30px");
  const [sizeBorder] = useState("40px");
  const [zIndex, setZIndex] = useState(DEFAULT_ZINDEX);

  useEffect(() => {
    setScale(eventHover ? SCALE_HOVER : SCALE_NORMAL);
    setZIndex(Math.round(scale * DEFAULT_ZINDEX) + (eventHover ? DEFAULT_ZINDEX : 0));
  }, [eventHover, scale]);

  const getEventClusterType = () => {
    const eTypes = [
      {
        id: "anchorOk",
        title: t("Title.Anchor"),
        icon: <i className="fas fa-anchor" style={{ color: "#087d06" }} />
      },
      {
        id: "anchorViolated",
        title: t("Title.Anchor"),
        icon: <i className="fas fa-anchor" />
      },
      {
        id: "battery",
        title: t("Title.Battery"),
        icon: <i className="fas fa-battery-empty" />
      },
      {
        id: "block",
        title: t("Title.Block"),
        icon: <i className="fas fa-lock" />
      },
      {
        id: "course",
        title: t("Title.Course"),
        icon: <i className="far fa-compass" />
      },
      {
        id: "espRestarted",
        title: t("Title.Reason.ESPRestarted"),
        icon: <span>R</span>
      },
      {
        id: "hatchOpen",
        title: t("Title.Hatch.HatchOpen"),
        icon: <span>A</span>
      },
      {
        id: "ignitionOff",
        title: t("Title.Ignition.Off"),
        icon: <i className="fas fa-key" />
      },
      {
        id: "ignitionOn",
        title: t("Title.Ignition.On"),
        icon: <i className="fas fa-key" style={{ color: "#087d06" }} />
      },
      {
        id: "jammer",
        title: t("Title.Jammer"),
        icon: <i className="fas fa-broadcast-tower" />
      },
      {
        id: "route",
        title: t("Title.Route"),
        icon: <i className="fas fa-route" />
      },
      {
        id: "speed",
        title: t("Title.Speeding"),
        icon: <i className="fas fa-tachometer-alt" />
      },
      {
        id: "stop",
        title: t("Title.Downtime"),
        icon: <i className="fas fa-hourglass-half" />
      },
      {
        id: "stopRework",
        title: t("Title.Downtime"),
        icon: <i className="fas fa-hourglass-half" />
      },
      {
        id: "panic",
        title: t("Title.Panic"),
        icon: <i className="fas fa-skull-crossbones" />
      },
      {
        id: "pump",
        title: t("Title.Pump"),
        icon: <i className="fas fa-tint" />
      },
      {
        id: "maintenance",
        title: t("Title.Niple.Maintenance"),
        icon: <span>M</span>
      },
      {
        id: "nipleTestTpw",
        title: t("Title.Niple.Test"),
        icon: <span style={{ color: "#087d06" }}>T</span>
      },
      {
        id: "nipleTestTpe",
        title: t("Title.Niple.Test"),
        icon: <span>T</span>
      },
      {
        id: "nipleFraud",
        title: t("Title.Niple.Fraud"),
        icon: <span>B</span>
      },
      {
        id: "nipleClosed",
        title: t("Title.Niple.Closed"),
        icon: <span>F</span>
      },
      {
        id: "nipleClosedBasic",
        title: t("Title.Niple.Closed"),
        icon: <span>F</span>
      },
      {
        id: "nipleDischarge",
        title: t("Title.Niple.Discharge"),
        icon: <span>D</span>
      },
      {
        id: "nipleDischargeTest",
        title: t("Title.Niple.DischargeTest"),
        icon: <span style={{ color: "#999999" }}>D</span>
      },
      {
        id: "nipleDischargeBasic",
        title: t("Title.Niple.Discharge"),
        icon: <span>D</span>
      },
      {
        id: "nipleCharge",
        title: t("Title.Niple.Charge"),
        icon: <span>C</span>
      },
      {
        id: "nipleChargeTest",
        title: t("Title.Niple.ChargeTest"),
        icon: <span style={{ color: "#999999" }}>C</span>
      },
      {
        id: "nipleChargeBasic",
        title: t("Title.Niple.Charge"),
        icon: <span>C</span>
      },
      {
        id: "transhipment",
        title: t("Title.Transhipment"),
        icon: <i className="fas fa-exchange-alt" />
      }
    ];

    const eTypeFilter = eTypes.filter(x => x.id === eType());
    if(eTypeFilter.length > 0) {
      return eTypeFilter[0];
    }
    return false;
  };

  const eventDetails = getEventClusterType();
  return (
    <div className="marker-event-cluster-all"
      onClick={onClick}
      onMouseEnter={e => setEventHover(true)}
      onMouseLeave={e => setEventHover(false)}
      style={{
        width: sizeBorder,
        height: sizeBorder,
        transform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        MozTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        WebkitTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        zIndex: zIndex
      }}
    >
      <div
        className="marker-event-cluster"
        style={{ width: sizeBox, height: sizeBox }}
      >
        <div className="icon">{eventDetails.icon}</div>
        <div className="count">{count}</div>
      </div>
    </div>
  );
};

export default memo(EventCluster);
