import React, { memo, useEffect, useState } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as MenusActions } from "../../store/ducks/menus";

import { isScopes } from "../../services/Auth";
import Grid from "../Grid";

import MenuDiagnosisItem from "../MenuDiagnosisItem";

import CommandForm from "../../forms/Command";

import CommandService from "../../services/Command";
import DiagnosisMap from "../../diagnosis/DiagnosisMap";

const MenuDiagnosis = ({
  props: { currentUser },
  funcs: { menuHandleOpen, menuDiagnosisHandleClose }
}) => {
  const { t } = useTranslation();
  const [selectedCommand, setSelectedCommand] = useState({ selected: false, options: false });

  useEffect(() => {
    if(selectedCommand.selected) {
      if(selectedCommand.options.scoped(currentUser) === false) changeSelectedCommand(false);
    }
  }, [currentUser]); /* eslint-disable-line */

  const changeSelectedCommand = (item) => {
    setSelectedCommand({ selected: false, options: false });
    if (item !== false) setTimeout(() => setSelectedCommand(item), 100);
  };

  const dynamicOptions = [
    {
      id: "mdi-option-patrimony",
      title: t("Title.Diagnosis"),
      icon: <i className="fa-solid fa-stethoscope" />,
      onClick: () => {
        const id = "mdi-option-patrimony";
        if (selectedCommand.selected === id) return setSelectedCommand(false);
        changeSelectedCommand({
          selected: id,
          options: {
            api: new CommandService(),
            render: (options) => <DiagnosisMap />,
            actions: [],
            title: t("Title.Diagnosis"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master"], every: false })
          }
        });
      },
      selected: () => selectedCommand.selected === "mdi-option-patrimony",
      scoped: () => isScopes({ currentUser, scopes: ["is:master"], every: false })
    },
  ];

  const fixedOptions = [
    {
      id: "mdi-option-close",
      title: t("Title.Close"),
      icon: <i className="fas fa-times" />,
      onClick: () => {
        menuDiagnosisHandleClose();
        menuHandleOpen();
      },
      selected: () => true,
      scoped: () => true
    }
  ];

  const renderBody = () => {
    if (selectedCommand.selected) return selectedCommand.options.render(selectedCommand.options);
    return null;
  };

  const renderTitle = () => {
    if (selectedCommand.selected) return <h3>{selectedCommand.options.title}</h3>;
    return null;
  };

  return (
    <div className="menu-diagnosis">
      <div className={`mdi-diagnosis-options-absolute ${selectedCommand.selected ? "active" : ""}`}>
        <div className="mdi-diagnosis-options-absolute-content">
          <div className="mdi-moa-content-body">{renderBody()}</div>
        </div>
      </div>
      <div className="mdi-diagnosis-options-fixed">
        <ul>
          {
            fixedOptions.map(option => {
              if (option.scoped()) return <MenuDiagnosisItem key={option.id} {...option} onClick={option.onClick} />;
              return null;
            })
          }
        </ul>
      </div>
      <div className="mdi-diagnosis-options-dynamic">
        <ul>
          {
            dynamicOptions.map(option => {
              if (option.scoped()) return <MenuDiagnosisItem key={option.id} {...option} onClick={option.onClick} />;
              return null;
            })
          }
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, MenusActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MenuDiagnosis));
