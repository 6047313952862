import ClientSchemaReduced, { schemaDate as schemaDateClient } from "../Client/SchemaReducedTitle";
import WorkingWeekSchemaReduced, { schemaDate as schemaDateWorkingWeek } from "../WorkingWeek/SchemaReducedTitle";

const schema = {
  active: "Status",
  createdAt: "Criado em",
  id: "id",
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  name: "Nome",
  updatedAt: "Atualizado em",
  workingWeek: JSON.parse(JSON.stringify(WorkingWeekSchemaReduced))
};

export const schemaDate = {
  createdAt: "datetime",
  client: JSON.parse(JSON.stringify(schemaDateClient)),
  updatedAt: "datetime",
  workingWeek: JSON.parse(JSON.stringify(schemaDateWorkingWeek))
};

export default schema;
