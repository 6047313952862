import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import "./index.css";

import { Creators as NotificationsActions } from "../../store/ducks/notifications";

import DateUtil from "../../utils/Date";
import { isValidForm } from "../../utils/Form";
import { clearObject, mergeObject } from "../../utils/Object";
import { isEmpty, ucFirstAll } from "../../utils/String";

import PatrimonyService from "../../services/Patrimony";
import PatrimonySchemaReduced from "../../services/Patrimony/SchemaReduced";
import PatrimonyJourneyIgnoreService from "../../services/PatrimonyJourneyIgnore";
import PatrimonyJourneyIgnoreSchema from "../../services/PatrimonyJourneyIgnore/Schema";
import UserService from "../../services/User";
import UserSchemaReduced from "../../services/User/SchemaReducedTitle";

registerLocale("pt-BR", ptBR);

const PatrimonyJourneyIgnore = ({
  options,
  props: { currentUser },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const patrimonyService = new PatrimonyService();
  const patrimonyJourneyIgnoreService = new PatrimonyJourneyIgnoreService();
  const userService = new UserService();

  const {
    patrimonyJourneyIgnore: __patrimonyJourneyIgnore = {},
    close: __close = () => {}
  } = options;

  const [patrimonyJourneyIgnore, setPatrimonyJourneyIgnore] = useState(mergeObject(JSON.parse(JSON.stringify(PatrimonyJourneyIgnoreSchema)), __patrimonyJourneyIgnore));

  const [patrimonySelectedOption, setPatrimonySelectedOption] = useState(patrimonyJourneyIgnore.patrimony.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(PatrimonySchemaReduced)), patrimonyJourneyIgnore.patrimony),
    label: patrimonyJourneyIgnore.patrimony.description,
    value: patrimonyJourneyIgnore.patrimony.id
  });

  const [running, setRunning] = useState(false);

  const [userSelectedOption, setUserSelectedOption] = useState(patrimonyJourneyIgnore.user.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(UserSchemaReduced)), patrimonyJourneyIgnore.user),
    label: patrimonyJourneyIgnore.user.name,
    value: patrimonyJourneyIgnore.user.id
  });

  const buttonDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const filterPatrimony = (patrimonies) => {
    return patrimonies.map(e => {
      return {
        dataAux: e,
        label: e.description,
        value: e.id
      };
    });
  };

  const filterUser = (users) => {
    return users.map(e => {
      return {
        dataAux: e,
        label: e.name,
        value: e.id
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { valid, message } = isValidForm(e);
      if(!valid) {
        if(!message) {
          message = t("Error.Fields.Empty");
        }
        addNotice({
          title: t("Title.PatrimonyJourneyIgnore"),
          content: message
        });
      }
      else {
        let response = false;
        if(isCreate()) {
          response = await patrimonyJourneyIgnoreService.create({
            data: handleSubmitForm({ patrimonyJourneyIgnore }),
            token: currentUser.token
          });
        }
        else {
          response = await patrimonyJourneyIgnoreService.update({
            id: patrimonyJourneyIgnore.id,
            data: handleSubmitForm({ patrimonyJourneyIgnore }),
            token: currentUser.token
          });
        }

        if(response.success) {
          addNotice({
            title: t("Title.PatrimonyJourneyIgnore"),
            content: isCreate() ? t("Success.Insert") : t("Success.Update")
          });
          setTimeout(() => {
            __close();
          }, 200);
        }
        else {
          console.log(response);
          addNotice({
            title: t("Title.PatrimonyJourneyIgnore"),
            content: t(`Error.PatrimonyJourneyIgnore.${response.error.type}.${ucFirstAll(response.error.details[0].path)}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ patrimonyJourneyIgnore }) => {
    let newPatrimonyJourneyIgnore = Object.assign({}, JSON.parse(JSON.stringify(patrimonyJourneyIgnore)));
    newPatrimonyJourneyIgnore = clearObject({ data: newPatrimonyJourneyIgnore });
    newPatrimonyJourneyIgnore.id = undefined;
    return newPatrimonyJourneyIgnore;
  };

  const inputDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const isCreate = () => {
    return patrimonyJourneyIgnore.id === "";
  };

  const patrimonyLoadOptions = (e, c) => {
    patrimonyLoadOptionsDebounce(e, c);
  };

  const patrimonyLoadOptionsDebounce = debounce(async (e, c) => {
    const patrimonies = await patrimonyService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterPatrimony(patrimonies));
  }, 1000);

  const userLoadOptions = (e, c) => {
    userLoadOptionsDebounce(e, c);
  };

  const userLoadOptionsDebounce = debounce(async (e, c) => {
    const users = await userService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterUser(users));
  }, 1000);

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  return (
    <Form id="forms-patrimony-journey-ignore" noValidate onSubmit={handleSubmit}>
      <Card>
        <Card.Header>{t("Title.GeneralData")}</Card.Header>
        <Card.Body>
          <Row>
          <Col xs={12}>
              <Form.Group controlId="forms-patrimony-journey-ignore-active">
                <Form.Label>{t("Label.Active")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  options={activeOptions}
                  isDisabled={inputDisabled()}
                  onChange={e => {
                    const active = e.value;
                    setPatrimonyJourneyIgnore(prevState => ({
                      ...prevState,
                      active
                    }));
                  }}
                  value={activeOptions.find(x => x.value === patrimonyJourneyIgnore.active)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="filter-date-fields">
                <Form.Group controlId="forms-patrimony-journey-ignore-start-at">
                  <Form.Label>{t("Title.Date.Start")}:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <DatePicker
                      disabled={inputDisabled()}
                      selected={DateUtil.toDate({ date: patrimonyJourneyIgnore.startAt })}
                      onChange={date => {
                        const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
                        const startAt = date === null ? new Date(`${todayDate} 00:00:00`) : date;
                        setPatrimonyJourneyIgnore(prevState => ({
                          ...prevState,
                          startAt
                        }));
                      }}
                      selectsStart
                      startDate={DateUtil.toDate({ date: patrimonyJourneyIgnore.startAt })}
                      endDate={DateUtil.toDate({ date: patrimonyJourneyIgnore.endAt })}
                      minDate={new Date(`${process.env.REACT_APP_APP_START_YEAR}-01-02`)}
                      maxDate={DateUtil.toDate({ date: patrimonyJourneyIgnore.endAt })}
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      showTimeSelect
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd/MM/yyyy HH:mm:ss"
                      className="react-datepicker-form-control"
                      id="forms-patrimony-journey-ignore-start-at"
                      popperPlacement="top-center"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "5px, 10px"
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: "viewport"
                        }
                      }}
                      locale="pt-BR"
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="filter-date-fields">
                <Form.Group controlId="forms-patrimony-journey-ignore-end-at">
                  <Form.Label>{t("Title.Date.End")}:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <DatePicker
                      disabled={inputDisabled()}
                      selected={DateUtil.toDate({ date: patrimonyJourneyIgnore.endAt })}
                      onChange={date => {
                        const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
                        const endAt = date === null ? new Date(`${todayDate} 23:55:00`) : date;
                        setPatrimonyJourneyIgnore(prevState => ({
                          ...prevState,
                          endAt
                        }));
                      }}
                      selectsEnd
                      startDate={DateUtil.toDate({ date: patrimonyJourneyIgnore.startAt })}
                      endDate={DateUtil.toDate({ date: patrimonyJourneyIgnore.endAt })}
                      minDate={DateUtil.toDate({ date: patrimonyJourneyIgnore.startAt })}
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      showTimeSelect
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd/MM/yyyy HH:mm:ss"
                      className="react-datepicker-form-control"
                      id="forms-patrimony-journey-ignore-end-at"
                      popperPlacement="top-center"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "5px, 10px"
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: "viewport"
                        }
                      }}
                      locale="pt-BR"
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group controlId="forms-patrimony-journey-ignore-patrimony">
                <Form.Label>{t("Title.Patrimony")}:</Form.Label>
                <AsyncSelect
                  className={`menu-outer-bottom ${!isEmpty(patrimonyJourneyIgnore.patrimony.id) ? "is-valid" : "is-invalid"}`}
                  classNamePrefix="select"
                  cacheOptions
                  defaultOptions
                  isDisabled={inputDisabled()}
                  loadOptions={patrimonyLoadOptions}
                  loadingMessage={() => t("React.Select.Wait")}
                  noOptionsMessage={() => t("React.Select.NoOptions")}
                  onChange={e => {
                    let patrimony = JSON.parse(JSON.stringify(PatrimonySchemaReduced));
                    if(e === null) {
                      setPatrimonySelectedOption(e);
                      setPatrimonyJourneyIgnore(prevState => ({
                        ...prevState,
                        patrimony
                      }));
                    }
                    else {
                      const { dataAux, label, value } = e;
                      setPatrimonySelectedOption({ dataAux, label, value });
                      patrimony = mergeObject(JSON.parse(JSON.stringify(PatrimonySchemaReduced)), dataAux);
                      setPatrimonyJourneyIgnore(prevState => ({
                        ...prevState,
                        patrimony
                      }));
                    }
                    setPatrimonyJourneyIgnore(prevState => ({...prevState}));
                  }}
                  placeholder={t("Title.Patrimony.Select")}
                  value={patrimonySelectedOption}
                  required={true}
                />
                <Form.Control.Feedback className={`${!isEmpty(patrimonyJourneyIgnore.patrimony.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Patrimony")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group controlId="forms-patrimony-journey-ignore-reason">
                <Form.Label>{t("Label.Reason")}:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder={t("Placeholder.Reason")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const reason = e.target.value;
                    setPatrimonyJourneyIgnore(prevState => ({
                      ...prevState,
                      reason: reason.toUpperCase()
                    }));
                  }}
                  autoComplete="off"
                  value={patrimonyJourneyIgnore.reason}
                  isValid={!isEmpty(patrimonyJourneyIgnore.reason)}
                  isInvalid={isEmpty(patrimonyJourneyIgnore.reason)}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.Reason")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Form.Group className="default-form-button">
        <Button
          variant="dark"
          type="submit"
          disabled={buttonDisabled()}
        >
          {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
        </Button>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(PatrimonyJourneyIgnore));
