import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { getDescriptionPatrimony } from "../../utils/Patrimony";
import { getTypeVirtualFence } from "../../utils/VirtualFence";

const LastNotificationListItem = ({ id, onClick, virtualFence, selected }) => {
  const { t } = useTranslation();
  const positions = useSelector((state) => {
    return state.lastPositions.positionsSearchQueryEnabled ? state.lastPositions.positionsSearch.filter(pos => {
      if (pos.position.virtualFence && pos.position.virtualFence.id === virtualFence.id) {
        return true;
      }
      return false;
    }) : state.lastPositions.positions.filter(pos => {
      if (pos.position.virtualFence && pos.position.virtualFence.id === virtualFence.id) {
        return true;
      }
      return false;
    })
  });

  return (
    <li className={"virtual-fence-list-item"} onClick={onClick}>
      <ul>
        <li>
          <div><span><strong>{t("Title.VirtualFence.Name")}:</strong></span></div>
          <div><span>{virtualFence.name}</span></div>
        </li>
        <li>
          <div><span><strong>{t("Title.VirtualFence.Type")}:</strong></span></div>
          <div><span>{t(getTypeVirtualFence(virtualFence))}</span></div>
        </li>
        <li>
          <div><span><strong>{t("Title.VirtualFence.NotifyMinMaxHour")}:</strong></span></div>
          <div>
            <span>
              {
                virtualFence.notifyMinHour && virtualFence.notifyMaxHour ? (
                  <>{virtualFence.notifyMinHour} - {virtualFence.notifyMaxHour}</>
                ) : (
                  <>{t("Title.VirtualFence.NoNotify")}</>
                )
              }
            </span>
          </div>
        </li>
        <li>
          <div><span><strong>{t("Title.VirtualFence.PermitedMinMaxHour")}:</strong></span></div>
          <div>
            <span>
              {
                virtualFence.permitedMinHour && virtualFence.permitedMaxHour ? (
                  <>{virtualFence.permitedMinHour} - {virtualFence.permitedMaxHour}</>
                ) : (
                  <>{t("Title.VirtualFence.NoPermited")}</>
                )
              }
            </span>
          </div>
        </li>
        <li className="niple-box">
          <ul>
            <li className="niple-box-title"><div>{t("Title.VirtualFence.Patrimonies")} ({positions.length}):</div></li>
            {
              positions.map((pos, i) => {
                const position = pos.position;
                return (
                  <li key={`vf-patrimony-${position.patrimony.id}-${i}`}>
                    <div>{t("Patrimony")}:</div>
                    <div><span>{getDescriptionPatrimony(position.patrimony)}</span></div>
                  </li>
                );
              })
            }
          </ul>
        </li>
      </ul>
    </li>
  );
};

export default LastNotificationListItem;
