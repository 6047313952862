import React, { useState } from "react";
import { Resizable } from "react-resizable";
import { connect, useDispatch } from "react-redux";
import "./index.css";

import MenuCalendars from "./../MenuCalendars";
import MenuCommands from "./../MenuCommands";
import MenuDiagnosis from "./../MenuDiagnosis";
import MenuProfiles from "./../MenuProfiles";
import MenuRegisters from "./../MenuRegisters";
import MenuReports from "./../MenuReports";
import MenuSimulators from "./../MenuSimulators";
import MenuSingleExported from "./../MenuSingleExported";
import MenuSingleRoute from "./../MenuSingleRoute";
import MenuSingleRoutePositions from "./../MenuSingleRoutePositions";
import MenuSingleTranshipment from "./../MenuSingleTranshipment";
import MenuSingleTranshipmentPositions from "./../MenuSingleTranshipmentPositions";

import SupportPointForm from "./../../forms/SupportPoint";

import { isScopes } from "./../../services/Auth";
import { Creators as MenusActions } from "../../store/ducks/menus";

const Components = {
  SupportPointForm
};

const SpecificComponent = (component, props, close) => {
  console.log(close);
  const ComponentRender = Components[component];
  return <ComponentRender options={{...props, close: () => close() }} />
};

const MenuFooter = ({
  props: {
    actions,
    calendars,
    commands,
    currentUser,
    diagnosis,
    profiles,
    registers,
    reports,
    simulators,
    singleExported,
    singleExportedMenuPositionsSelected,
    singleRoute,
    singleRouteMenuPositionsSelected,
    singleTranshipment,
    singleTranshipmentMenuPositionsSelected
  }
}) => {
  const dispatch = useDispatch();
  const [height, setHeight] = useState(400);

  const onResize = (event, {element, size, handle}) => {
    setHeight(size.height);
  };

  return (
    <>
      {
        (actions.toggled && (
          <div className="menu-actions">
            <div className={`mac-actions-options-absolute active`}>
              <div className="mac-actions-options-absolute-content">
                <div className="mac-roa-content-title">
                  <div className="mac-roa-action-content" onClick={() => dispatch(MenusActions.menuActionsHandleClose())}>
                    <img src={process.env.PUBLIC_URL + "/images/menu-icon-close.png"} alt="" />
                  </div>
                  <h3>{actions.title}</h3>
                </div>
                <div className="mac-roa-content-body">
                  {SpecificComponent(actions.content, actions.props, () => dispatch(MenusActions.menuActionsHandleClose()) )}
                </div>
              </div>
            </div>
          </div>
        ))
      }
      {
        (calendars && (
          <div className="menu-footer">
            <MenuCalendars />
          </div>
        ))
      }
      {
        isScopes({ currentUser, scopes: ["is:master", "menu:commands"], every: false }) ? (
          commands && <div className="menu-footer">
            <MenuCommands />
          </div>
        ) : null
      }
      {
        (profiles && (
          <div className="menu-footer">
            <MenuProfiles />
          </div>
        ))
      }
      {
        isScopes({ currentUser, scopes: ["is:master", "menu:registers"], every: false }) ? (
          registers && <div className="menu-footer">
            <MenuRegisters />
          </div>
        ) : null
      }
      {
        isScopes({ currentUser, scopes: ["is:master"], every: false }) ? (
          diagnosis && <div className="menu-footer">
            <MenuDiagnosis />
          </div>
        ) : null
      }
      {
        isScopes({ currentUser, scopes: ["is:master", "menu:reports"], every: false }) ? (
          reports && <div className="menu-footer">
            <MenuReports />
          </div>
        ) : null
      }
      {
        isScopes({ currentUser, scopes: ["is:master", "menu:simulators"], every: false }) ? (
          simulators && <div className="menu-footer">
            <MenuSimulators />
          </div>
        ) : null
      }
      {singleExported && <div className="menu-footer"><MenuSingleExported /></div>}
      {singleRoute && <div className="menu-footer"><MenuSingleRoute /></div>}
      {
        singleRoute && singleRouteMenuPositionsSelected && <Resizable className="menu-footer resizer" width={0} height={height} onResize={onResize} resizeHandles={["n"]} handleSize={[20, 20]} axis="y" draggableOpts={{grid: [-1, 10]}} minConstraints={[-1, 100]} maxConstraints={[-1, 400]}>
          <div className="box" style={{height: height + "px"}}>
            <MenuSingleRoutePositions />
          </div>
        </Resizable>
      }
      {
        isScopes({ currentUser, scopes: ["is:master", "menu:transhipment"], every: false }) ? (
          singleTranshipment && <div className="menu-footer">
            <MenuSingleTranshipment />
          </div>
        ) : null
      }
      {
        isScopes({ currentUser, scopes: ["is:master", "menu:transhipment"], every: false }) ? (
          singleTranshipment && singleTranshipmentMenuPositionsSelected && <Resizable className="menu-footer resizer" width={0} height={height} onResize={onResize} resizeHandles={["n"]} handleSize={[20, 20]} axis="y" draggableOpts={{grid: [-1, 10]}} minConstraints={[-1, 100]} maxConstraints={[-1, 400]}>
            <div className="box" style={{height: height + "px"}}>
              <MenuSingleTranshipmentPositions />
            </div>
          </Resizable>
        ) : null
      }
    </>
  );
};

const mapStateToProps = state => ({
  props: {
    actions: state.menus.actions,
    calendars: state.menus.calendars.toggled,
    commands: state.menus.commands.toggled,
    currentUser: state.users.currentUser,
    diagnosis: state.menus.diagnosis.toggled,
    profiles: state.menus.profiles.toggled,
    registers: state.menus.registers.toggled,
    reports: state.menus.reports.toggled,
    simulators: state.menus.simulators.toggled,
    singleExported: state.exporteds.singleExported,
    singleRoute: state.routes.singleRoute,
    singleRouteMenuPositionsSelected: state.routes.singleRouteMenuPositionsSelected,
    singleTranshipment: state.transhipments.singleTranshipment,
    singleTranshipmentMenuPositionsSelected: state.transhipments.singleTranshipmentMenuPositionsSelected
  }
});

export default connect(mapStateToProps)(MenuFooter);
