import firebase from "firebase/app";
import "firebase/messaging";

const config = {
  apiKey: "AIzaSyCVJGflH0j5M3M9pT5cSFh4bMUZ0iq9p6M",
  authDomain: "n-tracker-334b4.firebaseapp.com",
  databaseURL: "https://n-tracker-334b4.firebaseio.com",
  projectId: "n-tracker-334b4",
  storageBucket: "n-tracker-334b4.appspot.com",
  messagingSenderId: "410881996731",
  appId: "1:410881996731:web:6d9e5eb4bbe5edf288b69e"
};

firebase.initializeApp(config);
const messaging = firebase.messaging.isSupported() ? firebase.messaging() : false;

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    if(!messaging) {
      return reject(false);
    }
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    if(!messaging) {
      return reject(false);
    }
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
