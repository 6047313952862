const schema = {
  active: "Status",
  address: {
    city: {
      id: "Id da cidade",
      externalId: "Id externo da cidade",
      name: "Nome da cidade"
    },
    complement: "Complemento",
    country: {
      id: "Id do país",
      initials: "Sigla do país",
      name: "Nome do país"
    },
    neighborhood: "Bairro",
    number: "Número",
    state: {
      id: "Id do estado",
      externalId: "Id externo do estado",
      initials: "Sigla do estado",
      name: "Nome do estado"
    },
    street: "Logradouro",
    zipCode: "CEP"
  },
  contact: {
    cellphone: "Celular",
    email: "E-mail",
    telephone: "Telefone"
  },
  createdAt: "Criado em",
  id: "Id",
  legal: {
    cnpj: "CNPJ",
    fantasyName: "Nome fantasia",
    foundationDate: "Data de foundação",
    responsible: {
      cnpj: "CNPJ do responsável",
      cpf: "CPF do responsável",
      name: "Nome do responsável"
    },
    socialReason: "Razão social",
    stateRegistration: "Registro estadual"
  },
  personal: {
    birthDate: "Data de nascimento",
    cpf: "CPF",
    generalRegistration: "Registro geral",
    name: "Nome"
  },
  scopes: "Permissões",
  type: "Tipo de cliente",
  updatedAt: "Atualizado em"
};

export const schemaDate = {
  createdAt: "datetime",
  legal: {
    foundationDate: "date"
  },
  personal: {
    birthDate: "date"
  },
  updatedAt: "datetime"
};

export default schema;
