import PatrimonySchemaReduced from "../Patrimony/SchemaReduced";
import UserSchemaReduced from "../User/SchemaReducedTitle";

const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
const endAt = new Date(`${todayDate} 23:55:00`);
const startAt = new Date(`${todayDate} 00:00:00`);

const schema = {
  endAt,
  id: "",
  patrimony: JSON.parse(JSON.stringify(PatrimonySchemaReduced)),
  reason: "",
  startAt,
  user: JSON.parse(JSON.stringify(UserSchemaReduced))
};

export default schema;
