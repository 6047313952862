import React, { memo } from "react";
import "./index.css";

const SwitchVirtualFence = ({ checkbox = true, name, virtualFence, selected } = {}) => {
  const { virtualFence: vf } = virtualFence;
  return (
    <div
      className="marker-all marker-all-switch"
      board-color={""}
      board-country={""}
      board-layout={""}
      board-type={"USR"}
      mini-markers={"false"}
    >
      <input
        className="marker-data-checkbox"
        type={`${checkbox ? "checkbox" : "radio"}`}
        checked={selected}
        name={name}
        readOnly={true}
      />
      <div className="marker-data">
        <div className="marker-data-content">
          <div className="marker-mini-board-all-img">
            <img src={process.env.PUBLIC_URL + "/images/user-default.png"} alt={vf.name} />
          </div>
        </div>
      </div>
      <div className="marker-data-description">{vf.name}</div>
    </div>
  );
};

export default memo(SwitchVirtualFence);
