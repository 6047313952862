import React, { memo, useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  Row
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import "./index.css";

import { Creators as LoadersActions } from "../../store/ducks/loaders";
import { Creators as VirtualFencesActions } from "../../store/ducks/virtualFences";

import { mergeObject } from "../../utils/Object";
import { isScopes } from "../../services/Auth";

import ClientService from "../../services/Client";
import ClientSchemaReduced from "../../services/Client/SchemaReduced";

import VirtualFenceService from "../../services/VirtualFence";

import FilterVirtualFenceItem from "../FilterVirtualFenceItem";
import SwitchDefault from "../Switch/Default";

const FilterVirtualFence = ({
  props: {
    currentUser,
    virtualFences,
    virtualFencesSearch,
    virtualFencesSearchOnlyClient,
    virtualFencesSearchQueryEnabled
  },
  funcs: {
    disableLoader,
    enableLoader,
    searchVirtualFences,
    setVirtualFences,
    setVirtualFencesSelected
  },
  options: {
    filterActive,
    filterMultipleVirtualFence,
    filterPreSelected
  }
}) => {
  const { t } = useTranslation();
  const clientService = new ClientService();
  const virtualFenceService = new VirtualFenceService();

  const [switchAllChecked, setSwitchAllChecked] = useState(false);
  const [switchSingleChecked, setSwitchSingleChecked] = useState(false);
  const [_virtualFencesSelected, _setVirtualFencesSelected] = useState([]);

  const [_virtualFencesSearch, _setVirtualFencesSearch] = useState({
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    word: "",
    active: filterActive ? -1 : true
  });

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") },
    { value: -1, label: t("All") }
  ]);

  const [clientSelectedOption, setClientSelectedOption] = useState(_virtualFencesSearch.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), _virtualFencesSearch.client),
    label: _virtualFencesSearch.client.type === "LEG" ? _virtualFencesSearch.client.legal.socialReason : _virtualFencesSearch.client.personal.name,
    value: _virtualFencesSearch.client.id
  });

  useEffect(() => {
    findVirtualFences();
  }, []); /* eslint-disable-line */

  useEffect(() => {
    searchVirtualFences(_virtualFencesSearch);
  }, [_virtualFencesSearch]); /* eslint-disable-line */

  useEffect(() => {
    if(!switchSingleChecked) {
      if(switchAllChecked) {
        return _setVirtualFencesSelected(virtualFencesSearchOnlyClient);
      }
      return _setVirtualFencesSelected([]);
    }
    setSwitchSingleChecked(false);
  }, [switchAllChecked]); /* eslint-disable-line */

  useEffect(() => {
    setVirtualFencesSelected(_virtualFencesSelected);
    if(_virtualFencesSelected.length > 0 && (virtualFencesSearchOnlyClient.length === _virtualFencesSelected.length)) {
      return setSwitchAllChecked(true);
    }
    setSwitchAllChecked(false);
  }, [_virtualFencesSelected]); /* eslint-disable-line */

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? e.legal.socialReason : e.personal.name,
        value: e.id
      };
    });
  };

  const findVirtualFences = async () => {
    enableLoader();
    const virtualFences = await virtualFenceService.findAll({
      data: {
        limit: 1000,
        page: 1,
        search: ""
      },
      token: currentUser.token
    });
    const virtualFencesFilter = virtualFences.docs.slice();
    setVirtualFences(virtualFencesFilter);
    if(filterPreSelected) {
      _setVirtualFencesSelected(
        filterPreSelected.length === 0 ? (
          virtualFencesFilter
        ) : (
          virtualFencesFilter.filter(x => filterPreSelected.findIndex(y => y === x.id) !== -1)
        )
      );
    }
    disableLoader();
  };

  const handleClickVirtualFence = (e, virtualFence) => {
    if(filterMultipleVirtualFence) {
      return handleClickVirtualFenceMultiple(e, virtualFence);
    }
    return handleClickVirtualFenceSingle(e, virtualFence);
  };

  const handleClickVirtualFenceMultiple = (e, virtualFence) => {
    const virtualFencesSelected = _virtualFencesSelected.filter(x => x.id !== virtualFence.id);
    if(!e) {
      virtualFencesSelected.push(virtualFence);
    }
    setSwitchSingleChecked(true);
    _setVirtualFencesSelected(virtualFencesSelected);
  };

  const handleClickVirtualFenceSingle = (e, virtualFence) => {
    const virtualFencesSelected = [virtualFence];
    setSwitchSingleChecked(true);
    _setVirtualFencesSelected(virtualFencesSelected);
  };

  return (
    <Card>
      <Card.Header>{t("Title.VirtualFence")}</Card.Header>
      <Card.Body>
        <Row>
          {
            isScopes({ currentUser, scopes: [
              "is:master",
              "read:virtualFences:all"
            ], every: false }) ? (
                <>
                  <Col xs={12} md={4}>
                    <Form.Group controlId="filter-virtual-fence-search-client">
                      <AsyncSelect
                        className="menu-outer-bottom menu-outer-zindex"
                        classNamePrefix="select"
                        cacheOptions
                        defaultOptions
                        isClearable
                        loadOptions={clientLoadOptions}
                        loadingMessage={() => t("React.Select.Wait")}
                        noOptionsMessage={() => t("React.Select.NoOptions")}
                        onChange={e => {
                          if(e === null) {
                            setClientSelectedOption(e);
                            _setVirtualFencesSearch(prevState => ({
                              ...prevState,
                              client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                            }));
                          }
                          else {
                            const { dataAux, label, value } = e;
                            setClientSelectedOption({ dataAux, label, value });
                            _setVirtualFencesSearch(prevState => ({
                              ...prevState,
                              client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                            }));
                          }
                          setSwitchAllChecked(true);
                          setTimeout(() => {
                            setSwitchAllChecked(false);
                          }, 100);
                        }}
                        placeholder={t("React.Select.SelectClient")}
                        value={clientSelectedOption}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group controlId="filter-virtual-fence-search-virtualFence">
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.Search")}
                        autoComplete={"off"}
                        onChange={e => {
                          e.persist();
                          _setVirtualFencesSearch(prevState => ({
                            ...prevState,
                            word: e.target.value
                          }));
                        }}
                        value={_virtualFencesSearch.word}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group controlId="filter-virtual-fence-search-active">
                      <Select
                        className="menu-outer-bottom"
                        classNamePrefix="select"
                        options={activeOptions}
                        onChange={e => {
                          if(e === null) {
                            _setVirtualFencesSearch(prevState => ({
                              ...prevState,
                              active: -1
                            }));
                          }
                          else {
                            _setVirtualFencesSearch(prevState => ({
                              ...prevState,
                              active: e.value
                            }));
                          }
                          setSwitchAllChecked(true);
                          setTimeout(() => {
                            setSwitchAllChecked(false);
                          }, 100);
                        }}
                        isDisabled={!filterActive}
                        value={activeOptions.find(x => x.value === _virtualFencesSearch.active)}
                      />
                    </Form.Group>
                  </Col>
                </>
              ) : (
                <Col>
                  <Form.Group controlId="filter-virtual-fence-search-virtualFence">
                    <Form.Control
                      type="text"
                      placeholder={t("Placeholder.Search")}
                      autoComplete={"off"}
                      onChange={e => {
                        e.persist();
                        _setVirtualFencesSearch(prevState => ({
                          ...prevState,
                          word: e.target.value
                        }));
                      }}
                      value={_virtualFencesSearch.word}
                    />
                  </Form.Group>
                </Col>
              )
          }
        </Row>
        <Row>
          <Col>
            <div className="filter-virtual-fence-list-all">
              {
                filterMultipleVirtualFence ? (
                  <div className="filter-virtual-fence-select-all">
                    <SwitchDefault title={`${t("Title.All")} (${virtualFencesSearchQueryEnabled ? virtualFencesSearch.length : virtualFences.length})`} selected={switchAllChecked} onClick={e => {
                      setSwitchSingleChecked(false);
                      setSwitchAllChecked(!switchAllChecked);
                    }} />
                  </div>
                ) : null
              }
              <div className="filter-virtual-fence-list-box">
                <ul>
                  {
                    virtualFencesSearchQueryEnabled ? (
                      virtualFencesSearch.map(virtualFence => {
                        const selected = _virtualFencesSelected.find(x => x.id === virtualFence.id) ? true : false;
                        return (
                          <FilterVirtualFenceItem
                            key={`filter-virtual-fence-list-item-${virtualFence.id}`}
                            checkbox={filterMultipleVirtualFence}
                            name={filterMultipleVirtualFence ? `filter-virtual-fence-list-item-${virtualFence.id}` : "filter-virtual-fence-list-item"}
                            virtualFence={virtualFence}
                            selected={selected}
                            onClick={e => handleClickVirtualFence(selected, virtualFence)}
                          />
                        );
                      })
                    ) : (
                      virtualFences.map(virtualFence => {
                        const selected = _virtualFencesSelected.find(x => x.id === virtualFence.id) ? true : false;
                        return (
                          <FilterVirtualFenceItem
                            key={`filter-virtual-fence-list-item-${virtualFence.id}`}
                            checkbox={filterMultipleVirtualFence}
                            name={filterMultipleVirtualFence ? `filter-virtual-fence-list-item-${virtualFence.id}` : "filter-virtual-fence-list-item"}
                            virtualFence={virtualFence}
                            selected={selected}
                            onClick={e => handleClickVirtualFence(selected, virtualFence)}
                          />
                        );
                      })
                    )
                  }
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    virtualFences: state.virtualFences.virtualFences,
    virtualFencesSearch: state.virtualFences.virtualFencesSearch,
    virtualFencesSearchOnlyClient: state.virtualFences.virtualFencesSearchOnlyClient,
    virtualFencesSearchQueryEnabled: state.virtualFences.virtualFencesSearchQueryEnabled
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, VirtualFencesActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(FilterVirtualFence));
