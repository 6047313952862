import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

import GoogleMapReact from "google-map-react";
import { Creators as ExportedsActions } from "./../../store/ducks/exporteds";
import { Creators as LastPositionActions } from "./../../store/ducks/lastPositions";
import { Creators as LastPositionsProblemsActions } from "./../../store/ducks/lastPositionsProblems";
import { Creators as RoutesActions } from "./../../store/ducks/routes";
import { Creators as TranshipmentsActions } from "./../../store/ducks/transhipments";
import { Creators as VirtualFencesActions } from "./../../store/ducks/virtualFences";
import useSupercluster from "use-supercluster";

import EventClusterMarker from "./../Marker/EventCluster";
import EventMarker from "./../Marker/Event";
import ExportedClusterMarker from "./../Marker/ExportedCluster";
import ExportedMarker from "./../Marker/Exported";
import PatrimonyClusterMarker from "./../Marker/PatrimonyCluster";
import PatrimonyMarker from "./../Marker/Patrimony";

import { simplifyPath } from "./../../utils/Position";

const Map = ({
  props: {
    currentUser,
    lastPositions,
    lastPositionsSearch,
    lastPositionsSearchQueryEnabled,
    lastPositionSelected,
    lastPositionsLoad,

    // lastPositionProblem,
    // lastPositionProblemLoad,
    // lastPositionProblemShow,

    lastPositionsProblems,
    lastPositionsSearchProblems,
    lastPositionsSearchQueryEnabledProblems,
    lastPositionSelectedProblems,
    lastPositionsLoadProblems,
    lastPositionsShowProblems,

    regions,
    singleExported,
    singleExportedMenuSelected,
    singleExportedMenuPositionsMarkersSelected,
    singleRoute,
    singleRouteMenuSelected,
    singleRouteMenuPositionsMarkersSelected,
    singleTranshipment,
    singleTranshipmentMenuSelected,
    singleTranshipmentMenuPositionsMarkersSelected,
    virtualFences,
    virtualFencesLoad
  },
  funcs: {
    removeSingleExported,
    removeSingleRoute,
    removeSingleTranshipment,
    setLastPositionProblemShow,
    selectLastPosition,
    updateVirtualFences
  }
}) => {
  const __default_center__ = { lat: -14.2800653, lng: -46.9647527 };
  const __default_zoom__ = 4.78;
  const __default_min_zoom__ = 3;
  const __default_max_zoom__ = 19;
  const __default_cluster_max_zoom__ = 18;

  const mapRef = useRef();
  const mapsRef = useRef();
  const mapsTrafficRef = useRef();

  const [bounds, setBounds] = useState(null);
  const [mapBounds, setMapBounds] = useState(false);
  const [mapMiniMarkers, setMapMiniMarkers] = useState(currentUser ? currentUser.profile.map.miniMarkers : false);
  const [mapReady, setMapReady] = useState(false);
  const [mapTraffic, setMapTraffic] = useState(currentUser ? currentUser.profile.map.traffic : false);
  const [options, setOptions] = useState({
    mapTypeId: "DEF",
    minZoom: __default_min_zoom__,
    maxZoom: __default_max_zoom__,
    restriction: null,
    styles: [{
      stylers: [{
        saturation: -10
      }]
    }]
  });
  const [singleExportedPolyline, setSingleExportedPolyline] = useState(false);
  const [singlePolyline, setSinglePolyline] = useState(false);
  const [singleTranshipmentMarkers, setSingleTranshipmentMarkers] = useState(false);
  const [regionsPolygon, setRegionsPolygon] = useState(false);
  const [virtualFencesPolygon, setVirtualFencesPolygon] = useState(false);
  const [zoom, setZoom] = useState(12);

  useEffect(() => {
    if (lastPositionsShowProblems) {
      if(lastPositionSelectedProblems) {
        panToLastPositionSelected();
      }
      else {
        reloadBounds();
      }
    }
    else {
      if(lastPositionSelected) {
        if(!singleExported) {
          panToLastPositionSelected();
        }
        if(!singleRoute) {
          panToLastPositionSelected();
        }
        if(!singleTranshipment) {
          panToLastPositionSelected();
        }
      }
      else {
        reloadBounds();
      }
    }
  }, [lastPositionSelected, lastPositionSelectedProblems, lastPositionsShowProblems, singleExported, singleRoute, singleTranshipment]); /* eslint-disable-line */

  useEffect(() => {
    if(!currentUser) {
      setMapBounds(false);
      setSinglePolyline(false);
      reloadBounds();
    }
    else {
      const { miniMarkers, type, traffic } = currentUser.profile.map;
      setMapMiniMarkers(miniMarkers);
      setOptions(options => {
        return {
          ...options,
          mapTypeId: type
        };
      });
      setMapTraffic(traffic);
    }
  }, [currentUser]); /* eslint-disable-line */

  useEffect(() => {
    toggleTrafficLayer();
  }, [mapTraffic]); /* eslint-disable-line */

  useEffect(() => {
    if(singleExported && (!singleExportedPolyline || !Object.is(singleExported, singleExportedPolyline.singleExported))) {
      removeSingleExportedFromMap();
      setTimeout(() => {
        generateSingleExportedToMap();
      }, 100);
    }
    else if(!singleExported && singleExportedPolyline) {
      removeSingleExportedFromMap();
      setTimeout(() => {
        panToLastPositionSelected();
        setSingleExportedPolyline(false);
      }, 100);
    }
  }, [singleExported, singleExportedPolyline]); /* eslint-disable-line */

  useEffect(() => {
    if(singleRoute && (!singlePolyline || !Object.is(singleRoute, singlePolyline.singleRoute))) {
      removeSingleRouteFromMap();
      setTimeout(() => {
        generateSingleRouteToMap();
      }, 100);
    }
    else if(!singleRoute && singlePolyline) {
      removeSingleRouteFromMap();
      setTimeout(() => {
        panToLastPositionSelected();
        setSinglePolyline(false);
      }, 100);
    }
  }, [singleRoute, singlePolyline]); /* eslint-disable-line */

  useEffect(() => {
    if(singleTranshipment && (!singleTranshipment || !Object.is(singleTranshipment, singleTranshipmentMarkers.singleTranshipment))) {
      setTimeout(() => {
        generateSingleTranshipmentToMap();
      }, 100);
    }
    else if(!singleTranshipment && singleTranshipmentMarkers) {
      setTimeout(() => {
        panToLastPositionSelected();
        setSingleTranshipmentMarkers(false);
      }, 100);
    }
  }, [singleTranshipment, singleTranshipmentMarkers]); /* eslint-disable-line */

  useEffect(() => {
    if(regions && !regionsPolygon) {
      generateRegionsPolygonToMap();
    }
    else if(!regions && regionsPolygon) {
      removeRegionsPolygonFromMap();
      setTimeout(() => {
        setRegionsPolygon(false);
      }, 100);
    }
  }, [regions, regionsPolygon]); /* eslint-disable-line */

  useEffect(() => {
    if(virtualFencesLoad && !virtualFencesPolygon) {
      generateVirtualFencesPolygonToMap();
    }
    else if(!virtualFencesLoad && virtualFencesPolygon) {
      removeVirtualFencesPolygonFromMap();
      setTimeout(() => {
        setVirtualFencesPolygon(false);
      }, 100);
    }
  }, [virtualFencesLoad, virtualFencesPolygon]); /* eslint-disable-line */

  const getMapTypeId = ({ type }) => {
    switch(type) {
    case "SAT":
      return "hybrid";
    default:
      return "roadmap";
    }
  };

  const getPositionsSource = () => {
    if(lastPositionsSearchQueryEnabled) return lastPositionsSearch;
    return lastPositions;
  };

  const getPositionsProblemsSource = () => {
    if(lastPositionsSearchQueryEnabledProblems) return lastPositionsSearchProblems;
    return lastPositionsProblems;
  }

  const getEventsSource = () => {
    if(singleRouteMenuSelected) {
      return singleRouteMenuSelected;
    }
    else if(singleTranshipmentMenuSelected) {
      return singleTranshipmentMenuSelected;
    }
    return {
      id: null,
      value: []
    };
  };

  const getEventsSourceSelected = () => {
    if(singleRouteMenuPositionsMarkersSelected) {
      return singleRouteMenuPositionsMarkersSelected;
    }
    else if(singleTranshipmentMenuPositionsMarkersSelected) {
      return singleTranshipmentMenuPositionsMarkersSelected;
    }
    return [];
  };

  const getExportedSource = () => {
    if(singleExportedMenuSelected) {
      return singleExportedMenuSelected;
    }
    return {
      id: null,
      value: []
    };
  };

  const getExportedSourceSelected = () => {
    if(singleExportedMenuPositionsMarkersSelected) {
      return singleExportedMenuPositionsMarkersSelected;
    }
    return [];
  };

  const getPoints = () => {
    if(mapReady && lastPositionsLoad && !mapBounds) {
      setMapBounds(true);
      reloadBounds();
    }
    if (lastPositionsShowProblems) {
      return getPositionsProblemsSource().map(lp => ({
        type: "Feature",
        properties: { cluster: false, position: lp },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(lp.position.gps.coordinate.longitude),
            parseFloat(lp.position.gps.coordinate.latitude)
          ]
        }
      }));
    }
    if(singleExported || singleRoute || singleTranshipment) {
      return getPositionsSource()
        .filter(x => x.position.tracker.serial === lastPositionSelected.position.tracker.serial).map(lp => ({
          type: "Feature",
          properties: { cluster: false, position: lp },
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(lp.position.gps.coordinate.longitude),
              parseFloat(lp.position.gps.coordinate.latitude)
            ]
          }
        }));
    }
    return getPositionsSource().map(lp => ({
      type: "Feature",
      properties: { cluster: false, position: lp },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(lp.position.gps.coordinate.longitude),
          parseFloat(lp.position.gps.coordinate.latitude)
        ]
      }
    }));
  };

  const getEventPoints = () => {
    let  { id, value } = getEventsSource();
    let selected = getEventsSourceSelected();
    if(selected.length > 0) {
      value = selected;
    }
    return value.map(ep => ({
      type: "Feature",
      properties: { cluster: false, position: ep, type: id },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(ep.gps.coordinate.longitude),
          parseFloat(ep.gps.coordinate.latitude)
        ]
      }
    }));
  };

  const getExportedPoints = () => {
    let  { id, value } = getExportedSource();
    let selected = getExportedSourceSelected();
    if(selected.length > 0) {
      value = selected;
    }
    return value.map(ep => ({
      type: "Feature",
      properties: { cluster: false, position: ep, type: id },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(ep.tracker.longitude),
          parseFloat(ep.tracker.latitude)
        ]
      }
    }));
  };

  const getBounds = () => {
    if(mapReady) {
      const pointsBounds = new mapsRef.current.LatLngBounds();
      if(getPositionsSource().length > 1) {
        getPositionsSource().map(lp => (
          pointsBounds.extend(new mapsRef.current.LatLng(
            lp.position.gps.coordinate.latitude,
            lp.position.gps.coordinate.longitude
          )))
        );
        return pointsBounds;
      }
      else {
        mapRef.current.setCenter(__default_center__);
        mapRef.current.setZoom(__default_zoom__);
      }
    }
    return false;
  };

  const panToLastPositionSelected = () => {
    if (lastPositionsShowProblems) {
      if(lastPositionSelectedProblems) {
        const { latitude, longitude } = lastPositionSelectedProblems.position.gps.coordinate;
        mapRef.current.setZoom(__default_max_zoom__);
        mapRef.current.panTo({ lat: latitude, lng: longitude });
      }
      return;
    }
    if(lastPositionSelected && !singleExported && !singleRoute && !singleTranshipment) {
      const { latitude, longitude } = lastPositionSelected.position.gps.coordinate;
      mapRef.current.setZoom(__default_max_zoom__);
      mapRef.current.panTo({ lat: latitude, lng: longitude });
    }
  };

  const generateRegionsPolygonToMap = () => {
    const regionsColor = (externalId) => {
      switch (externalId) {
      case "4201":
        return "rgb(74, 94, 202)";
      case "4202":
        return "rgb(208, 22, 43)";
      case "4203":
        return "rgb(112, 173, 251)";
      case "4204":
        return "rgb(91, 15, 200)";
      case "4205":
        return "rgb(175, 16, 242)";
      case "4206":
        return "rgb(192, 96, 15)";
      case "4207":
        return "rgb(252, 73, 131)";
      case "4208":
        return "rgb(106, 53, 33)";
      case "4209":
        return "rgb(67, 125, 20)";
      case "4210":
        return "rgb(127, 162, 97)";
      case "4211":
        return "rgb(16, 120, 133)";
      case "4212":
        return "rgb(241, 170, 199)";
      case "4213":
        return "rgb(254, 157, 45)";
      case "4214":
        return "rgb(0, 0, 0)";
      case "4215":
        return "rgb(96, 125, 149)";
      case "4216":
        return "rgb(149, 137, 35)";
      default:
        return "rgb(255,255,255)";
      }
    };

    const polygons = [];
    const newRegions = JSON.parse(JSON.stringify(regions));
    newRegions.map(city => {
      return city.polygons.map((polygon, index) => {
        const poly = polygon.polygon;
        for(var i = 0; i < poly.length; i++) {
          poly[i] = new mapsRef.current.LatLng(poly[i].latitude, poly[i].longitude);
        }
        let polygonMap = new mapsRef.current.Polygon({
          path: poly,
          strokeColor: regionsColor(city.region.externalId),
          strokeOpacity: 0.5,
          strokeWeight: 1,
          fillColor: regionsColor(city.region.externalId),
          fillOpacity: 0.35,
          indexID: city
        });
        polygonMap.setMap(mapRef.current);
        mapsRef.current.event.addListener(polygonMap, "click", e => {});
        polygons.push(polygonMap);
        return true;
      });
    });
    setRegionsPolygon(polygons);
    setOptions(options => {
      return {
        ...options,
        restriction: {
          latLngBounds: {
            north: -25.9557195,
            south: -29.4249416,
            west: -53.8373481,
            east: -48.1107586
          },
          strictBounds: false
        }
      };
    });
  };

  const removeRegionsPolygonFromMap = () => {
    if(regionsPolygon) {
      regionsPolygon.map(polygon => {
        return polygon.setMap(null);
      });
      setOptions(options => {
        return {
          ...options,
          restriction: null
        };
      });
    }
  };

  const generateVirtualFencesPolygonToMap = () => {
    const virtualFencesColor = (type) => {
      switch (type) {
      case "DEF":
        return "#820404";
      case "RES":
        return "#008c23";
      case "RIS":
        return "#000000";
      case "WAI":
        return "#0453A2";
      default:
        return "#820404";
      }
    };

    const polygons = [];
    const newVirtualFences = JSON.parse(JSON.stringify(virtualFences));
    newVirtualFences.map(virtualFence => {
      const vf = virtualFence.virtualFence;
      vf.getBounds = () => {
        const path = vf.location.coordinates[0].map(latLng => new mapsRef.current.LatLng(latLng[1], latLng[0]));
        const pointsBounds = new mapsRef.current.LatLngBounds();
        if (path.length > 0) {
          path.map(rs => pointsBounds.extend(rs));
          return pointsBounds;
        }
      };
      vf.onClick = (e) => {
        mapRef.current.fitBounds(vf.getBounds());
      };
      const path = vf.location.coordinates[0].map(latLng => new mapsRef.current.LatLng(latLng[1], latLng[0]));
      let polygonMap = new mapsRef.current.Polygon({
        path: path,
        strokeColor: virtualFencesColor(vf.type),
        strokeOpacity: 0.5,
        strokeWeight: 1,
        fillColor: virtualFencesColor(vf.type),
        fillOpacity: 0.35,
        indexID: vf
      });
      polygonMap.setMap(mapRef.current);
      mapsRef.current.event.addListener(polygonMap, "click", e => vf.onClick(e));
      polygons.push(polygonMap);
      updateVirtualFences(vf.id, vf.onClick);
      return true;
    });
    setVirtualFencesPolygon(polygons);
  };

  const removeVirtualFencesPolygonFromMap = () => {
    if(virtualFencesPolygon) {
      virtualFencesPolygon.map(polygon => {
        return polygon.setMap(null);
      });
    }
  };

  const generateSingleExportedToMap = () => {
    const polylineBounds = new mapsRef.current.LatLngBounds();
    const polylineCoordsConn = [];
    const polylineCoordsDisc = [];
    let polylineCoordsDiscAux = [];
    singleExported.route.map(routeItem => {
      const { latitude, longitude } = routeItem.tracker;
      if(routeItem.response.status === false) {
        polylineCoordsDiscAux.push(
          new mapsRef.current.LatLng(
            latitude,
            longitude
          )
        );
      }
      else {
        polylineCoordsDisc.push(polylineCoordsDiscAux);
        polylineCoordsDiscAux = [];
      }
      polylineCoordsConn.push({
        lat: latitude,
        lng: longitude
      });
      polylineBounds.extend(
        new mapsRef.current.LatLng(
          latitude,
          longitude
        )
      );
      return true;
    });

    if(polylineCoordsDiscAux.length > 0) {
      polylineCoordsDisc.push(polylineCoordsDiscAux);
      polylineCoordsDiscAux = [];
    }

    let singlePolylineConnBorder = new mapsRef.current.Polyline({
      path: simplifyPath(polylineCoordsConn, 0.00045),
      geodesic: true,
      strokeColor: "#b50e0e",
      strokeWeight: 8
    });

    let singlePolylineConn = new mapsRef.current.Polyline({
      path: simplifyPath(polylineCoordsConn, 0.00045),
      geodesic: true,
      strokeColor: "#f45f5f",
      strokeWeight: 5
    });

    singlePolylineConnBorder.setMap(mapRef.current);
    singlePolylineConn.setMap(mapRef.current);

    let singlePolylineDisc = polylineCoordsDisc.map(itemDisc => {
      if (itemDisc.length > 0) {
        const singlePolylineDisc = new mapsRef.current.Polyline({
          path: simplifyPath(itemDisc, 0.00045),
          geodesic: true,
          strokeColor: "#fca130",
          strokeWeight: 5
        });
        singlePolylineDisc.setMap(mapRef.current);
        return singlePolylineDisc;
      }
      return false;
    });

    setSingleExportedPolyline({
      singleExportedPolylineConnBorder: singlePolylineConnBorder,
      singleExportedPolylineConn: singlePolylineConn,
      singleExportedPolylineDisc: singlePolylineDisc,
      singleExported,
      singleExportedMenuSelected
    });

    if(singleExported.route.length > 0) {
      mapRef.current.fitBounds(polylineBounds);
    }
  };

  const generateSingleRouteToMap = () => {
    const polylineBounds = new mapsRef.current.LatLngBounds();
    const polylineCoordsConn = [];
    const polylineCoordsDisc = [];
    let polylineCoordsDiscAux = [];

    // const route = [[
    //   -53.193123,
    //   -27.06682
    // ],
    // [
    //   -53.18957,
    //   -27.065181
    // ],
    // [
    //   -53.188655,
    //   -27.06496
    // ],
    // [
    //   -53.181502,
    //   -27.065749
    // ],
    // [
    //   -53.17998,
    //   -27.066462
    // ],
    // [
    //   -53.176553,
    //   -27.07011
    // ],
    // [
    //   -53.176157,
    //   -27.070355
    // ],
    // [
    //   -53.175725,
    //   -27.070404
    // ],
    // [
    //   -53.17519,
    //   -27.070221
    // ],
    // [
    //   -53.174856,
    //   -27.0698
    // ],
    // [
    //   -53.174567,
    //   -27.067252
    // ],
    // [
    //   -53.174035,
    //   -27.066458
    // ],
    // [
    //   -53.173223,
    //   -27.065972
    // ],
    // [
    //   -53.170918,
    //   -27.065023
    // ],
    // [
    //   -53.16866,
    //   -27.063126
    // ],
    // [
    //   -53.168149,
    //   -27.063015
    // ],
    // [
    //   -53.167499,
    //   -27.06311
    // ],
    // [
    //   -53.164608,
    //   -27.064487
    // ]]
    //   .map(routeItem => {
    //     const latitude = routeItem[1];
    //     const longitude = routeItem[0];
    //     polylineCoordsDisc.push(polylineCoordsDiscAux);
    //     polylineCoordsDiscAux = [];
    //     polylineCoordsConn.push({
    //       lat: latitude,
    //       lng: longitude
    //     });
    //     polylineBounds.extend(
    //       new mapsRef.current.LatLng(
    //         latitude,
    //         longitude
    //       )
    //     );
    //   });

    singleRoute.route.map(routeItem => {
      const { latitude, longitude } = routeItem.gps.coordinate;
      if(routeItem.gps.apnConnect === "APN" || routeItem.gps.apnConnect === "DIS" || routeItem.gps.apnConnect === "SER") {
        polylineCoordsDiscAux.push(
          new mapsRef.current.LatLng(
            latitude,
            longitude
          )
        );
      }
      else {
        polylineCoordsDisc.push(polylineCoordsDiscAux);
        polylineCoordsDiscAux = [];
      }
      polylineCoordsConn.push({
        lat: latitude,
        lng: longitude
      });
      polylineBounds.extend(
        new mapsRef.current.LatLng(
          latitude,
          longitude
        )
      );
      return true;
    });

    if(polylineCoordsDiscAux.length > 0) {
      polylineCoordsDisc.push(polylineCoordsDiscAux);
      polylineCoordsDiscAux = [];
    }

    let singlePolylineConnBorder = new mapsRef.current.Polyline({
      path: simplifyPath(polylineCoordsConn, 0.00045),
      geodesic: true,
      strokeColor: "#b50e0e",
      strokeWeight: 8
    });

    let singlePolylineConn = new mapsRef.current.Polyline({
      path: simplifyPath(polylineCoordsConn, 0.00045),
      geodesic: true,
      strokeColor: "#f45f5f",
      strokeWeight: 5
    });

    singlePolylineConnBorder.setMap(mapRef.current);
    singlePolylineConn.setMap(mapRef.current);

    let singlePolylineDisc = polylineCoordsDisc.map(itemDisc => {
      if (itemDisc.length > 0) {
        const singlePolylineDisc = new mapsRef.current.Polyline({
          path: simplifyPath(itemDisc, 0.00045),
          geodesic: true,
          strokeColor: "#fca130",
          strokeWeight: 5
        });
        singlePolylineDisc.setMap(mapRef.current);
        return singlePolylineDisc;
      }
      return false;
    });

    setSinglePolyline({
      singlePolylineConnBorder,
      singlePolylineConn,
      singlePolylineDisc,
      singleRoute,
      singleRouteMenuSelected
    });

    if(singleRoute.route.length > 0) {
      mapRef.current.fitBounds(polylineBounds);
    }
  };

  const generateSingleTranshipmentToMap = () => {
    const markersBounds = new mapsRef.current.LatLngBounds();

    singleTranshipment.transhipment.map(transhipmentItem => {
      const { latitude, longitude } = transhipmentItem.gps.coordinate;
      markersBounds.extend(
        new mapsRef.current.LatLng(
          latitude,
          longitude
        )
      );
      return true;
    });

    setSingleTranshipmentMarkers({
      singleTranshipment,
      singleTranshipmentMenuSelected
    });

    if(singleTranshipment.transhipment.length > 0) {
      mapRef.current.fitBounds(markersBounds);
    }
  };

  const removeSingleExportedFromMap = () => {
    if(singleExportedPolyline) {
      const {
        singleExportedPolylineConnBorder,
        singleExportedPolylineConn,
        singleExportedPolylineDisc
      } = singleExportedPolyline;
      singleExportedPolylineConnBorder.setMap(null);
      singleExportedPolylineConn.setMap(null);
      singleExportedPolylineDisc.map(singleExportedPolylineDiscItem => singleExportedPolylineDiscItem ? singleExportedPolylineDiscItem.setMap(null) : false);
    }
  };

  const removeSingleRouteFromMap = () => {
    if(singlePolyline) {
      const {
        singlePolylineConnBorder,
        singlePolylineConn,
        singlePolylineDisc
      } = singlePolyline;
      singlePolylineConnBorder.setMap(null);
      singlePolylineConn.setMap(null);
      singlePolylineDisc.map(singlePolylineDiscItem => singlePolylineDiscItem ? singlePolylineDiscItem.setMap(null) : false);
    }
  };

  const reloadBounds = () => {
    const bounds = getBounds();
    if(bounds) {
      mapRef.current.fitBounds(bounds);
    }
  };

  const toggleTrafficLayer = () => {
    if(mapReady) {
      mapsTrafficRef.current.setMap(mapTraffic ? mapRef.current : null);
    }
  };

  /*
    Cluster
  */
  const points = mapReady ? getPoints() : [];
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: !lastPositionsShowProblems ? 75 : 0,
      maxZoom: __default_cluster_max_zoom__
    }
  });

  const eventPoints = mapReady ? getEventPoints() : [];
  const { clusters: eventClusters, supercluster: eventSupercluster } = useSupercluster({
    points: eventPoints,
    bounds,
    zoom,
    options: {
      radius: 75,
      maxZoom: __default_cluster_max_zoom__
    }
  });

  const exportedPoints = mapReady ? getExportedPoints() : [];
  const { clusters: exportedClusters, supercluster: exportedSupercluster } = useSupercluster({
    points: exportedPoints,
    bounds,
    zoom,
    options: {
      radius: 75,
      maxZoom: __default_cluster_max_zoom__
    }
  });

  return (
    <div className={`map-container ${currentUser ? "" : "blur"}`}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_APP_GOOGLE_MAP_KEY,
          libraries: ["places", "geometry"]
        }}
        defaultCenter={__default_center__}
        defaultZoom={__default_zoom__}
        yesIWantToUseGoogleMapApiInternals
        options={{
          disableDefaultUI: true,
          mapTypeId: getMapTypeId({ type: options.mapTypeId }),
          minZoom: options.minZoom,
          maxZoom: options.maxZoom,
          restriction: options.restriction,
          styles: options.styles
        }}
        onGoogleApiLoaded={({ map, maps, places }) => {
          mapRef.current = map;
          mapsRef.current = maps;
          mapsTrafficRef.current = new maps.TrafficLayer();
          mapsTrafficRef.current.setMap(mapTraffic ? mapRef.current : null);
          setMapReady(true);
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}>
        {
          clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = cluster.properties;
            if (isCluster) {
              return (
                <PatrimonyClusterMarker
                  key={`patrimony-cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                  size={(20 + (pointCount / points.length) * 20)}
                  count={pointCount}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      __default_max_zoom__
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                />
              );
            }
            return (
              <PatrimonyMarker
                key={`position-${cluster.properties.position.id}`}
                lat={latitude}
                lng={longitude}
                onClick={() => {
                  removeSingleExported();
                  removeSingleRoute();
                  removeSingleTranshipment();
                  selectLastPosition(cluster.properties.position.id);
                }}
                patrimony={cluster.properties.position.position.patrimony}
                position={cluster.properties.position.position}
                profile={{
                  currentUser,
                  miniMarkers: mapMiniMarkers
                }}
                selected={cluster.properties.position.selected}
              />
            );
          })
        }
        {
          eventClusters.map((eCluster, eIndex) => {
            const [longitude, latitude] = eCluster.geometry.coordinates;
            const eType = () => getEventsSource().id;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = eCluster.properties;
            if (isCluster) {
              return (
                <EventClusterMarker
                  key={`event-cluster-${eCluster.id}`}
                  lat={latitude}
                  lng={longitude}
                  size={(20 + (pointCount / eventPoints.length) * 20)}
                  count={pointCount}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      eventSupercluster.getClusterExpansionZoom(eCluster.id),
                      __default_max_zoom__
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                  eType={eType}
                />
              );
            }
            return (
              <EventMarker
                key={`event-position-${eCluster.properties.position.id}`}
                lat={latitude}
                lng={longitude}
                onClick={() => {
                  mapRef.current.setZoom(__default_max_zoom__);
                  mapRef.current.panTo({ lat: latitude, lng: longitude });
                }}
                eType={eType}
                ePosition={eCluster.properties.position}
                profile={{
                  currentUser,
                  miniMarkers: mapMiniMarkers
                }}
              />
            );
          })
        }
        {
          exportedClusters.map((exCluster, eIndex) => {
            const [longitude, latitude] = exCluster.geometry.coordinates;
            const exType = () => getExportedSource().id;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = exCluster.properties;
            if (isCluster) {
              return (
                <ExportedClusterMarker
                  key={`exported-cluster-${exCluster.id}`}
                  lat={latitude}
                  lng={longitude}
                  size={(20 + (pointCount / eventPoints.length) * 20)}
                  count={pointCount}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      exportedSupercluster.getClusterExpansionZoom(exCluster.id),
                      __default_max_zoom__
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                  exType={exType}
                />
              );
            }
            return (
              <ExportedMarker
                key={`exported-position-${exCluster.properties.position.id}`}
                lat={latitude}
                lng={longitude}
                onClick={() => {
                  mapRef.current.setZoom(__default_max_zoom__);
                  mapRef.current.panTo({ lat: latitude, lng: longitude });
                }}
                exType={exType}
                exPosition={exCluster.properties.position}
                profile={{
                  currentUser,
                  miniMarkers: mapMiniMarkers
                }}
              />
            );
          })
        }
      </GoogleMapReact>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,

    lastPositions: state.lastPositions.positions,
    lastPositionsSearch: state.lastPositions.positionsSearch,
    lastPositionsSearchQueryEnabled: state.lastPositions.positionsSearchQueryEnabled,
    lastPositionSelected: state.lastPositions.positionSelected,
    lastPositionsLoad: state.lastPositions.positionsLoad,

    lastPositionsProblems: state.lastPositionsProblems.positions,
    lastPositionsSearchProblems: state.lastPositionsProblems.positionsSearch,
    lastPositionsSearchQueryEnabledProblems: state.lastPositionsProblems.positionsSearchQueryEnabled,
    lastPositionSelectedProblems: state.lastPositionsProblems.positionSelected,
    lastPositionsLoadProblems: state.lastPositionsProblems.positionsLoad,
    lastPositionsShowProblems: state.lastPositionsProblems.positionShow,

    // lastPositionProblem: state.lastPositionProblem.positionProblems,
    // lastPositionProblemLoad: state.lastPositionProblem.positionsLoad,
    // lastPositionProblemShow: state.lastPositionProblem.positionShow,

    regions: state.regions.regions,
    singleExported: state.exporteds.singleExported,
    singleExportedMenuSelected: state.exporteds.singleExportedMenuSelected,
    singleExportedMenuPositionsMarkersSelected: state.exporteds.singleExportedMenuPositionsMarkersSelected,
    singleRoute: state.routes.singleRoute,
    singleRouteMenuSelected: state.routes.singleRouteMenuSelected,
    singleRouteMenuPositionsMarkersSelected: state.routes.singleRouteMenuPositionsMarkersSelected,
    singleTranshipment: state.transhipments.singleTranshipment,
    singleTranshipmentMenuSelected: state.transhipments.singleTranshipmentMenuSelected,
    singleTranshipmentMenuPositionsMarkersSelected: state.transhipments.singleTranshipmentMenuPositionsMarkersSelected,
    virtualFences: state.virtualFences.virtualFences,
    virtualFencesLoad: state.virtualFences.virtualFencesLoad
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, ExportedsActions, LastPositionActions, LastPositionsProblemsActions, RoutesActions, TranshipmentsActions, VirtualFencesActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Map);
