import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";

const DEFAULT_ZINDEX = 5000;
const SCALE_HOVER = 1;
const SCALE_NORMAL = 1;

const Diagnosisluster = ({ count, size, onClick, eType }) => {
  const { t } = useTranslation();
  const [diagnosisHover, setEventHover] = useState(false);
  const [scale, setScale] = useState(SCALE_NORMAL);
  const [sizeBox] = useState("30px");
  const [sizeBorder] = useState("40px");
  const [zIndex, setZIndex] = useState(DEFAULT_ZINDEX);

  useEffect(() => {
    setScale(diagnosisHover ? SCALE_HOVER : SCALE_NORMAL);
    setZIndex(Math.round(scale * DEFAULT_ZINDEX) + (diagnosisHover ? DEFAULT_ZINDEX : 0));
  }, [diagnosisHover, scale]);

  const getDiagnosislusterType = () => {
    const eTypes = [
      {
        id: "route",
        title: t("Title.Diagnosis"),
        icon: <i className="fa-solid fa-stethoscope" />
      }
    ];

    const eTypeFilter = eTypes.filter(x => x.id === eType());
    if(eTypeFilter.length > 0) {
      return eTypeFilter[0];
    }
    return false;
  };

  const diagnosisDetails = getDiagnosislusterType();
  return (
    <div className="marker-diagnosis-cluster-all"
      onClick={onClick}
      onMouseEnter={e => setEventHover(true)}
      onMouseLeave={e => setEventHover(false)}
      style={{
        width: sizeBorder,
        height: sizeBorder,
        transform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        MozTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        WebkitTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        zIndex: zIndex
      }}
    >
      <div
        className="marker-diagnosis-cluster"
        style={{ width: sizeBox, height: sizeBox }}
      >
        <div className="icon">{diagnosisDetails.icon}</div>
        <div className="count">{count}</div>
      </div>
    </div>
  );
};

export default memo(Diagnosisluster);
