import React from "react";
import "./index.css";

import SwitchVirtualFence from "../Switch/VirtualFence";

const FilterVirtualFenceItem = ({ checkbox = true, name, virtualFence, onClick, selected } = {}) => {
  return (
    <li className={"filter-virtual-fence-list-item"} onClick={onClick}>
      <SwitchVirtualFence checkbox={checkbox} name={name} virtualFence={virtualFence} selected={selected} />
    </li>
  );
};

export default FilterVirtualFenceItem;
